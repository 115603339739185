const INIT_STATE = {
   
    header: "#ffffff",
    background: "#ffffff",
    links: "#ffffff",
    buttons: "#ffffff",
    button_text: "#ffffff",
}
const customThemeColorReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_CUSTOM_COLOR':
            return action.payload 
        case 'RESET_CUSTOM_COLOR':
            return INIT_STATE
        default:
            return state
    }

}
export default customThemeColorReducer