const INIT_STATE = {user: null, from: "login"}
const loginReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_USER':
            return action.payload 
        case 'LOGOUT':
            return INIT_STATE
        default:
            return state
    }

}
export default loginReducer