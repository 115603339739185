const INIT_STATE = {
    emailSignupUrl: null,
    contactFormUrl: null,
    productSaleUrl: null,
    tab: "integrations"
}
const integrationZapierReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_ZAPIER_INTEGRATION':
            return action.payload 
        case 'RESET_ZAPIER_INTEGRATION':
            return INIT_STATE
        default:
            return state
    }
}
export default integrationZapierReducer