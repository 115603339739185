import { Modal } from "react-bootstrap"
import folderIcon from "../../../../assets/img/modals/folder-icon.svg"
import { useEffect, useState } from "react"
import ModalErrors from "../../../errors/modal-errors"

const CreateFolderModal = 
({ show, handleHide, returnIcon, handleSubmit, slug, error, setfolderModal, folderModal }) => {

    const [value, setValue] = useState({
        name: null,
    });

    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        setValue({...value, name: folderModal?.name})
    },[folderModal])

    const heading = folderModal ? "Update link folder" : "Create a link folder";
    
// console.log(heading);
    return (
        <Modal centered show={show} onHide={!folderModal?.show ?  handleHide : ()=>{
            setfolderModal({
                ...folderModal,
                show: !folderModal?.show,
            })
        }} id="createFolderModal">
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content">

                    <div className="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e, value, folderModal?.show ?  folderModal?.id : "folder", true, )
                        }}>
                            <div className="notification-container"></div>


                            <div className="form-group">
                                <img src={folderIcon} width="30px" alt="" />
                                <h4 className="mt-2 mb-1">{heading}</h4>
                                <p className="text-gray font-weight-500">Organise your related links into folders.</p>
                            </div>

                            <div className="form-group">
                                <label><small>Title:</small></label>
                                <input type="text" value={value?.name} onChange={handleChange}
                                    className={`form-control zaap-form-control`} name="name" required="required" placeholder="My Links" />
                           
                           {error?.map((val, index) => <ModalErrors text={val} />)}
                            </div>


                            <div className="text-center mt-4 d-flex">
                                <button type="button" onClick={!folderModal?.show ?  handleHide : ()=>{
                                        setfolderModal({
                                            ...folderModal,
                                            show: !folderModal?.show,
                                        })
                                }}
                                    className="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b className="me-2">Cancel</b>
                                    <small className="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" className="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b className="me-2">Save Changes</b>
                                    <small className="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default CreateFolderModal