const INIT_STATE = {available_pros: 0, total_pros: 0}
const billingsReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_BILLINGS':
            return action.payload 
        case 'RESET_BILLINGS':
            return INIT_STATE
        default:
            return state
    }

}
export default billingsReducer