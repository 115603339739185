const INIT_STATE = {
    
}
const editLinkModal = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_EDIT_LINK_MODAL':
            return action.payload 
        case 'RESET_EDIT_LINK_MODAL':
            return INIT_STATE
        default:
            return state
    }

}
export default editLinkModal