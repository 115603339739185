import React from 'react'
import { useState } from "react"
import { Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import returnIcon from "../../assets/img/modals/return.svg"
import ErrorMessage from '../errors/errorMessage'
import { hideLoader, showLoader } from '../loader'
import ConfirmationModal from '../confirmation-modal'
import { audiencePosts as deleteUser } from '../../services/postRequests/audience'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { apiUrl } from '../../utils/url'
import { useAlert } from '../../contexts/alertContext'
const DeleteAccount = () => {
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null });
    const [error, setError] = useState([])
    let history = useHistory();
    const [password, setPassword] = useState(null)
    const { setAlert } = useAlert();

    const confirmation = () => {
        if (password) {
            setconfirmationModalShow({show: true, text: "Are you sure you want to delete this Account?", callBackTo: "delete" })
        }else{
            setError(["Please Enter Password"])
        }
    }
    const deleteAccount = () => {
            const api = apiUrl + "/account/delete";
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            let formData = new FormData();
            formData.append("password",password); 
            const setOptions = {
                method: "POST",
                body: formData,
                headers: myHeaders,
            }
            deleteUser(null, api, hideLoader, setOptions, "custom_message").then((response) => {
                setconfirmationModalShow({ show: false, text: null, id: null })
                if (response?.status_code === 200) {
                    setAlert({ type: 'success', title: response?.status_message })
                    setTimeout(() => {
                        localStorage.clear()
                        window.location.pathname = "/login";
                    }, 200);
                }else {
                    toast.error(response?.status_message)
                }
            }).catch((err) => {
                setconfirmationModalShow({ show: false, text: null, id: null })
            })
    }
  return (
    <section class="container h-100  account-form" style={{overflowY:"auto"}}>
        <div className="container settings-section"style={{paddingLeft: "30px",paddingRight: "30px",paddingBottom: "30px"}}>
                <ErrorMessage error={error} />
                <div>
                    <div class="">
                        <span class="acount-header settings-section-heading">Delete Account</span>
                        <div class="form-group mt-3">
                            <label htmlFor="password" style={{marginBottom:"5px"}}>Enter Password</label>
                            <input type="password" id="password" name="password" placeholder='Current Password' class="form-control" onChange={(e)=>setPassword(e.target.value)}/>
                        </div>
                        <button type="submit" name="submit" onClick={confirmation} style={{marginTop:"20px"}}
                            className="btn-primary settings-button-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                            <span>Delete Account</span>
                            <small className="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                <img src={returnIcon} alt="" />
                            </small>
                        </button>
                    </div>
                </div>
            </div>
            {confirmationModalShow?.show && <ConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} callback={deleteAccount}/>} 
        </section>

  )
}

export default DeleteAccount
