import React from 'react';
import returnIcon from "../../../assets/img/modals/return.svg";
import dragIcon from "../../../assets/img/page/drag-icon.svg";
// import * as socialsOptions from "../../../utils/socials.json"
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../loader";
import { apiUrl } from "../../../utils/url";
import Teammoadal from '../../teammember/team_modal';
import { matchObject } from "../customize-page";

const FontsCustomize = ({ handleSubmit, setShowSaveChanges, }) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state?.customizeFontReducer)
    const [fontFormState, setFontFormState] = useState(value );
    const { slug } = useSelector(state => state?.slugReducer);
    const fonts = useSelector(state => state?.allFontsReducer)
    const pro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const [showModalasd, setShowModalasd] = useState(false)
    const [timer, setTimer] = useState(null);
    const [countChange, setCountChange] = useState(0);

    useEffect(() => {
        if (!pro) {
            setShowModalasd(true);
        }else {
            setShowModalasd(false);
        }
    }, [pro]);

    // useEffect(() => {
    //     if (window.location.pathname.includes("customizePageFonts")) {
    //         if (slug) {
    //             showLoader()
    //             var myHeaders = new Headers();
    //             myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
    //             var requestOptions = {
    //                 method: 'GET',
    //                 headers: myHeaders
    //             };
    //             fetch(apiUrl + `/project/get-fonts/${slug}`, requestOptions)
    //                 .then(async (res) => {
    //                     const response = await res?.json();
    //                     if (response?.status_code == 200) {
    //                         setFonts(response?.data)
    //                         hideLoader();
    //                     }
    //                 }).catch((err) => {
    //                     hideLoader();
    //                 })
    //         }
    //     }
    // }, [window.location.pathname])
    const handleChange = (e) => {
        if (pro) {
            dispatch({ type: "SET_CUSTOMIZE_FONT", payload: {font_family: e.target.value } })
            dispatch({ type: "SET_All_FONTS", payload: {...fonts,selected_font:e.target.value} })
        }
    };
    function changeDelay() {
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
        }
        setTimer(
          setTimeout(() => {
            handleSubmit(value,  "custom_fonts");
          }, 3000)
        );
    };
    useEffect(()=>{
        if (countChange > 0) {
            if (value && fontFormState && !matchObject(value, fontFormState)) {
                // changeDelay();
                setShowSaveChanges(prevState => ({
                    ...prevState,
                    show: true,
                    formId: prevState.formId.includes("fontValue")
                        ? prevState.formId
                        : [...prevState.formId, "fontValue"]
                }));
            } else {
                setShowSaveChanges(prevState => ({
                    ...prevState,
                    show: true,
                    formId: prevState.formId.filter(form => form !== "fontValue")
                }));
            }
        } else {
            setCountChange(1);
            return;
        }
    },[value])
    const renderFonts = () => {
        const fontGroups = [];
        const chunkSize = 3;
    
        for (let i = 0; i < fonts?.all_fonts?.length; i += chunkSize) {
            const fontChunk = fonts?.all_fonts?.slice(i, i + chunkSize);
            const fontGroup = (
                <div className={`customize-font-tabs up-in-toggle bg-f1f1f1 btn-semi-rounded`} style={{ padding: "5px 3px"}} key={i}>
                    {fontChunk.map((font) => (
                        <React.Fragment key={font.id}>
                            <input type="radio" id={`font_${font?.id}`} name="font_family" disabled={!pro} onChange={handleChange} checked={pro ? ( font?.title === fonts?.selected_font ? true : false) : (font?.title == 'Default'? true : false)} value={font?.title} style={{ display: "none" }} />
                            <label htmlFor={`font_${font?.id}`} className="btn-semi-rounded newslet0ter-btn-semi-rounded" style={{width:"33%"}}> <span> {font?.title}</span></label>
                        </React.Fragment>
                    ))}
                </div>
            );
    
            fontGroups.push(fontGroup);
        }
    
        return fontGroups;
    };
    return (
        <div class="tab-pane fade customize-page-main active show position-relative" id="customizePageFonts" role="tabpanel" aria-labelledby="font-tab">
            <h4 class="mb-2 font-weight-700" style={{  fontSize: '22px' }}>Edit Fonts</h4>
            <section>
                <form className={`${!pro && 'd-none'}`} action="" onSubmit={(e) => {
                        e.preventDefault()
                        // handleSubmit(value, "custom_fonts")
                    }}>
                <div className='customize-font-tabs-background'>
                    {/* <div class="customize-font-tabs customize-font-tabs-first-row up-in-toggle bg-f1f1f1 btn-semi-rounded" style={{ padding: "5px 3px" }}>
                        {fonts?.all_fonts?.map((font) => (
                            <React.Fragment key={font.id}>
                                <input type="radio" id={`font_${font?.id}`} name="font_family" onChange={handleChange} defaultChecked={font?.title === fonts?.selected_font ? true : false} value={font?.title} style={{ display: "none" }} />
                                <label for={`font_${font?.id}`} class="btn-semi-rounded newslet0ter-btn-semi-rounded " > <span> {font?.title}</span></label>
                            </React.Fragment>
                        ))}
                    </div> */}
                    {renderFonts()}
                </div>
                {/* <div class="text-center mt-4 d-flex submit-buttons-fixed">
                    <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                        <b class="me-2">Save Changes</b>
                        <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                            <img src={returnIcon} alt="" />
                        </small>
                    </button>
                </div> */}
                </form>
            {showModalasd && <Teammoadal showModal={showModalasd} text={'Font is a Pro feature. <br /> Upgrade to Zaap Pro to unlock this feature.'} isUltra={false}/>}
            </section>
        </div >
    )
}
export default FontsCustomize