const INIT_STATE = {
    tab: "seo",
    page_title: null,
    page_description: null,
    block_indexing: 0,
    imageData: null,
}
const customizeSeoReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_CUSTOMIZE_SEO':
            return action.payload 
        case 'RESET_CUSTOMIZE_SEO':
            return INIT_STATE
        default:
            return state
    }
}
export default customizeSeoReducer