import React, { useEffect, useState } from 'react'
import { hideLoader, showLoader } from '../loader';
import { toast } from 'react-toastify';
import { apiUrl } from '../../utils/url';
import * as $ from "jquery";
import { useAlert } from '../../contexts/alertContext';

function BioStep({ moveNext, moveBack }) {
    const [error, setError] = useState([])
    const [value, setvalue] = useState({"bioText":null,step:4})
    const { setAlert } = useAlert();
    useEffect(() => {
        try {
            const text = JSON.parse(localStorage?.backStepValue);
            if (text) {
                setvalue({...value, bioText: (text?.bioText !== "null" && text?.bioText !== "undefined") ? text?.bioText : null});
            }
        } catch { }
    }, [localStorage?.backStepValue]);
    const onSubmit = (e, from, values) => {
        showLoader();
        setError([])
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData();
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                formdata.append(key, values[key]);
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/walkthrough", requestOptions)
            .then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    if (from == "next" || from == "skip") {
                        setAlert({ type: 'success', title: response?.status_message})
                        // toast.success(response?.status_message)
                        moveNext(e, 5)
                    }
                    else if (from == "back") {
                        moveBack(e, response)
                        hideLoader()
                    }
                }
                else {
                    setError(response?.errors)
                    setAlert({ type: 'error', title: response?.status_message})
                    // toast.error(response?.status_message)
                }

            }).catch((err) => {
                hideLoader()
            })

    }
    useEffect(()=>{
        generateBio();
    },[value?.step])
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(e, "next", value)
    }
    const generateBio = () => {

        showTypingLoader();
        setError([])
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(apiUrl + "/generate-bio", requestOptions)
            .then(async (res) => {
                hideTypingLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    setvalue({ ...value, ["bioText"]: response?.data?.content });
                    // toast.success(response?.status_message)
                    setAlert({ type: 'success', title: response?.status_message})

                }
                else {
                    setError(response?.errors)
                    setAlert({ type: 'error', title: response?.status_message})
                    // toast.error(response?.status_message)
                }

            }).catch((err) => {
                hideTypingLoader()
            })
    }
    const handleBioChange = (event) => {
        setvalue({ ...value, ["bioText"]: event.target.value });
    };
    const showTypingLoader = () => {
        // Show loader in textarea
        $("#bioTextarea").addClass('d-none');
        $(".typing-loader").removeClass('d-none');
    };

    const hideTypingLoader = () => {
        $("#bioTextarea").removeClass('d-none');
        $(".typing-loader").addClass('d-none');
    };
  return (
    <div class="background-area">
        <div class="steps-card">
            <div class="counter">3</div>
            <h2 class="card-head2">Lets write a short bio</h2>
            <p class="card-para">Page Description</p>
            <div class="discription-area-box">
                <div class="typing-loader d-none"></div>
                <textarea id="bioTextarea" value={value?.bioText} onChange={handleBioChange}></textarea>
                <button class="regen-blue-btn2-u" onClick={generateBio}>Re-Generate</button>
            </div>
            <div class="bottom-btn-area">
                <button class="red-btn-u" onClick={(e)=>handleSubmit(e)}>
                    Next Step
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none"><path d="M2.85366 8C3.12287 8 3.30511 7.80467 3.30511 7.53715C3.30511 7.39703 3.25127 7.29512 3.16843 7.21019L2.11643 6.15711L1.39162 5.53291L2.34837 5.57537H7.51726C8.55683 5.57537 9 5.09554 9 4.04246V1.52442C9 0.458599 8.55683 0 7.51726 0H5.21859C4.9411 0 4.74643 0.212314 4.74643 0.475584C4.74643 0.734607 4.9411 0.946921 5.21445 0.946921H7.49655C7.91072 0.946921 8.08882 1.12951 8.08882 1.55414V4.01274C8.08882 4.45011 7.91072 4.62845 7.49655 4.62845H2.34837L1.39162 4.67516L2.11643 4.04671L3.16843 2.99788C3.25127 2.91295 3.30511 2.80679 3.30511 2.66667C3.30511 2.4034 3.12287 2.20807 2.85366 2.20807C2.73769 2.20807 2.6093 2.26327 2.51818 2.35669L0.144961 4.75159C0.0497009 4.84501 0 4.9724 0 5.10403C0 5.23142 0.0497009 5.35881 0.144961 5.45223L2.51818 7.85138C2.6093 7.9448 2.73769 8 2.85366 8Z" fill="white"/></svg></span>
                </button>   
                <button class="gray-btn-u"
                onClick={(e) =>
                        onSubmit(e, "skip", {
                    skip: 1,
                    step: e.target.dataset.step
                })}
                data-step="4">
                    Skip
                </button>
            </div>
        </div>
    </div>
  )
}

export default BioStep