import { useEffect } from "react";
import { showLoader } from "../components/loader";
const StripeConnectReauth = () => {
    useEffect(() => {
        showLoader()
        window.opener.postMessage({
            stripe_error: "Stripe Connection Unsuccessful"
        })
        setTimeout(() => {
            window.close()
        }, 1000);

    }, [])
    return (
        <div class="full-page-loader">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    )
}
export default StripeConnectReauth