import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import lifetimeEarnings from "../../assets/img/lifetime-earning.svg"
import totalReferrals from "../../assets/img/total-referral.svg"
import monthlyRevenue from "../../assets/img/monthl-revenue.svg"
import returnIcon from "../../assets/img/modals/return.svg"
import { audiencePosts as getData } from "../../services/postRequests/audience";
import { apiUrl, app, appUrl, appUrlUrlappUrl } from '../../utils/url';
import { hideLoader } from '../loader';
import AffiliatePayoutModal from '../modals/affiliate-payout-modal';
import { useAlert } from '../../contexts/alertContext';

function AffiliateProgram({ setActivePage }) {
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [payoutValues, setPayoutValues] = useState(false);
    const { setAlert } = useAlert();
    const copyingText = () => {
        navigator.clipboard
            .writeText(`${appUrl}/create-url?ref=${data?.referrer_name}`)
            .then((r) =>setAlert({ type: 'success', title: "Successfully Copied"}));
    };
    const callData = () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const api = apiUrl + `/affiliate/get-referrels`;
        const setOptions = {
            method: "GET",
            headers: myHeaders
        };
        getData(null, api, hideLoader, setOptions).then((response) => {
            if (response) {
                setData(response)
            }
        })
    }
    useEffect(()=>{
        callData();
    },[]);
    const showPayoutModal = (payoutType) => {
        setPayoutValues({
            amount:data?.unpaid_earnings,
            referrer_id:data?.referrer_id,
            payout_type:[payoutType]
        })
        setShow(true)

    }
    return (
        <section className="up-cont-u affiliate-program-page">
            <div className="settings-section affiliate-program-page-container">
                <div className="affiliate-program-page-heading" >Share Zaap. Get Paid.</div>
                <div className="affiliate-program-page-desc">Refer friends and earn a commission when they subscribe.</div>
                <div className="account-form">
                    <div className="notification-container"></div>
                    <div className="domain-steps-label" style={{ marginTop: "17px" }}>
                        Your Unique URL
                    </div>
                    <div className="d-flex justify-content-between align-items-end">
                        <div className="form-group mt-3 w-100" style={{ marginRight: "8px" }}>
                            <div className={`form-control`} >
                            {appUrl}/create-url?ref={data?.referrer_name}
                            </div>
                        </div>

                        <button onClick={copyingText} className="btn text-nowrap settings-connect-btn btn-primary btn-semi-rounded">
                            Copy URL
                        </button>
                    </div>
                </div>
            </div>
            <div className="settings-section affiliate-program-page-container">
                <div className="affiliate-program-page-heading" >Analytics</div>
                <div className="affiliate-analytics d-flex gap-2 mt-3">
                    <div className='affiliate-program-page-analytics'>
                        <img src={lifetimeEarnings} alt="" />
                        <div>
                            <div className='affiliate-analytics-heading'>Lifetime Earnings</div>
                            <div className='affiliate-analytics-stats'>${data?.lifetime_earnings ?? 0}</div>
                        </div>
                    </div>
                    <div className='affiliate-program-page-analytics'>
                        <img src={totalReferrals} alt="" />
                        <div>
                            <div className='affiliate-analytics-heading'>Total Referrals</div>
                            <div className='affiliate-analytics-stats'>{data?.total_referrals ?? 0}</div>
                        </div>
                    </div>
                    <div className='affiliate-program-page-analytics'>
                        <img src={monthlyRevenue} alt="" />
                        <div>
                            <div className='affiliate-analytics-heading'>Monthly Revenue</div>
                            <div className='affiliate-analytics-stats'>${data?.monthly_revenue ?? 0}</div>
                        </div>
                    </div>
                </div>
                <div className="affiliate-program-page-heading fs-14px mt-4" >Recent Referrals</div>
                <div className='recent-referrrals'>
                    <div className='recent-referral-tabs recent-referral-header'>
                        <div className='d-flex'>
                            <div>
                                <label className="custom-checkbox checkbox">
                                    <input type="checkbox" name="select-all"/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div>Customer</div>
                        </div>
                        <div className='recent-referral-last-column-heading'>
                            <div>Monthly Revenue</div>
                            <div className='recent-referral-last-column'>Date</div>
                        </div>
                    </div>
                    {data?.recent_referrals?.map((val) => (
                    <>
                        <div className='recent-referral-tabs recent-referral-body'>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <label className="custom-checkbox checkbox">
                                        <input type="checkbox" name="select-all"/>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className='d-flex gap-2'>
                                    <div className="name-icon">{val?.referrals?.name?.match(/\b(\w)/g)?.join("")}</div>
                                    <div>
                                        <div>{val?.referrals?.name}</div>
                                        <div>{val?.referrals?.email}</div>
                                    </div>

                                </div>
                            </div>
                            <div className='recent-referrals-end-area'>
                                <div className='referral-monthly-revenue'>${val?.monthly_revenue}</div>
                                <div className='recent-referral-last-column'>{new Date(val?.referrals?.created_at)?.toLocaleDateString('en-GB')}</div>
                            </div>
                        </div>
                        <div className='border-grey mt-10px'></div>
                    </>
                    ))
                    }
                    
                </div>
            </div>
            <div className="settings-section affiliate-program-page-container">
                <div className="affiliate-program-page-heading" >Payouts</div>
                <div className='mt-3 affiliate-payout'>
                    <div className='affiliate-payout-header'>
                        Unpaid Earnings
                    </div>
                    <div className='affiliate-payout-main-earning'>
                        <div className='affiliate-payout-box'>$</div>
                        {data?.unpaid_earnings && data?.unpaid_earnings != null && data?.unpaid_earnings > 0 ? (
                            data?.unpaid_earnings?.toString()?.padStart(6, '0')?.split('')?.map((digit, index) => (
                                <div key={index} className='affiliate-payout-box'>{digit}</div>
                            ))
                        ) : (
                            Array.from({ length: 6 }).map((_, index) => (
                                <div key={index} className='affiliate-payout-box'>0</div>
                            ))
                        )}
                    </div>
                    <div className='affiliate-payout-gradient'></div>
                </div>
                <div className='affiliate-cash-out justify-content-between flex-wrap'>
                    <div>Cash out or donate if you’re feeling generous!</div>
                    <div className='d-flex gap-2'>
                        <button type="button" disabled={data?.unpaid_earnings < 15} onClick={()=>showPayoutModal('cash')} className="settings-button-primary btn-primary ">
                            Cash Out          
                            <small><img src={returnIcon} alt="" /></small>
                        </button>
                        <button type="button" disabled={data?.unpaid_earnings < 15} onClick={()=>showPayoutModal('donate')} className="btn-grey settings-button-primary">
                            Donate to Charity
                        </button>
                    </div>
                </div>
            </div>
            <div className="settings-section affiliate-program-page-container">
                <div className="affiliate-program-page-heading" >Affiliate Program Details</div>
                <div className='affiliate-cash-out affiliate-program-details'>
                    <div className='box-primary'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M8 11.5363V8.73627M8 5.93627H8.007M15 8.73627C15 12.6023 11.866 15.7363 8 15.7363C4.13401 15.7363 1 12.6023 1 8.73627C1 4.87027 4.13401 1.73627 8 1.73627C11.866 1.73627 15 4.87027 15 8.73627Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div>Earn 25% of the monthly revenue from each user you refer</div>
                </div>
                <div className='affiliate-cash-out affiliate-program-details'>
                    <div className='box-primary'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M8 11.5363V8.73627M8 5.93627H8.007M15 8.73627C15 12.6023 11.866 15.7363 8 15.7363C4.13401 15.7363 1 12.6023 1 8.73627C1 4.87027 4.13401 1.73627 8 1.73627C11.866 1.73627 15 4.87027 15 8.73627Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div>$15 minimum payout</div>
                </div>
                <div className='affiliate-cash-out affiliate-program-details'>
                    <div className='box-primary'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M8 11.5363V8.73627M8 5.93627H8.007M15 8.73627C15 12.6023 11.866 15.7363 8 15.7363C4.13401 15.7363 1 12.6023 1 8.73627C1 4.87027 4.13401 1.73627 8 1.73627C11.866 1.73627 15 4.87027 15 8.73627Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div>60 Day cookie period</div>
                </div>
                <a href='https://www.zaap.ai/admin/brand' className='affiliate-cash-out affiliate-program-details'>
                    <div className='box-primary'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M1.5 10.3667L10.5 1.3667M10.5 1.3667H4.5M10.5 1.3667V7.3667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div>Brand Assets</div>
                </a>
                <a href='https://www.zaap.ai/admin/terms' className='affiliate-cash-out affiliate-program-details'>
                    <div className='box-primary'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M1.5 10.3667L10.5 1.3667M10.5 1.3667H4.5M10.5 1.3667V7.3667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div>Terms and conditions</div>
                </a>
            </div>
            <AffiliatePayoutModal show={show} setShow={setShow} payoutValues={payoutValues} callData={callData}/>
        </section>
    )
}

export default AffiliateProgram