import React from "react";
import { Modal } from "react-bootstrap";
import thundaricon from "../../assets/img/products/thunder-icon.svg"
import { useSelector } from "react-redux";



export const PostsUpgradeModal = ({ showModal, upgradeHandleClick, setshowModal }) => {
    const isPro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const closeModal = () => setshowModal(false);
    return (
        <div class="upgradeTeamModal" style={{zIndex:9999}} onClick={closeModal}>
            <div id="upgradeTeamModal">
                <div className="" role="document">
                    <div className="modal-content modal-semi-curve" style={{ boxShadow: "0px 0px 29px rgba(48, 49, 62, 0.12)", border: 'none' }}>

                        <div className="modal-body">

                            <form name="create_folder" method="post" role="form" >
                                <div className="notification-container"></div>


                                <div className="form-group">
                                    <img src={thundaricon} width="40px" alt="" />
                                    <div className="mt-3">
                                        <h4>Upgrade to {!newUser ? 'keep': ''} posting.</h4>
                                        <p className="text-gray font-weight-500">
                                        {newUser ? <>You have to publish your page first  to  send <br /> newsletter/posts.</>
                                        :
                                        <>You’ve reached the max audience size for the <br /> free plan. Upgrade to Pro to keep posting.</>}
                                        </p>
                                    </div>
                                </div>
                                <div class="text-center mt-4 d-flex">
                                    <button
                                        type="button"
                                        onClick={upgradeHandleClick}
                                        className="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center"
                                    >
                                        <b className="me-2">Upgrade</b>
                                    </button>
                                    {newUser == 1 && 
                                        <>
                                            <button type="button" class={`close btn btn-grey btn-semi-rounded d-flex align-items-center justify-content-center  ms-2`} onClick={closeModal}>
                                                <b class="me-2">Cancel </b>
                                            </button>
                                        </>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}