const INIT_STATE = false
const accountsModalReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_ACCOUNTS_MODAL':
            return action.payload 
        case 'RESET_ACCOUNTS_MODAL':
            return INIT_STATE
        default:
            return state
    }

}
export default accountsModalReducer