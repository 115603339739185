import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader, { hideLoader, showLoader } from "../components/loader";
import authRoutes from "../routes/authRoutes";
import routes from "../routes/mainRoutes"

const NotFound = () => {

    return (
        <> <p>not found</p>
        </>
    )
}
export default NotFound