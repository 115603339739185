


import { Modal } from "react-bootstrap"
import youtubeIcon from "../../../../assets/img/modals/youtube-icon.svg"
import ModalErrors from "../../../errors/modal-errors"
import { useEffect, useState } from "react"

const CreateYoutubeWidgetModal =
    ({ show, handleHide, returnIcon, handleSubmit, slug, error, youtubeModal, setyoutubeModal }) => {

        const [value, setValue] = useState({
            title: null,
            channel_url: null,
            no_of_videos: null,
            content_type: 'all_content',
            show_premiered_video: false,
        });

        useEffect(() => {
            setValue({ ...value, title: youtubeModal?.name, no_of_videos: youtubeModal?.details?.no_of_videos, channel_url: youtubeModal?.details?.channel_url })
        }, [youtubeModal])
        const heading = youtubeModal ? "Update your latest YouTube videos" : "Sync your latest YouTube videos";

        const handleChange = (e) => setValue({ ...value, [e.target.name]: e.target.value });
        const handleDropdownChange = (e) => {
            const newValue = e.target.getAttribute('value');
            const name = e.target.getAttribute('name');
            setValue({ ...value, [name]: newValue });
        };
        return (
            <Modal centered show={show} onHide={!youtubeModal ? handleHide : () => {
                setyoutubeModal(!youtubeModal)
            }} id="createYoutubeWidgetModal">
                <div class="modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-body">

                            <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e, value, youtubeModal?.show ? youtubeModal?.id : "youtube", true,)
                            }}>
                                <div className="notification-container"></div>


                                <div className="form-group">
                                    <img src={youtubeIcon} width="30px" alt="" />
                                    <h4 className="mt-2 mb-1">{heading}</h4>
                                    <p className="text-gray font-weight-500">Keep your page updated with your latest YouTube videos.</p>
                                </div>

                                <div className="form-group">
                                    <label><small>Title</small></label>
                                    <input type="text" className={`form-control zaap-form-control`} onChange={handleChange} value={value?.title} name="title" required="required" placeholder="Youtube videos" />
                                </div>

                                <div className="form-group mt-2">
                                    <label><small>Youtube Channel URL</small></label>
                                    <p style={{ marginBottom: "1px" }}><small style={{ fontSize: "12px" }}>Type in a channel URL to always show the latest videos </small></p>
                                    <input type="url" className={`form-control zaap-form-control`} onChange={handleChange} value={value?.channel_url} name="channel_url" required="required" placeholder="https://youtube.com/example" />
                                </div>

                                <div className="form-group mt-2">
                                    <label><small>Number of videos</small></label>
                                    <input type="number" className={`form-control zaap-form-control`} onChange={handleChange} value={value?.no_of_videos} name="no_of_videos" required="required" placeholder="5" />
                                </div>

                                <div className="form-group mt-2">
                                    <label><small>Choose Content Type</small></label>
                                    {/* <select className="content-dropdown" value={value?.content_type} onChange={handleChange} name="content_type">
                                        <option value="live_streams_only">Live Streams Only</option>
                                        <option value="all_content">All Content Types</option>
                                    </select> */}
                                    <div className="content-dropdown dropdown-toggle noselect " id="content-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        {value?.content_type === 'live_streams_only' ? 'Live Streams Only' : 'All Content Types'}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                            <path d="M1 1.5L4 4.5L7 1.5" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <ul className="dropdown-menu content-dropdown-menu" aria-labelledby="content-dropdown">
                                        <li className="content-dropdown-item dropdown-item" value="live_streams_only" name="content_type" onClick={handleDropdownChange}> Live Streams Only </li>
                                        <li className="content-dropdown-item dropdown-item" value="all_content" name="content_type" onClick={handleDropdownChange}> All Content Types </li>
                                    </ul>
                                </div>
                                <div className="form-group mt-3">
                                    <label><small>Show Premiered Videos</small></label>
                                    <div className="switch-btns">
                                        <input type="radio" id={'switch-btns-true'} onChange={handleChange} defaultChecked={value?.show_premiered_video == true} name="show_premiered_video" value={true} />
                                        <label for={'switch-btns-true'} >Yes</label>
                                        <input type="radio" id={'switch-btns-false'} onChange={handleChange} defaultChecked={value?.show_premiered_video == false} name="show_premiered_video" value={false} />
                                        <label for={'switch-btns-false'} >No</label>
                                    </div>
                                </div>

                                <div className="text-center mt-4 d-flex">
                                    <button type="button" onClick={!youtubeModal?.show ? handleHide : () => {
                                        setyoutubeModal({
                                            ...youtubeModal,
                                            show: !youtubeModal?.show,
                                        })
                                    }} className="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                        <b className="me-2">Cancel</b>
                                        <small className="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="submit" className="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                        <b className="me-2">Save Changes</b>
                                        <small className="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal>
        )
    }
export default CreateYoutubeWidgetModal