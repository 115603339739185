import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import blockIcon from "../../../../../assets/img/block-icon.svg";
import returnIcon from "../../../../../assets/img/modals/return.svg";
import { ChevronRight } from '../../../../../utils/svg';
import addFaqIcon from "../../../../../assets/img/products/add-faq-btn.svg";
import dotsIcon from "../../../../../assets/img/products/faq-dots.svg";
import removeicon from "../../../../../assets/img/products/removeicon.svg";
import CroppieModal from '../../../../croppie-modal';
import { hideLoader, showLoader } from '../../../../loader';
import ErrorMessage from '../../../../errors/errorMessage';

function ReviewPage({data, showSectionChildModal, setShowSectionChildModal, error, setError, handleSubmit, handleUpdate }) {
    const close = () => setShowSectionChildModal(false);
    const [reviewheading, setReviewheading] = useState("");
    const [croppie, setCroppie] = useState(null);
    const [index, setIndex] = useState(null);
    const [showCroppieModal, setshowCroppieModal] = useState(null);
    const [croppie_image, setcroppie_image] = useState({ url: "", id: null });
    const [reviews, setReviews] = useState([
        {
          name: "",
          review: "",
          bio: "",
          image: "",
          image_url: "",
        },
      ]);
      const handleopenmodal = (e,i) => {
        setIndex(i);
        setcroppie_image({ url: "", id: null, name: null, type: null });
        generate_image_preview(e.target);
        hideLoader();
    };
      
  function handleadd() {
    var newdata = [...reviews];
    newdata.push({name: "" ,review: "" ,bio: "" ,image: "" ,image_url: "", });
    setReviews(newdata);
  }

  function handleDelete(i) {
    const newarr = [...reviews];
    newarr.splice(i, 1);
    setReviews(newarr);
  }

  const handleHideModal = () => {
    setshowCroppieModal(false);
    setCroppie(null);
    setcroppie_image({ url: "", id: null });
    // history.push(basePath + "/products");
  };
  const croppieClose = () => {
    showLoader();
    setshowCroppieModal(false);
    setCroppie(null);
    setTimeout(() => {
      hideLoader();
    }, 400);
  };
  const croppedImage = () => {
    if (croppie !== null) {
      croppie
        .result({
          type: "canvas",
          size: "original",
          format: "png",
          quality: 1,
        })
        .then((response) => {
          const tempObj = { image_url: response };
          croppie
            .result({
              size: "viewport",
              format: "png",
              quality: 1,
              type: "blob",
            })
            .then((res) => {
              const tempReview = [...reviews];
              const currentObj = { ...tempReview[index] };
              if (currentObj) {
                currentObj.image = response;
          
                  currentObj.image_url = response;
                  
                  tempReview[index] = currentObj;
                  setReviews([...tempReview]);
              }
              croppieClose()
            });
        });
    }
  };
  function removeImage(index) {
    const tempReview = [...reviews];
      const currentObj = { ...tempReview[index] };
      if (currentObj) {
        currentObj.image = null;
        currentObj.image_url = null;
        tempReview[index] = currentObj;
        setReviews([...tempReview]);
    }
  }
  function generate_image_preview(input) {
    showLoader();
    if (input.files && input.files[0]) {
      let reader = new FileReader();
      if (input.files[0].size >= 5242880) {
        $("#image_file_input").val("");
        let notification_container = $(".notification-container");
        toast.error("File Size Should not exceed 5MBs");
        // showModal();
        return;
      }
      let val = input.files[0].name;
      let valid_extensions = ["png", "jpg", "jpeg"];
      let extension =
        val.substring(val.lastIndexOf(".") + 1, val.length) || val;
      if (!valid_extensions.includes(extension)) {
        $("#image_file_input").val("");
        let notification_container = $(".notification-container");
        toast.error("Invalid Image File. Should be png, jpg or jpeg");
        // showModal();
        return;
      }
      reader.onload = (event) => {
        setcroppie_image({
          ...croppie_image,
          url: event.target.result,
        });
        setshowCroppieModal(true);
        setTimeout(() => {
          const el = document.getElementById("image_demo2");
          try {
            console.log("el", el);
            if (el) {
              var croppieInstance = new Croppie(el, {
                enableExif: true,
                viewport: {
                  width: 300,
                  height: 300,
                  type: 'circle',
                  showCroppieModal
                },
                boundary: {
                  width: 350,
                  height: 350
                },
              });
              croppieInstance
                .bind({
                  url: event.target.result,
                })
                .then(function () {
                  console.log("jQuery bind complete");
                  setTimeout(() => { }, 300);
                })
                .catch(() => {
                  croppieClose();
                });
              setCroppie(croppieInstance);
            }
          } catch { }
        }, 500);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
  const handleValues = (a, i) => {
    const value = a.target.value;
    const name = a.target.name;
    const tempReview = [...reviews];
    const currentObj = { ...tempReview[i] };
    currentObj[name] = value;
    tempReview[i] = currentObj;
    setReviews([...tempReview]);
  };
  const handleSave = () => {
    const errors = [];
    const validReviews = reviews.filter((review) => {
      if (!review?.name) {
          errors.push(`Name is required for Review`);
          return false;
      }
      if (!review?.bio) {
          errors.push(`Bio is required for Review`);
          return false;
      }
      if (!review?.review) {
          errors.push(`Review is required for Review`);
          return false;
      }
      if (!review?.image) {
          errors.push(`Image is required for Review`);
          return false;
      }
      return review?.name && review?.bio && review?.review && review?.image
    });
    if (errors.length > 0) {
        setError(errors);
        return;
    }
    const formattedData = {
        title: reviewheading,
        review_data: validReviews.map((review) => ({
        name:review?.name,
        bio: review?.bio,
        review: review?.review,
        image: review?.image
      })),
    };

    handleSubmit(formattedData,'reviews');
  };
  const handleUpdateData = (e) => {
    const errors = [];
    const validReviews = reviews.filter((review) => {
      if (!review?.name) {
          errors.push(`Name is required for Review`);
          return false;
      }
      if (!review?.bio) {
          errors.push(`Bio is required for Review`);
          return false;
      }
      if (!review?.review) {
          errors.push(`Review is required for Review`);
          return false;
      }
      if (!review?.image) {
          errors.push(`Image is required for Review`);
          return false;
      }
      return review?.name && review?.bio && review?.review && review?.image
    });
    if (errors.length > 0) {
        setError(errors);
        return;
    }
    const formattedData = {
        title: reviewheading,
        review_data: validReviews.map((review) => ({
        name:review?.name,
        bio: review?.bio,
        review: review?.review,
        image: review?.image
      })),
    };
    handleUpdate(formattedData,'reviews',e.target.dataset?.value)
  }
  useEffect(() => {
    if (data?.meta) {
        const metaObject = JSON.parse(data.meta);
        console.log("metaObject",metaObject)
        const { title, review_data } = metaObject;
        setReviewheading(title);
        setReviews(review_data);
    }
  },[data?.meta])
    return (
        <Modal centered show={showSectionChildModal} onHide={close}>
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <form name="product_type" method="post" role="form">
                            <div className="notification-container"></div>
                            <div className="form-group">
                                <img src={blockIcon} width="40px" alt="" />
                                <h4 className="productHead1 mt-15px">Edit Reviews Section</h4>
                            </div>
                            <ErrorMessage error={error} />
                       
                            <div className="form-group mt-20px">
                                    <label className="productlabels">
                                        Reviews Section Title
                                    </label>    
                                    <input onChange={(a) => setReviewheading(a.target.value)} type="text" className="zaapGlobal-input mt-10px text-dark " name="short_description" value={reviewheading} placeholder="Reviews"/>
                            </div>

                            {reviews?.map((e, i) => {return (<div key={i}>
                                <div className="form-group mt-15px">
                                    <div className='qnaContainer' style={{padding:"10px"}}>
                                        <div className='w-85'>
                                            <div className="qna-box2">
                                                <label>Name:</label>
                                                <input className="zaapGlobal-input bg-transparent  text-dark" name="name" value={e.name} onChange={(a) => {handleValues(a, i);}}  placeholder="Who gave this review?" style={{marginLeft: 5,}}/>
                                            </div>
                                            <div className="qna-box2" style={{marginTop:"5px"}}>
                                                <label>Bio:</label>
                                                <input className="zaapGlobal-input bg-transparent  text-dark" name="bio" value={e.bio} onChange={(a) => {handleValues(a, i);}}  placeholder="Who are they? Or add their username" style={{marginLeft: 5,}}/>
                                            </div>
                                            <div className="qna-box2" style={{marginTop:"5px"}}>
                                                <label>Review:</label>
                                                <input className="zaapGlobal-input bg-transparent  text-dark" name="review" value={e.review} onChange={(a) => {handleValues(a, i);}}  placeholder="Add their review here" style={{marginLeft: 5,}}/>
                                            </div>
                                            {!e?.image_url && !e?.image ?
                                            <button type="button" className="newGraybtn position-relative bg-white">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z" fill="#333333" />
                                                    </svg>Upload Picture
                                                    <input
                                                        type="file"
                                                        className="dropzone dropzone4 inset-0"
                                                        name="cover_image"
                                                        accept=".png,.jpg,.jpeg"
                                                        id="image_file_input"
                                                        onChange={(e)=>handleopenmodal(e,i)}
                                                    />
                                            </button>: null
                                              }
                                            <div className="preview-zone hidden "></div>

                                            <div>
                                                {
                                                e?.image_url || e?.image ? 
                                                <div className='d-flex align-items-center mt-15px'>
                                                    <div>
                                                        <img className="prevImageU" src={e?.image_url ?? e?.image} />
                                                        {console.log(e?.image,"e?.image_url")}
                                                    </div>
                                                    <div>
                                                        <img onClick={(e) => removeImage(i)} src={removeicon} width={"22px"} style={{ padding: 4, cursor: "pointer" }} />
                                                    </div>
                                                </div>: null
                                                }
                                            </div>
                                        </div>
                                        {reviews.length > 1 ? 
                                        <div>
                                            <img src={removeicon} width={"13px"} style={{margin:'0 10px'}} onClick={(a) => handleDelete(i)}/>
                                        </div>
                                         : <></>}
                                        </div>
                                    </div>
                                </div>
                            );})}


                            <div className="mt-15px">
                                <button type="button" className="newGraybtn" onClick={handleadd} width="100%">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z" fill="#333333" />
                                    </svg>
                                    <span className="me-2">Add Review</span>
                                </button>
                            </div>
                            <div class="text-center d-flex submit-buttons-fixed mt-20px">
                            <button type="button" onClick={close} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center save-btn-u">
                                <span class="me-2 font-weight-500">Cancel</span>
                                <small class="badge bg-light text-dark p-1">Esc</small>
                            </button>
                            <button data-value={data?.id} onClick={data?.id ? handleUpdateData : handleSave } type="button" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center save-btn-u">
                                <span data-value={data?.id} class="me-2 font-weight-500">Save Changes</span>
                                <small data-value={data?.id} class="badge bg-primary-dark text-dark px-2 py-1 mt-1 d-flex align-items-center justify-content-center">
                                    <img src={returnIcon} alt="" />
                                </small>
                            </button>
                        </div>
                            
                        </form>
                    </div>
                </div>
            </div>
            <CroppieModal show={showCroppieModal} handleHide={handleHideModal} croppieClose={croppieClose} croppedImage={croppedImage} modalWidth={500} productCroppie={"productCroppie"} />

        </Modal>
    )
}

export default ReviewPage