const INIT_STATE = {
    is_custom : 0,
    code: null,
    code_type : null,
    amount : null,
    no_of_uses : null,
    no_of_used : null,
    products: [],
    cover_image: null,
    date_range: null,
    date_range_end: null,
}
    const codeModalReducer = (state = INIT_STATE, action)=> {
        switch (action.type) {
            case 'SET_CODE_MODAL':
                return action.payload 
            case 'RESET_CODE_MODAL':
                return INIT_STATE
            default:
                return state
        }
    
    }
    export default codeModalReducer