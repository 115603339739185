import { useLocation, useParams } from "react-router-dom"
import { basePath } from "../routes/mainRoutes"
import BlockEditor from "./page-editor/block-editor"
import { CustomizePageModal } from "./page-editor/customize-page"

const ModalsHandler = ({ show, handleClose, showChild, slug }) => {
    const { blockEditorId,  } = useParams();
    if (!showChild) {
        if (blockEditorId == "newBlockModal")
            return <BlockEditor slug={slug} show={show} handleClose={handleClose} />
        // else if (blockEditorId == "customizePageModal")
        //     return <CustomizePageModal show={show} handleClose={handleClose} />
        else return
        
    }
}

export default ModalsHandler