import { hideLoader, showLoader } from "../components/loader";
import { apiUrl } from "../utils/url";

const getAudience = async (slug, params) => {
    // showLoader()
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/community/subscribers/get/${slug}?${params}`, {
            headers: myHeaders
        })
        const response = await res.json();
        return response?.data
        
    }
    catch (err) {
        
// console.log("error", err);
    }
}

export default getAudience;

export const getDefaultAudience = async (slug, params) => {
    // showLoader()
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/community/subscribers/getDefault/${slug}`, {
            headers: myHeaders
        })
        const response = await res.json();
        return response?.data
        
    }
    catch (err) {
        
// console.log("error", err);
    }
}
