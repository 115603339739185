import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { googleAppUrl } from "../utils/url";
import { hideLoader, showLoader } from "./loader";
import googleLogo from "../assets/img/google-white-logo.svg"

export const redirectToWalkthrough = (param_to_set, history,googlelogin = null) => {

    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set("step", param_to_set);
    setTimeout(() => {
        if (queryParams.get("step")) {
            if (googlelogin || param_to_set == 1) {
            history.push({
                pathname: `/walkthrough`,
                search: `step=${param_to_set}`,
                state:{"signupfromgoogle":true}
            })
            }else{
                history.push({
                    pathname: `/walkthrough`,
                    search: `step=${param_to_set}`
                })
            }
        }
    }, 500)
}
const GoogleSignin = ({ title }) => {

    const googleClick = () => {
        showLoader()
        let invite_token = localStorage.getItem("invite_token");
        let slug_created = localStorage.getItem("slug_created");
        localStorage.clear()
        if(invite_token){
            localStorage.setItem('invite_token',invite_token);
        }
        if(slug_created){
            localStorage.setItem('slug_created',slug_created);
        }
        // const w = 700;
        // const h = 600
        // var y = screen.width / 2 - (w / 2)
        // var x = screen.height / 2 - (h / 2)
        location.href = `https://accounts.google.com/o/oauth2/auth?response_type=code&access_type=online&client_id=566604054736-3e3hs18rfto7b5mkc6mmf2pqn9sa5ne6.apps.googleusercontent.com&redirect_uri=${googleAppUrl}&state&scope=email%20profile&approval_prompt=auto`
        // const popup_window = window.open(

        //     ,
        //     'targetWindow',
        //     `toolbar=no,
        //         directories=no,titlebar=no
        //        location=no,
        //        status=no,
        //        menubar=no,
        //        scrollbars=yes,
        //        resizable=yes,
        //        width=${w},
        //        height=${h}, top='${y}', left='${x}`)

        // const checkChild = () => {
        //     if (popup_window.closed) {
        //         hideLoader()
        //     }
        // }
        // var timer = setInterval(checkChild, 500);

    }




    return (
        <div className="form-group mt-3 mb-4">
            <a href="#" onClick={googleClick}
                className="google-btn">
                <img src={googleLogo}
                    />
                <span>{title}</span>

            </a>
        </div >
    )
}
export default GoogleSignin