import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom"
import { toast } from "react-toastify";
import getProjects from "../../services/getProjects";
import { apiUrl } from "../../utils/url";
import { hideLoader, showLoader } from "../loader";
import { ConvertkitModal, FacebookPixelModal, GoogleAnalyticsModal, MailchimpModal, SendinblueModal } from "../page-editor/cutomizePageOptions/integrationsCustomize"

const IntegrationModals = ({ setShowCustomizeParent }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [error, setError] = useState([]);
    const settings = useSelector(state => state?.customizePageReducer);

    const integrationGoogleReducer = useSelector(state => state?.integrationGoogleReducer);
    const integrationFacebookReducer = useSelector(state => state?.integrationFacebookReducer);
    const integrationMailChimpReducer = useSelector(state => state?.integrationMailChimpReducer);
    const integrationConvertkitReducer = useSelector(state => state?.integrationConvertkitReducer);
    const integrationSendinblueReducer = useSelector(state => state?.integrationSendinblueReducer);

    const handleClose = () => history.push(`${basePath}/page-editor/customizePageModal/customizePageIntegrations`)

    const handleSubmit = (e, values, integration) => {
        e.preventDefault();
        showLoader();
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const errorList = []
        const formdata = new FormData();
        let formValues = {};
        if (values?.disconnect == 1)
            formValues = { project: slug, tab: "integrations", integration: integration, disconnect: 1 };
        else
            formValues = { ...values, project: slug, integration: integration };

        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                formdata.append(key, formValues[key]);
            }
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + `/project/update`, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {

                    toast.success(response?.status_message);
                    if (integration == "mailchimp") {
                        dispatch({ type: "SET_MAILCHIMP_INTEGRATION", payload: { ...integrationMailChimpReducer, "disconnect": 1 } })
                    }
                    else if (integration == "convertkit") {
                        dispatch({ type: "SET_CONVERTKIT_INTEGRATION", payload: { ...integrationConvertkitReducer, "disconnect": 1 } })
                    }
                    else if (integration == "facebook") {
                        dispatch({ type: "SET_FACEBOOK_INTEGRATION", payload: { ...integrationFacebookReducer, "disconnect": 1 } })
                    }
                    else if (integration == "google") {
                        dispatch({ type: "SET_GOOGLE_INTEGRATION", payload: { ...integrationGoogleReducer, "disconnect": 1 } })
                    }
                    else if (integration == "sendinblue") {
                        dispatch({ type: "SET_SENDINBLUE_INTEGRATION", payload: { ...integrationSendinblueReducer, "disconnect": 1 } })
                    }
                    hideLoader();

                    setTimeout(() => {
                        
// console.log("handleSubmit")
                        handleClose(true)

                    }, 1000);
                }

                else {
                    let errorList = []
                    response?.errors?.link?.map((val) => errorList?.push(val));
                    response?.errors?.pixel_id?.map((val) => errorList?.push(val));
                    response?.errors?.analytics_id?.map((val) => errorList?.push(val));
                    response?.errors?.api_key?.map((val) => errorList?.push(val));
                    // setError(errorList)
                    
// console.log(errorList)

                    if (errorList.length !== 0) {
                        
// console.log("Error List", errorList.length)
                        errorList?.map((err) => {
                            return (
                                toast.error(err)
                            );
                        });
                    }
                    else {
                        toast.error(response?.status_message)
                    }

                    // toast.error(response?.status_message)
                    hideLoader()
                }
            }).catch((err) => {
                hideLoader()
            })
    }


    return (
        <>

            <Route path={basePath + "/page-editor/customizePageModal/customizePageIntegrations/googleAnalytics"}
                children={
                    <GoogleAnalyticsModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />
                } />
            <Route path={basePath + "/page-editor/customizePageModal/customizePageIntegrations/facebookPixel"}
                children={
                    <FacebookPixelModal show={true} settings={settings?.facebook_pixel} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />
                } />
            <Route path={basePath + "/page-editor/customizePageModal/customizePageIntegrations/mailchimp"}
                children={
                    <MailchimpModal show={true} settings={settings?.mailchimpSettings} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />
                } />
            <Route path={basePath + "/page-editor/customizePageModal/customizePageIntegrations/convertkit"}
                children={
                    <ConvertkitModal show={true} settings={settings?.convertkitSettings} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />
                } />
            <Route path={basePath + "/page-editor/customizePageModal/customizePageIntegrations/sendinblue"}
                children={
                    <SendinblueModal show={true} settings={settings?.sendinblue_settings} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />
                } />
        </>
    )
}
export default IntegrationModals