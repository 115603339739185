const INIT_STATE = "gradient"
const linkStyleSelected = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_LINKSTYLE_SELECTED':
            return action.payload 
        case 'RESET_LINKSTYLE_SELECTED':
            return INIT_STATE
        default:
            return state
    }
}
export default linkStyleSelected