const hostname= location.hostname;
// const hostname= 'dev.app.zaap.ai';
let appUrlCheck=""
let apiUrlCheck= ""
let pageUrlCheck= ""
let protocolCheck= ""

if(hostname == (process.env.REACT_APP_HOSTNAME_LOCAL)){
    appUrlCheck = process.env.REACT_APP_URL_LOCAL;
    apiUrlCheck = process.env.REACT_APP_BACKEND_URL_LOCAL;
    pageUrlCheck = process.env.REACT_APP_PAGE_HOSTNAME_LOCAL;
    protocolCheck = process.env.REACT_APP_PAGE_PROTOCOL_LOCAL;
}
else if(hostname == (process.env.REACT_APP_HOSTNAME_DEV)){
    appUrlCheck = process.env.REACT_APP_URL_DEV;
    apiUrlCheck = process.env.REACT_APP_BACKEND_URL_DEV;
    pageUrlCheck = process.env.REACT_APP_PAGE_HOSTNAME_DEV;
    protocolCheck = process.env.REACT_APP_PAGE_PROTOCOL_DEV;
}
else if(hostname == (process.env.REACT_APP_HOSTNAME_PROD)){
    appUrlCheck = process.env.REACT_APP_URL_PROD;
    apiUrlCheck = process.env.REACT_APP_BACKEND_URL_PROD;
    pageUrlCheck = process.env.REACT_APP_PAGE_HOSTNAME_PROD;
    protocolCheck = process.env.REACT_APP_PAGE_PROTOCOL_PROD;
}

export const googleAppUrl = `${appUrlCheck}/google-oauth`
export const appUrl = appUrlCheck;
export const apiUrl = apiUrlCheck;
export const pageUrl = pageUrlCheck;
export const protocol = protocolCheck;
export const zaapLogo = 'https://imagedelivery.net/tqC70bVt8T6GtQUXNsa2-g/c4ad5adc-ada1-44bb-e3d6-12bd73e6a300/public';