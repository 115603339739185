import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { hideLoader } from "../components/loader"
import { audiencePosts as oathPost } from "../services/postRequests/audience";
import { apiUrl } from "../utils/url";

const ZoomCallback = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const params = new URLSearchParams(window.location.search);
    const [code, setCode] = useState(params?.get("code"));
    const meeting_tokens = useSelector(state => state?.meetConnectedReducer);
    useEffect(() => {
        if (code) sendRequest();
    }, [])

  const redirectToPage = () => {

      setTimeout(() => {
          history.push(localStorage.last_activity)
          localStorage.removeItem("lastActivity")
        }, 1000);
    }

    const sendRequest = () => {
        if (localStorage.getItem("sendRequest")) return true
        localStorage.setItem("sendRequest", true)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData()
        formdata.append("project", localStorage?.getItem("slug"))
        formdata.append("code", code)
        const api = apiUrl + "/zoom-connect/callback";
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        oathPost(null, api, hideLoader, setOptions).then((response) => {
            if (response) {
                localStorage.removeItem("sendRequest")
                dispatch({ type: "SET_MEET_CONNECTED", payload: {...meeting_tokens,zoom_token: true } });
                window.opener?.postMessage({ type: 'zoomToken', zoomToken:true }, '*');
                setTimeout(() => {
                    window.location.href = response?.redirect_url;
                },300)
            }
            
        }).catch(() => {
                localStorage.removeItem("lastActivity")
            redirectToPage()
        })
    }

    return (
        <div class="full-page-loader">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    )
}
export default ZoomCallback