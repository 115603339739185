const INIT_STATE = {
    api_key: null,
    form_id: null,
    tab: "integrations"
}
const integrationConvertkitReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_CONVERTKIT_INTEGRATION':
            return action.payload 
        case 'RESET_CONVERTKIT_INTEGRATION':
            return INIT_STATE
        default:
            return state
    }
}
export default integrationConvertkitReducer