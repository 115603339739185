import { Modal } from "react-bootstrap";
import frame1 from "../../../../assets/img/modals/custom-domain-loader-frame1.svg";
import frame2 from "../../../../assets/img/modals/custom-domain-loader-frame2.svg";
import frame3 from "../../../../assets/img/modals/custom-domain-loader-frame3.svg";
import frame4 from "../../../../assets/img/modals/custom-domain-loader-frame4.svg";
import closeIcon from "../../../../assets/img/modals/close-icon.svg"
import domainIcon from "../../../../assets/img/modals/domain-icon.svg"
import returnIcon from "../../../../assets/img/modals/return.svg";

const CustomDomainDotModal = ({ show }) => (
    <Modal show={show} centered>
        <Modal.Body style={{ padding: "0px" }}>

            <div class="modal-body p-0">

                <form name="create_folder" class="p-3" method="post" role="form">
                    <div class="notification-container"></div>


                    <div class="form-group">
                        <img src={domainIcon} width="30px" alt="" />
                        <h4 class="mt-2 mb-1">Connecting the dots...</h4>
                        <p class="text-gray font-weight-500">We're doing the technical stuff to make your<br />domain work. This should only take a few<br /> minutes.</p>
                    </div>
                </form>
                <div class="form-group">
                    <div class="d-flex align-items-center justify-content-center marquee mb-2">
                        <p class="m-0">
                            <img src={frame2} width="37%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame1} width="40%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame2} width="37%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame3} width="43%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame4} width="43%" alt="" />
                        </p>
                    </div>

                    <div class="d-flex align-items-center justify-content-center marquee-reverse mb-2">
                        <p class="m-0">
                            <img src={frame1} width="40%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame3} width="43%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame2} width="37%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame4} width="43%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame1} width="40%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame2} width="37%" alt="" />
                        </p>
                    </div>

                    <div class="d-flex align-items-center justify-content-center marquee mb-4">
                        <p class="m-0">
                            <img src={frame2} width="37%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame1} width="40%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame4} width="43%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame3} width="43%" alt="" />
                            &nbsp;&nbsp;
                            <img src={frame1} width="40%" alt="" />
                        </p>
                    </div>
                </div>

            </div>
        </Modal.Body>
    </Modal>
)
export default CustomDomainDotModal;
