
const INIT_STATE = { from: null, show: false }
    
const bannerModal = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_BANNER_MODAL':
            return action.payload
        case 'RESET_BANNER_MODAL':
            return INIT_STATE
        default:
            return state
    }
}
export default bannerModal