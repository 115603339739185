import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { redirectToWalkthrough } from "../components/google-sign";
import { hideLoader, showLoader } from "../components/loader"
import { audiencePosts as oathPost } from "../services/postRequests/audience";
import { apiUrl } from "../utils/url";

const GoogleOauth = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    const [code] = useState(params?.get("code"));

    useEffect(() => {
        if (code) sendRequest();
    }, [])

    const sendRequest = () => {
        if (localStorage.sendRequest) {
            return
        }
        localStorage.sendRequest = true
        const formdata = new FormData()
        formdata.append("code", code)
        
        localStorage.getItem("invite_token") && formdata.append("invite_token", localStorage.getItem("invite_token"))
        localStorage.getItem("slug_created") && formdata.append("slug_created", localStorage.getItem("slug_created"))
        const api = apiUrl + "/google-authenticate";
        const setOptions = {
            method: "POST",
            body: formdata
        }
        oathPost(null, api, hideLoader, setOptions).then((response) => {

            if (response) {
                if (response?.walkthrough == 0) {
                    window.dataLayer.push({
                        event: 'Googlesignup',
                        userId:response?.user_id,
                        name:response?.name,
                        email:response?.email,
                        country:response?.country,
                        
                      });
                    localStorage.setItem("walkthrough", response?.walkthrough);
                    localStorage.setItem("walkthrough", response?.walkthrough);
                    localStorage.setItem("walkthrough_step", response?.walkthrough_step);
                    localStorage.setItem("currentStep", response?.walkthrough_step);
                    localStorage.setItem("lastStep", response?.walkthrough_step - 1);
                    localStorage.setItem("slug", response?.default_project);
                    // window.opener.postMessage({ pathname: "walkthrough", walkthrough_step: response?.walkthrough_step, walkthrough: response?.walkthrough, name: response?.name, token: response?.token })

                    setTimeout(() => {
                        localStorage.removeItem("sendRequest")
                        dispatch({ type: "SET_STEPS", payload: { lastStep: response?.walkthrough_step - 1, currentStep: response?.walkthrough_step, walkthrough: response?.walkthrough } });

                        redirectToWalkthrough(response?.walkthrough_step, history,response?.walkthrough_step == 1 ? 'googlelogin':null)
                        localStorage.setItem("user", response?.name);
                        localStorage.setItem("token", response?.token);
                    }, 500);

                }
                else if (response?.walkthrough == 1) {
                    // window.dataLayer.push({
                    //     event: 'Googlelogin',
                    //     userId:response?.user_id
                    //   });
                    // window.opener.postMessage({ pathname: "dashboard", walkthrough: response?.walkthrough})
                    localStorage.setItem("user", response?.name);
                    localStorage.setItem("email", response?.email);
                    localStorage.setItem("token", response?.token);
                    localStorage.setItem("walkthrough", response?.walkthrough);
                    localStorage.setItem("slug", response?.default_project);
                    setTimeout(() => {
                        localStorage.removeItem("sendRequest")
                        dispatch({ type: "SET_STEPS", payload: { lastStep: response?.walkthrough_step - 1, currentStep: response?.walkthrough_step, walkthrough: response?.walkthrough } });

                        dispatch({ type: "SET_SLUG", payload: { slug: response?.default_project, basePath: "/project/" + response?.default_project, newChange: false } })

                        history.push("/project/" + response?.default_project + "/page-editor")
                    }, 500);
                }
            }
            else {
                history.push("login")
                localStorage.removeItem("sendRequest")
            }
        }).catch((err) => {
            toast.error(err)
            history.push("login")
            localStorage.removeItem("sendRequest")
        })
    }

    return (
        <div class="full-page-loader">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    )
}
export default GoogleOauth