import defaultUser from "../../../assets/media/user_pp/default.png";
import returnIcon from "../../../assets/img/modals/return.svg";
import style from "../../../assets/css/project.css";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import ErrorMessage from "../../errors/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { hideLoader, showLoader } from "../../loader";
import proIcon from "../../../assets/img/pro.svg";
import { useRef } from 'react';
import { apiUrl } from "../../../utils/url";
import Teammoadal from "../../teammember/team_modal";
import { matchObject } from "../customize-page";
import removeicon from "../../../assets/img/products/removeicon.svg";

const ProfileCustomize = ({ handleSubmit, setShowSaveChanges, error, settings }) => {

    const dispatch = useDispatch();
    const history = useHistory()
    const value = useSelector(state => state?.customizeProfileReducer);
    const [profileFormState, setProfileFormState] = useState({ ...value });
    const { slug, basePath } = useSelector(state => state?.slugReducer);
    const pro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const fileupload = useRef(null);
    const [faviconImg, setFaviconImg] = useState(null);
    // const [languages, setLanguages] = useState([]);
    const languages = useSelector(state => state.allLanguagesReducer)
    const [showProModal, setShowProModal] = useState(false)
    const [timer, setTimer] = useState(null);
    const [countChange, setCountChange] = useState(0);

    useEffect(() => {
        dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: { ...value, "slug": slug } })
    }, [slug])

    const handleChange = (e) => {
        if (e.target.name == "verified_check" || e.target.name == "collect_emails") {
            if (e.target.checked && !pro) {
                setShowProModal(true);
                // history.push(basePath + "/settings")
                // dispatch({ type: "SET_UPGRADE_MODAL", payload: { isPro: false, open: true } })
            } else {
                dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: { ...value, [e.target.name]: e.target.checked ? 1 : 0 } })
                // changeDelay()
            }
        }
        else {
            dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: { ...value, [e.target.name]: e.target.value } })
            // changeDelay()
        }
    }
    function changeDelay() {
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
        }
        setTimer(
          setTimeout(() => {
            handleSubmit(value, value.isEdit);
          }, 3000)
        );
    };
    useEffect(()=>{
        // console.log("if condition true")
        if (countChange > 0) {
            if (value && profileFormState && !matchObject(value, profileFormState)) {
                // changeDelay();
                setShowSaveChanges(prevState => ({
                    ...prevState,
                    show: true,
                    formId: prevState.formId.includes("profileValue")
                        ? prevState.formId
                        : [...prevState.formId, "profileValue"]
                }));
            } else {
                setShowSaveChanges(prevState => ({
                    ...prevState,
                    show: true,
                    formId: prevState.formId.filter(form => form !== "profileValue")
                }));
            }
        } else {
            setCountChange(1);
            return;
        }
    },[value])
    useEffect(()=>{
        setCountChange(0);
    },[languages])
    const profileUploaderOpener = () => {
        showLoader()
        history.push(basePath + "/page-editor");
        setTimeout(() => {
            hideLoader()
            dispatch({ type: "SET_PROFILE_PIC_MODAL", payload: { show: true, to: "profile" } });
        }, 300);

    }
    const handleClick = (e) => {
        e.preventDefault();
        if (!pro) {
            setShowProModal(true);
            // history.push(basePath + "/settings");
            // dispatch({ type: "SET_UPGRADE_MODAL", payload: { isPro: false, open: true } });
        } else {
            fileupload.current.click();
        }
    }
    const onfilechange = (e) => {
        const fileList = e.target.files[0];
        dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: { ...value, [e.target.name]: fileList } })
        var reader = new FileReader();

        reader.onload = function (event) {
            setFaviconImg(event.target.result);
        };
        reader.readAsDataURL(fileList);

        // console.log("fileList",fileList);

    }

    // console.log(value);
    useEffect(() => {
        dispatch({ type: "RESET_UPLOAD_IMAGE" })
    }, [])
    // useEffect(() => {

    //     if (slug) {
    //         showLoader();

    //         var myHeaders = new Headers();
    //         myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
    //         var requestOptions = {
    //             method: 'GET',
    //             headers: myHeaders
    //         };
    //         fetch(apiUrl + `/language`, requestOptions)
    //             .then(async (res) => {
    //                 const response = await res?.json();

    //                 if (response?.status_code == 200) {

    //                     // console.log(response.data);
    //                     setLanguages(response.data);

    //                     hideLoader();
    //                 }

    //             }).catch((err) => {
    //                 hideLoader();
    //             })
    //     }
    // }, [slug]);

    const deleteImage = () => {
        setFaviconImg(null);
        dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: { ...value, "favicon_upload": null } })
    }


    return (
        <div class="tab-pane fade customize-page-main active show position-relative" id="customizePageProfile" role="tabpanel" aria-labelledby="profile-tab">
            <h4>Profile</h4>
            <section>
                <form
                    style={{ paddingBottom: "60px" }}
                    // onSubmit={(e) => {
                        // e.preventDefault();
                        // const formValues = { ...value }

                        // console.log("formValues", formValues)
                        // if(!pro) delete formValues["verified_check"]
                        // handleSubmit(formValues, value.isEdit)
                    // }}
                    >
                    <ErrorMessage error={error} />
                    <div class="form-group upload-pp-div">
                        <div class="user-pp mt-2" onClick={profileUploaderOpener}>
                            <img src={value?.image_url ? value?.image_url : defaultUser} width="100%" alt="" />
                        </div>
                        <div className="upload-pp">
                            <label class="upload-pp-text">Profile Picture</label>
                            <button type="button" className="upload-pp-btn" onClick={profileUploaderOpener}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z" fill="#333333"/>
                                </svg>
                                Upload New
                            </button>
                        </div>
                    </div>

                    <div class="form-group mt-3">
                        <label class="text-gray font-14-u">Page Title</label>
                        <input type="text" class={`form-control zaap-form-control border-0`} name="title" onChange={handleChange} placeholder="Page Title" value={value?.title} />
                    </div>

                    <div class="form-group mt-3">
                        <label class="text-gray font-14-u">Page Description</label>
                        <input type="text" class={`form-control zaap-form-control border-0`} name="description" onChange={handleChange} placeholder="Page Bio" value={value?.description} />
                    </div>

                    <div class="form-group mt-3">
                        <label class="text-gray font-14-u">Page Username</label>
                        {/* <div class="input-group mb-3">
                            <label class="input-group-text zaap-border-rounded-left text-gray font-14-u px-4 py-2" id="basic-addon1"><b>zaap.bio/</b></label>
                            <input type="text"
                                class={`form-control zaap-form-control zaap-border-straight zaap-border-rounded-right`} name="link" onChange={handleChange} placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" value={value?.link ? value?.link : value?.slug} />
                        </div> */}
                        <div className="d-flex justify-content-between align-items-center form-control zaap-form-control border-0">
                            <div className="text-grey-u">zaap.bio/</div>
                            <input style={{color:"#5B5B5B"}} type="text" class={`ps-0 font-12 `} name="link" onChange={handleChange} placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" value={value?.link ? value?.link : value?.slug}/>
                        </div>
                    </div>

                    <div className="form-group mt-3 ">
                        <label className="text-gray font-14-u">Page Language</label>
                        <select
                            name="page_language"
                            id=""
                            className={`form-control zaap-form-control check-para border-0`}
                            value={value?.page_language}
                            onChange={handleChange}
                        >
                            {languages.map((language) => (
                                <option className="check-para" key={language.id} value={language.short_code}>
                                    {language.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* {!pro &&  */}
                    {/* <div class="form-group mt-3 d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-start justify-content-center flex-column">
                            <label class="text-gray font-14-u">Collect Emails</label>
                            <p class="mb-0 text-muted"><small>Visitors can leave their email to ‘Join’ your audience</small></p>
                        </div>
                        <div class="d-flex align-items-start justify-content-center flex-column">
                            <label class="my-0 ms-0 me-2 link-switch">
                                <input type="checkbox" class="" name="collect_emails" value={value?.collect_emails == 1 ? true : false} onChange={handleChange} checked={value?.collect_emails == 1 ? true : false} />
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div> */}
                    {/* <label class="text-gray font-14-u mt-3">Verified Check</label>
                    <div class="form-group d-flex align-items-center justify-content-between form-control zaap-form-control padd-u border-0">
                        <div class="">
                            <p class="mb-0 check-para">Show the world that you’re real with the Pro Verified check</p>
                        </div>
                        <div class="d-flex align-items-start justify-content-center flex-column">
                            <label class="my-0 ms-0 link-switch">
                                <input type="checkbox" class="" name="verified_check" value={value?.verified_check == 1 ? true : false} onChange={handleChange} checked={value?.verified_check == 1 ? true : false} />
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div> */}
                    {/* } */}
                    <div class="form-group mt-3">
                        <div className="d-flex align-items-center py-1">
                            <label class="text-gray font-14-u">Favicon</label>
                            <img className="ms-2" src={proIcon} alt="" />
                        </div>
                        {(value?.favicon_upload || faviconImg) ?
                            (
                                <div className="d-flex align-items-center justify-content-start gap-1">
                                    <div className="position-relative">
                                    <input id="file-upload" type="file" ref={fileupload} className="invisible position-absolute top-0 bottom-0 left-0 right-0" accept=".png,.jpg,.jpeg" name="favicon_upload" onChange={onfilechange} />
                                    <img onClick={handleClick} src={faviconImg ? faviconImg : (value?.favicon_upload)} alt="" id="faviconImg" className={`dropzone-wrapper dropzone-wrapper5 fav-uplod`} />
                                    </div>
                                    <div className="cursor-pointer" onClick={deleteImage}>
                                        <img src={removeicon} width={15}/>
                                    </div>
                                </div>
                            )
                            :
                            (
                                <div class="mb-3 d-flex justify-content-between ps-0 zaap-border-rounded py-2" style={{ borderColor: '#E8E9EA' }}>
                                   
                                    <div className="d-flex justify-content-end uplod-btn-u">
                                        <input id="file-upload" type="file" ref={fileupload} className="d-none" accept=".png,.jpg,.jpeg" name="favicon_upload" onChange={onfilechange} />
                                        <button type="button" onClick={handleClick} class="btn btn-semi-rounded d-flex align-items-center justify-content-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z" fill="#333333"/>
                                            </svg>
                                            <span class="upload-text">Upload New</span>
                                        </button>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    {/* <div class="text-center d-flex submit-buttons-fixed">
                        <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center save-btn-u">
                            <b class="me-2">Save Changes</b>
                            <small class="badge bg-primary-dark text-dark px-2 py-1 mt-1 d-flex align-items-center justify-content-center">
                                <img src={returnIcon} alt="" />
                            </small>
                        </button>
                    </div> */}

                </form>
                {showProModal && <Teammoadal from={'customizePageModal'} showModal={showProModal} setShowModal={setShowProModal} heading={'Upgrade to access this Feature.'} text={' This is a Pro Feature<br /> Upgrade to Zaap Pro to unlock this feature.'} isUltra={false}/>}
            </section>
        </div>
    )
}
export default ProfileCustomize