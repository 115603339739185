import repeatBlackIcon from "../../assets/img/modals/repeat-black.svg";
import repeatIcon from "../../assets/img/modals/repeat.svg";
import integration_google_analytics from "../../assets/img/modals/integration-google-analytics.svg";
import integration_facebook_analytics from "../../assets/img/modals/integration-facebook-pixel.svg";
import integration_mailchimp from "../../assets/img/modals/integration-mailchimp.svg";
import integration_convertkit from "../../assets/img/modals/integration-convertkit.svg";
import integration_sendinblue from "../../assets/img/modals/integration-sendinblue.svg";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../loader";
import returnIcon from "../../assets/img/modals/return.svg";
import { useDispatch, useSelector } from "react-redux";
import ModalErrors from "../errors/modal-errors";
import { toast } from "react-toastify"
import { apiUrl } from "../../utils/url";

export const GoogleAnalyticsModal = ({ show, handleClose, error, handleSubmit }) => {

    const dispatch = useDispatch()
    const value = useSelector(state => state?.integrationGoogleReducer);
    const handleChange = (e) => dispatch({ type: "SET_GOOGLE_INTEGRATION", payload: { ...value, [e.target.name]: e.target.value } });

    return (

        <Modal show={show} onHide={handleClose} id="googleAnalyticsModal" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value, "google")}>
                            <div class="notification-container"></div>


                            <div class="form-group">
                                <img src={integration_google_analytics} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Google Analytics</h4>
                            </div>

                            <div class="form-group mt-3">
                                <label><small>Google Analytics ID</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="analytics_id" value={value?.analytics_id} required="required" placeholder="Google Analytics ID" onChange={handleChange} />
                                <p style={{ marginBottom: 0 }}><ModalErrors text={error} /></p>
                                <small class="text-muted-light">Enable tracking with Google Analytics by adding your analytics ID<br />(ex: UA-22222222-33)</small>
                            </div>

                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Modal>
    )
}
export const FacebookPixelModal = ({ show, handleClose, handleSubmit, error }) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state?.integrationFacebookReducer);

    const handleChange = (e) => dispatch({ type: "SET_FACEBOOK_INTEGRATION", payload: { ...value, [e.target.name]: e.target.value } });
    return (

        <Modal show={show} onHide={handleClose} id="facebookPixelModal" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value, "facebook")}>
                            <div class="notification-container"></div>


                            <div class="form-group">
                                <img src={integration_facebook_analytics} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Facebook Pixel</h4>
                            </div>

                            <div class="form-group mt-3">
                                <label><small>Facebook Pixel ID</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="pixel_id" onChange={handleChange} value={value?.pixel_id} required="required" placeholder="Facebook Pixel ID" />
                                <ModalErrors text={error} />
                                <small class="text-muted-light">Enable the Facebook Pixel by adding your only the Facebook Pixel ID.</small>
                            </div>

                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Modal>
    )
}
export const MailchimpModal = ({ show, handleClose, handleSubmit, error }) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state?.integrationMailChimpReducer);
    const handleChange = (e) => dispatch({ type: "SET_MAILCHIMP_INTEGRATION", payload: { ...value, [e.target.name]: e.target.value } });
    return (
        <Modal show={show} onHide={handleClose} id="mailchimpModal" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">
                    <div class="modal-body">
                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value, "mailchimp")}>
                            <div class="notification-container"></div>
                            <div class="form-group">
                                <img src={integration_mailchimp} width="40px" alt="" />
                                <h4 class="mt-2 mb-1">Connect Mailchimp</h4>
                                <p class="text-gray font-weight-500">Send new email subscribers to your Mailchimp list from Zaap.</p>
                            </div>

                            <div class="form-group">
                                <label><small>API Key</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="api_key" value={value?.api_key} onChange={handleChange} required="required" placeholder="API Key" />
                            </div>

                            <div class="form-group mt-2">
                                <label><small>Server Prefix</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="server_prefix" value={value?.server_prefix} onChange={handleChange} required="required" placeholder="Server Prefix" />
                            </div>

                            <div class="form-group mt-2">
                                <label><small>List ID</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="list_id" value={value?.list_id} onChange={handleChange} required="required" placeholder="List ID" />
                            </div>


                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export const ConvertkitModal = ({ show, handleClose, handleSubmit, error }) => {


    const dispatch = useDispatch()
    const value = useSelector(state => state?.integrationConvertkitReducer);
    const handleChange = (e) => dispatch({ type: "SET_CONVERTKIT_INTEGRATION", payload: { ...value, [e.target.name]: e.target.value } });
    return (
        <Modal show={show} onHide={handleClose} id="convertkit" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value, "convertkit")}>
                            <div class="form-group">
                                <img src={integration_convertkit} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Connect ConvertKit</h4>
                                <p class="text-gray font-weight-500">Send new email subscribers to your ConvertKit list from Znaplink.</p>
                            </div>

                            <div class="form-group">
                                <label><small>API Key</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="api_key" onChange={handleChange} value={value?.api_key} required="required" placeholder="API Key" />
                            </div>

                            <div class="form-group mt-2">
                                <label><small>Form ID (Collects only email)</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="form_id" onChange={handleChange} value={value?.form_id} required="required" placeholder="Form ID" />
                            </div>


                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export const SendinblueModal = ({ show, handleClose, handleSubmit, error }) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state?.integrationSendinblueReducer);
    const handleChange = (e) => dispatch({ type: "SET_SENDINBLUE_INTEGRATION", payload: { ...value, [e.target.name]: e.target.value } });

    return (
        <Modal show={show} onHide={handleClose} id="sendinblue" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value, "sendinblue")}>
                            <div class="notification-container"></div>

                            <div class="form-group">
                                <img src={integration_sendinblue} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Connect SendInBlue</h4>
                                <p class="text-gray font-weight-500">Send new email subscribers to your SendInBlue list from Znaplink.</p>
                            </div>

                            <div class="form-group">
                                <label><small>API Key</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="api_key" value={value?.api_key} onChange={handleChange} required="required" placeholder="API Key" />
                            </div>

                            <div class="form-group mt-2">
                                <label><small>List ID</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="list_id" value={value?.list_id} onChange={handleChange} required="required" placeholder="List ID" />
                            </div>


                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export const ConnectZapierModal = ({type, text, show, handleClose, handleSubmit, error }) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state?.integrationZapierReducer);

    const handleChange = (e) => dispatch({ type: "SET_ZAPIER_INTEGRATION", payload: {  [e.target.name]: e.target.value,tab:"integrations" } });
    return (

        <Modal show={show} onHide={handleClose} id="facebookPixelModal" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value, type)}>
                            <div class="notification-container"></div>


                            <div class="form-group">
                            <svg xmlns="http://www.w3.org/2000/svg" height="30" preserveAspectRatio="xMidYMid" viewBox="0 0 256 256" width="30"><path d="m159.998995 128.056308c-.004022 9.512019-1.745546 18.613794-4.914879 27.023786-8.40597 3.169333-17.515789 4.910857-27.03183 4.922922h-.108594c-9.507997-.012065-18.617816-1.749567-27.023786-4.9189-3.1693326-8.40597-4.9148785-17.515789-4.9189005-27.027808v-.112616c.004022-9.507997 1.745546-18.617816 4.9108565-27.019764 8.40597-3.1733546 17.519811-4.9189005 27.03183-4.9229225h.108594c9.516041.004022 18.62586 1.7495679 27.03183 4.9229225 3.169333 8.401948 4.910857 17.511767 4.914879 27.019764zm94.223283-21.388971h-74.716606l52.828908-52.8329301c-4.150699-5.8318932-8.780016-11.3018067-13.82762-16.3494108v-.004022c-5.047604-5.0435821-10.517518-9.6648547-16.345389-13.8155538l-52.83293 52.8329301v-74.71660649c-6.91381-1.16235664-14.016654-1.77772191-21.264289-1.78174391h-.132726c-7.247635.004022-14.346457.61938727-21.264289 1.78174391v74.71660649l-52.8329301-52.8329301c-5.8318932 4.1506991-11.2977847 8.7759937-16.3373448 13.8235978l-.028154.0201099c-5.0395601 5.0395601-9.6568107 10.5014297-13.8075098 16.3252789l52.8369521 52.8329301h-74.71660649s-1.78174391 14.032742-1.78174391 21.288421v.088484c0 7.255679.61536528 14.366567 1.78174391 21.288421h74.71660649l-52.8369521 52.83293c8.3094422 11.659764 18.5132443 21.867588 30.1730086 30.173009l52.8329301-52.836952v74.720628c6.91381 1.158335 14.004588 1.769678 21.240157 1.777722h.18099c7.239591-.008044 14.338413-.619387 21.240157-1.777722v-74.720628l52.836952 52.836952c5.827871-4.150699 11.293763-8.775994 16.341367-13.819576l.012066-.012066c5.03956-5.047604 9.668877-10.517518 13.815554-16.341367l-52.836952-52.83293h74.72465c1.162357-6.909788 1.769678-14.000566 1.777722-21.240157v-.185012c-.008044-7.239591-.615365-14.330369-1.777722-21.240157z" fill="#ff4a00"/></svg>
                                <h4 class="mt-2 mb-1">Zapier Webhook For {text}</h4>
                            </div>

                            <div class="form-group mt-3">
                                <label><small>Webhook URL</small></label>
                                <input type="url" class={`form-control zaap-form-control`} name={type} onChange={handleChange} value={value?.type} required="required" placeholder="Webhook URL" />
                                <ModalErrors text={error} />
                            </div>

                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Modal>
    )
}