const INIT_STATE = {
    stripe: null,
    paypal: null,
    productType:  "paid"
}
const stripeConnectReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_STRTPE_CONNECT':
            return action.payload;
        case 'RESET_STRTPE_CONNECT':
            return INIT_STATE;
        default:
            return state
    }

}
export default stripeConnectReducer