import copyClipboard from "../assets/img/copy-clipboard.svg"
import gearIcon from "../assets/img/gear.svg"
import communityIcon from "../assets/img/community.svg"
import tagIcon from "../assets/img/tag.svg"
import barChartIcon from "../assets/img/bar-chart.svg"
import pageActivity from "../assets/img/page-activity.svg"
import forwardArrow from "../assets/img/forward-arrow.svg"
import readOurTutorialsIcon from "../assets/img/read-our-tutorials.svg"
import envelopeIcon from "../assets/img/envelope.svg"
import copyClipboardWhite from "../assets/img/copy-clipboard-white.svg"
import instaIcon from "../assets/img/inst.svg"
import youtubeIcon from "../assets/img/youtube.svg"
import tiktokIcon from "../assets/img/tiktok.svg"
import communityGraph from "../assets/img/community-graph.svg"
import dashboardMail from "../assets/img/dashboard-mail.svg"
import SideBar from "../components/side-bar/dashboard";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
import "../assets/css/dashboard.css";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import { showLoader, hideLoader } from "../components/loader";
import DashboardProjectsModal from "../components/dashboard/projects/modal";
import { apiUrl, pageUrl, protocol } from "../utils/url";
import { toast } from "react-toastify";
import goldImg from "../assets/img/gold.svg"
import { DashboardHeader } from "../components/dashboard/projects/DashboardHeader"
import { useDispatch ,useSelector } from "react-redux"
import ConnectCalendarModal from "../components/connect-calendar-modal"
import UpgradeModal from "../components/upgrade/upgrade-modal"

const Dashboard = () => {
    const dispatch = useDispatch();
    const [isLoading, setisLoading] = useState(true)
    const [isProjectLoading, setisProjectLoading] = useState(true)
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(true)
    const [sharedProjects, setSharedProjects] = useState([]);
    const [showModal, setShowModal] = useState({ show: false, duplicate: false });
    const [basePath, setbasePath] = useState(null)
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [selectedSlug, setSelectedSlug] = useState(null)
    const [stats, setStats] = useState([]);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const getProjects = () => {
        showLoader();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        fetch(apiUrl + "/project/get-all", { headers: myHeaders })
            .then(async (res) => {
                const response = await res.json();
                dispatch({ type: "SET_NEW_USER", payload: response?.data.projects[0]?.new_user == 1 ? true : false });
                // if (response?.data?.new_user == 1 && response?.data.projects[0]?.pro !== 1) {
                //     setShowUpgradeModal(true);
                // }
                setProjects(response?.data.projects)
                setSharedProjects(response?.data?.shared_projects)
                setSelectedProfile(response?.data.projects[0]?.id)
                setSelectedSlug(response?.data.projects[0]?.slug)
                setbasePath("/project/" + response?.data.projects[0]?.slug)
                setSelectedProject(true)
                setisProjectLoading(false)
                setisLoading(false)
                hideLoader()
            }).catch((err) => {
                
// console.log(err)
                setisProjectLoading(false)
                hideLoader()
            })
        hideLoader()
    }
    const handleCloseModal = () => setShowModal({ show: false, duplicate: false })
    const handleOpenModal = () => setShowModal({ show: true, duplicate: false })
    const copyingText = (text) => navigator.clipboard.writeText(text).then((r => toast.success("Successfully Copied")))
    const order = Math.min((stats?.order_sum / 10), 100);
    useEffect(() => {
        if (projects?.length == 0)
            getProjects()
        else localStorage.removeItem("sendRequest")
    }, []);
    const closeUpgradeModal = () => setShowUpgradeModal(false)
    return (
        <div id="wrapper">
            <PageHeader from="dashboard" isPro={stats?.pro} projects={projects} selectedProfile={selectedProfile} />
            <div className="dashboard-main">
                <SideBar setShowModal={setShowModal} sharedProjects={sharedProjects} setSelectedProfile={setSelectedProfile} getProjects={getProjects} selectedProfile={selectedProfile} isLoading={isProjectLoading} projects={projects} handleOpenModal={handleOpenModal} setbasePath={setbasePath} setStats={setStats} isPro={stats?.pro} selectedSlug={selectedSlug} setSelectedProject={setSelectedProject} />
                {!isLoading ?
                    <section className="dashboard-section">
                        <DashboardHeader projects={selectedProject ? projects:sharedProjects} selectedProfile={selectedProfile} stats={stats} selectedProject={selectedProject} />
                        {/* {projects?.filter((val) => val?.id == selectedProfile).map((value, index) => (
                            <>
                                <div className="project-details-main d-flex align-items-center" key={index}>
                                    <div className="project-user-pp">
                                        <img src={value?.settings[1]?.value} width="100%" alt="" />
                                    </div>
                                    <div className="project-details d-flex align-items-start justify-content-center flex-column ps-2">
                                        <div className="project-title d-flex align-items-center">
                                            <h4 className="mb-0">{value?.settings[0]?.value}</h4>
                                            {stats?.pro == 1 ? (<span className="ms-2 zaap-badge badge-free badge-pro">PRO</span>) : (<span className="ms-2 zaap-badge badge-free">FREE</span>)}
                                        </div>
                                        <div className="project-link d-flex align-items-center">
                                            <a href={`${protocol + pageUrl}/${value?.slug}`} target="blank" className="text-muted-light">{`${pageUrl}/${value?.slug}`}</a>
                                            <img className="ms-3 mt-1 copy-clipboard"
                                                data-toggle="tooltip" title={`${pageUrl}/${value?.slug}`}
                                                src={copyClipboard}
                                                alt="" onClick={() => copyingText(`${protocol + pageUrl}/${value?.slug}`)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="project-links-main d-flex align-items-center justify-content-start">
                                    <Link to={`project/${value?.slug}/page-editor`} className="project-link d-flex align-items-center justify-content-center text-decoration-none">
                                        <img src={gearIcon} width="17px" alt="" />
                                        <span>Edit Page</span>
                                    </Link>
                                    <Link to={`project/${value?.slug}/audience`} className="project-link d-flex align-items-center justify-content-center text-decoration-none">
                                        <img src={communityIcon} width="17px" alt="" />
                                        <span>Community</span>
                                    </Link>
                                    <Link to={`project/${value?.slug}/products`} className="project-link d-flex align-items-center justify-content-center text-decoration-none">
                                        <img src={tagIcon} width="17px" alt="" />
                                        <span>Monetization</span>
                                    </Link>
                                    <Link to={`project/${value?.slug}/analytics`} className="project-link d-flex align-items-center justify-content-center text-decoration-none">
                                        <img src={barChartIcon} width="17px" alt="" />
                                        <span>Analytics</span>
                                    </Link>
                                </div>
                            </>
                        ))} */}
                        <div className="dashboard-analytics d-flex">
                            <div className="d-flex flex-column align-items-center justify-content-between dashboard-analytics-first-division">
                                <div className="page-activity">
                                    <h5 className="mb-2">Page Activity</h5>
                                    <p className="mb-0"><small>Unique Visitors</small></p>
                                    <h1 className="">{stats?.page_activity?.unique_visitors}</h1>

                                    <img src={pageActivity} width="100%" alt="" />
                                    <p className="mt-3 mb-0"><small>Total Impressions</small></p>
                                    <h1 className="mb-0">{stats?.page_activity?.impressions}</h1>
                                    <img src={pageActivity} width="100%" alt="" />

                                    <Link to={`project/${stats?.project?.slug}/analytics`} className="btn btn-block see-more-btn mt-3">
                                        <span className="me-2">See More</span>
                                        <img src={forwardArrow} alt="" />
                                    </Link>
                                </div>
                                <div class="tutorials d-flex align-items-center ps-4">
                                    <h4>Read our tutorials.</h4>
                                    <img src={readOurTutorialsIcon} alt="" />
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-between ps-2 dashboard-analytics-second-division">
                                {stats?.products == 0 ?
                                    <div className="sales">
                                        <h5 className="mb-2">Create new product</h5>
                                        <p className="mb-2">Start selling products from your page.</p>

                                        <Link to={`project/${stats?.project?.slug}/products/newProducts/`} className="btn btn-block see-more-btn mt-3">
                                            <span className="me-2">Create Product</span>
                                            <img src={forwardArrow} alt="" />
                                        </Link>
                                    </div> :
                                    <div className="sales">
                                        <h5 className="mb-2">Sales</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="col-4">
                                                <p className="mb-0"><small>Today</small></p>
                                                <h1 className="mb-0">${(stats?.sales?.today?.revenue) ?? 0}</h1>
                                                <p className="mb-0"><small>{(stats?.sales?.today?.sales) ?? 0} Sales</small></p>
                                            </div>
                                            <div className="col-4">
                                                <p className="mb-0"><small>7 Days</small></p>
                                                <h1 className="mb-0">${(stats?.sales?.seven_days?.revenue) ?? 0}</h1>
                                                <p className="mb-0"><small>{(stats?.sales?.seven_days?.sales) ?? 0} Sales</small></p>
                                            </div>
                                            <div className="col-4">
                                                <p className="mb-0"><small>30 Days</small></p>
                                                <h1 className="mb-0">${stats?.sales?.thirty_days?.revenue}</h1>
                                                <p className="mb-0"><small>{stats?.sales?.thirty_days?.revenue} Sales</small></p>
                                            </div>
                                        </div>
                                    </div>}

                                {stats && stats?.latest_email && stats.latest_email?.length > 0 ?
                                    (
                                        <>

                                            <div className="latest-emails">
                                                <h5 className="mb-2">Latest Emails</h5>
                                                <div className="d-flex">
                                                    <div className="row">
                                                        <div className="col-9 pe-0">
                                                            <p className="mb-0"><small>Email:</small></p>
                                                        </div>
                                                        <div className="col-3 ps-1">
                                                            <p className="mb-0"><small>Opens:</small></p>
                                                        </div>

                                                        {stats.latest_email.map((val, key) => {
                                                            return <>
                                                                <div className="col-9 pe-0">
                                                                    <p className="pills">{val.subject}</p>
                                                                </div>
                                                                <div className="col-3 ps-1">
                                                                    <p className="pills d-flex align-items-center">
                                                                        <img src={envelopeIcon} width="16px" alt="" />&nbsp;{val.opens}
                                                                    </p>
                                                                </div>
                                                            </>
                                                        })}

                                                    </div>


                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>

                                            <div className="latest-emails d-flex p-0">
                                                <h5 className="mb-2 d-flex align-items-center">Send an email newsletter to your audience.</h5>
                                                <img src={dashboardMail} alt="" />
                                            </div>
                                        </>
                                    )}

                                <div className="share-your-page">
                                    <h5 className="mb-2">Share your page</h5>
                                    <h5>Copy your page link to share everywhere.</h5>
                                    <div className="copy-link d-flex justify-content-between align-items-center">
                                        <a className="text-decoration-none text-light" href={protocol + '' + pageUrl + '/' + stats?.project?.slug} target="blank"><span>{protocol + '' + pageUrl + '/' + stats?.project?.slug}</span></a>
                                        <button className="copy-btn btn btn-sm" onClick={() => copyingText(protocol + '' + pageUrl + '/' + (stats?.project?.slug))}>
                                            <img src={copyClipboardWhite} width="15px" alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-between ps-2 dashboard-analytics-third-division">
                                <div className="community">
                                    <h5 className="mb-2">Community</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="col-6">
                                            <p className="mb-0"><small>Today</small></p>
                                            <h1 className="mb-0">{stats?.community?.community_today}</h1>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0"><small>24 hours</small></p>
                                            <h1 className="mb-0">{stats?.community?.community_24_hours}</h1>
                                        </div>
                                    </div>
                                    <img src={communityGraph} width="100%" alt="" />
                                </div>
                                {/* <div className="socials">
                                    <h5 className="mb-2">Your Socials</h5>
                                    <div className="d-flex align-items-center flex-column">

                                        <div className="col-12 social-divs">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <p className="mb-0"><small>Instagram</small></p>
                                                    <h1 className="mb-0">458k</h1>
                                                </div>
                                                <img src={instaIcon} width="50px" alt="" />
                                            </div>
                                        </div>

                                        <div className="col-12 social-divs">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <p className="mb-0"><small>Youtube</small></p>
                                                    <h1 className="mb-0">-</h1>
                                                </div>
                                                <img src={youtubeIcon} width="50px" alt="" />
                                            </div>
                                        </div>

                                        <div className="col-12 social-divs">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <p className="mb-0"><small>TikTok</small></p>
                                                    <h1 className="mb-0">1.8M</h1>
                                                </div>
                                                <img src={tiktokIcon} width="50px" alt="" />
                                            </div>
                                        </div>

                                    </div>
                                </div> */}
                                <div className="card dashboard-revenue-card">
                                    <div className="card-header dashboard-revenue-card-header">
                                        <img className="card-img-top" src={goldImg} alt="" />
                                    </div>
                                    <div className="card-body dashboard-revenue-card-body">
                                        <h5 className="card-title my-1"><h3>$1k Award</h3></h5>
                                        <p className="card-text">The golden dollar award for when you reach $1k in revenue.</p>
                                        <div className="card-footer dashboard-revenue-card-footer">
                                            <div className="row">
                                                <div className="col-6 d-flex justify-content-start">$0</div>
                                                <div className="col-6 d-flex justify-content-end">$1000</div>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: `${order}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="dashboard-section">

                        <div className="project-details-main d-flex align-items-center">
                            <div className="project-user-pp">
                                <Skeleton
                                    circle
                                    height={70}
                                    width={70}
                                    containerClassName="avatar-skeleton"
                                />
                            </div>
                            <div className="project-details d-flex align-items-start justify-content-center flex-column ps-2">
                                <div className="project-title d-flex align-items-center">
                                    <Skeleton width={100} className="ms-2 zaap-badge badge-free" />
                                    <Skeleton width={50} className="ms-2 zaap-badge badge-free" />
                                </div>
                                <div className="project-link d-flex align-items-center">
                                    <Skeleton width={150} className="text-muted-light ms-2" />
                                    <Skeleton count={1} className="ms-3 mt-1 copy-clipboard" />
                                </div>
                            </div>
                        </div>
                        <div className="project-links-main d-flex align-items-center justify-content-start">

                            <Skeleton width={120} style={{ padding: "8px 15px", marginRight: "10px", borderRadius: "10px" }} className=" d-flex align-items-center justify-content-center" />
                            <Skeleton width={120} style={{ padding: "8px 15px", marginRight: "10px", borderRadius: "10px" }} className=" d-flex align-items-center justify-content-center" />
                            <Skeleton width={120} style={{ padding: "8px 15px", marginRight: "10px", borderRadius: "10px" }} className=" d-flex align-items-center justify-content-center" />
                            <Skeleton width={120} style={{ padding: "8px 15px", marginRight: "10px", borderRadius: "10px" }} className=" d-flex align-items-center justify-content-center" />

                        </div>

                        <div className="dashboard-analytics d-flex">
                            <div className="d-flex flex-column align-items-center justify-content-between dashboard-analytics-first-division">
                                <div className="page-activity">
                                    <h5 className="mb-2">Page Activity</h5>
                                    <p className="mb-0"><small>Unique Visitors</small></p>
                                    <Skeleton width={50} height={40}
                                        className="mb-2"
                                        baseColor="#FFBE23"
                                        highlightColor="#FF8524"
                                        borderRadius="0.5rem"
                                        duration={4}
                                    />
                                    <img src={pageActivity} width="100%" alt="" />
                                    <p className="mt-3 mb-0"><small>Total Impressions</small></p>
                                    <Skeleton width={50} height={40}
                                        className="mb-2"
                                        baseColor="#FFBE23"
                                        highlightColor="#FF8524"
                                        borderRadius="0.5rem"
                                        duration={4}
                                    />
                                    <img src={pageActivity} width="100%" alt="" />

                                    <button className="btn btn-block see-more-btn mt-3">
                                        <span className="me-2">See More</span>
                                        <img src={forwardArrow} alt="" />
                                    </button>
                                </div>
                                <div className="tutorials d-flex align-items-center ps-4 mb-3">
                                    <h4>Read our tutorials.</h4>
                                    <img src={readOurTutorialsIcon} alt="" />
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center col-5 ps-2 dashboard-analytics-second-division">
                                <div className="sales">
                                    <h5 className="mb-2">Sales</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="col-4">
                                            <p className="mb-0"><small>Today</small></p>

                                            <Skeleton width={50} height={40}
                                                className="mb-2"
                                                baseColor="#C31AFF"
                                                highlightColor="#680CFF"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                            <Skeleton width={50} height={20}
                                                className="mb-2"
                                                baseColor="#C31AFF"
                                                highlightColor="#680CFF"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <p className="mb-0"><small>7 Days</small></p>

                                            <Skeleton width={50} height={40}
                                                className="mb-2"
                                                baseColor="#C31AFF"
                                                highlightColor="#680CFF"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                            <Skeleton width={50} height={20}
                                                className="mb-2"
                                                baseColor="#C31AFF"
                                                highlightColor="#680CFF"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <p className="mb-0"><small>30 Days</small></p>

                                            <Skeleton width={50} height={40}
                                                className="mb-2"
                                                baseColor="#C31AFF"
                                                highlightColor="#680CFF"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                            <Skeleton width={50} height={20}
                                                className="mb-2"
                                                baseColor="#C31AFF"
                                                highlightColor="#680CFF"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="latest-emails">
                                    <h5 className="mb-2">Latest Emails</h5>
                                    <div className="d-flex">
                                        <div className="col-9 pe-2">
                                            <p className="mb-0"><small>Email:</small></p>
                                            <Skeleton height={30} className="pills" baseColor="#CA0049" highlightColor="#ca0049a8" />
                                            <Skeleton height={40} className="pills" baseColor="#CA0049" highlightColor="#ca0049a8" />
                                            <Skeleton height={40} className="pills" baseColor="#CA0049" highlightColor="#ca0049a8" />
                                        </div>
                                        <div className="col-3">
                                            <p className="mb-0"><small>Opens:</small></p>
                                            <Skeleton height={30} className="pills" baseColor="#CA0049" highlightColor="#ca0049a8" />
                                            <Skeleton height={30} className="pills" baseColor="#CA0049" highlightColor="#ca0049a8" />
                                            <Skeleton height={30} className="pills" baseColor="#CA0049" highlightColor="#ca0049a8" />
                                        </div>

                                    </div>
                                </div>
                                <div className="share-your-page">
                                    <h5 className="mb-2">Share your page</h5>
                                    <h5>Copy your page link to share everywhere.</h5>
                                    <Skeleton className="copy-link" height={40} baseColor="#DFAE02" highlightColor="#F5B40A" />
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-between ps-2 dashboard-analytics-third-division">
                                <div className="community">
                                    <h5 className="mb-2">Community</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="col-6">
                                            <p className="mb-0"><small>Today</small></p>
                                            <Skeleton width={50} height={40}
                                                className="mb-2"
                                                baseColor="#1E011B"
                                                highlightColor="#384159"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0"><small>24 hours</small></p>
                                            <Skeleton width={50} height={40}
                                                className="mb-2"
                                                baseColor="#1E011B"
                                                highlightColor="#384159"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                    </div>
                                    <img src={communityGraph} width="100%" alt="" />
                                </div>
                                {/* <div className="socials">
                                    <h5 className="mb-2">Your Socials</h5>
                                    <div className="d-flex align-items-center flex-column">

                                        <div className="col-12 social-divs">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <p className="mb-0"><small>Instagram</small></p>
                                                    <Skeleton width={50} height={40}
                                                        className="mb-2"
                                                        baseColor="#0C77C4"
                                                        highlightColor="#1A91FF"
                                                        borderRadius="0.5rem"
                                                        duration={4}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 social-divs">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <p className="mb-0"><small>Youtube</small></p>
                                                    <Skeleton width={50} height={40}
                                                        className="mb-2"
                                                        baseColor="#0C77C4"
                                                        highlightColor="#1A91FF"
                                                        borderRadius="0.5rem"
                                                        duration={4}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 social-divs">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <p className="mb-0"><small>TikTok</small></p>
                                                    <Skeleton width={50} height={40}
                                                        className="mb-2"
                                                        baseColor="#0C77C4"
                                                        highlightColor="#1A91FF"
                                                        borderRadius="0.5rem"
                                                        duration={4}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}
                            </div>
                        </div>

                    </section>

                }
            </div>

            <DashboardProjectsModal showModal={showModal} handleCloseModal={handleCloseModal} getProjects={getProjects} />
            {showUpgradeModal && <UpgradeModal isOpen={showUpgradeModal} onHide={closeUpgradeModal} from={'dashboard'}/>}
        </div>
    )
}
export default Dashboard