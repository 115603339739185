export const ChevronRight = () => (
    <svg width="10" height="11" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.339129 0.315168C0.7913 -0.105056 1.52442 -0.105056 1.97659 0.315168L5.66087 3.73915C6.11304 4.15938 6.11304 4.84069 5.66087 5.26092C5.2087 5.68114 4.47558 5.68115 4.02341 5.26092L0.339129 1.83694C-0.113043 1.41671 -0.113043 0.735394 0.339129 0.315168Z" fill="#999999" />
        <path d="M5.66087 3.73915C6.11304 4.15938 6.11304 4.84069 5.66087 5.26092L1.97715 8.68483C1.52498 9.10506 0.791862 9.10506 0.339691 8.68483C-0.112481 8.26461 -0.112481 7.58329 0.339691 7.16306L4.02397 3.73908C4.47615 3.31886 5.2087 3.31893 5.66087 3.73915Z" fill="#999999" />
    </svg>
);
export const ChevronDown = () => (
    <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6148 0.39565C11.1284 0.923184 11.1284 1.77849 10.6148 2.30602L6.42993 6.60435C5.91632 7.13188 5.0836 7.13188 4.56999 6.60435C4.05638 6.07682 4.05638 5.22151 4.56999 4.69398L8.75485 0.39565C9.26846 -0.131883 10.1012 -0.131884 10.6148 0.39565Z" fill="#999999" />
        <path d="M6.42993 6.60435C5.91632 7.13188 5.0836 7.13188 4.56999 6.60435L0.385206 2.30668C-0.128402 1.77914 -0.128402 0.92384 0.385206 0.396306C0.898814 -0.131227 1.73154 -0.131228 2.24515 0.396306L6.43001 4.69464C6.94362 5.22217 6.94354 6.07682 6.42993 6.60435Z" fill="#999999" />
    </svg>
)