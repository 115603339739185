import { Route, useHistory, useLocation } from "react-router-dom";
import authRoutes from "../../routes/authRoutes";
import "../../assets/css/app.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/media_queries.css";
import "../../assets/css/style.css";
import "../../assets/css/auth.css";
import { useEffect } from "react";
import routes from "../../routes/mainRoutes";
import GoogleOauth from "../../pages/google-oauth";
import { useState } from "react";
// import 'bootstrap/dist/js/bootstrap.bundle.min';

const AuthLayout = () => {
  const location = useLocation();
  const history = useHistory();
  const [googleOauth, setgoogleOauth] = useState(null)
  useEffect(() => {
    const authpath = authRoutes.filter((val) => val.path == location.pathname);
    const matchPath = routes.filter((val) => val.path == location.pathname);
    if (matchPath.length > 0 && !localStorage.getItem("user")) {
      if (location.pathname !== "/callback-login") history.push("/login");
    } else if (
      matchPath.length > 0 &&
      !localStorage.getItem("user") &&
      location.pathname == "/"
    ) {
      history.push("/login");
    } else if (matchPath.length > 0 && localStorage.getItem("user")) {
      history.push("/dashboard");
    } else if (authpath.length == 0) {
      history.push("/login");
    } else if (authpath.length > 0) {
    } else if (matchPath.length == 0) {
      history.push("/dashboard");
    }
    setgoogleOauth(<Route path={"/google-oauth"} component={() => <GoogleOauth />} />)
  }, []);

  return (
    <>
      {authRoutes.map((rt, key) => (
        rt?.path !== "/google-oauth" ? 
        <Route
          exact={rt.exact}
          key={key}
          component={() => <rt.component />}
          path={rt.path}
        />
        : googleOauth
      ))}
    </>
  );
};
export default AuthLayout;
