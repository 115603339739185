const INIT_STATE = null;
const plantypeReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_PlAN_TYPE':
            return action.payload 
        case 'RESET_PlAN_TYPE':
            return INIT_STATE
        default:
            return state
    }

}
export default plantypeReducer