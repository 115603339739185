const INIT_STATE = {
    font_family:'Default'
}
const customizeFontReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_CUSTOMIZE_FONT':
            return action.payload 
        case 'RESET_CUSTOMIZE_FONT':
            return INIT_STATE
        default:
            return state
    }
}
export default customizeFontReducer