import repeatBlackIcon from "../../../assets/img/modals/repeat-black.svg";
import repeatIcon from "../../../assets/img/modals/repeat.svg";
import integration_google_analytics from "../../../assets/img/modals/integration-google-analytics.svg";
import integration_facebook_analytics from "../../../assets/img/modals/integration-facebook-pixel.svg";
import integration_mailchimp from "../../../assets/img/modals/integration-mailchimp.svg";
import integration_convertkit from "../../../assets/img/modals/integration-convertkit.svg";
import integration_sendinblue from "../../../assets/img/modals/integration-sendinblue.svg";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../../loader";
import returnIcon from "../../../assets/img/modals/return.svg";
import { useDispatch, useSelector } from "react-redux";
import ModalErrors from "../../errors/modal-errors";
import { toast } from "react-toastify"
import { apiUrl } from "../../../utils/url";


const IntegrationsCustomize = ({ setShowCustomizeParent }) => {
    const dispatch = useDispatch()
    const [show, setshow] = useState(false);
    const location = useLocation()
    const history = useHistory()
    const { subPath } = useParams();
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const isPro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const customizePageReducer = useSelector(state => state?.customizePageReducer);
    const integrationGoogleReducer = useSelector(state => state?.integrationGoogleReducer);
    const integrationFacebookReducer = useSelector(state => state?.integrationFacebookReducer);
    const integrationMailChimpReducer = useSelector(state => state?.integrationMailChimpReducer);
    const integrationConvertkitReducer = useSelector(state => state?.integrationConvertkitReducer);
    const integrationSendinblueReducer = useSelector(state => state?.integrationSendinblueReducer);

    const setParams = (paramName, tabName) => {
        showLoader()
        if (!isPro && !newUser) {
            dispatch({ type: "SET_UPGRADE_MODAL", payload: { isPro: true, open: true } })
            hideLoader()
            history.push( `${basePath}/page-editor`)
         return
        }
        setTimeout(() => {
            hideLoader()
            history.push({
                pathname: `${basePath}/page-editor/${paramName}/${tabName}`,
                state: { showCustomizeParent: true }
            })

        }, 300);
    }
    useEffect(() => {
        if (location.pathname == basePath + "/page-editor/customizePageModal/customizePageIntegrations/googleAnalytics") {
            setshow(true)
        }
        else {
            setshow(false)
        }
    }, [location.pathname]);

    const diconnecting = (name, values, actionType) => {
        showLoader();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const errorList = []
        const formdata = new FormData();
        const formValues = { project: slug, tab: "integrations", integration: name, disconnect: 1 };

        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                formdata.append(key, formValues[key]);
            }
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + `/project/update`, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {

                    let temp_obj = { ...values }
                    for (const key in temp_obj) {
                        if (Object.hasOwnProperty.call(temp_obj, key)) {
                            delete temp_obj["disconnect"];
                        }
                        dispatch({ type: actionType, payload: { ...temp_obj } })
                    }

                    toast.success(response?.status_message);
                    hideLoader();

                    setTimeout(() => {
                        handleClose(true)
                    }, 1000);
                }

                else {
                    let errorList = []
                    response?.errors?.link?.map((val) => errorList?.push(val));
                    response?.errors?.pixel_id?.map((val) => errorList?.push(val));
                    response?.errors?.analytics_id?.map((val) => errorList?.push(val));
                    toast.error(response?.status_message)
                    hideLoader()
                }
            }).catch((err) => {
                hideLoader()
            })
    }

    return (
        <>
            <div class="tab-pane fade customize-page-main active show" id="customizePageIntegrations" role="tabpanel" aria-labelledby="social-tab">
                <h4>Integrations</h4>

                <section>

                    <div class="form-group mt-3">
                        <div class="d-flex align-items-center justify-content-start flex-wrap">

                            <div class="integration-card mb-3 me-3">
                                <img src={integration_google_analytics} alt="" />
                                <h5 class="mb-1 mt-1">Google<br />Analytics</h5>
                                <label class="text-lightgray">Analytics</label>

                                {
                                    (!integrationGoogleReducer?.disconnect) ?
                                        <button data-bs-dismiss="modal" data-bs-toggle="modal"
                                            data-bs-target="#googleAnalyticsModal"
                                            class="btn text-white btn-rounded btn-sm w-100 py-2 mt-2 d-flex align-items-center
    justify-content-center google-analytics-btn"
                                            onClick={() => setParams("customizePageModal/customizePageIntegrations", "googleAnalytics")}
                                        >
                                            <img src={repeatIcon} class="me-2" alt="" />
                                            <span><b>Connect</b></span>
                                        </button>
                                        :

                                        <button data-bs-dismiss="modal"
                                            class="btn text-white btn-rounded btn-sm w-100 py-2 mt-2 d-flex align-items-center
    justify-content-center google-analytics-btn"
                                            onClick={() => diconnecting("google", integrationGoogleReducer, "SET_GOOGLE_INTEGRATION")}
                                        >
                                            <img src={repeatIcon} class="me-2" alt="" />
                                            <span><b>Disconnect</b></span>
                                        </button>
                                }
                            </div>
                            <div class="integration-card mb-3 me-3">
                                <img src={integration_facebook_analytics} alt="" />
                                <h5 class="mb-1 mt-1">Facebook<br />Pixel</h5>
                                <label class="text-lightgray">Tracking</label>

                                {
                                    (!integrationFacebookReducer?.disconnect) ?
                                        <button data-bs-dismiss="modal" data-bs-toggle="modal"
                                            data-bs-target="#facebookPixelModal"
                                            class="btn text-white btn-rounded btn-sm w-100 py-2 mt-2 d-flex align-items-center
    justify-content-center facebook-pixel-btn"
                                            onClick={() => setParams("customizePageModal/customizePageIntegrations", "facebookPixel")}
                                        >
                                            <img src={repeatIcon} class="me-2" alt="" />
                                            <span><b>Connect</b></span>
                                        </button>
                                        :

                                        <button data-bs-dismiss="modal"
                                            class="btn text-white btn-rounded btn-sm w-100 py-2 mt-2 d-flex align-items-center
    justify-content-center facebook-pixel-btn"
                                            onClick={() => diconnecting("facebook", integrationFacebookReducer, "SET_FACEBOOK_INTEGRATION")}
                                        >
                                            <img src={repeatIcon} class="me-2" alt="" />
                                            <span><b>Disconnect</b></span>
                                        </button>
                                }
                            </div>

                            <div class="integration-card mb-3 me-3">
                                <img src={integration_mailchimp} alt="" />
                                <h5 class="mb-1 mt-1">Mailchimp</h5>
                                <label class="text-lightgray mb-2">Email marketing</label>

                                {
                                    (!integrationMailChimpReducer?.disconnect) ?
                                        <button data-bs-dismiss="modal" data-bs-toggle="modal"
                                            data-bs-target="#mailchimpModal"
                                            class="btn btn-rounded btn-sm w-100 py-2 mt-4 d-flex align-items-center justify-content-center 
                                            mailchimp-btn"
                                            onClick={() => setParams("customizePageModal/customizePageIntegrations", "mailchimp")}
                                        >
                                            <img src={repeatIcon} class="me-2" alt="" />
                                            <span><b>Connect</b></span>
                                        </button>
                                        :

                                        <button data-bs-dismiss="modal"
                                            class="btn btn-rounded btn-sm w-100 py-2 mt-4 d-flex align-items-center justify-content-center 
                                            mailchimp-btn"
                                            onClick={() => diconnecting("mailchimp", integrationMailChimpReducer, "SET_MAILCHIMP_INTEGRATION")}
                                        >
                                            <img src={repeatIcon} class="me-2" alt="" />
                                            <span><b>Disconnect</b></span>
                                        </button>
                                }
                            </div>

                            <div class="integration-card mb-3 me-3">
                                <img src={integration_convertkit} alt="" />
                                <h5 class="mb-1 mt-1">Convertkit</h5>
                                <label class="text-lightgray mb-2">Email marketing</label>

                                {
                                    (!integrationConvertkitReducer?.disconnect) ?
                                        <button data-bs-dismiss="modal" data-bs-toggle="modal"
                                            data-bs-target="#convertkitModal"
                                            class="btn btn-rounded text-white btn-sm w-100 
                                            py-2 mt-4 d-flex align-items-center justify-content-center convertkit-btn"
                                            onClick={() => setParams("customizePageModal/customizePageIntegrations", "convertkit")}
                                        >
                                            <img src={repeatIcon} class="me-2" alt="" />
                                            <span><b>Connect</b></span>
                                        </button>
                                        :

                                        <button data-bs-dismiss="modal"
                                            class="btn btn-rounded text-white btn-sm w-100 
                                            py-2 mt-4 d-flex align-items-center justify-content-center convertkit-btn"
                                            onClick={() => diconnecting("convertkit", integrationConvertkitReducer, "SET_CONVERTKIT_INTEGRATION")}
                                        >
                                            <img src={repeatIcon} class="me-2" alt="" />
                                            <span><b>Disconnect</b></span>
                                        </button>
                                }
                            </div>

                            <div class="integration-card mb-3 me-3">
                                <img src={integration_sendinblue} alt="" />
                                <h5 class="mb-1 mt-1">SendinBlue</h5>
                                <label class="text-lightgray mb-2">Email marketing</label>

                                {
                                    (!integrationSendinblueReducer?.disconnect) ?
                                        <button data-bs-dismiss="modal" data-bs-toggle="modal"
                                            data-bs-target="#sendinblueModal"
                                            class="btn btn-rounded text-white btn-sm w-100 
                                            py-2 mt-4 d-flex align-items-center justify-content-center sendinblue-btn"
                                            onClick={() => setParams("customizePageModal/customizePageIntegrations", "sendinblue")}
                                        >
                                            <img src={repeatIcon} class="me-2" alt="" />
                                            <span><b>Connect</b></span>
                                        </button>
                                        :

                                        <button data-bs-dismiss="modal"
                                            class="btn btn-rounded text-white btn-sm w-100 
                                            py-2 mt-4 d-flex align-items-center justify-content-center sendinblue-btn"
                                            onClick={() => diconnecting("sendinblue", integrationSendinblueReducer, "SET_SENDINBLUE_INTEGRATION")}
                                        >
                                            <img src={repeatIcon} class="me-2" alt="" />
                                            <span><b>Disconnect</b></span>
                                        </button>
                                }
                            </div>

                        </div>
                    </div>

                </section>
            </div>


        </>
    )
}
export default IntegrationsCustomize;
export const GoogleAnalyticsModal = ({ show, handleClose, error, handleSubmit }) => {

    const dispatch = useDispatch()
    const value = useSelector(state => state?.integrationGoogleReducer);
    const handleChange = (e) => dispatch({ type: "SET_GOOGLE_INTEGRATION", payload: { ...value, [e.target.name]: e.target.value } });

    return (

        <Modal show={show} onHide={handleClose} id="googleAnalyticsModal" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value, "google")}>
                            <div class="notification-container"></div>


                            <div class="form-group">
                                <img src={integration_google_analytics} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Google Analytics</h4>
                            </div>

                            <div class="form-group mt-3">
                                <label><small>Google Measurement ID</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="analytics_id" value={value?.analytics_id} required="required" placeholder="Google Measurement ID" onChange={handleChange} />
                                <p style={{ marginBottom: 0 }}><ModalErrors text={error} /></p>
                                <small class="text-muted-light">Enable tracking with Google Analytics by adding your Measurement ID<br />(ex: G-22222222-33)</small>
                            </div>

                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Modal>
    )
}
export const FacebookPixelModal = ({ show, handleClose, handleSubmit, error }) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state?.integrationFacebookReducer);

    const handleChange = (e) => dispatch({ type: "SET_FACEBOOK_INTEGRATION", payload: { ...value, [e.target.name]: e.target.value } });
    return (

        <Modal show={show} onHide={handleClose} id="facebookPixelModal" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value, "facebook")}>
                            <div class="notification-container"></div>


                            <div class="form-group">
                                <img src={integration_facebook_analytics} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Facebook Pixel</h4>
                            </div>

                            <div class="form-group mt-3">
                                <label><small>Facebook Pixel ID</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="pixel_id" onChange={handleChange} value={value?.pixel_id} required="required" placeholder="Facebook Pixel ID" />
                                <ModalErrors text={error} />
                                <small class="text-muted-light">Enable the Facebook Pixel by adding your only the Facebook Pixel ID.</small>
                            </div>

                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Modal>
    )
}
export const MailchimpModal = ({ show, handleClose, handleSubmit, error }) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state?.integrationMailChimpReducer);
    const handleChange = (e) => dispatch({ type: "SET_MAILCHIMP_INTEGRATION", payload: { ...value, [e.target.name]: e.target.value } });
    return (
        <Modal show={show} onHide={handleClose} id="mailchimpModal" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">
                    <div class="modal-body">
                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value, "mailchimp")}>
                            <div class="notification-container"></div>
                            <div class="form-group">
                                <img src={integration_mailchimp} width="40px" alt="" />
                                <h4 class="mt-2 mb-1">Connect Mailchimp</h4>
                                <p class="text-gray font-weight-500">Send new email subscribers to your Mailchimp list from Zaap.</p>
                            </div>

                            <div class="form-group">
                                <label><small>API Key</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="api_key" value={value?.api_key} onChange={handleChange} required="required" placeholder="API Key" />
                            </div>

                            <div class="form-group mt-2">
                                <label><small>Server Prefix</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="server_prefix" value={value?.server_prefix} onChange={handleChange} required="required" placeholder="Server Prefix" />
                            </div>

                            <div class="form-group mt-2">
                                <label><small>List ID</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="list_id" value={value?.list_id} onChange={handleChange} required="required" placeholder="List ID" />
                            </div>


                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export const ConvertkitModal = ({ show, handleClose, handleSubmit, error }) => {


    const dispatch = useDispatch()
    const value = useSelector(state => state?.integrationConvertkitReducer);
    const handleChange = (e) => dispatch({ type: "SET_CONVERTKIT_INTEGRATION", payload: { ...value, [e.target.name]: e.target.value } });
    return (
        <Modal show={show} onHide={handleClose} id="convertkit" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value, "convertkit")}>
                            <div class="form-group">
                                <img src={integration_convertkit} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Connect ConvertKit</h4>
                                <p class="text-gray font-weight-500">Send new email subscribers to your ConvertKit list from Znaplink.</p>
                            </div>

                            <div class="form-group">
                                <label><small>API Key</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="api_key" onChange={handleChange} value={value?.api_key} required="required" placeholder="API Key" />
                            </div>

                            <div class="form-group mt-2">
                                <label><small>Form ID (Collects only email)</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="form_id" onChange={handleChange} value={value?.form_id} required="required" placeholder="Form ID" />
                            </div>


                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export const SendinblueModal = ({ show, handleClose, handleSubmit, error }) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state?.integrationSendinblueReducer);
    const handleChange = (e) => dispatch({ type: "SET_SENDINBLUE_INTEGRATION", payload: { ...value, [e.target.name]: e.target.value } });

    return (
        <Modal show={show} onHide={handleClose} id="sendinblue" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value, "sendinblue")}>
                            <div class="notification-container"></div>

                            <div class="form-group">
                                <img src={integration_sendinblue} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Connect SendInBlue</h4>
                                <p class="text-gray font-weight-500">Send new email subscribers to your SendInBlue list from Znaplink.</p>
                            </div>

                            <div class="form-group">
                                <label><small>API Key</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="api_key" value={value?.api_key} onChange={handleChange} required="required" placeholder="API Key" />
                            </div>

                            <div class="form-group mt-2">
                                <label><small>List ID</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="list_id" value={value?.list_id} onChange={handleChange} required="required" placeholder="List ID" />
                            </div>


                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}