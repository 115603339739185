import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import blockIcon from "../../../../../assets/img/block-icon.svg";
import returnIcon from "../../../../../assets/img/modals/return.svg";
import ErrorMessage from '../../../../errors/errorMessage';

function ThreeBoxPage({ data, showSectionChildModal, setShowSectionChildModal, handleSubmit, handleUpdate, error, setError, close }) {
    const [box1, setBox1] = useState({ heading: null, description: null });
    const [box2, setBox2] = useState({ heading: null, description: null });
    const [box3, setBox3] = useState({ heading: null, description: null });
    const handleSave = () => {
        if (isBoxEmpty(box1) || isBoxEmpty(box2) || isBoxEmpty(box3)) {
            setError(["All boxes must be filled before saving."]);
            return;
        }
        const formData = {box1, box2, box3};
        handleSubmit(formData,'three_box');
    }
    const handleUpdateData = (e) => {
        if (isBoxEmpty(box1) || isBoxEmpty(box2) || isBoxEmpty(box3)) {
            setError(["All boxes must be filled before updating."]);
            return;
        }
        const formData = {box1, box2, box3};
        handleUpdate(formData,'three_box',e.target.dataset?.value);
    };
    const isBoxEmpty = (box) => {
        return !box.heading || !box.description;
    };
    useEffect(()=>{
        if (data?.meta) {
            setBox1({heading:JSON?.parse(data?.meta)?.box1?.heading, description: JSON?.parse(data?.meta)?.box1?.description})
            setBox2({heading:JSON?.parse(data?.meta)?.box2?.heading, description: JSON?.parse(data?.meta)?.box2?.description})
            setBox3({heading:JSON?.parse(data?.meta)?.box3?.heading, description: JSON?.parse(data?.meta)?.box3?.description})
        }
    },[data?.meta])
    return (
        <Modal centered show={showSectionChildModal} onHide={close}>
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <form name="product_type" method="post" role="form">
                            <div className="notification-container"></div>
                            <div className="form-group">
                                <img src={blockIcon} width="40px" alt="" />
                                <h4 className="productHead1 mt-15px">Edit 3 Boxes Section</h4>
                            </div>
                            <ErrorMessage error={error} />
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item borderless arcordian-u mt-15px">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button btn borderless d-flex align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#sendNewsletterDetails" aria-expanded="false" aria-controls="sendNewsletterDetails">
                                            <h5 class="mb-0 font-weight-500 productlabel2">Box 1</h5>
                                        </button>
                                    </h2>
                                    <div id="sendNewsletterDetails" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body acc-body-u">
                                            <div className="form-group">
                                                <label className="productlabels">
                                                    Headline
                                                </label>
                                                <input
                                                    type="text"
                                                    className="zaapGlobal-input mt-5px"
                                                    name="headline"
                                                    placeholder="Add a feature or benefit"
                                                    value={box1.heading} 
                                                    onChange={(e) => setBox1({ ...box1, heading: e.target.value })}
                                                />
                                            </div>
                                            <div className="form-group mt-20px">
                                                <label className="productlabels">
                                                    Description
                                                </label>
                                                <input
                                                    type="text"
                                                    className="zaapGlobal-input mt-5px"
                                                    name="description"
                                                    placeholder="Add more details"
                                                    value={box1.description}
                                                    onChange={(e) => setBox1({ ...box1, description: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item borderless arcordian-u mt-15px">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button btn borderless d-flex align-items-center collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sendNewsletterContent" aria-expanded="false" aria-controls="sendNewsletterContent">
                                            <h5 class="mb-0 font-weight-500 productlabel2">Box 2</h5>
                                        </button>
                                    </h2>
                                    <div id="sendNewsletterContent" class="accordion-collapse collapse mt-0" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body acc-body-u">
                                            <div className="form-group">
                                                <label className="productlabels">
                                                    Headline
                                                </label>
                                                <input
                                                    type="text"
                                                    className="zaapGlobal-input mt-5px"
                                                    name="headline"
                                                    placeholder="Add a feature or benefit"
                                                    value={box2.heading}
                                                    onChange={(e) => setBox2({ ...box2, heading: e.target.value })}
                                                />
                                            </div>
                                            <div className="form-group mt-20px">
                                                <label className="productlabels">
                                                    Description
                                                </label>
                                                <input
                                                    type="text"
                                                    className="zaapGlobal-input mt-5px"
                                                    name="description"
                                                    placeholder="Add more details"
                                                    value={box2.description}
                                                    onChange={(e) => setBox2({ ...box2, description: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item borderless arcordian-u mt-15px">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button btn borderless d-flex align-items-center collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sendNewsletterSchedule" aria-expanded="false" aria-controls="sendNewsletterScheduled">
                                            <h5 class="mb-0 font-weight-500 productlabel2">Box 3</h5>
                                        </button>
                                    </h2>
                                    <div id="sendNewsletterSchedule" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body acc-body-u">
                                            <div className="form-group">
                                                <label className="productlabels">
                                                    Headline
                                                </label>
                                                <input
                                                    type="text"
                                                    className="zaapGlobal-input mt-5px"
                                                    name="headline"
                                                    placeholder="Add a feature or benefit"
                                                    value={box3.heading}
                                                    onChange={(e) => setBox3({ ...box3, heading: e.target.value })}
                                                />
                                            </div>
                                            <div className="form-group mt-20px">
                                                <label className="productlabels">
                                                    Description
                                                </label>
                                                <input
                                                    type="text"
                                                    className="zaapGlobal-input mt-5px"
                                                    name="description"
                                                    placeholder="Add more details"
                                                    value={box3.description}
                                                    onChange={(e) => setBox3({ ...box3, description: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center d-flex submit-buttons-fixed mt-20px">
                                    <button type="button" onClick={close} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center save-btn-u">
                                        <span class="me-2 font-weight-500">Cancel</span>
                                        <small class="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="button" onClick={data?.id ? handleUpdateData : handleSave } data-value={data?.id} class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center save-btn-u">
                                        <b class="me-2 font-weight-500" data-value={data?.id}>Save Changes</b>
                                        <small data-value={data?.id} class="badge bg-primary-dark text-dark px-2 py-1 mt-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ThreeBoxPage