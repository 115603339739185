const INIT_STATE = {
    api_key: null,
    server_prefix: null,
    list_id: null,
    tab: "integrations"
}
const integrationMailChimpReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_MAILCHIMP_INTEGRATION':
            return action.payload 
        case 'RESET_MAILCHIMP_INTEGRATION':
            return INIT_STATE
        default:
            return state
    }
}
export default integrationMailChimpReducer