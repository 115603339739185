import { createStore, } from "redux";

import reducers from "../reducer";

const configureStore = () => {
  const store = createStore(
    reducers,
  );
  return store;
};

const store = configureStore();

export default store;
