const INIT_STATE = {
    theme: 1, tab: "theme", selectedTheme: 1 , link_style: "gradient", link_shadow: "soft",verified_check: false,
}
const customizeThemeReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_CUSTOMIZE_THEME':
            return action.payload 
        case 'RESET_CUSTOMIZE_THEME':
            return INIT_STATE
        default:
            return state
    }
}
export default customizeThemeReducer