const INIT_STATE = {
    meet_token: null,
    zoom_token: null,
}
const meetConnectedReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_MEET_CONNECTED':
            return action.payload 
        case 'RESET_MEET_CONNECTED':
            return INIT_STATE
        default:
            return state
    }

}
export default meetConnectedReducer