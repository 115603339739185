import { Modal } from "react-bootstrap"
import emailIcon from "../../assets/img/newsletter/newsletter.svg"
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import returnIcon from "../../assets/img/modals/return.svg"
import { hideLoader, showLoader } from "../loader";
import { apiUrl } from "../../utils/url";
import { toast } from "react-toastify";
import NewsletterModal from "./modal";
import { useHistory, useParams } from "react-router-dom";



const EmailTestModal = ({ isOpen, onHide,callNewsletter, edit, setIsEdit }) => {
    // const [showUpgradeModal, setshowUpgradeModal] = useState(false);
    const [testemail, setTestemail] = useState(null);
    const isModalOpen = useSelector(state => state?.upgradeModalReducer?.open)
    const newsletterModalReducer = useSelector(state => state.newsletterModalReducer);
    const getNewsletter = useSelector(state => state.getNewsletterReducer);
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    // const handleChange = (e) => {
    //     setTestemail()
    // };
    // const handleChange = (e) => {dispatch({ type: "SET_NEWSLETTER_MODAL", payload: { ...newsletterModalReducer, [e.target.name]: e.target.value } });
    // 
// console.log("e.target.value",e.target.value)
    // }
    const dispatch = useDispatch();
    const [error, setError] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    let history = useHistory();
    const [isEdit, setisEdit] = useState({});
    
    let emailTemplateMarkup = [];
    let editorJSTemplateMarkup = [];

    function sendNewsletter(apiName) {     
        // event.preventDefault(); 
        var form_data = new FormData();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);

        for (let index = 0; index < $('.email-body-image-file').length; index++) {
            form_data.append('images[]', $('.email-body-image-file')[index].files[0]);
        }

        for (const key in newsletterModalReducer) {
            if (Object.hasOwnProperty.call(newsletterModalReducer, key)) {
                form_data.append(key, newsletterModalReducer[key]);
            }
        };


        emailTemplateMarkup = newsletterModalReducer?.template_json;
        editorJSTemplateMarkup = newsletterModalReducer?.editorjs_template;

        form_data.append('test_email', testemail);
        form_data.append('project', slug);
        
        form_data.append('template', emailTemplateMarkup);
        form_data.append('editojs_template', editorJSTemplateMarkup);
        // form_data.append('template', JSON.stringify(emailTemplateMarkup));
        if (apiName == "update") 
        form_data.append('newsletter', edit);
        $.ajax({
            type: 'POST',
            url: apiUrl + "/community/newsletter/" + apiName,
            dataType: 'text',  // <-- what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            headers: {
                'Authorization': `Bearer ${localStorage?.getItem("token")}`
            },
            data: form_data,
            success: (data) => {
                data = JSON.parse(data);
                if (data.status_code !== 200) {
                    toast.error(data.status_message)
                    hideLoader();
                }
                else if (data.status_code == 200) {
                    toast.success(data.status_message)
                    // setFormSubmitted(true);
                    onHide();
                    // history.push(basePath + "/newsletter")
                    // callNewsletter();
                    // hideLoader();
                }
            },
            error: (data) => {
                const errors = Object.values(JSON.parse(data.responseText)?.errors);
                setError(errors);
                toast.error(JSON.parse(data.responseText)?.status_message)
                $(".modal-body").scrollTop(0);
                hideLoader();

            }
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
    const apiName = edit === newsletterModalReducer?.id ? "update" : "create"
    // if (!emailTemplateMarkup || emailTemplateMarkup.length === 0) {
    if (emailTemplateMarkup == [] || emailTemplateMarkup == null) {
        toast.error("Empty Template")
        
// console.log(emailTemplateMarkup)
        return;
        
    }
    sessionStorage.removeItem("isEdit")
    if (emailTemplateMarkup) {
        sendNewsletter(apiName)
    }
    }
    return (
        <>
            <Modal centered id="newBlockModal"
                show={isOpen == null ? isModalOpen : isOpen} onHide={isOpen == null ? onClose : onHide} size={"md"} >
                    <form onSubmit={handleSubmit}>
                <div className=" modal-dialog-centered" role="document">
                    <div className="modal-content ">
                        <div className="modal-body">
                            <div className="form-group">
                                <img src={emailIcon} style={{ width: "40px" }} alt="" />
                                <h4 className="mt-2">Send a test email</h4>
                                <p className="newsletter-modaltext">Preview this email by sending yourself a test</p>
                                <div class="form-group mt-3 mb-3">
                                    <label class="text-gray font-weight-500 fs-6"><small>Email Address</small></label>
                                    <input type="email" class="test_email form-control zaap-form-control" autoComplete="off" name="test_email" onChange={(e)=>setTestemail(e.target.value)} required placeholder="micheal@test.com" />
                                </div>
                                 <button type="button" class="close btn btn-grey btn-semi-rounded me-2" onClick={isOpen == null ? onClose : onHide}>
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded">
                                    <b class="me-2">Send Now</b>
                                    <small class="badge bg-primary-dark text-dark ">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </Modal>
        </>
    )
}
export default EmailTestModal
