const INIT_STATE = {
    title: null,
    short_description: null,
    cover_image: null,
    pricingType: "free",
    price: null,
    theme: 1,
    content: 'files',
    description: "",
    editorjs_template_json: "",
    content_url: null,
    content_file: null,
    cover_image_data_url: null,
    template_json: null,
    editorjs_template: null,
}
const productModalReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_NEW_PRODUCT_DETAILS':
            return action.payload
        case 'RESET_NEW_PRODUCT_DETAILS':
            return INIT_STATE
        default:
            return state
    }
}
export default productModalReducer