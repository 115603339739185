import { hideLoader, showLoader } from "../components/loader";
import { apiUrl } from "../utils/url";

const getTheme = async () => {
    showLoader()
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(apiUrl + "/themes", {
            headers: myHeaders
        })
        const response = await res.json();
        hideLoader()
        return response?.data

    }
    catch (err) {
        hideLoader()

        // console.log("error", err);
    }

};
export default getTheme;