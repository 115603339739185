import { ReactSortable } from "react-sortablejs";
import returnIcon from "../../../../assets/img/modals/return.svg";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../loader";
import { apiUrl } from "../../../../utils/url";
import FormFieldError from "../../../errors/formFieldError";
import tickIconPrimary from "../../../../assets/img/white-tick-icon.svg";
import ErrorMessage from "../../../errors/errorMessage";
import CroppieModal from "../../../croppie-modal";
import { toast } from "react-toastify";
import removeicon from "../../../../assets/img/products/removeicon.svg";

const DetailsPage = ({ data, handleSubmit , error, setError }) => {
    const [title, setTitle] = useState(data?.name);
    const [short_description, setShort_Description] = useState(data?.short_description);
    const [price_type, setPriceType] = useState(data?.price_type);
    const [price, setPrice] = useState(data?.price);
    const [min_price, setMin_Price] = useState(data?.price_type !== "suggested_price" ? 0 : data?.price );
    const [suggested_price, setSugested_Price] = useState(data?.suggested_price);
    const [currency, setCurrency] = useState(data?.currency);
    const [errorMessage, setErrorMessage] = useState('');
    const [product_slug, setProductSlug] = useState(data?.slug);
    const [cover_image, setCover_Image] = useState(data?.cover_images ?? []);
    const [croppie_image, setcroppie_image] = useState({ url: "", id: null });
    const [croppie, setCroppie] = useState(null);
    const [deleted_cover_image, setDeleted_cover_image] = useState([])
    const dispatch = useDispatch();
    const { slug } = useSelector(state => state?.slugReducer);
    const handlePriceTypeChange = (a) => {
        setPriceType(a.target.value)
        if (a.target.value == 'free') {
            setPrice(0);
            setMin_Price(0);
            setSugested_Price(0);
        } else if (a.target.value == 'single_payment') {
            setMin_Price(0);
            setSugested_Price(0);
        }
    };
    const handlePriceChange = (e) => {
        const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');

        const singleDecimalValue = sanitizedValue.replace(/(\..*?)\..*/g, '$1');
        if (e.target.name === "price") {
            setPrice(singleDecimalValue);
            setMin_Price(0);
            setSugested_Price(0);
        }
        else if (e.target.name === "min_price") {
            setMin_Price(singleDecimalValue);
            setPrice(0);
        }
        else if (e.target.name === "suggested_price") {
            setSugested_Price(singleDecimalValue);
            setPrice(0);
        }
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
        let slug = e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
        slug = slug.replaceAll(' ', '-');
        setProductSlug(slug)
    }
    const handleopenmodal = (e) => {
        setcroppie_image({ url: "", id: null, name: null, type: null });
        generate_image_preview(e.target);
        hideLoader();
    };
    const [showCroppieModal, setshowCroppieModal] = useState(null);

    function generate_image_preview(input) {
        showLoader();
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            if (input.files[0].size >= 5242880) {
            $("#image_file_input").val("");
            let notification_container = $(".notification-container");
            toast.error("File Size Should not exceed 5MBs");
            // showModal();
            return;
            }
            let val = input.files[0].name;
            let valid_extensions = ["png", "jpg", "jpeg"];
            let extension =
            val.substring(val.lastIndexOf(".") + 1, val.length) || val;
            if (!valid_extensions.includes(extension)) {
            $("#image_file_input").val("");
            let notification_container = $(".notification-container");
            toast.error("Invalid Image File. Should be png, jpg or jpeg");
            // showModal();
            return;
            }
            reader.onload = (event) => {
            setcroppie_image({
                ...croppie_image,
                url: event.target.result,
            });
            console.log(setcroppie_image);
            console.log("setcroppie_image", croppie_image);
            setshowCroppieModal(true);
            setTimeout(() => {
                const el = document.getElementById("image_demo2");
                try {
                console.log("el", el);
                if (el) {
                    var croppieInstance = new Croppie(el, {
                    enableExif: true,
                    viewport: {
                        width: 850,
                        height: 470,
                        type: "square",
                    },
                    boundary: {
                        width: 870,
                        height: 570,
                    },
                    });
                    croppieInstance
                    .bind({
                        url: event.target.result,
                    })
                    .then(function () {
                        console.log("jQuery bind complete");
                        setTimeout(() => { }, 300);
                    })
                    .catch(() => {
                        croppieClose();
                    });
                    setCroppie(croppieInstance);
                }
                } catch { }
            }, 500);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }
    const croppedImage = () => {
        if (croppie !== null) {
          croppie
            .result({
              type: "canvas",
              size: "original",
              format: "png",
              quality: 1,
            })
            .then((response) => {
              const tempObj = { image_url: response };
              croppie
                .result({
                  size: "viewport",
                  format: "png",
                  quality: 1,
                  type: "blob",
                })
                .then((response) => {
                  setCover_Image([
                    ...cover_image,
                    {
                      ...tempObj,
                      img: response
                    }]
                  )
                  croppieClose()
                });
            });
        }
    };
    const handleHideModal = () => {
        setshowCroppieModal(false);
        setCroppie(null);
        setcroppie_image({ url: "", id: null });
    };
    const croppieClose = () => {
        showLoader();
        setshowCroppieModal(false);
        setCroppie(null);
        setTimeout(() => {
            hideLoader();
        }, 400);
    };
    function Removecardfun({ i, id }) {
        setDeleted_cover_image([...deleted_cover_image, id])
        if (!id) {
            setDeleted_cover_image([...deleted_cover_image, 'old_cover_image'])
        }
        const covimg = [...cover_image]
        covimg.splice(i, 1)
        setCover_Image(covimg)
    }
    const updateDetials = (e) => {
        const obj = {
            title,
            short_description,
            product_slug,
            price_type,
            price,
            min_price,
            suggested_price,
            currency,
            cover_image,
            deleted_cover_image,
        };
        handleSubmit(e,obj,'details');
    }
    useEffect(()=>{
        if (data?.cover_image) {
            setCover_Image(prevCoverImages => [...prevCoverImages, {images:data?.cover_image}]);
        }
    },[data?.cover_image])
    return (
        <div class="tab-pane fade customize-page-main active show p20-u" id="customizePageSocials" role="tabpanel" aria-labelledby="social-tab">
            <h4 class="productHead1">Product Details</h4>
                <form id="newProductModal" className="ProductBody" name="product_type" method="post" role="form">
                    <div className="notification-container"></div>
                    <div>
                        <ErrorMessage error={error} />
                        <div className="form-group mt-20px">
                            <label className="productlabels">
                                Product Title
                            </label>
                            <input
                                value={title}
                                onChange={(e) => handleTitleChange(e)}
                                type="text"
                                className="zaapGlobal-input"
                                name="title"
                                placeholder="My Awesome Product"
                            />
                            <FormFieldError error={error?.title} />
                        </div>
                        <div className="form-group mt-20px">
                            <label className="productlabels">
                                Product Description
                            </label>
                            <input value={short_description} onChange={(a) => setShort_Description(a.target.value)}
                                type="text"
                                className="zaapGlobal-input"
                                name="short_description"
                                placeholder="Got my new awesome course!"
                            />
                        </div>

                        <div className="form-group mt-20px">
                            <label className="productlabels">
                                Product URL
                            </label>
                            <div className="zaapGlobal-input d-flex align-items-center">
                                <div>
                                    zaap.bio/{slug}/
                                </div>
                                <input className="input-style2" maxLength={40} value={product_slug?.toLowerCase()} onChange={(a) => setProductSlug(a.target.value)} autoComplete="off" type="text" name="product_slug"/>
                            </div>
                        </div>

                        <div className="form-group mt-20px">
                                    <label className="productlabels">
                                        Product Images
                                    </label>
                                    <div className="preview-zone hidden "></div>
                                    <button type="button" className="newGraybtn position-relative ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z" fill="#333333"/>
                                        </svg>Upload New
                                        <input
                                            type="file"
                                            className="dropzone dropzone4 inset-0" 
                                            name="cover_image"
                                            accept=".png,.jpg,.jpeg"
                                            id="image_file_input"
                                            onChange={handleopenmodal}
                                        />
                                    </button>
                            </div>
                            <div >
                                <ReactSortable className="prevContainer " list={cover_image} setList={setCover_Image}>
                                {cover_image?.map((e, i) => {
                                    return <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <div key={i}>
                                                    <img className="prevImageU" draggable src={e.image_url ? e.image_url : e.images}/>
                                                </div>
                                            <div>
                                            <img onClick={() => Removecardfun({ i, id: e.id })} src={removeicon} width={"22px"} style={{ padding: 4, cursor: "pointer" }} />
                                        </div>
                                    </div>;
                                })}

                                </ReactSortable>
                            </div>
                    </div>
                    <div
                        id="productPricing"
                        className="form-group"
                        aria-labelledby="headingOne"
                    >
                        <div className="form-group w-100 p-0 mt-3">
                            <label className="productlabels">
                                Pricing
                            </label>
                            <div className="up-in-toggle bg-white price_type_check mt-2 p-0">
                                <input
                                    checked={price_type == 'free' ? true : false}
                                    type="radio"
                                    id="switch_left"
                                    name="pricingType"
                                    value="free"
                                    onChange={(a) => { handlePriceTypeChange(a) }}
                                />
                                <label
                                    htmlFor="switch_left"
                                    className="d-flex align-items-start flex-column"
                                >
                                    <span className="w-100 d-flex align-items-center justify-content-between">
                                        <h5 className="m-0 font-weight-500 pricing-text productlabel2">
                                            Free
                                        </h5>
                                        <img
                                            className="mb--u"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                        />
                                    </span>
                                    <h6 className="productlabel3">
                                        Allow access for free.
                                    </h6>
                                </label>
                                <input
                                    checked={price_type == 'single_payment' ? true : false}
                                    type="radio"
                                    id="switch_right"
                                    name="pricingType"
                                    value="single_payment"
                                    onChange={(a) => { handlePriceTypeChange(a) }}
                                />
                                <label
                                    htmlFor="switch_right"
                                    className="d-flex align-items-start flex-column"
                                >
                                    <span className="w-100 d-flex align-items-center justify-content-between">
                                        <h5
                                            className="m-0 font-weight-500 pricing-text productlabel2"
                                        >
                                            Single Payment
                                        </h5>
                                        <img
                                            className="mb--u"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                        />
                                    </span>
                                    <h6  className="productlabel3">
                                        Charge a one-time fee.
                                    </h6>
                                </label>
                            </div>
                        </div>
                        <div className="form-group w-100 p-0 mt-3">
                            <div className="up-in-toggle bg-white price_type_check p-0 pay-class">
                                <input checked={price_type == 'Pay_what_you_want' ? true : false}
                                    type="radio"
                                    id="payment"
                                    name="pricingType"
                                    value="Pay_what_you_want"
                                    onChange={(a) => { handlePriceTypeChange(a) }}
                                />
                                <label
                                    htmlFor="payment"
                                    className="d-flex align-items-start flex-column"
                                >
                                    <span className="w-100 d-flex align-items-center justify-content-between">
                                        <h5
                                            className="m-0 font-weight-500 pricing-tex productlabel2"
                                        >
                                            Pay what you want
                                        </h5>
                                        <img
                                            className="mb--u"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                        />
                                    </span>
                                    <h6  className="productlabel3">
                                        Let customers choose
                                    </h6>
                                </label>
                                <input
                                    type="radio"
                                    id="membership"
                                    name="pricingType"
                                    value="menbership"
                                    disabled
                                />
                                <label
                                    htmlFor="membership"
                                    className="d-flex align-items-start flex-column membershipstyle"
                                >
                                    <span className="w-100 d-flex align-items-center justify-content-between ">
                                        <h5
                                            className="m-0 font-weight-500 pricing-text productlabel2"
                                            style={{
                                                color: "#b1b1b1",
                                            }}
                                        >
                                            Membership
                                        </h5>
                                        <img
                                            className="mb--u"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                        />
                                    </span>
                                    <h6
                                        className="productlabel3"
                                        style={{
                                            color: "#cfcfcf",
                                        }}
                                    >
                                        COMING SOON
                                    </h6>
                                </label>
                            </div>
                        </div>
                    </div>
                    {price_type == "single_payment" && (
                        <div className="form-group mt-3">
                            <label className="productlabels">
                                Price
                                <small className="text-red">*</small>
                            </label>
                            <div>
                                <div className="d-flex w-100 align-items-center gap-1 zaapGlobal-input">
                                    <span>
                                        {currency == "eur" ? '€' : currency == "gbp" ? '£' : '$'}
                                    </span>
                                    <div className="w-100">
                                        <input value={price} onChange={handlePriceChange} className="" type="text" name="price" placeholder="0" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {price_type == "Pay_what_you_want" && (
                        <div>
                            <div className="d-flex gap-3">
                                <div className="w-100">
                                    <div className="form-group mt-3">
                                        <label className="productlabels">
                                                Minimum Price
                                                <small className="text-red">
                                                    *
                                                </small>
                                        </label>
                                        <div className="d-flex w-100 align-items-center gap-1 zaapGlobal-input">
                                            <span>
                                                {currency == "eur" ? '€' : currency == "gbp" ? '£' : '$'}
                                            </span>
                                            <div className="w-100">
                                                <input value={min_price} onChange={handlePriceChange} className="" type="text" name="min_price" placeholder="0" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div className="form-group mt-3">
                                        <label className="productlabels">
                                                Suggested Price 
                                                <small className="text-red">
                                                    *
                                                </small>
                                        </label>
                                        <div className="d-flex w-100 align-items-center gap-1 zaapGlobal-input">
                                            <span>
                                                {currency == "eur" ? '€' : currency == "gbp" ? '£' : '$'}
                                            </span>
                                            <div className="w-100">
                                                <input value={suggested_price} onChange={handlePriceChange} className="" type="text" name="suggested_price" placeholder="0" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(price_type == "Pay_what_you_want") || (price_type == "single_payment") ?
                        <div className="currency-dropdown mt-4">
                            <div className="d-flex flex-column">
                                <span>Currency</span>
                                <small className="text-gray">This can't be edited later</small>
                            </div>
                            <div className="currency-select-box">
                                <select name="currency" id="currency" disabled={true} value={currency} onChange={(a) => setCurrency(a.target.value)}>
                                        <option value={currency}>{currency == "eur" ? '€' : currency == "gbp" ? '£' : '$' + currency.toUpperCase()}</option>
                                </select>
                            </div>
                        </div> : ''
                    }

                    <div class="text-center mt-4 d-flex submit-buttons-fixed">
                        <button type="button" onClick={(e)=>updateDetials(e)} class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                            <b class="me-2 productlabel2">Save Changes</b>
                            <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                <img src={returnIcon} alt="" />
                            </small>
                        </button>
                    </div>
                </form>
                <CroppieModal show={showCroppieModal} handleHide={handleHideModal} croppieClose={croppieClose} croppedImage={croppedImage} modalWidth={500} productCroppie={"productCroppie"} />
        </div >
    )
}
export default DetailsPage