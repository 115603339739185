import returnIcon from "../../../../assets/img/modals/return.svg";
// import * as socialsOptions from "../../../utils/socials.json"
import { useState, useEffect } from "react";
import productType from "../../../../assets/img/product-type-icon.svg";
import lightmodeicon from "../../../../assets/img/products/lightmodeicon.svg";
import iro from "@jaames/iro";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import questionIcon from "../../../../assets/img/products/questionicon.svg";
import addFaqIcon from "../../../../assets/img/products/add-faq-btn.svg";
import removeicon from "../../../../assets/img/products/removeicon.svg";


const CheckoutPage = ({ data, handleSubmit , error, setError  }) => {
    const [buy_button_text, set_buy_button_text] = useState('Buy Now');
    const [checkout_question, setCheckout_Question] = useState([{ questions: "", isRequired: false, }]);
      function handleQuestion() {
        var newdata = [...checkout_question];
        newdata.push({ questions: "", isRequired: false });
        setCheckout_Question(newdata);
      }
      useEffect(() => {
        if (data?.checkoutquestion) {
            const updatedCheckoutQuestion = data?.checkoutquestion.map(item => ({
                questions: item.questions,
                isRequired: item.isrequired == true, // Convert "true" to true, and "false" to false
            }));
    
            setCheckout_Question(updatedCheckoutQuestion);
        }
    }, [data?.checkoutquestion]);
    function handleCheckFun(a, i) {
        const questionscheck = a.target.value;
        const tempCheckoutQuestion = [...checkout_question];
        const currentObj = { ...tempCheckoutQuestion[i] };
        currentObj.questions = questionscheck;
        tempCheckoutQuestion[i] = currentObj;
        setCheckout_Question([...tempCheckoutQuestion]);
      }
    
      const delete_question = (index) => {
        setCheckout_Question(prevState => {
            const updatedQuestions = [...prevState];
            updatedQuestions.splice(index, 1); // Remove the element at the specified index
            return updatedQuestions;
        });
      };
    
      function handleswitch(a, i) {
        const isRequired = a.target.checked;
        const tempCheckoutQuestion = [...checkout_question];
        const currentObj = { ...tempCheckoutQuestion[i] };
        currentObj.isRequired = isRequired;
        // currentObj.isRequired = !currentObj.isRequired;
        tempCheckoutQuestion[i] = currentObj;
        setCheckout_Question([...tempCheckoutQuestion]);
      }
    const updateCheckout = (e) => {
        const obj = {
            checkout_question: JSON.stringify(checkout_question),
            buy_button_text:buy_button_text,
        };
        handleSubmit(e,obj,'checkout');
    };
    return (
        <div class="tab-pane fade customize-page-main active show p20-u" id="customizePageSocials" role="tabpanel" aria-labelledby="social-tab">
            <h4 class="productHead1">Checkout</h4>
                <form id="newProductModal" className="ProductBody" name="product_type" method="post" role="form">
                    <div className="notification-container"></div>
                    <div className="form-group mt-30px">
                        <label className="text-gray font-weight-500 ">
                        <span className="d-flex align-items-center">
                            <span className="productlabels ">Checkout Question</span>
                            <small>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="button-tooltip-2">Ask your customer questions before purchasing</Tooltip>}
                            ><img src={questionIcon} width="12px" alt="" className="payout-warning ms-1" />
                            </OverlayTrigger>
                            </small>
                        </span>
                        </label>
                        {checkout_question?.map((ele, i) => {
                        return (
                            !ele?.questions && i == 0?  '' :
                            <div key={i} className="d-flex align-items-center mt-15px">
                                <div className="zaapGlobal-input " style={{padding:"8px 10px"}}>
                                    <div className="d-flex align-items-center">
                                        <input  className=" productlabel3 m-0 p-0 color-5b5b5b" value={ele.questions} type="text" name="title" onChange={(a) => {handleCheckFun(a, i);}} placeholder="what is  your username"/>
                                        <div class="d-flex align-items-center" style={{gap:"15px"}}>
                                            <div className="input-text m-0 productlabel3 line-height-none">Required?</div>
                                            <label className="link-switch switch-u">
                                                <input defaultChecked={ele.isRequired == 1 ? true : false} type="checkbox" className="" name="isrequired" value={ele.isRequired} onChange={(a) => handleswitch(a, i)}/>
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <img style={{ cursor: "pointer" }} src={removeicon} className="ms-2" width={16} height={16} onClick={() => delete_question(i)} />
                            </div>
                        );
                        })}
                    </div>
                    <div className="mt-15px">
                        <button type="button" className="add-question-btn" onClick={handleQuestion}>
                            <span className="m-0">Add question</span>
                            <img src={addFaqIcon} width="18px" alt="" />
                        </button>
                    </div>
                    <div className="form-group mt-30px">
                        <label className="input-text productlabel2">
                            CTA Button Text
                        </label>
                        <input onChange={(a) => set_buy_button_text(a.target.value)} type="text" className="zaapGlobal-input mt-10px" name="short_description" placeholder="Got my new awesome course!" value={buy_button_text} />
                    </div>
                    <div class="text-center mt-4 d-flex submit-buttons-fixed">
                        <button type="button" onClick={(e)=>{updateCheckout(e)}} class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                            <b class="me-2 productlabel2">Save Changes</b>
                            <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                <img src={returnIcon} alt="" />
                            </small>
                        </button>
                    </div>
                </form>
        </div >
    )
}
export default CheckoutPage