


import { Modal } from "react-bootstrap"
import postIcon from "../../../../assets/img/post-block.svg";
import ModalErrors from "../../../errors/modal-errors"
import { useEffect, useState } from "react"

const CreateZaapPostsModal =
    ({ show, handleHide, returnIcon, handleSubmit, slug, error, zaapPostsModal, setzaapPostsModal }) => {

        const [value, setValue] = useState({
            title: null,
            no_of_posts: null,
        });

        useEffect(() => {
            setValue({ ...value, title: zaapPostsModal?.name, no_of_posts: zaapPostsModal?.details?.no_of_posts })
        }, [zaapPostsModal])
        const heading = zaapPostsModal ? "Update your latest Zaap posts" : "Sync your latest Zaap posts";

        const handleChange = (e) => setValue({ ...value, [e.target.name]: e.target.value });
        return (
            <Modal centered show={show} onHide={!zaapPostsModal?.show ? handleHide : () => {
                setzaapPostsModal({
                    ...zaapPostsModal,
                    show: !zaapPostsModal?.show,
                })
            }} id="createZaapWidgetModal">
                <div class="modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-body">

                            <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e, value, zaapPostsModal?.show ? zaapPostsModal?.id : "zaap_posts", true,)
                            }}>
                                <div className="notification-container"></div>


                                <div className="form-group">
                                    <img src={postIcon} width="30px" alt="" />
                                    <h4 className="mt-2 mb-1">{heading}</h4>
                                    <p className="text-gray font-weight-500">Keep your page updated with your latest Zaap posts.</p>
                                </div>

                                <div className="form-group">
                                    <label><small>Title</small></label>
                                    <input type="text" className={`form-control zaap-form-control`} onChange={handleChange} value={value?.title} name="title" required="required" placeholder="My Posts" />
                                </div>

                                <div className="form-group mt-2">
                                    <label><small>Number of posts</small></label>
                                    <input type="number" className={`form-control zaap-form-control`} onChange={handleChange} value={value?.no_of_posts} name="no_of_posts" required="required" placeholder="5" />
                                </div>


                                <div className="text-center mt-4 d-flex">
                                    <button type="button" onClick={!zaapPostsModal?.show ? handleHide : () => {
                                        setzaapPostsModal({
                                            ...zaapPostsModal,
                                            show: !zaapPostsModal?.show,
                                        })
                                    }} className="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                        <b className="me-2">Cancel</b>
                                        <small className="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="submit" className="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                        <b className="me-2">Save Changes</b>
                                        <small className="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal>
        )
    }
export default CreateZaapPostsModal