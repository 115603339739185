import { hideLoader, showLoader } from "../components/loader";
import { apiUrl } from "../utils/url";
const getFont = async (slug) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(apiUrl + `/project/get-fonts/${slug}`, {
            headers: myHeaders
        })
        const response = await res.json();
        hideLoader()
        return response?.data

    }
    catch (err) {
        hideLoader()

        // console.log("error", err);
    }
}
export default getFont;