import { Modal } from "react-bootstrap"
import emailIcon from "../../assets/img/newsletter/newsletter.svg"
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import returnIcon from "../../assets/img/modals/return.svg"
import { hideLoader, showLoader } from "../loader";
import { apiUrl } from "../../utils/url";
import { toast } from "react-toastify";
import NewsletterModal from "./modal";
import { useHistory, useParams } from "react-router-dom";
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Checklist from '@editorjs/checklist';
import RawTool from '@editorjs/raw';
import CodeTool from '@editorjs/code';
import Warning from '@editorjs/warning';
import Delimiter from '@editorjs/delimiter';
import Button from 'editorjs-button';
import ImageTool from '@editorjs/image';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';

const EditContentModal = ({ isOpen, onHide, callNewsletter, edit, setIsEdit }) => {
    // const [showUpgradeModal, setshowUpgradeModal] = useState(false);
    const [testemail, setTestemail] = useState(null);
    const isModalOpen = useSelector(state => state?.upgradeModalReducer?.open)
    const newsletterModalReducer = useSelector(state => state.newsletterModalReducer);
    const getNewsletter = useSelector(state => state.getNewsletterReducer);
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [editorContent, setEditorContent] = useState([]);
    const [editorTemplate, setEditorTemplate] = useState([]);
    let emailTemplateMarkup = [];
    let editorJSTemplateMarkup = [];
    // const handleChange = (e) => {
    //     setTestemail()
    // };
    // const handleChange = (e) => {dispatch({ type: "SET_NEWSLETTER_MODAL", payload: { ...newsletterModalReducer, [e.target.name]: e.target.value } });
    // 
// console.log("e.target.value",e.target.value)
    // }
    const dispatch = useDispatch();
    const [error, setError] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    let history = useHistory();
    const [isEdit, setisEdit] = useState({});
    let ejInstance = useRef();


    const saveNewsletter = (e) => {
        e.preventDefault();

        if (editorContent?.length>0) {
            dispatch({ type: "SET_NEWSLETTER_MODAL", payload: { ...newsletterModalReducer, ['editorjs_template']: editorContent, ['template_json']: editorTemplate } })
        }
        // console.log('newsletterModalReducer', newsletterModalReducer);
        onHide();
    }

    let TEMPLATE_EDIT = {
        time: new Date().getTime(),
        blocks: null
    };

    const initEditor = () => {
        // if(newsletterModalReducer?.editorjs_template?.length){
        // console.log('newsletterModalReducer?.editorjs_template',newsletterModalReducer?.editorjs_template);
        if (newsletterModalReducer?.editorjs_template) {
            TEMPLATE_EDIT = JSON.parse(newsletterModalReducer?.editorjs_template);
        }
        else {
            TEMPLATE_EDIT = {
                time: new Date().getTime(),
                blocks: null
            };
        }
        let editorConfig = new EditorJS({
            holder: 'editor',
            onReady: () => {
                ejInstance = editorConfig;
            },
            // autofocus: true,
            tools: {
                header: {
                    class: Header,
                    tunes: ['anyTuneName'],
                    config: {
                        placeholder: 'Enter a heading',
                        levels: [1, 2, 3, 4],
                        defaultLevel: 2,
                    },
                    shortcut: 'CMD+SHIFT+H',
                },
                button: {
                    class: Button,
                    tunes: ['anyTuneName'],
                    shortcut: 'CMD+SHIFT+B',

                },
                paragraph: {
                    class: Paragraph,
                    inlineToolbar: true,
                    tunes: ['anyTuneName'],
                    shortcut: 'CMD+SHIFT+E',
                },
                anyTuneName: {
                    class: AlignmentTuneTool,
                    config: {
                        default: "left",
                        blocks: {
                            header: 'left',
                            list: 'left',
                            button: 'left',
                        }
                    },
                },
               
                image: {
                    class: ImageTool,
                    config: {
                        uploader: {
                            uploadByFile(file){
                                // your own uploading logic here
                                const api = apiUrl + '/community/newsletter/create';
                                var myHeaders = new Headers();
                                myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
                                const form_data = new FormData();

                                form_data.append("image", file);
                                form_data.append("newsletter_content_image", 1);

                                const setOptions = {
                                    method: "POST",
                                    headers: myHeaders,
                                    body: form_data
                                }

                                return fetch(api, setOptions).then((response)=> response.json()).then((response)=>{
                                    if (response?.status_code == 200) {
                                        return response.data;
                                    }
                                    else {
                                        toast?.error(response?.status_message);
                                        return {
                                            success: 0,
                                        };
                                    }
                                })
                                
                              },
                        },
                        // endpoints: {
                        //     byFile: apiUrl + '/community/newsletter/create',
                        //     // byUrl: apiUrl + '/community/newsletter/create'
                        // },
                        field: 'image',
                        types: 'image/*',
                        additionalRequestHeaders: {
                            'authorization': `Bearer ${localStorage?.getItem("token")}`,
                        },
                        additionalRequestData: {
                            'newsletter_content_image': 1
                        }
                    },
                    shortcut: 'CMD+SHIFT+I',
                },
                delimiter: {
                    class: Delimiter,
                    shortcut: 'CMD+SHIFT+D',
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'unordered'
                    },
                    shortcut: 'CMD+SHIFT+L',
                },
                raw: RawTool,
                checklist: {
                    class: Checklist,
                    inlineToolbar: true,
                },
                quote: {
                    class: Quote,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+O',
                    config: {
                        quotePlaceholder: 'Enter a quote',
                        captionPlaceholder: 'Quote\'s author',
                    },
                },
                code: CodeTool,
                warning: {
                    class: Warning,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+W',
                    config: {
                        titlePlaceholder: 'Title',
                        messagePlaceholder: 'Message',
                    },
                },
            },
            data: TEMPLATE_EDIT,
            onChange: async () => {
                // console.log(editor)
                emailTemplateMarkup = [];
                editorJSTemplateMarkup = [];
                let content = await editorConfig.saver.save();
                for (let i = 0; i < content.blocks.length; i++) {
                    const element = content.blocks[i];
                    let key = element.type;
                    let value = element.data.text;
                    if (element.type == 'header') {
                        console.log(element);
                        key = 'h' + element.data.level
                        value = element.data.text + ', align:' + element.tunes.anyTuneName.alignment;
                        console.log("key", key)
                    }
                    else if (element.type == 'paragraph') {
                        key = 'text';
                        value = element;
                    }
                    else if (element.type == 'button') {
                        key = 'button';
                        value = element;
                    }
                    else if (element.type == 'list') {
                        key = 'list';
                        value = element;
                    }
                    else if (element.type == 'delimiter') {
                        key = 'delimiter';
                        value = 'hr';
                        console.log("key", key)
                        console.log("value", value)
                    }
                    else if (element.type == 'image') {
                        console.log(element);
                        key = 'image';
                        value = element.data;
                    }
                    else if (element.type == 'quote') {
                        console.log(element);
                        console.log("Quote");
                        key = 'quote';
                        value = element.data;
                    }
                    else if (element.type == 'checklist') {
                        console.log(element);
                        key = 'checklist';
                        value = element.data;
                    }
                    else if (element.type == 'code') {
                        console.log(element);
                        key = 'code';
                        value = element.data;
                    }
                    else if (element.type == 'raw') {
                        console.log(element);
                        console.log("Raw");
                        key = 'raw';
                        value = element.data;
                    }
                    else if (element.type == 'warning') {
                        console.log(element);
                        key = 'warning';
                        value = element.data;
                    }

                    emailTemplateMarkup[i] = {
                        [key]: value
                    };
                    // console.log("elementDataValue", element.data.text)  
                    // console.log("elementDatakey", element.type)  
                    // console.log("elementDatalevel", element.data.level)  
                    // console.log("key",key)
                    // console.log("value", value);

                    setEditorContent(JSON.stringify(content));
                    setEditorTemplate(JSON.stringify(emailTemplateMarkup));
                    editorJSTemplateMarkup = content;
                    // console.log("emailTemplateMarkup" , emailTemplateMarkup)
                    // console.log("editorJSMarkup" , content)
                }
            },

            placeholder: 'Type text or paste a link',
        });
    }
    useEffect(() => {
// alert("a")
        setTimeout(() => {
            initEditor();
        }, 2000);
    }, []);




    return (
        <>
            <Modal centered id="newBlockModal"
                show={isOpen == null ? isModalOpen : isOpen} backdrop="static" onHide={isOpen == null ? onClose : onHide} size={"lg"} >
                <form onSubmit={saveNewsletter}>
                    <div className=" modal-dialog-centered" role="document">
                        <div className="modal-content ">
                            <div className="modal-body">
                                <div className="form-group">
                                    <img src={emailIcon} style={{ width: "40px" }} alt="" />
                                    <h4 className="mt-2">Build your {newsletterModalReducer.channel == "newsletter" ? "Broadcast" : "Post"}</h4>
                                    <p className="newsletter-modaltext">Add blocks, or just start typing.</p>
                                    <div class="form-group mt-3 mb-3">
                                        <div class="editor-template-body" id="editor">
                                        </div>
                                    </div>
                                    <button type="button" class="close btn btn-grey btn-semi-rounded me-2" onClick={isOpen == null ? onClose : onHide}>
                                        <b class="me-2">Cancel</b>
                                        <small class="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="submit" class="btn btn-primary btn-semi-rounded">
                                        <b class="me-2">Save {newsletterModalReducer.channel == "newsletter" ? "broadcast" : "Post"}</b>
                                        <small class="badge bg-primary-dark text-dark ">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}
export default EditContentModal
