const INIT_STATE = {}
const customizePageReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_CUSTOMIZE_SETTING':
            return action.payload 
        case 'RESET_CUSTOMIZE_SETTING':
            return INIT_STATE
        default:
            return state
    }

}
export default customizePageReducer