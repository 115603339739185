import { Modal } from "react-bootstrap"
import thunderIcon from "../../assets/img/thunder.svg"
import { useState } from "react";
import UpgradeModal from "./upgrade-modal";
import { useDispatch, useSelector } from "react-redux";


const NewsUpgradeModal = ({ isOpen, onHide }) => {
    const [showUpgradeModal, setshowUpgradeModal] = useState(false);
    const [showNewsUpgradeModal, setshowNewsUpgradeModal] = useState(false);
    const isModalOpen = useSelector(state => state?.upgradeModalReducer?.open)
    const openUpgradeModal = () => {
        setshowUpgradeModal(true);
        onClose();
    }
    const closeUpgradeModal = () => setshowUpgradeModal(false);

    return (
        <>
        <div id="wrapper">
            {!showUpgradeModal &&<Modal centered id="newBlockModal"
                show={isOpen == null ? isModalOpen : isOpen} onHide={isOpen == null ? onClose : onHide} size={"md"} >
                <div className=" modal-dialog-centered" role="document">
                    <div className="modal-content upgradenewsmodal">
                        <div className="modal-body mx-2 p-0">
                            <div className="form-group">
                                <img src={thunderIcon} style={{ width: "40px" }} alt="" />
                                <h4 className="mt-3 newsletter-modalheading">Upgrade to keep sending emails.</h4>
                                <p className="  newsletter-modaltext my-3">You’ve reached the max audience size for the free plan. Upgrade to Pro to send unlimited email newsletters to your audience.</p>
                                <div className="my-2">
                                <button className="btn btn-primary btn-semi-rounded news-modal-btn" onClick={openUpgradeModal}>Upgrade</button>
                                <a target="_blank" href="https://zaap.ai/pricing" className=" btn btn-grey btn-semi-rounded ms-3 news-modal-btn" >
                                    See Pricing
                                </a>
                                </div>
                            </div>
                        </div>
    
    
                    </div>
    
                </div>
            </Modal>}
        </div>
        {showUpgradeModal && <UpgradeModal isOpen={showUpgradeModal} onHide={closeUpgradeModal} />}
        </>
    )
}
export default NewsUpgradeModal
