import PageHeader from "../components/page-header"
import analyticsInsta from "../assets/img/analytics-insta.svg"
import desktopIcon from "../assets/img/desktop-icon.svg";
import mobileIcon from "../assets/img/mobile-icon.svg";
import tabletIcon from "../assets/img/tablet-icon.svg";
import analyticsUp from "../assets/img/analytics-up.svg"
import analyticsDown from "../assets/img/analytics-down.svg"
import menuOverview from "../assets/img/menu-overview.svg"
import safariIcon from "../assets/img/safari-icon.svg"
import chromeIcon from "../assets/img/chrome-icon.svg"
import edgeIcon from "../assets/img/edge-icon.svg"
import chevron_down from "../assets/img/modals/chevron-down.svg"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "../assets/css/analytics.css"
import "../assets/calendar-plugin/css/mobiscroll.jquery.min.css"
import { useEffect, useRef, useState } from "react"
import { Line } from 'react-chartjs-2';
import Skeleton from "react-loading-skeleton"
import { months, originalOptions, originalData } from "../utils/analytics/line";
import "../assets/calendar-plugin/js/mobiscroll.jquery.min.js"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
} from 'chart.js';
import * as $ from "jquery";
import { hideLoader, showLoader } from "../components/loader"
import { apiUrl } from "../utils/url"
import { audiencePosts as statGets } from "../services/postRequests/audience"
import setDateFormat from "../utils/setDateFormat"
import { useDispatch, useSelector } from "react-redux";
import CommunityFooter from "../components/community/footer"
import Teammoadal from "../components/teammember/team_modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewSideBar from "../components/side-bar/new-sidebar";
// import * as flags from "../assets/img/flags"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
);

let apiBasePath = "/project/statistics"

var today = new Date();
var today_text = months[today.getMonth()] + ' ' + today.getDate();

var week_before = new Date();
week_before.setDate(week_before.getDate() - 7);
var week_before_text = months[week_before.getMonth()] + ' ' + week_before.getDate();
var first_date, first_date_text = '', last_date, last_date_text = '';

let graph_dates = new Date();
var labels = []

for (let index = 1; index < 10; index++) {
    graph_dates.setDate(graph_dates.getDate() - 1);
    labels.push(months[graph_dates.getMonth()] + ' ' + graph_dates.getDate());
}

const flagsPath = ""
let params = [];
let queryParams = []

const Analytics = () => {
    const [loading, setloading] = useState(true);
    const history = useHistory()
    const [screenWidth, setscreenWidth] = useState(false)
    const { basePath , slug } = useSelector(state => state?.slugReducer);
    const [topSources, settopSources] = useState(null)
    const [topCountries, settopCountries] = useState(null)
    const [devices, setdevices] = useState({})
    const [browsers, setbrowsers] = useState({})
    const [languages, setlanguages] = useState({})
    const [activity, setactivity] = useState({})
    const secondPg = useRef(null);
    const [showModalasd, setShowModalasd] = useState(false)
    const pro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const planType = useSelector(state => state?.planTypeReducer);
    const dispatch = useDispatch();
    const [filter, setfilter] = useState({
        page: 1,
    })

    const [statsData, setstatsData] = useState({
        unique_visitors: {},
        impressions: {},
        new_subscribers: {},
        revenue: {},
    })

    const [graphData, setgraphdata] = useState({
        unique_visitors: {
            label: 'Unique Visitors',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: '#0C8CFB',
            tension: 0.1
        },
        impressions: {
            label: 'Impressions Visitors',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: '#7F48FB',
            tension: 0.1
        },
        new_subscribers: {
            label: 'New Subscribers',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: '#FB48C9',
            tension: 0.1
        },
        revenue: {
            label: 'Revenue',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: '#FB9E48',
            tension: 0.1
        },
    })
console.log("pro",pro,'newUser',newUser,"planType",planType)
    useEffect(() => {
        setShowModalasd(!pro);
    }, [pro]);

    const [data, setData] = useState({
        loaded: false,
        labels: labels,
        datasets: [
            {
                label: 'Unique Visitors',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: '#0C8CFB',
                tension: 0.1
            },
            {
                label: 'Impressions Visitors',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: '#7F48FB',
                tension: 0.1
            },
            {
                label: 'New Subscribers',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: '#FB48C9',
                tension: 0.1
            },
            {
                label: 'Revenue',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: '#FB9E48',
                tension: 0.1
            }
        ]
    })

    const callActivity = (_filter) => {
        showLoader()
        params = [];
        for (const key in _filter) {
            if (Object.hasOwnProperty.call(_filter, key)) {
                if (_filter[key])
                    params.push(`${[key]}=${_filter[key]}`)
            }
        };
        if (pro) {
        if (_filter?.page) {
            const formdata = new FormData();
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const setOptions = {
                method: "GET",
                headers: myHeaders,
            }
            statGets(null, apiUrl + apiBasePath + "/getActivityLog/" + localStorage.slug + queryParams + "&" + params?.join("&"), hideLoader, setOptions).then((response) => setactivity(response)).catch((err) => console.error(err))
        }
        }
    }

    useEffect(() => {
        if (window.screen.width < 454) {
            setscreenWidth(true)
        }
        setTimeout(() => {
            setloading(false)
            // renderCalendar();
        }, 1500);

    }, []);

    const getStats = (from_date, to_date) => {
        // setloading(true)
        queryParams = []
        queryParams = "?from_date=" + from_date + "&to_date=" + to_date;
        for (const key in filter) {
            if (Object.hasOwnProperty.call(filter, key)) {
                if (filter[key])
                queryParams += `&${key}=${filter[key]}`;
            }
        };
        const formdata = new FormData();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
        }
        let temp_graph_data = []
        statGets(null, apiUrl + apiBasePath + "/get/" + slug + params +queryParams, hideLoader, setOptions).then((response) => {
            setstatsData({ ...response })

            temp_graph_data.push(
                {
                    label: 'Unique Visitors',
                    fill: false,
                    borderColor: '#0C8CFB',
                    tension: 0.1,
                    data: response?.graph_data?.unique_visitors
                },
                {
                    label: 'Impressions Visitors',
                    fill: false,
                    borderColor: '#7F48FB',
                    tension: 0.1,
                    data: response?.graph_data?.impressions
                },
                {
                    label: 'New Subscribers',
                    fill: false,
                    borderColor: '#FB48C9',
                    tension: 0.1,
                    data: response?.graph_data?.new_subscribers
                },
                {
                    label: 'Revenue',
                    fill: false,
                    borderColor: '#FB9E48',
                    tension: 0.1,
                    data: response?.graph_data?.revenue,
                });

            setData({ labels: response?.graph_data?.labels, datasets: temp_graph_data });

            setgraphdata({
                unique_visitors: {
                    label: 'Unique Visitors',
                    data: response?.graph_data?.unique_visitors,
                    fill: false,
                    borderColor: '#0C8CFB',
                    tension: 0.1
                },
                impressions: {
                    label: 'Impressions Visitors',
                    data: response?.graph_data?.impressions,
                    fill: false,
                    borderColor: '#7F48FB',
                    tension: 0.1
                },
                new_subscribers: {
                    label: 'New Subscribers',
                    data: response?.graph_data?.new_subscribers,
                    fill: false,
                    borderColor: '#FB48C9',
                    tension: 0.1
                },
                revenue: {
                    label: 'Revenue',
                    data: response?.graph_data?.revenue,
                    fill: false,
                    borderColor: '#FB9E48',
                    tension: 0.1
                }
            });

            statGets(null, apiUrl + apiBasePath + "/getDetailed/" + slug + queryParams, hideLoader, setOptions).then((response) => {
                settopSources(response?.top_sources)
                settopCountries(response?.top_countries);
                setdevices(response?.devices)
                setlanguages(response?.languages)
                setbrowsers(response?.browsers)
                statGets(null, apiUrl + apiBasePath + "/getActivityLog/" + slug + queryParams, hideLoader, setOptions).then((response) => {
                    setactivity(response)
                }).catch((err) => console.error(err))
                // settopSources()
                // setloading(false)
            }).catch((err) => console.error(err))
            // setloading(false)
        })
    }

    const renderCalendar = () => {
        var myCalendar = $('#calendar-picker').mobiscroll().datepicker({
            controls: ['calendar'],
            display: 'inline',
            calendarSize: 1,
            selectMultiple: true,
            selectMin: 1,
            selectMax: 2,
            returnFormat: 'locale',
            themeVariant: 'light',
            theme: 'ios',
            onChange: function (event, inst) {
                if (event.value[0]) {
                    first_date = new Date(event.value[0]);
                    first_date_text = months[first_date.getMonth()] + ' ' + first_date.getDate();
                }
                if (event.value[1]) {
                    last_date = new Date(event.value[1]);
                    last_date_text = months[last_date.getMonth()] + ' ' + last_date.getDate();
                }
                if (first_date < last_date) {
                    $(".date-filter-dropdown-menu-selected").text(first_date_text + ' - ' + last_date_text);
                }
                else {
                    $(".date-filter-dropdown-menu-selected").text(last_date_text + ' - ' + first_date_text);
                }
                if (pro) {
                getStats(setDateFormat(first_date, false, true), setDateFormat(last_date, false, true))
                }
            }
        }).mobiscroll('getInst');

        // alert(today);
        setTimeout(() => {
            myCalendar?.setVal([week_before, today]);
        }, 1500);
    }

    useEffect(() => {
        // mobiscroll.setOptions({
        //     theme: 'ios',
        //     themeVariant: 'light'
        // });
        if (!loading) {

            $('.date-filter-dropdown-menu').on('click', function (e) {
                e.stopPropagation();
            });
            $(".date-filter-dropdown-menu-selected").text(week_before_text + ' - ' + today_text);
            renderCalendar();

        }
    }, [loading,filter])

    useEffect(() => {
        regenerateGraph();
    }, [graphData])

    function regenerateGraph() {
        let tempArr = []
        for (let index = 0; index < 4; index++) {
            if ($('input[name="analytics-graph-filter[]"]').eq(index).is(':checked')) {
                var checked_value = $('input[name="analytics-graph-filter[]"]').eq(index).val();
                tempArr.push(graphData[checked_value]);

            }
        }
        setData({
            labels: data?.labels,
            datasets: tempArr
        });

    }
    const analyticsGraphFilterClick = () => {
        // showLoader();
        if ($('input[name="analytics-graph-filter[]"]:checked').length == 4) {
            $(".filter-dropdown-menu-selected").text('All');
        }
        else if ($('input[name="analytics-graph-filter[]"]:checked').length > 0) {
            $(".filter-dropdown-menu-selected").text('Applied');
        }
        else {
            $(".filter-dropdown-menu-selected").text('None');
        }
        regenerateGraph();
    }

    const upgradeHandleClick = (e) => {
        e.preventDefault();
        // $(".upgrade-main-btn").click();
        if (newUser == 1) {
            history.push(basePath + '/settings/upgrade-zaap')
        }else{
        dispatch({ type: "SET_UPGRADE_MODAL", payload: { isPro: false, open: true } });
        }
        // setShowModalasd(false);
    }

    return (
        <>
            <div id="wrapper">
                <PageHeader />
                <div class="analytics-main">
                        <NewSideBar from={"analytics"}/>
                    {/* <div class="left-side-bar d-flex align-items-center flex-column justify-content-start">
                        <a href="" class="left-menu-item active">
                            <img src={menuOverview} width="20px" alt="" />
                            <p class="mb-0 ms-2">Overview</p>
                        </a>
                    </div> */}


                    <section class="analytics-section">
                    {showModalasd && <Teammoadal showModal={showModalasd} upgradeHandleClick={upgradeHandleClick} heading={'Upgrade to Unlock Analytics.'} text={' Analytics is a PRO only Feature, Subscribe to<br /> PRO to unlock Analytics'} isUltra={false}/>}

                        <div class="section-header">
                            <h1 class="mb-0">Overview</h1>
                        </div>

                        {
                            <div class="section-main mt-4">
                                <div class="d-flex align-items-end justify-content-between analytics-header">

                                    <div class="search-bar d-flex align-items-center jusitfy-content-start flex-wrap">
                                        {statsData?.unique_visitors?.type ?
                                            <div class="analytics-card analytics-card-blue">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="mb-1 me-2">{statsData?.unique_visitors?.count}</h1>
                                                    <span class={`analytics-badge ${statsData?.unique_visitors?.type == "up" ? 'analytics-badge-success' : 'analytics-badge-danger'}  "d-flex align-items-center justify-content-center`}>
                                                        {statsData?.unique_visitors?.type !== "-" && <img src={statsData?.unique_visitors?.type == "up" ? analyticsUp : analyticsDown} width="10px" alt="" />}
                                                        <span class="ms-1">{statsData?.unique_visitors?.progress == "-" ? 0 : statsData?.unique_visitors?.progress}%</span>
                                                    </span>
                                                </div>
                                                <h5 class="mb-0 font-weight-500">Unique Visitors</h5>
                                            </div>
                                            :
                                            <div class="analytics-card analytics-card-blue">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="mb-1 me-2">

                                                        <Skeleton width={50} height={40}
                                                            className="mb-2"
                                                            highlightColor="#0C8CFB"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </h1>
                                                    <span class=" d-flex align-items-center justify-content-center">
                                                        <Skeleton width={40} height={15}
                                                            className="mb-2"
                                                            baseColor="#D5F5E3"
                                                            highlightColor="#0CBD5B"
                                                            duration={4}
                                                            borderRadius="5px"
                                                        />
                                                    </span>

                                                </div>
                                                <h5 class="mb-0 font-weight-500">Unique Visitors</h5>
                                            </div>
                                        }
                                        {statsData?.impressions?.type ?
                                            <div class="analytics-card analytics-card-purple">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="mb-1 me-2">{statsData?.impressions?.count}</h1>
                                                    <span class={`analytics-badge ${statsData?.impressions?.type == "up" ? 'analytics-badge-success' : 'analytics-badge-danger'}  "d-flex align-items-center justify-content-center`}>
                                                        {statsData?.impressions?.type !== "-" && <img src={statsData?.impressions?.type == "up" ? analyticsUp : analyticsDown} width="10px" alt="" />}
                                                        <span class="ms-1">{statsData?.impressions?.progress == "-" ? 0 : statsData?.impressions?.progress}%</span>
                                                    </span>
                                                </div>
                                                <h5 class="mb-0 font-weight-500">Impressions</h5>
                                            </div>
                                            :
                                            <div class="analytics-card analytics-card-purple">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="mb-1 me-2">
                                                        <Skeleton width={50} height={40}
                                                            className="mb-2"
                                                            highlightColor="#7F48FB"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </h1>
                                                    <span class="d-flex align-items-center justify-content-center">

                                                        <Skeleton width={40} height={15}
                                                            className="mb-2"
                                                            baseColor="#FCD9DB"
                                                            highlightColor="#EA1320"
                                                            duration={4}
                                                            borderRadius="5px"
                                                        />
                                                    </span>
                                                </div>
                                                <h5 class="mb-0 font-weight-500">Impressions</h5>
                                            </div>
                                        }
                                        {statsData?.new_subscribers?.type ?
                                            <div class="analytics-card analytics-card-pink">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="mb-1 me-2">{statsData?.new_subscribers?.count}</h1>
                                                    <span class={`analytics-badge ${statsData?.new_subscribers?.type == "up" ? 'analytics-badge-success' : 'analytics-badge-danger'}  "d-flex align-items-center justify-content-center`}>
                                                        {statsData?.new_subscribers?.type !== "-" && <img src={statsData?.new_subscribers?.type == "up" ? analyticsUp : analyticsDown} width="10px" alt="" />}
                                                        <span class="ms-1">{statsData?.new_subscribers?.progress == "-" ? 0 : statsData?.new_subscribers?.progress}%</span>
                                                    </span>
                                                </div>
                                                <h5 class="mb-0 font-weight-500">New Subcribers</h5>
                                            </div>
                                            :
                                            <div class="analytics-card analytics-card-pink">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="mb-1 me-2">
                                                        <Skeleton width={50} height={40}
                                                            className="mb-2"
                                                            highlightColor="#FB48C9"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </h1>
                                                    <span class="d-flex align-items-center justify-content-center">

                                                        <Skeleton width={40} height={15}
                                                            className="mb-2"
                                                            baseColor="#D5F5E3"
                                                            highlightColor="#0CBD5B"
                                                            duration={4}
                                                            borderRadius="5px"
                                                        />
                                                    </span>
                                                </div>
                                                <h5 class="mb-0 font-weight-500">New Subcribers</h5>
                                            </div>
                                        }
                                        {statsData?.new_subscribers?.type ?
                                            <div class="analytics-card analytics-card-orange me-0">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="mb-1 me-2">${statsData?.revenue?.count}</h1>
                                                    <span class={`analytics-badge ${statsData?.revenue?.type == "up" ? 'analytics-badge-success' : 'analytics-badge-danger'}  "d-flex align-items-center justify-content-center`}>
                                                        {statsData?.revenue?.type !== "-" && <img src={statsData?.revenue?.type == "up" ? analyticsUp : analyticsDown} width="10px" alt="" />}
                                                        <span class="ms-1">{statsData?.revenue?.progress == "-" ? 0 : statsData?.revenue?.progress}%</span>
                                                    </span>
                                                </div>
                                                <h5 class="mb-0 font-weight-500">Revenue</h5>
                                            </div>
                                            :
                                            <div class="analytics-card analytics-card-orange me-0">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="mb-1 me-2">
                                                        <Skeleton width={50} height={40}
                                                            className="mb-2"
                                                            highlightColor="#FB9E48"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </h1>
                                                    <span class="d-flex align-items-center justify-content-center">

                                                        <Skeleton width={40} height={15}
                                                            className="mb-2"
                                                            baseColor="#D5F5E3"
                                                            highlightColor="#0CBD5B"
                                                            duration={4}
                                                            borderRadius="5px"
                                                        />
                                                    </span>
                                                </div>
                                                <h5 class="mb-0 font-weight-500">Revenue</h5>
                                            </div>
                                        }
                                    </div>




                                    <div class="d-flex align-items-center justify-content-end analytics-filter-main">
                                        <div class="filter-dropdown dropdown-toggle noselect" id="filter-dropdown"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <span class="">Filter:</span>
                                            <span class="filter-dropdown-menu-selected ms-2 font-weight-500">All</span>
                                            <img src={chevron_down} width="10px" alt="" class="ms-1" />
                                        </div>

                                        <ul class="dropdown-menu filter-dropdown-menu analytics-filter-dropdown-menu"
                                            aria-labelledby="filter-dropdown" onClick={e => e.stopPropagation()}>
                                            <li>
                                                <a class="dropdown-item active" href="#" data-value="All">
                                                    <span class="me-5 d-flex align-items-center justify-content-betwen">
                                                        <input type="checkbox" value="unique_visitors" name="analytics-graph-filter[]" id="graph-label-1" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                                        <label htmlFor="graph-label-1">Unique Visitors</label>
                                                    </span>
                                                    <small class="text-muted">{statsData?.unique_visitors?.count}</small>
                                                </a>
                                            </li>

                                            <li>
                                                <a class="dropdown-item active" href="#" data-value="All">
                                                    <span class="me-5 d-flex align-items-center justify-content-betwen">
                                                        <input type="checkbox" value="impressions" name="analytics-graph-filter[]" id="graph-label-2" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                                        <label htmlFor="graph-label-2">Impressions</label>
                                                    </span>
                                                    <small class="text-muted">{statsData?.impressions?.count}</small>
                                                </a>
                                            </li>

                                            <li>
                                                <a class="dropdown-item active" href="#" data-value="All">
                                                    <span class="me-5 d-flex align-items-center justify-content-betwen">
                                                        <input type="checkbox" value="new_subscribers" name="analytics-graph-filter[]" id="graph-label-3" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                                        <label htmlFor="graph-label-3">New subscribers</label>
                                                    </span>
                                                    <small class="text-muted">{statsData?.new_subscribers?.count}</small>
                                                </a>
                                            </li>


                                            <li>
                                                <a class="dropdown-item active" href="#" data-value="All">
                                                    <span class="me-5 d-flex align-items-center justify-content-betwen">
                                                        <input type="checkbox" value="revenue" name="analytics-graph-filter[]" id="graph-label-4" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                                        <label htmlFor="graph-label-4">Revenue</label>
                                                    </span>
                                                    <small class="text-muted">${statsData?.revenue?.count}</small>
                                                </a>
                                            </li>

                                        </ul>

                                        <div class="ms-1 date-filter-dropdown dropdown-toggle noselect"
                                            id="date-filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span class="date-filter-dropdown-menu-selected font-weight-500">All</span>
                                            <img src={chevron_down} width="10px" alt="" class="ms-1" />
                                        </div>

                                        <ul class="dropdown-menu date-filter-dropdown-menu" aria-labelledby="date-filter-dropdown" onClick={e => e.stopPropagation()}>
                                            <li>
                                                <div id="calendar-picker"></div>
                                            </li>
                                        </ul>

                                    </div>


                                </div>


                                {data?.loaded == false ?
                                    <Skeleton height={300} className="my-2 w-full d-flex mt-4 analytics-main-chart-main mb-4"
                                        highlightColor="#ffffff"
                                        baseColor="rgb(208, 233, 255)"
                                        borderRadius="0.5rem" duration={4} />
                                    :
                                    <div class="d-flex mt-4 analytics-main-chart-main mb-4">
                                        <Line id="analytics-main-chart"
                                            data={data} config={originalOptions}
                                            width={"100%"} height={screenWidth ? 50 : 30} />

                                    </div>
                                }

                                <div class="d-flex align-items-start justify-content-between mt-2 row ms-0 mr-0 w-100">
                                    {topSources ?
                                        topSources?.length > 0 &&
                                        <div class="col-md-6 col-sm-12 pe-md-4 pe-0 ps-0 mt-4">
                                            <h3>Top Sources</h3>
                                            <div class="d-flex flex-column mt-2 pe-md-3 pe-0">

                                                {topSources?.map((val, index) => (
                                                    <div class="d-flex flex-column analytics-progress" key={index}>
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <div class="d-flex align-items-center py-2">
                                                                <img src={val?.icon} width="22px" alt="" />
                                                                <h6 class="font-weight-500 mb-0 ms-2 pt-1">{val?.source}</h6>
                                                            </div>
                                                            <span class="font-weight-500 text-analytics-blue">{val?.count}</span>
                                                        </div>
                                                        <div class="w-100 analytics-progress-bar">
                                                            <div style={{ width: val?.percent }}></div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                        :
                                        <div class="col-md-6 col-sm-12 pe-md-4 pe-0 ps-0 mt-4">
                                            <h3>Top Sources</h3>
                                            <div class="d-flex flex-column mt-2 pe-md-3 pe-0">
                                                <div class="d-flex flex-column analytics-progress">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div class="d-flex align-items-center py-2">
                                                            {/* <img src={analyticsInsta} width="22px" alt="" /> */}
                                                            {/* <h6 class="font-weight-500 mb-0 ms-2 pt-1">https://instagram.com</h6> */}
                                                        </div>
                                                        <Skeleton width={30} height={20}
                                                            className=" text-analytics-blue"
                                                            baseColor="rgb(208, 233, 255)"
                                                            highlightColor="rgb(12, 140, 251)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                    <Skeleton width="65%" height={10}
                                                        className="w-100"
                                                        baseColor="rgb(208, 233, 255)"
                                                        highlightColor="rgb(12, 140, 251)"
                                                        borderRadius="0.5rem"
                                                        duration={4}
                                                    />
                                                </div>
                                                <div class="d-flex flex-column analytics-progress mt-2">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div class="d-flex align-items-center py-2">
                                                            {/* <img src={analyticsYoutube} width="22px" alt="" /> */}
                                                            {/* <h6 class="font-weight-500 mb-0 ms-2 pt-1">https://youtube.com</h6> */}
                                                        </div>
                                                        <Skeleton width={30} height={20}
                                                            className=" text-analytics-purple"
                                                            baseColor="rgb(232, 222, 255)"
                                                            highlightColor="rgb(127, 72, 251)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                    <Skeleton width="45%" height={10}
                                                        className="w-100"
                                                        baseColor="rgb(232, 222, 255)"
                                                        highlightColor="rgb(127, 72, 251)"
                                                        borderRadius="0.5rem"
                                                        duration={4}
                                                    />
                                                </div>
                                                <div class="d-flex flex-column analytics-progress mt-2">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div class="d-flex align-items-center py-2">
                                                            {/* <img src={analyticsTwitter} width="22px" alt="" /> */}
                                                            {/* <h6 class="font-weight-500 mb-0 ms-2 pt-1">https://twitter.com</h6> */}
                                                        </div>
                                                        <Skeleton width={30} height={20}
                                                            className=" text-analytics-pink"
                                                            baseColor="rgb(255, 226, 247)"
                                                            highlightColor="rgb(251, 72, 201)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                    <Skeleton width="23%" height={10}
                                                        className="w-100"
                                                        baseColor="rgb(255, 226, 247)"
                                                        highlightColor="rgb(251, 72, 201)"
                                                        borderRadius="0.5rem"
                                                        duration={4}
                                                    />
                                                </div>
                                                <div class="d-flex flex-column analytics-progress mt-2">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div class="d-flex align-items-center py-2">
                                                            {/* <img src={analyticsTiktok} width="22px" alt="" /> */}
                                                            {/* <h6 class="font-weight-500 mb-0 ms-2 pt-1">https://tiktok.com</h6> */}
                                                        </div>
                                                        <Skeleton width={30} height={20}
                                                            className=" text-analytics-pink"
                                                            baseColor="rgb(255, 235, 217)"
                                                            highlightColor="rgb(251, 158, 72)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                    <Skeleton width="15%" height={10}
                                                        className="w-100"
                                                        baseColor="rgb(255, 235, 217)"
                                                        highlightColor="rgb(251, 158, 72)"
                                                        borderRadius="0.5rem"
                                                        duration={4}
                                                    />
                                                </div>
                                                <div class="d-flex flex-column analytics-progress mt-2">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div class="d-flex align-items-center py-2">
                                                            {/* <img src={analyticsLinkedin} width="22px" alt="" /> */}
                                                            {/* <h6 class="font-weight-500 mb-0 ms-2 pt-1">https://linkedin.com</h6> */}
                                                        </div>
                                                        <Skeleton width={30} height={20}
                                                            className=" text-analytics-pink"
                                                            baseColor="rgb(228, 250, 255)"
                                                            highlightColor="rgb(73, 219, 251)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                    <Skeleton width="9.8%" height={10}
                                                        className="w-100"
                                                        baseColor="rgb(228, 250, 255)"
                                                        highlightColor="rgb(73, 219, 251)"
                                                        borderRadius="0.5rem"
                                                        duration={4}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {topCountries ?
                                        topCountries?.length > 0 &&
                                        <div class="col-md-6 col-sm-12 mt-4 ps-0 pe-0">
                                            <h3>Top Locations</h3>
                                            <div class="d-flex flex-column mt-2 pe-md-3 pe-0">
                                                {topCountries?.map((val, index) => {
                                                    return (
                                                        <div class="d-flex flex-column analytics-progress" key={index}>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <div class="d-flex align-items-center py-2">
                                                                    <img src={require(`../assets/img/flags/${val?.source}.png`)} width="22px" alt="" />
                                                                    <h6 class="font-weight-500 mb-0 ms-2 pt-1">{val?.country_name}</h6>
                                                                </div>
                                                                <span class="font-weight-500 text-analytics-blue">{val?.count}</span>
                                                            </div>
                                                            <div class="w-100 analytics-progress-bar">
                                                                <div style={{ width: val?.percent }}></div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div class="col-md-6 col-sm-12 mt-4 ps-0 pe-0">
                                                <h3>Top Locations</h3>
                                                <div class="d-flex flex-column mt-2 pe-md-3 pe-0">
                                                    <div class="d-flex flex-column analytics-progress">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <div class="d-flex align-items-center py-2">
                                                                {/* <img src={analyticsFlag_uk} width="22px" alt="" /> */}
                                                                {/* <h6 class="font-weight-500 mb-0 ms-2 pt-1">United Kingdom</h6> */}
                                                            </div>
                                                            <Skeleton width={30} height={20}
                                                                className=" text-analytics-blue"
                                                                baseColor="rgb(208, 233, 255)"
                                                                highlightColor="rgb(12, 140, 251)"
                                                                borderRadius="0.5rem"
                                                                duration={4}
                                                            />
                                                        </div>
                                                        <Skeleton width="65%" height={10}
                                                            className="w-100"
                                                            baseColor="rgb(208, 233, 255)"
                                                            highlightColor="rgb(12, 140, 251)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>

                                                    <div class="d-flex flex-column analytics-progress mt-2">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <div class="d-flex align-items-center py-2">
                                                                {/* <img src={analyticsFlag_fr} width="22px" alt="" /> */}
                                                                {/* <h6 class="font-weight-500 mb-0 ms-2 pt-1">France</h6> */}
                                                            </div>
                                                            <Skeleton width={30} height={20}
                                                                className=" text-analytics-purple"
                                                                baseColor="rgb(232, 222, 255)"
                                                                highlightColor="rgb(127, 72, 251)"
                                                                borderRadius="0.5rem"
                                                                duration={4}
                                                            />
                                                        </div>
                                                        <Skeleton width="45%" height={10}
                                                            className="w-100"
                                                            baseColor="rgb(232, 222, 255)"
                                                            highlightColor="rgb(127, 72, 251)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>

                                                    <div class="d-flex flex-column analytics-progress mt-2">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <div class="d-flex align-items-center py-2">
                                                                {/* <img src={analyticsFlag_es} width="22px" alt="" /> */}
                                                                {/* <h6 class="font-weight-500 mb-0 ms-2 pt-1">Spain</h6> */}
                                                            </div>
                                                            <Skeleton width={30} height={20}
                                                                className=" text-analytics-pink"
                                                                baseColor="rgb(255, 226, 247)"
                                                                highlightColor="rgb(251, 72, 201)"
                                                                borderRadius="0.5rem"
                                                                duration={4}
                                                            />
                                                        </div>
                                                        <Skeleton width="23%" height={10}
                                                            className="w-100"
                                                            baseColor="rgb(255, 226, 247)"
                                                            highlightColor="rgb(251, 72, 201)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>

                                                    <div class="d-flex flex-column analytics-progress mt-2">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <div class="d-flex align-items-center py-2">
                                                                {/* <img src={analyticsFlag_in} width="22px" alt="" /> */}
                                                                {/* <h6 class="font-weight-500 mb-0 ms-2 pt-1">India</h6> */}
                                                            </div>
                                                            <Skeleton width={30} height={20}
                                                                className=" text-analytics-pink"
                                                                baseColor="rgb(255, 235, 217)"
                                                                highlightColor="rgb(251, 158, 72)"
                                                                borderRadius="0.5rem"
                                                                duration={4}
                                                            />
                                                        </div>
                                                        <Skeleton width="15%" height={10}
                                                            className="w-100"
                                                            baseColor="rgb(255, 235, 217)"
                                                            highlightColor="rgb(251, 158, 72)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>

                                                    <div class="d-flex flex-column analytics-progress mt-2">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <div class="d-flex align-items-center py-2">
                                                                {/* <img src={analyticsFlag_us} width="22px" alt="" /> */}
                                                                {/* <h6 class="font-weight-500 mb-0 ms-2 pt-1">United States</h6> */}
                                                            </div>
                                                            <Skeleton width={30} height={20}
                                                                className=" text-analytics-pink"
                                                                baseColor="rgb(228, 250, 255)"
                                                                highlightColor="rgb(73, 219, 251)"
                                                                borderRadius="0.5rem"
                                                                duration={4}
                                                            />
                                                        </div>
                                                        <Skeleton width="9.8%" height={10}
                                                            className="w-100"
                                                            baseColor="rgb(228, 250, 255)"
                                                            highlightColor="rgb(73, 219, 251)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div></div></div>
                                        </>
                                    }
                                </div>

                                <div class="d-flex align-items-start justify-content-between mt-2 row ms-0 mr-0 w-100">
                                    <div class="col-md-4 col-sm-12 pe-md-4 pe-0 ps-0 mt-md-5 mt-4">
                                        <h3>Devices</h3>

                                        {devices && Object.keys(devices)?.length !== 0 ?
                                            <div class="d-flex flex-column mt-3">


                                                <div class="d-flex align-items-center justify-content-start analytics-stats">
                                                    <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
                                                        <img src={desktopIcon} alt="" />
                                                        <span class="font-weight-500 ms-2">Desktop</span>
                                                    </div>

                                                    <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
                                                        <div style={{ width: `${devices?.desktop}%` }}></div>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
                                                    <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
                                                        <img src={mobileIcon} alt="" />
                                                        <span class="font-weight-500 ms-2">Mobile</span>
                                                    </div>

                                                    <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
                                                        <div style={{ width: `${devices?.mobile}%` }}></div>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
                                                    <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
                                                        <img src={tabletIcon} alt="" />
                                                        <span class="font-weight-500 ms-2">Tablet</span>
                                                    </div>

                                                    <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
                                                        <div style={{ width: `${devices?.tablet}%` }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div class="d-flex flex-column mt-3">
                                                <div class="d-flex align-items-center justify-content-start analytics-stats">
                                                    <div class="w-100 analytics-progress-bar-large me-2">
                                                        <Skeleton width={"45%"} height={35}
                                                            className=" w-100"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>

                                                    <div class="w-100 analytics-progress-bar-large">
                                                        <Skeleton width={"45%"} height={35}
                                                            className=" w-100"
                                                            baseColor="rgb(208, 233, 255)"
                                                            highlightColor="rgb(12, 140, 251)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-start analytics-stats mt-3">

                                                    <div class="w-100 analytics-progress-bar-large me-2">
                                                        <Skeleton width={"45%"} height={35}
                                                            className=" w-100"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>

                                                    <div class="w-100 analytics-progress-bar-large">
                                                        <Skeleton width={"45%"} height={35}
                                                            className=" w-100"
                                                            baseColor="rgb(232, 222, 255)"
                                                            highlightColor="rgb(127, 72, 251)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-start analytics-stats mt-3">

                                                    <div class="w-100 analytics-progress-bar-large me-2">
                                                        <Skeleton width={"45%"} height={35}
                                                            className=" w-100"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                    <div class="w-100 analytics-progress-bar-large">
                                                        <Skeleton width={"20%"} height={35}
                                                            className=" w-100"
                                                            baseColor="rgb(255, 226, 247)"
                                                            highlightColor="rgb(251, 72, 201)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div class="col-md-4 col-sm-12 pe-md-4 pe-0 ps-0 mt-md-5 mt-4">
                                        <h3>Browsers</h3>
                                        {
                                            browsers && Object.keys(browsers)?.length > 0 ?
                                                <div class="d-flex flex-column mt-3">
                                                    <div class="d-flex align-items-center justify-content-start analytics-stats">
                                                        <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
                                                            <img src={safariIcon} alt="" />
                                                            <span class="font-weight-500 ms-2">Safari</span>
                                                        </div>
                                                        <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
                                                            <div style={{ width: `${browsers?.Safari}%` }}></div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
                                                        <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
                                                            <img src={chromeIcon} alt="" />
                                                            <span class="font-weight-500 ms-2">Chrome</span>
                                                        </div>

                                                        <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
                                                            <div style={{ width: `${browsers?.Chrome}%` }}></div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
                                                        <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
                                                            <img src={edgeIcon} alt="" />
                                                            <span class="font-weight-500 ms-2">Edge</span>
                                                        </div>

                                                        <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
                                                            <div style={{ width: `${browsers?.Edge}%` }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div class="d-flex flex-column mt-3">
                                                    <div class="d-flex align-items-center justify-content-start analytics-stats">
                                                        <div class="w-100 analytics-progress-bar-large me-2">
                                                            <Skeleton width={"45%"} height={35}
                                                                className=" w-100"
                                                                borderRadius="0.5rem"
                                                                duration={4}
                                                            />
                                                        </div>
                                                        <div class="w-100 analytics-progress-bar-large">
                                                            <Skeleton width={"45%"} height={35}
                                                                className=" w-100"
                                                                baseColor="rgb(208, 233, 255)"
                                                                highlightColor="rgb(12, 140, 251)"
                                                                borderRadius="0.5rem"
                                                                duration={4}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start analytics-stats mt-3">
                                                        <div class="w-100 analytics-progress-bar-large me-2">
                                                            <Skeleton width={"45%"} height={35}
                                                                className=" w-100"
                                                                borderRadius="0.5rem"
                                                                duration={4}
                                                            />
                                                        </div>
                                                        <div class="w-100 analytics-progress-bar-large">
                                                            <Skeleton width={"45%"} height={35}
                                                                className=" w-100"
                                                                baseColor="rgb(232, 222, 255)"
                                                                highlightColor="rgb(127, 72, 251)"
                                                                borderRadius="0.5rem"
                                                                duration={4}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start analytics-stats mt-3">

                                                        <div class="w-100 analytics-progress-bar-large me-2">
                                                            <Skeleton width={"45%"} height={35}
                                                                className=" w-100"
                                                                borderRadius="0.5rem"
                                                                duration={4}
                                                            />
                                                        </div>
                                                        <div class="w-100 analytics-progress-bar-large">
                                                            <Skeleton width={"20%"} height={35}
                                                                className=" w-100"
                                                                baseColor="rgb(255, 226, 247)"
                                                                highlightColor="rgb(251, 72, 201)"
                                                                borderRadius="0.5rem"
                                                                duration={4}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    {languages && Object.keys(languages)?.length <= 0 ?
                                        <div class="col-md-4 col-sm-12 pe-md-4 pe-0 ps-0 mt-md-5 mt-4">
                                            <h3>Languages</h3>
                                            <div class="d-flex flex-column mt-3">
                                                <div className='d-flex align-items-center justify-content-start analytics-stats'>
                                                    <div class='d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2'>
                                                        <span class='font-weight-500 ms-2'>English</span>
                                                    </div>
                                                    <div class='w-100 analytics-progress-bar analytics-progress-bar-large'>
                                                        <div style={{ width: '0%' }}></div>
                                                    </div>
                                                </div><div className='d-flex align-items-center justify-content-start analytics-stats'>
                                                    <div class='d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2'>
                                                        <span class='font-weight-500 ms-2'>Spanish</span>
                                                    </div>
                                                    <div class='w-100 analytics-progress-bar analytics-progress-bar-large'>
                                                        <div style={{ width: '0%' }}></div>
                                                    </div>
                                                </div><div className='d-flex align-items-center justify-content-start analytics-stats'>
                                                    <div class='d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2'>
                                                        <span class='font-weight-500 ms-2'>French</span>
                                                    </div>
                                                    <div class='w-100 analytics-progress-bar analytics-progress-bar-large'>
                                                        <div style={{ width: '0%' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''
                                    }
                                    {languages ?
                                        Object.keys(languages)?.length > 0 &&
                                        (<div class="col-md-4 col-sm-12 pe-md-4 pe-0 ps-0 mt-md-5 mt-4">
                                            <h3>Languages</h3>
                                            <div class="d-flex flex-column mt-3">
                                                {Object.keys(languages)?.length > 0 && Object.keys(languages)?.map((val, index) => (
                                                    <div class="d-flex align-items-center justify-content-start analytics-stats" key={index}>
                                                        <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
                                                            <span class="font-weight-500 ms-2">{val}</span>
                                                        </div>
                                                        <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
                                                            <div style={{ width: `${languages[val]}%` }}></div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>)
                                        :
                                        <div class="col-md-4 col-sm-12 pe-md-4 pe-0 ps-0 mt-md-5 mt-4">
                                            <h3>Languages</h3>
                                            <div class="d-flex flex-column mt-3">
                                                <div class="d-flex align-items-center justify-content-start analytics-stats">
                                                    <div class="w-100 analytics-progress-bar-large me-2">
                                                        <Skeleton width={"45%"} height={35}
                                                            className=" w-100"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                    <div class="w-100 analytics-progress-bar-large">
                                                        <Skeleton width={"45%"} height={35}
                                                            className=" w-100"
                                                            baseColor="rgb(208, 233, 255)"
                                                            highlightColor="rgb(12, 140, 251)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-start analytics-stats mt-3">
                                                    <div class="w-100 analytics-progress-bar-large me-2">
                                                        <Skeleton width={"45%"} height={35}
                                                            className=" w-100"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                    <div class="w-100 analytics-progress-bar-large">
                                                        <Skeleton width={"45%"} height={35}
                                                            className=" w-100"
                                                            baseColor="rgb(232, 222, 255)"
                                                            highlightColor="rgb(127, 72, 251)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-start analytics-stats mt-3">
                                                    <div class="w-100 analytics-progress-bar-large me-2">
                                                        <Skeleton width={"45%"} height={35}
                                                            className=" w-100"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                    <div class="w-100 analytics-progress-bar-large">
                                                        <Skeleton width={"20%"} height={35}
                                                            className=" w-100"
                                                            baseColor="rgb(255, 226, 247)"
                                                            highlightColor="rgb(251, 72, 201)"
                                                            borderRadius="0.5rem"
                                                            duration={4}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                </div>

                                {
                                    activity?.activities ?
                                        <div class="mt-5 d-md-block">
                                            <h3>Last Activity</h3>
                                            <div class="col-12 table-responsiveness analytic-tab" >

                                                <table class="table table-borderless table-sm analytics-table"  >
                                                    <thead >
                                                        <tr>
                                                            <th class="text-gray font-weight-500">Location</th>
                                                            <th class="text-gray font-weight-500">When</th>
                                                            <th class="text-gray font-weight-500">From</th>
                                                            <th class="text-gray font-weight-500">Device</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {activity?.activities?.length > 0 && activity?.activities?.map((val, index) => (
                                                            <tr key={index} >
                                                                <td>
                                                                    <span class="analytics-table-pill">
                                                                        <img src={require(`../assets/img/flags/${val?.country_code}.png`)} width="22px" alt="" />
                                                                        <span class="text-gray">{val?.country}</span>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span class="analytics-table-pill-min">
                                                                        <span class="text-gray" >{val?.when}</span>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {val?.source != "Instagram" ?
                                                                        <span class="analytics-table-pill">
                                                                            <img src={require(`../assets/img/flags/Unknown.png`)} width="22px" alt="" />
                                                                            <span class="text-gray">{val?.source}</span>
                                                                        </span> : ''
                                                                    }
                                                                    {val?.source == "Instagram" ?
                                                                        <span class="analytics-table-pill">
                                                                            <img src={analyticsInsta} alt="" />
                                                                            <span class="text-gray">{val?.source}</span>
                                                                        </span> : ''
                                                                    }
                                                                    {/* {val?.source == "Facebook" ?
                                                                    <span class="analytics-table-pill">
                                                                        <img src={analyticsInsta} width="22px" alt="" />
                                                                        <span class="text-gray">{val?.source}</span>
                                                                    </span>:''
                                                                     } */}
                                                                </td>
                                                                <td>
                                                                    {val?.device_type == "Desktop" ?
                                                                        <span class="analytics-table-pill">
                                                                            <img src={desktopIcon} alt="" />
                                                                            <span class="text-gray">{val?.device_type}</span>
                                                                        </span> : ''
                                                                    }
                                                                    {val?.device_type == "Mobile" ?
                                                                        <span class="analytics-table-pill">
                                                                            <img src={mobileIcon} alt="" />
                                                                            <span class="text-gray">{val?.device_type}</span>
                                                                        </span> : ''
                                                                    }
                                                                    {val?.device_type == "Tablet" ?
                                                                        <span class="analytics-table-pill">
                                                                            <img src={tabletIcon} alt="" />
                                                                            <span class="text-gray">{val?.device_type}</span>
                                                                        </span> : ''
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <div class="mt-5 d-md-block d-none">
                                            <h3>Last Activity</h3>
                                            <div class="col-12 table-responsiveness">
                                                <table class="table table-borderless table-sm analytics-table">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-gray font-weight-500">Location</th>
                                                            <th class="text-gray font-weight-500">When</th>
                                                            <th class="text-gray font-weight-500">From</th>
                                                            <th class="text-gray font-weight-500">Device</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                }
                            <div class="section-footer d-flex align-items-center justify-content-between">
                                <CommunityFooter filter={filter} setfilter={setfilter} total={activity?.paginated?.total} last_page={activity?.paginated?.last_page} current_page={activity?.paginated?.current_page}/>
                            </div>
                            </div>
                        }
                        {/* <div class={"section-footer d-flex align-items-center justify-content-between  d-md-flex"}>
                            <span class="text-muted">{activity?.paginated?.total} results</span>
                            {
                                filter?.page && (
                                    <div class="table-pagination analytics-table-pagination">
                                        {filter?.page !== 1 && <button onClick={(e) => {
                                            const _filter = { ...filter, page: filter?.page - 1 }
                                            setfilter({ ...filter, page: filter?.page - 1 })
                                            $(".table-pagination > .btn-light").removeClass("active");

                                            $(e.target).addClass('active');
                                            callActivity(_filter)
                                        }} class="btn btn-light btn-sm 1">{filter?.page - 1}</button>}

                                        <button onClick={(e) => {
                                            const _filter = { ...filter, page: filter?.page }
                                            setfilter({ ...filter, page: filter?.page })
                                            $(".table-pagination > .btn-light").removeClass("active");

                                            $(e.target).addClass('active');
                                            callActivity(_filter)
                                        }} class="btn btn-light btn-sm 2">{filter?.page}</button>

                                        <button ref={secondPg}
                                            onClick={(e) => {
                                                const _filter = { ...filter, page: filter?.page + 1 }
                                                setfilter({ ...filter, page: filter?.page + 1 })
                                                $(".table-pagination > .btn-light").removeClass("active");
                                                $(e.target).addClass('active');
                                                callActivity(_filter)
                                            }} class="btn btn-light btn-sm 3" disabled={filter?.page == activity?.paginated?.last_page}>{filter?.page + 1}</button>
                                        {filter?.page == 1 && <button

                                            onClick={(e) => {
                                                const _filter = { ...filter, page: filter?.page + 2 }
                                                setfilter({ ...filter, page: filter?.page + 2 })
                                                $(".table-pagination > .btn-light").removeClass("active");

                                                $(e.target).addClass('active');
                                                callActivity(_filter)
                                            }}
                                            disabled={activity?.activities?.length < 10 && filter?.page + 1 >= activity?.paginated?.last_page} class="btn btn-light btn-sm 4">{filter?.page + 2}</button>}
                                    </div>
                                )
                            }
                        </div> */}
                    </section>
                </div >
            </div >
        </>
    )
}
export default Analytics