const INIT_STATE = {
    editLink: null,
    selected: null
}
const uploadImageReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_UPLOAD_IMAGE':
            return action.payload 
        case 'RESET_UPLOAD_IMAGE':
            return INIT_STATE
        default:
            return state
    }

}
export default uploadImageReducer