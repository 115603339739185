
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap"
import embed_svg from "../../../../assets/img/embed-block-icon2.svg"


const CreateEmbedBlockModal = ({ show, handleHide, returnIcon, handleSubmit, embedBlockModal, setembedBlockModal }) => {

    const embedList = ["Youtube", "Twitter"]

    const [value, setValue] = useState({
        url: null,
        embed_type: null
    });
    
// console.log("embedBlockModalembedBlockModal", embedBlockModal);
    const handleChange = (e) => setValue({ ...value, [e.target.name]: e.target.value })

    const selectBtnClick = (e) => {
        let selectDropdown = document.getElementById("selectDropdown");
        selectDropdown.classList.toggle('toggle');
    }

    const selectOptionClick = (b) => {
        b.target.parentElement.classList.remove('toggle');

        let parent = b.target.closest('.select').children[0];
        parent.setAttribute('data-type', b.target.getAttribute('data-type'));
        parent.innerText = b.target.innerText;

        setValue({ ...value, embed_type: b.target.getAttribute('data-type') });
    };

    useEffect(()=>{
        setValue({...embedBlockModal, url: embedBlockModal?.details?.url, embed_type: embedBlockModal?.details?.embed_type})
    },[])
    const heading = embedBlockModal ? "Update an Embed to your page" : "Add an Embed to your page";

    return (
        <Modal centered show={show} onHide={!embedBlockModal?.show ? handleHide : () => {
            setembedBlockModal({
                ...embedBlockModal,
                show: !embedBlockModal?.show,
            })
        }} id="createTextBlockModal">
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e, {
                                url: value?.url,
                                embed_type: value?.embed_type?.toLowerCase()
                            }, embedBlockModal?.show ?  embedBlockModal?.id : "embed", true)
                        }}>
                            <div class="notification-container"></div>


                            <div class="form-group">
                                <img src={embed_svg} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Add an Embed to your page</h4>
                                <p class="text-gray font-weight-500">Embed Youtube videos, Tweets and more.</p>
                            </div>

                            <div class="form-group">
                                <label><small>Select embed type</small></label>
                                <div class="product-custom-input">
                                    <div class="select">
                                        <div class="selectBtn" onClick={selectBtnClick} data-type={(value?.embed_type) ?? ''}>{(value?.embed_type) ?? 'Select Embed'}</div>
                                        <div class="selectDropdown" id="selectDropdown">
                                            {embedList?.length > 0 && embedList?.map((val, index) => <div key={index} onClick={selectOptionClick} class="product-widget-option option" data-type={val}>{val}</div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br />
                            <div class="form-group">
                                <label><small>URL</small></label>
                                <input type="url" value={value?.url} class={`form-control zaap-form-control`} onChange={handleChange} name="url" required="required" placeholder="https://www.youtube.com/watch/" />
                            </div>
                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={!embedBlockModal?.show ? handleHide : () => {
                                    setembedBlockModal({
                                        ...embedBlockModal,
                                        show: !embedBlockModal?.show,
                                    })
                                }} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button autoFocus type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Modal>
    )
}

export default CreateEmbedBlockModal