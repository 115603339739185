const INIT_STATE = []
const blockReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_BLOCK_SETTING':
            return action.payload 
        case 'RESET_BLOCK_SETTING':
            return INIT_STATE
        default:
            return state
    }

}
export default blockReducer