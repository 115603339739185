import { useHistory } from "react-router-dom";
import upgrade_new_nfc_card from "../../assets/img/upgrade_new_nfc_card.svg";
import upgrade_new_1 from "../../assets/img/upgrade_new_1.svg";
import upgrade_new_8 from "../../assets/img/upgrade_new_8.svg";
import upgrade_new_2 from "../../assets/img/upgrade_new_2.svg";
import upgrade_new_youtube from "../../assets/img/upgrade_new_youtube.svg";
import upgrade_new_tiktok from "../../assets/img/upgrade_new_tiktok.svg";
import upgrade_new_twitter from "../../assets/img/upgrade_new_twitter.svg";
import * as $ from "jquery"
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from '../../components/loader';
import { Alert } from "react-bootstrap";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import UpgradeInputField from "../upgrade/upgrade-input";
import upgradeSubmit from "../upgrade/upgrade-button";
import { apiUrl } from "../../utils/url";

const getErrors = (errors) => {
    const errorList = []
    if (errors?.category)
        errors?.category?.map((val) => errorList?.push(val))
    if (errors?.step)
        errors?.step?.map((val) => errorList?.push(val))
    return errorList
}

window?.addEventListener("message", (e) => {
    if (e?.data?.isPro) {
        $(".skip-btn-submit").click()
    }
    hideLoader()
})

const Step6 = ({ moveNext, moveBack }) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [error, setError] = useState([]);
    const [value, setValue] = useState({ step: 6 });
    const [code, setCode] = useState(null)
    const { slug } = useSelector(state => state?.slugReducer);

    const onSubmit = (e, from, values) => {
        showLoader();
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData();
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                formdata.append(key, values[key]);
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/walkthrough", requestOptions)
            .then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    if (from == "next" || from == "skip") {
                        toast.success(response?.status_message)
                        localStorage.removeItem("lastStep")
                        localStorage.removeItem("currentStep")
                        localStorage.removeItem("walkthrough_step")
                        localStorage.setItem("walkthrough", 1)
                        moveNext(e, `dashboard`)
                    }
                    else if (from == "back") moveBack(e, response)
                }
                else {
                    setError(getErrors(response?.errors))
                    toast.error(response?.status_message)
                }
            }).catch((err) => hideLoader())
    };

    const handleChange = (e) => setCode(e.target.value)

    return (
        <>
            <div class="col-11 col-sm-11 col-md-8 col-lg-8 h-100 d-flex align-items-center justify-content-center">
                <div class="card border-0 p-0 m-0 w-100 h-100">
                    <div class="card-body">
                        <h1 class="page-heading text-left">Unlock a whole lot of power with Znaplink Pro</h1>
                        <p class="page-subtitle text-left">$9/month. Cancel anytime</p>

                        <form action="" method="" class="mt-3" role="form">
                            {error?.length > 0 && (
                                <Alert variant="danger">
                                    {error?.map((val, index) => <li>{val}</li>)}
                                </Alert>
                            )}
                            <div class="category-main d-flex">

                                <div class="d-flex align-items-center mt-2 px-2 dev_upgrade_new_main">
                                    <div class="dv_upgrade_new px-2">
                                        <img src={upgrade_new_nfc_card} style={{ width: "40px" }} alt="" />
                                    </div>
                                    <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                        <h5 class="mb-1 text-left"><span style={{ color: "#FF1451" }}>Free</span>&nbsp;Znaplink NFC Card</h5>
                                        <small class="text-muted m-0">Bring your page to life! Learn more <a href="https://www.znaplink.com/card" style={{ color: "#FF1451" }}>here</a></small>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center mt-2 px-2 dev_upgrade_new_main">
                                    <div class="dv_upgrade_new px-2">
                                        <img src={upgrade_new_1} style={{ width: "30px" }} alt="" />
                                    </div>
                                    <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                        <h5 class="mb-1 text-left">Use Your&nbsp;<span style={{ color: "#FF1451" }}>Own Domain</span></h5>
                                        <small class="text-muted m-0">Go from ‘zaap.bio/you’ to ‘yourdomain.com’</small>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center mt-2 px-2 dev_upgrade_new_main">
                                    <div class="dv_upgrade_new px-2">
                                        <img src={upgrade_new_8} style={{ width: "30px" }} alt="" />
                                    </div>
                                    <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                        <h5 class="mb-1 text-left">Send Emails&nbsp;<span style={{ color: "#FF1451" }}>Directly</span>&nbsp;From Znaplink</h5>
                                        <small class="text-muted m-0">Unlimited emails to your community from Znaplink</small>
                                    </div>
                                </div>


                                <div class="d-flex align-items-center mt-2 px-2 dev_upgrade_new_main">
                                    <div class="dv_upgrade_new px-2">
                                        <img src={upgrade_new_2} style={{ width: "30px" }} alt="" />
                                    </div>
                                    <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                        <h5 class="mb-1 text-left">Remove Znaplink's&nbsp;<span style={{ color: "#FF1451" }}>Branding</span></h5>
                                        <small class="text-muted m-0">Own your page and design - without our logo on it</small>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center mt-2 px-2 dev_upgrade_new_main">
                                    <div class="dv_upgrade_new px-2">
                                        <img src={upgrade_new_youtube} style={{ width: "40px" }} alt="" />
                                    </div>
                                    <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                        <h5 class="mb-1 text-left">Sync Your&nbsp;<span style={{ color: "#FF1451" }}>Latest YouTube</span>&nbsp;Videos</h5>
                                        <small class="text-muted m-0">Your page will auto-update to show your latest videos</small>
                                    </div>
                                </div>


                                <div class="d-flex align-items-center mt-2 px-2 dev_upgrade_new_main">
                                    <div class="dv_upgrade_new px-2">
                                        <img src={upgrade_new_tiktok} style={{ width: "40px" }} alt="" />
                                    </div>
                                    <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                        <h5 class="mb-1 text-left">Sync Your&nbsp;<span style={{ color: "#FF1451" }}>Latest TikTok</span>&nbsp;Videos</h5>
                                        <small class="text-muted m-0">Your page will auto-update & show your latest TikToks</small>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center mt-2 mb-2 px-2 dev_upgrade_new_main">
                                    <div class="dv_upgrade_new px-2">
                                        <img src={upgrade_new_twitter} style={{ width: "40px" }} alt="" />
                                    </div>
                                    <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                        <h5 class="mb-1 text-left">Sync Your&nbsp;<span style={{ color: "#FF1451" }}>Latest Twitter</span>&nbsp;Posts</h5>
                                        <small class="text-muted m-0">Your page will auto-update to show your latest Tweets</small>
                                    </div>
                                </div>

                                <UpgradeInputField handleChange={handleChange} code={code} />

                            </div>
                            <div class="form-group mt-3 mb-0 d-flex justify-content-center">
                                <button
                                    type="button" onClick={(e) =>
                                        onSubmit(e, "skip", {
                                            skip: 1,
                                            step: 6
                                        })} class="btn  btn btn-grey btn-rounded text-dark btn-block my-1 flex-1 me-2 skip-btn-submit py-2"><b>Skip</b></button>

                                <button onClick={(e) => {

                                    dispatch({ type: "RESET_PRO" })

                                    localStorage.removeItem("sendRequest")
                                    upgradeSubmit(e, code, slug, false, "stripe")
                                }} type="button"

                                    class="join-btn btn btn-primary btn-block my-1 flex-1 py-2">
                                    <b>
                                        Join the Pro’s
                                        {` `}

                                        <svg width="18" height="12" class="ml-1 align-baseline" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">

                                            <path d="M14.2517 2.42589C13.711 1.87093 13.711 0.971172 14.2517 0.416217C14.7924 -0.138739 15.6691 -0.138739 16.2098 0.416217L23.5945 7.99516C24.1352 8.55012 24.1352 9.44988 23.5945 10.0048L16.2098 17.5838C15.6691 18.1387 14.7924 18.1387 14.2517 17.5838C13.711 17.0288 13.711 16.1291 14.2517 15.5741L19.2726 10.4211H1.38462C0.619914 10.4211 0 9.78483 0 9C0 8.21517 0.619914 7.57895 1.38462 7.57895H19.2726L14.2517 2.42589Z" fill="white" />

                                        </svg></b>

                                </button>

                            </div>
                            <div class="form-group d-flex justify-content-center">
                                <button onClick={(e) => {
                                    onSubmit(e, "back", {
                                        back: 1,
                                        step: 6
                                    })

                                }}
                                    type="button" class="btn flex-1 mr-2 back-btn text-muted-light" data-step="5">Back</button>

                            </div>
                        </form>
                    </div>
                </div>



            </div>
            {/* <div class="page-aside-bg page-aside-bg3"></div> */}
        </>

    )
}
export default Step6