const INIT_STATE = {show: false, to: "customize"}
const profilePicUploadReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_PROFILE_PIC_MODAL':
            return action.payload 
        case 'RESET_PROFILE_PIC_MODAL':
            return INIT_STATE
        default:
            return state
    }

}
export default profilePicUploadReducer