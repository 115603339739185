import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { apiUrl } from "../../../utils/url"
import { hideLoader, showLoader } from "../../loader"
// import { basePath } from "../../../routes/mainRoutes"
import DomainsCustomize from "../cutomizePageOptions/domainsCustomize"
import IntegrationsCustomize from "../cutomizePageOptions/integrationsCustomize"
import ProfileCustomize from "../cutomizePageOptions/profileCustomize"
import SeoCustomize from "../cutomizePageOptions/SeoCustomize"
import SocialsCustomize from "../cutomizePageOptions/socialsCustomize"
import ThemeCustomize from "../cutomizePageOptions/themeCustomize"
import FontsCustomize from "../cutomizePageOptions/fontsCustomize"

const TabContent = ({ tabShow, subPath, setShowSaveChanges, show, setShowCustomizeParent, settings, handleSubmit, error, setBannerModal, slug }) => {
    const history = useHistory();
    const location = useLocation();
    const { basePath } = useSelector(state => state?.slugReducer);

    const handleClose = () => history.push(basePath + "/page-editor/customizePageModal")

    if (subPath !== undefined || tabShow !== undefined)
        if (subPath == tabShow) {
            if (subPath == "customizePageProfile")
                return (

                    <div class="main-section tab-content">
                        <ProfileCustomize setShowSaveChanges={setShowSaveChanges}
                            settings={{ title: settings?.title, description: settings?.description, link: settings?.link, collect_emails: settings?.collect_emails, verified_check: settings?.verified_check, page_language: settings?.page_language, favicon_uploaod: settings?.favicon_uploaod }}
                            error={error} handleSubmit={handleSubmit} show={show} />

                    </div>
                )
            else if (subPath == "customizePageTheme")
                return (

                    <div class="main-section tab-content">

                        <ThemeCustomize setShowSaveChanges={setShowSaveChanges} setBannerModal={setBannerModal} theme_color={settings?.theme_color} error={error} handleSubmit={handleSubmit} show={show} onHide={handleClose} />

                    </div>
                )
            else if (subPath == "customizePageSocials")
                return (
                    <div class="main-section tab-content">
                        <SocialsCustomize setShowSaveChanges={setShowSaveChanges} handleSubmit={handleSubmit} error={error} settings={settings?.seo} />
                    </div>
                )
            // else if (subPath == "customizePageDomains")
            //     return (
            //         <div class="main-section tab-content">
            //             <DomainsCustomize slug={slug} settings={settings?.custom_domain} handleSubmit={handleSubmit} error={error} setShowCustomizeParent={setShowCustomizeParent} />
            //         </div>
            //     )
            else if (subPath == "customizePageSEO")
                return (
                    <div class="main-section tab-content">
                        <SeoCustomize setShowSaveChanges={setShowSaveChanges} handleSubmit={handleSubmit} error={error} settings={settings?.seo} />
                    </div>
                )
            // else if (subPath == "customizePageIntegrations")
            //     return (
            //         <div class="main-section tab-content">
            //             <IntegrationsCustomize setShowCustomizeParent={setShowCustomizeParent} />
            //         </div>
            //     )
            else if (subPath == "customizePageFonts")
                return (

                    <div class="main-section tab-content">

                        <FontsCustomize setShowSaveChanges={setShowSaveChanges} handleSubmit={handleSubmit} error={error} />

                    </div>
                )
            else {
                return (

                    <div class="main-section tab-content">

                        <ProfileCustomize setShowSaveChanges={setShowSaveChanges}
                            settings={{ title: settings?.title, description: settings?.description, link: settings?.link, collect_emails: settings?.collect_emails, verified_check: settings?.verified_check, page_language: settings?.page_language, favicon_uploaod: settings?.favicon_uploaod }}
                            error={error} handleSubmit={handleSubmit} show={show} onHide={handleClose} />

                    </div>
                )
            }
        }
        else {
            if (tabShow == "customizePageProfile") {
                return (

                    <div class="main-section tab-content">

                        <ProfileCustomize setShowSaveChanges={setShowSaveChanges}
                            settings={{ title: settings?.title, description: settings?.description, link: settings?.link, collect_emails: settings?.collect_emails, verified_check: settings?.verified_check, page_language: settings?.page_language, favicon_uploaod: settings?.favicon_uploaod }}
                            error={error} handleSubmit={handleSubmit} show={show} onHide={handleClose} />

                    </div>
                )
            }
            else if (tabShow == "customizePageTheme") {
                return (

                    <div class="main-section tab-content">

                        <ThemeCustomize setShowSaveChanges={setShowSaveChanges} setBannerModal={setBannerModal} theme_color={settings?.theme_color} error={error} handleSubmit={handleSubmit} show={show} onHide={handleClose} />

                    </div>
                )

            }
            else if (tabShow == "customizePageSocials")
                return (
                    <div class="main-section tab-content">
                        <SocialsCustomize setShowSaveChanges={setShowSaveChanges} handleSubmit={handleSubmit} error={error} />
                    </div>
                )
            else if (subPath == "customizePageFonts")
                return (
                    <div class="main-section tab-content">
                        <FontsCustomize setShowSaveChanges={setShowSaveChanges} handleSubmit={handleSubmit} error={error} />
                    </div>
                )
            // else if (tabShow == "customizePageDomains")
            //     return (
            //         <div class="main-section tab-content">
            //             <DomainsCustomize slug={slug} settings={settings?.custom_domain} handleSubmit={handleSubmit} error={error} setShowCustomizeParent={setShowCustomizeParent} />
            //         </div>
            //     )
            else if (tabShow == "customizePageSEO")
                return (
                    <div class="main-section tab-content">
                        <SeoCustomize setShowSaveChanges={setShowSaveChanges} handleSubmit={handleSubmit} error={error} settings={settings?.seo} />
                    </div>
                )
            // else if (tabShow == "customizePageIntegrations")
            //     return (
            //         <div class="main-section tab-content">
            //             <IntegrationsCustomize setShowCustomizeParent={setShowCustomizeParent} />
            //         </div>
            //     )
            else {
                return (

                    <div class="main-section tab-content">

                        <ProfileCustomize setShowSaveChanges={setShowSaveChanges}
                            settings={{ title: settings?.title, description: settings?.description, link: settings?.link, collect_emails: settings?.collect_emails, verified_check: settings?.verified_check, page_language: settings?.page_language, favicon_uploaod: settings?.favicon_uploaod }}
                            error={error} handleSubmit={handleSubmit} show={show} onHide={handleClose} />

                    </div>
                )
            }
        }
    else {
        if (location.pathname.split("/")[5] == "customizePageProfile") {
            return (

                <div class="main-section tab-content">

                    <ProfileCustomize setShowSaveChanges={setShowSaveChanges}
                        settings={{ title: settings?.title, description: settings?.description, link: settings?.link, collect_emails: settings?.collect_emails, verified_check: settings?.verified_check, page_language: settings?.page_language, favicon_uploaod: settings?.favicon_uploaod }}
                        error={error} handleSubmit={handleSubmit} show={show} onHide={handleClose} />

                </div>
            )
        }
        else if (location.pathname.split("/")[5] == "customizePageTheme") {
            return (

                <div class="main-section tab-content">

                    <ThemeCustomize setShowSaveChanges={setShowSaveChanges} setBannerModal={setBannerModal} theme_color={settings?.theme_color} error={error} handleSubmit={handleSubmit} show={show} onHide={handleClose} />

                </div>
            )

        }
        else if (location.pathname.split("/")[5] == "customizePageSocials")
            return (
                <div class="main-section tab-content">
                    <SocialsCustomize setShowSaveChanges={setShowSaveChanges} handleSubmit={handleSubmit} error={error} />
                </div>
            )
        // else if (location.pathname.split("/")[5] == "customizePageDomains")
        //     return (
        //         <div class="main-section tab-content">
        //             <DomainsCustomize slug={slug} settings={settings?.custom_domain} handleSubmit={handleSubmit} error={error} setShowCustomizeParent={setShowCustomizeParent} />
        //         </div>
        //     )
        else if (location.pathname.split("/")[5] == "customizePageSEO")
            return (
                <div class="main-section tab-content">
                    <SeoCustomize setShowSaveChanges={setShowSaveChanges} handleSubmit={handleSubmit} error={error} settings={settings?.seo} />
                </div>
            )
        // else if (location.pathname.split("/")[5] == "customizePageIntegrations")
        //     return (
        //         <div class="main-section tab-content">
        //             <IntegrationsCustomize setShowCustomizeParent={setShowCustomizeParent} />
        //         </div>
        //     )
        else {
            return (

                <div class="main-section tab-content">

                    <ProfileCustomize setShowSaveChanges={setShowSaveChanges}
                        settings={{ title: settings?.title, description: settings?.description, link: settings?.link, collect_emails: settings?.collect_emails, verified_check: settings?.verified_check, page_language: settings?.page_language, favicon_uploaod: settings?.favicon_uploaod }}
                        error={error} handleSubmit={handleSubmit} show={show} onHide={handleClose} />

                </div>
            )
        }

    }
}
export default TabContent