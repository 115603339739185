

export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
let labels = [];
let graph_dates = new Date();

for (let index = 1; index < 10; index++) {
    graph_dates.setDate(graph_dates.getDate() - 1);
    labels.push(months[graph_dates.getMonth()]+' '+graph_dates.getDate());    
}

export const originalData = {
    labels: labels,
    datasets: [
        {
            label: 'Unique Visitors',
            data: [20, 40, 70, 100, 140, 180, 240, 230, 120, 40],
            fill: false,
            borderColor: '#0C8CFB',
            tension: 0.1
        },
        {
            label: 'Impressions Visitors',
            data: [120, 240, 170, 100, 40, 80, 140, 130, 140, 60],
            fill: false,
            borderColor: '#7F48FB',
            tension: 0.1
        },
        {
            label: 'New Subscribers',
            data: [220, 40, 210, 110, 140, 80, 240, 170, 100, 40],
            fill: false,
            borderColor: '#FB48C9',
            tension: 0.1
        },
        {
            label: 'Revenue',
            data: [130, 40, 100, 150, 200, 250, 220, 150, 170, 100],
            fill: false,
            borderColor: '#FB9E48',
            tension: 0.1
        }
    ]
};

export const originalOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },

    },
    scales: {
        xAxes: {
            ticks: {
              callback: (value, index, values) => {
                // Show only the first and last label
                console.log(" values.lengths - 1",  values);
                if (index === 0 || index === values.length - 1) {
                  return value;
                }
                return '';
              },
            },
          },
        x: {
            grid: {
                display: false
            },
            display: false
        },
        y: {
            grid: {
                drawBorder: false
            },
            display: false
        }
    },
    legend: {
        display: false,
    },
}
export const originalOptionsForDashboardGraph =  {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        filler: {
            propagate: true
        }
    },
    scales: {
        // x: {
        //     ticks: {
        //       callback: (value, index, values) => {
        //         console.log(" values.lengths - 1",  value);
        //         if (index === 0 || index === values.length - 1) {
        //           return 'value';
        //         }
        //         return '';
        //       },
        //     },
        //   },
        x: {
            grid: {
                borderDash: [8, 4],
                display: true
            },
            display: true
        },
        y: {
            display: false
        }
    },
    tooltips: {
        callbacks: {
           label: function(tooltipItem) {
                  return tooltipItem.yLabel;
           }
        }
    },
    legend: {
        display: false,
    },
    elements: {
        point:{
            radius: 0
        }
    }
}
export const options = {
    scales: {
        xAxes: {
          ticks: {
            callback: (value, index, values) => {
              // Show only the first and last label
              console.log(" values.lengths - 1",  values);
              if (index === 0 || index === values.length - 1) {
                return value;
              }
              return '';
            },
          },
        },
      },
}