import { useRef } from "react"

const CommunityFooter = ({from,total, filter, setfilter, last_page,current_page,loading}) => {
    const secondPg = useRef(null);
    const showRows = (count) => {
        const newLastPage = Math.ceil(total / count);
        const newPage = Math.min(filter.page, newLastPage);
        localStorage?.setItem(from+'_count',count)
        setfilter({ ...filter, count: count, page: newPage });
    };
    return (
        <>
        <div class="result-foot d-flex align-items-center gap-1">
                <span> Showing </span>
                <span class="filter-action-count-dropdown  dropdown-toggle noselect d-flex align-items-center justify-content-end dropdown-subscribers" id="filter-count-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false" >
                    {filter?.count}
                </span>
                <ul class="filter-dropdown-menu dropdown-menu" style={{minWidth: "54px"}} aria-labelledby="filter-action-count-dropdown">
                    <li onClick={() => showRows(10)} className={`filter-dropdown-item`}>10</li>
                    <li onClick={() => showRows(25)} className={`filter-dropdown-item`}>25</li>
                    <li onClick={() => showRows(50)} className={`filter-dropdown-item`}>50</li>
                    <li onClick={() => showRows(100)} className={`filter-dropdown-item`}>100</li>
                </ul>
                <span>of {total} records</span>
            </div>
            {
                filter?.page && (
                    <div class="table-pagination pagebtn-u">
                        <button className={`btn nextprvbtn ${current_page == 1 ? 'btn-disabeled' : '' }`} onClick={() => setfilter({ ...filter, page: filter?.page - 1 })} disabled={loading ? true : (current_page == 1 ? true : false) }>Previous</button>

                        {filter?.page !== 1 && <button disabled={loading} onClick={() => setfilter({ ...filter, page: filter?.page - 1 })} class="btn btn-light btn-sm 1">{filter?.page - 1}</button>}

                        <button onClick={() => setfilter({ ...filter, page: filter?.page })} class="btn btn-light btn-sm active 2">{filter?.page}</button>

                        <button ref={secondPg} onClick={() => setfilter({ ...filter, page: filter?.page + 1 })} class="btn btn-light btn-sm 3" disabled={loading ? true : (filter?.page == last_page)}>{filter?.page + 1}</button>

                        {filter?.page == 1 && <button onClick={() => setfilter({ ...filter, page: filter?.page + 2 })} disabled={loading ? true : (filter?.page +2 > last_page)} class="btn btn-light btn-sm 4">{filter?.page + 2}</button>}

                        <button ref={secondPg} onClick={() => setfilter({ ...filter, page: filter?.page + 1 })} disabled={loading ? true : (filter?.page == last_page)} className={`btn nextprvbtn ${filter?.page == last_page ? 'btn-disabeled' : '' }`}>Next</button>
                    </div>

                )
            }
        </>
    )
}
export default CommunityFooter