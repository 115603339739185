const INIT_STATE = {
    dotModal:false,
    connectedModal:false,
}
const domainDotModalShowReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_DOMAIN_MODAL_SHOW':
            return action.payload 
        case 'RESET_DOMAIN_MODAL_SHOW':
            return INIT_STATE
        default:
            return state
    }
}
export default domainDotModalShowReducer