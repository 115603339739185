import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from '../../components/loader';
import { Alert } from "react-bootstrap";
import { toast } from 'react-toastify';
import { apiUrl } from "../../utils/url";
import * as $ from "jquery"

const getErrors = (errors) => {
    const errorList = []
    if (errors?.category)
        errors?.category?.map((val) => errorList?.push(val))
    if (errors?.step)
        errors?.step?.map((val) => errorList?.push(val))
    return errorList
}

const categoryPillsActive = (_this) => {
    
    $(".category-pill").removeClass('active');
    $(_this).toggleClass('active');

    $("#category-input").val($(_this).text());
    $("button").removeAttr('disabled');
}
$(document).on('click', '.category-pill', function () {
    categoryPillsActive(this)
  });

const Step5 = ({ moveNext, moveBack }) => {
    const { state } = useLocation();
    const [error, setError] = useState([])
    const [allCategories, setallCategories] = useState([])
    const [value, setValue] = useState({
        category: null, step: 5
    });
      
    const handleChange = (e) => setValue({ ...value, ["category"]: e.target.innerText });

    const onSubmit = (e, from, values) => {
        showLoader();
        setError([])
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData();
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                formdata.append(key, values[key]);
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/walkthrough", requestOptions)
            .then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    if (from == "next" || from == "skip") {
                        toast.success(response?.status_message)
                        moveNext(e, 6)
                    }
                    else if (from == "back") {
                        moveBack(e, response)
                    }
                }
                else {
                    setError(getErrors(response?.errors))
                    toast.error(response?.status_message)
                }

            }).catch((err) => {
                hideLoader()
            })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(e, "next", value)
    }

    const getCategories = async (e) => {
        showLoader()
        fetch(apiUrl + "/categories")
            .then(async (res) => {
                hideLoader()
                const response = await res.json();
                setallCategories(response)
            }).catch((err) => {
                
// console.log(err)
                hideLoader()
            })
    }

    useEffect(() => { getCategories() }, [])
    useEffect(() => {
        try {
            const state = JSON.parse(localStorage?.backStepValue)
            
// console.log("state", state);
            if (state?.category) setValue({ ...value, category: state?.category })
        } catch { }
    }, [localStorage?.backStepValue])

    return (
        <>
            <div class="col-11 col-sm-11 col-md-8 col-lg-8 h-100 d-flex align-items-center justify-content-center">
                <div class="card border-0 p-0 m-0 w-100 h-100">
                    <div class="card-body themes-selection">
                        <h1 class="page-heading text-left">Tell us about yourself</h1>
                        <p class="page-subtitle text-left">Select a category that best describes your Znaplink. We use this to provide a personalised experience.</p>

                        <form action="" method="post" class="mt-4" role="form" onSubmit={(e) => handleSubmit(e)}>
                            {error?.length > 0 && (
                                <Alert variant="danger">
                                    {error?.map((val, index) => <li>{val}</li>)}
                                </Alert>
                            )}
                            <div class="category-main">

                                {allCategories?.length > 0 && (
                                    allCategories?.map((val, index) => (
                                        (val == value?.category) ?
                                            <div class="category-pill-main" name={val} key={index}>
                                                <div class="category-pill active" onClick={handleChange}>{val}</div>
                                            </div>
                                            :
                                            <div class="category-pill-main" name={val} key={index}>
                                                <div class="category-pill" onClick={handleChange}>{val}</div>
                                            </div>
                                    )))}


                            </div>


                            <div class="form-group mt-3">
                                <button type="submit" name="submit" class="btn btn-primary btn-block my-1 py-25"
                                    style={{ backgroundColor: "#FF1451", border: "none" }}
                                    disabled={!value?.category}
                                >
                                    <b>Next
                                        <svg width="18" height="12" class="ml-1 align-baseline" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.2517 2.42589C13.711 1.87093 13.711 0.971172 14.2517 0.416217C14.7924 -0.138739 15.6691 -0.138739 16.2098 0.416217L23.5945 7.99516C24.1352 8.55012 24.1352 9.44988 23.5945 10.0048L16.2098 17.5838C15.6691 18.1387 14.7924 18.1387 14.2517 17.5838C13.711 17.0288 13.711 16.1291 14.2517 15.5741L19.2726 10.4211H1.38462C0.619914 10.4211 0 9.78483 0 9C0 8.21517 0.619914 7.57895 1.38462 7.57895H19.2726L14.2517 2.42589Z" fill="white" />
                                        </svg></b>
                                </button>
                            </div>

                            <div class="form-group d-flex justify-content-around">
                                <button
                                    onClick={(e) =>
                                        onSubmit(e, "back", {
                                            back: 1,
                                            step: 5
                                        })} type="button" class="btn flex-1 mr-2 back-btn text-muted-light"
                                    data-step="4">Back</button>

                                <button type="button" name="button" class="btn  flex-1 mr-2 skip-btn text-muted-light"
                                    disabled={!value?.category}
                                    onClick={(e) =>
                                        onSubmit(e, "skip", {
                                            skip: 1,
                                            step: e.target.dataset.step
                                        })} data-step="5">Skip for now</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            {/* <div class="page-aside-bg page-aside-bg3"></div> */}
        </>
    )
}
export default Step5