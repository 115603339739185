import React from 'react'
import warningIcon from "../../assets/img/warning.svg";
import * as $ from "jquery";
import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Modal, Offcanvas } from "react-bootstrap";


import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../../assets/css/monetization.css"

export default function saveChanges({isOpen, onHide, handleSave}) {
  let history = useHistory();
  const location = useLocation();
  const isModalOpen = useSelector(state => state?.upgradeModalReducer?.open)
  const { slug, basePath } = useSelector((state) => state?.slugReducer);
  const moveback = () => {history.push(basePath + "/products")}
  const saveChanges =() => {
    handleSave();
    onHide();
    
  }
  return (
    <>
    <Modal centered id="newBlockModal"  show={isOpen == null ? isModalOpen : isOpen} onHide={isOpen == null ? onClose : onHide} backdrop="static"  >
                    <div className=" modal-dialog-centered" role="document" style={{width:'100px !important'}}>
                        <div className="modal-content ">
                            <div className="modal-body">
                                <div className="form-group">
                                    <img src={warningIcon} style={{ width: "40px" }} alt="" />
                                    <h4 className="mt-2 fs-3">Save changes?</h4>
                                    <p className="editor-modaltext">You are able to loose any changes made. Save changes to avoid.</p>
                                    <button type="submit" class="btn btn-primary btn-semi-rounded me-2" onClick={saveChanges}>
                                        <b class="me-2" >Save Changes</b>
                                    </button>
                                    <button type="button" class="close btn btn-grey btn-semi-rounded " onClick={moveback}>
                                        Don't Show
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>
</>
  )
}
