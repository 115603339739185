const INIT_STATE = []
const allLanguagesReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_All_LANGUAGES':
            return action.payload 
        case 'RESET_All_LANGUAGES':
            return INIT_STATE
        default:
            return state
    }

}
export default allLanguagesReducer