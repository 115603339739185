import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ModalErrors from "../../../errors/modal-errors";
import emailIcon from "../../../../assets/img/modals/contact-block-icon.svg";
import centerAlignIcon from "../../../../assets/img/modals/center-align-icon.svg";
import leftAlignIcon from "../../../../assets/img/modals/left-align-icon.svg";
import textIcon from "../../../../assets/img/modals/text-icon.svg";

const CreateContactModal = ({
  show,
  handleHide,
  returnIcon,
  handleSubmit,
  slug,
  error,
  contactModal,
  setcontactModal,
}) =>
{
  const [value, setValue] = useState({
    title: null,
    sendresponseto: null,
    shownform: "visible",
      name: false,
      name_required: false,
      email: false,
      email_required: false,
      phone: false,
      phone_required: false,
      message: false,
      message_required: false,
    });

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  const handleChangecheck = (e) => {
    setValue({ ...value, [e.target.name]: e.target.checked });
};
  useEffect(() => {
if(contactModal)
    setValue({
      ...value,
      title: contactModal?.name?? null,
      sendresponseto: contactModal?.details?.sendresponseto?? null,
      shownform: contactModal?.details?.shownform ?? "visible",
      name: JSON.parse(contactModal?.details?.name) ?? false,
      name_required: JSON.parse(contactModal?.details?.name_required ) ?? false,
      email: JSON.parse(contactModal?.details?.email) ?? false,
      email_required: JSON.parse(contactModal?.details?.email_required) ?? false,
      phone: JSON.parse(contactModal?.details?.phone)?? false,
      phone_required:JSON.parse( contactModal?.details?.phone_required )?? false,
      message: JSON.parse(contactModal?.details?.message)?? false,
      message_required: JSON.parse(contactModal?.details?.message_required) ?? false,
    });
  }, [contactModal]);

  // 
// console.log(value?.shownform);
  const heading = contactModal ? "Update contact form" : "Add a contact form";
  return (
    <Modal
      centered
      show={show}
      size="lg"
      onHide={
        !contactModal
          ? handleHide
          : () => {
              setcontactModal(!contactModal);
            }
      }
      id="createcontactModal"
    >
      <div class="modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <form
              name="contact"
              method="post"
              role="form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(
                  e,
                  value,
                  contactModal?.show ? contactModal?.id : "contact",
                  true
                );
              }}
            >
              <div class="notification-container"></div>

              <div class="form-group">
                <img src={emailIcon} width="30px" alt="" />
                <h4 class="mt-2 mb-1">{heading}</h4>
                <p class="text-gray font-weight-500">
                  Allow your audience to contact you directly.
                </p>
              </div>

              <div class="form-group">
                <label>
                  <small>
                    Title{" "}
                    <label>
                      <span class="text-danger">*</span>
                    </label>
                  </small>
                </label>
                <input
                  type="text"
                  class={`form-control zaap-form-control`}
                  onChange={handleChange}
                  name="title"
                  required="required"
                  placeholder="Contact me!"
                  value={value?.title}
                />
              </div>

              <div class="form-group mt-2 mb-2">
                <label>
                  <small>
                    Send responses to{" "}
                    <label>
                      <span class="text-danger">*</span>
                    </label>
                  </small>
                </label>
                <input
                  type="email"
                  class={`form-control zaap-form-control`}
                  onChange={handleChange}
                  name="sendresponseto"
                  required="required"
                  placeholder="you@email.com"
                  value={value?.sendresponseto}
                />
              </div>

              <div class="form-group">
                <label>
                  <small>Shown form as:</small>
                </label>
                <div class="up-in-toggle bg-f1f1f1 contact-toggle w-50 btn-semi-rounded"  
               
                >
                  <input
                    type="radio"
                    id="text_align_left"
                    name="shownform"
                    checked={value?.shownform == "visible"}
                    value={"visible"}
                    onChange={handleChange}
                    style={{
                    display: "none",
                    }}
                  />
                  <label
                    htmlFor="text_align_left"
                    class="btn-semi-rounded"
                    style={{
                      padding: "7px 0px",
                    }}
                  >
                    Visible
                  </label>
                  <input
                    type="radio"
                    id="text_align_right"
                    checked={value?.shownform == "dropdown"}
                    name="shownform"
                    value={"dropdown"}
                    onChange={handleChange}
                    style={{
                    display: "none",
                    }}
                  />
                  <label
                    htmlFor="text_align_right"
                    class="btn-semi-rounded "
                    style={{
                      padding: "7px 0px",
                    }}
                  >
                    Dropdown
                  </label>
                </div>
              </div>

              <div class="row justify-content-evenly align-items-center my-3">
                <div class="col-6 mt-2 pe-1">
                  <div class="d-flex justify-content-between bg-f1f1f1 flex-wrap px-2 py-2 contact-block-fields">
                    <div class="d-flex align-items-center">
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          class=""
                          style={{ "flex-basis": "0%" }}
                          id="checkbox-name"
                          name="name"
                          onChange={handleChangecheck}
                          checked={value?.name}
                        />
                        <label htmlFor="checkbox-name" id="label"></label>
                      </div>
                      <label htmlFor="checkbox-name" class="ms-1 cursor-pointer">Name </label>
                    </div>
                    <div className="d-flex align-items-center justify-content-between ">
                      <span class={"me-2 " + (value?.name ? '':'text-muted-light')}>Required?</span>

                      <label className="link-switch" style={{ opacity: value?.name ?1:'0.4' }}>
                        <input
                          type="checkbox"
                          className=""
                          name="name_required"
                          onChange={handleChangecheck}
                          checked={value?.name_required}
                          disabled={!value?.name}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-6 mt-2 ps-1">
                  <div class="d-flex justify-content-between bg-f1f1f1 flex-wrap px-2 py-2 contact-block-fields">
                    <div class="d-flex align-items-center">
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          class=""
                          style={{ "flex-basis": "0%" }}
                          id="checkbox-email"
                          name="email"
                          onChange={handleChangecheck}
                          checked={value?.email}
                        />
                        <label htmlFor="checkbox-email" id="label"></label>
                      </div>
                      <label htmlFor="checkbox-email" class="ms-1 cursor-pointer">Email</label>
                    </div>
                    <div className="d-flex align-items-center justify-content-between ">
                      <span class={"me-2 " + (value?.email ? '':'text-muted-light')}>Required?</span>

                      <label className="link-switch" style={{ opacity: value?.email ?1:'0.4' }}>
                        <input
                          type="checkbox"
                          className=""
                          name="email_required"
                          onChange={handleChangecheck}
                          checked={value?.email_required}
                          disabled={!value?.email}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-6 mt-2 pe-1">
                  <div class="d-flex justify-content-between bg-f1f1f1 flex-wrap px-2 py-2 contact-block-fields">
                    <div class="d-flex align-items-center">
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          class=""
                          style={{ "flex-basis": "0%" }}
                          id="checkbox-phone"
                          name="phone"
                          onChange={handleChangecheck}
                          checked={value?.phone}
                        />
                        <label htmlFor="checkbox-phone" id="label"></label>
                      </div>
                      <label htmlFor="checkbox-phone" class="cursor-pointer ms-1">Phone</label>
                    </div>
                    <div className="d-flex align-items-center justify-content-between ">
                      <span class={"me-2 " + (value?.phone ? '':'text-muted-light')}>Required?</span>

                      <label className="link-switch" style={{ opacity: value?.phone ?1:'0.4' }}>
                        <input
                          type="checkbox"
                          className=""
                          name="phone_required"
                          onChange={handleChangecheck}
                          checked={value?.phone_required}
                          disabled={!value?.phone}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-6 mt-2 ps-1">
                  <div class="d-flex justify-content-between bg-f1f1f1 flex-wrap px-2 py-2 contact-block-fields">
                    <div class="d-flex align-items-center">
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          class=""
                          style={{ "flex-basis": "0%" }}
                          id="checkbox-message"
                          name="message"
                          onChange={handleChangecheck}
                          checked={value?.message}
                        />
                        <label htmlFor="checkbox-message" id="label"></label>
                      </div>
                      <label htmlFor="checkbox-message" class="ms-1 cursor-pointer">Message</label>
                    </div>
                    <div className="d-flex align-items-center justify-content-between ">
                      <span class={"me-2 " + (value?.message ? '':'text-muted-light')}>Required?</span>

                      <label className="link-switch" style={{ opacity: value?.message ?1:'0.4' }}>
                        <input
                          type="checkbox"
                          className=""
                          name="message_required"
                          onChange={handleChangecheck}
                          checked={value?.message_required}
                          disabled={!value?.message}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-center mt-4 d-flex">
                <button
                  type="button"
                  onClick={
                    !contactModal?.show
                      ? handleHide
                      : () => {
                          setcontactModal({
                            ...contactModal,
                            show: !contactModal?.show,
                          });
                        }
                  }
                  class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center"
                >
                  <b class="me-2">Cancel</b>
                  <small class="badge bg-light text-dark p-1">Esc</small>
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center"
                >
                  <b class="me-2">Save Changes</b>
                  <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                    <img src={returnIcon} alt="" />
                  </small>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default CreateContactModal;
