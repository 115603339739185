const INIT_STATE = true
const proReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_PRO':
            return action.payload 
        case 'RESET_PRO':
            return INIT_STATE
        default:
            return state
    }

}
export default proReducer