
// import "../assets/css/auth.css"
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { hideLoader, showLoader } from '../components/loader';
import zaapLogo from "../assets/img/logo.svg"
import { apiUrl } from '../utils/url';
const ForgetPassword = () => {
    const dispatch = useDispatch();

    const [values, setvalues] = useState({
        email: null
    });
    const [error, setError] = useState([])

    const formSubmit = (e) => {
        e.preventDefault();
        showLoader()

        const formdata = new FormData()
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                formdata.append(key, values[key]);
            }
        }
        fetch(apiUrl + "/forgot-password", {
            body: formdata,
            method: "POST"
        }).then(async (res) => {
            hideLoader()
            const response = await res?.json()
            if (response?.status_code == 200) {
                toast.success(response?.status_message)
            }
            else {
                if (response?.errors) {
                    const errorList = [];
                    response?.errors?.map((val) => errorList.push(val))
                    setError(errorList)
                    if (response?.status_message)
                        toast.error(response?.status_message);
                }
                else {
                    toast.error(response?.status_message);
                }
            }
        }).catch((err) => {
            hideLoader()
            
// console.log(err?.response?.data?.message)
        })

    }
    const handleChange = (e) => {
        setvalues({ ...values, [e.target.name]: e.target.value })
    }

    return (
        <div id="wrapper">
            {/* <nav id="navbar" class="ps-3 pt-2 navbar bavbar-main navbar-expand-lg navbar-light">
                <div class="">
                    <div class="p-lg-0 p-md-0 p-sm-2">
                        <a class="navbar-brand" href="https://zaap.ai/">
                            <img src={zaapLogo}
                                class="img-fluid navbar-logo" alt="Website Logo" />
                        </a>
                    </div>
                </div>
            </nav> */}
            {/* <div class="page-aside-bg"></div> */}

            <div class="auth-main newmain">

                <div class="steps-card">
                    <div class="card border-0 p-0 m-0 w-100 h-100">
                        <div>
                            <h1 class="newpageheading">Forgot Password</h1>
                            <p class="page-subtitle text-left newpage-subtitle "style={{marginBottom: "26px"}}>Already Have an account? <Link to="/login" className="text-primary font-weight-bolder text-decoration-none ">{`Sign in`}</Link></p>
                            <form action="" method="post" class="mt-4" role="form" onSubmit={formSubmit}>
                                <div class="form-group">
                                    <label><strong>Your Email</strong></label>
                                    <div class='newinput-u'>
                                        <input type="email" name="email" placeholder="jerry@example.com" value={values.email}
                                            onChange={handleChange}
                                            required="required" />
                                        {error.length > 0 && (
                                            <Alert variant="danger">
                                                {error.map((val, index) => { val })}
                                            </Alert>
                                        )}

                                    </div>


                                </div>


                                <div class="form-group mt-4">
                                    <button type="submit" name="submit" class="red-btn-u">
                                        Forget Password
                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none"><path d="M2.85366 8C3.12287 8 3.30511 7.80467 3.30511 7.53715C3.30511 7.39703 3.25127 7.29512 3.16843 7.21019L2.11643 6.15711L1.39162 5.53291L2.34837 5.57537H7.51726C8.55683 5.57537 9 5.09554 9 4.04246V1.52442C9 0.458599 8.55683 0 7.51726 0H5.21859C4.9411 0 4.74643 0.212314 4.74643 0.475584C4.74643 0.734607 4.9411 0.946921 5.21445 0.946921H7.49655C7.91072 0.946921 8.08882 1.12951 8.08882 1.55414V4.01274C8.08882 4.45011 7.91072 4.62845 7.49655 4.62845H2.34837L1.39162 4.67516L2.11643 4.04671L3.16843 2.99788C3.25127 2.91295 3.30511 2.80679 3.30511 2.66667C3.30511 2.4034 3.12287 2.20807 2.85366 2.20807C2.73769 2.20807 2.6093 2.26327 2.51818 2.35669L0.144961 4.75159C0.0497009 4.84501 0 4.9724 0 5.10403C0 5.23142 0.0497009 5.35881 0.144961 5.45223L2.51818 7.85138C2.6093 7.9448 2.73769 8 2.85366 8Z" fill="white"/></svg></span>

                                    </button>
                                </div>

                                <div class="form-check ps-0 text-right">
                                    <label class="form-check-label d-none">
                                        <input type="checkbox" checked class="form-check-input" name="rememberme" />
                                        <small class="form-text text-muted">Remember me</small>
                                    </label>
                                </div>


                                {/* <div class="row pt-1 mt-3 border"></div>

                                <div class="text-center mt-4"
                                    style={{ fontWeight: "600", flexBasis: "100%", color: "#64687D", fontSize: "14px" }} >

                                    <span class="text">Have an account?</span> {` `}
                                    <Link to="/login" class="text-primary font-weight-bolder">{`Login`}</Link>
                                </div> */}

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ForgetPassword