const INIT_STATE = {
    pixel_id: null,
    tab: "integrations"
}
const integrationFacebookReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_FACEBOOK_INTEGRATION':
            return action.payload 
        case 'RESET_FACEBOOK_INTEGRATION':
            return INIT_STATE
        default:
            return state
    }
}
export default integrationFacebookReducer