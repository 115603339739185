const setDateFormat = (date_to_set, only_date_month, isSlash) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };

    const date = new Date(date_to_set);
    var strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = date.getDate();
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    var t = date.toLocaleTimeString()
    if (only_date_month)
        return '' + (d <= 9 ? '0' + d : d) + '-' + m;
    if (isSlash)
        return '' + eval(date.getMonth()+1) + '/' + (d <= 9 ? '0' + d : d) + '/' + + y;
    return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y + " " + t;

}
export default setDateFormat
export const newDateFormat = (scheduledAt) => {

    var dateTime = new Date(scheduledAt);
    
    var optionsDate = { day: 'numeric', month: 'short', year: 'numeric' };
    var formattedDate = dateTime.toLocaleDateString('en-US', optionsDate);

    return formattedDate;
}
export const newTimeFormat = (scheduledAt) => {

    var dateTime = new Date(scheduledAt);

    var optionsTime = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    var formattedTime = dateTime.toLocaleTimeString('en-US', optionsTime);
    return formattedTime;
}
// const Analytics = () => {
//     const [loading, setloading] = useState(true);
//     const [screenWidth, setscreenWidth] = useState(false)
//     const { slug } = useSelector(state => state?.slugReducer);

//     const [statsData, setstatsData] = useState({unique_visitors: {},impressions: {},new_subscribers: {},revenue: {}})
//     const [topSources, settopSources] = useState([])
//     const [graphData, setgraphdata] = useState({
//         unique_visitors: {
//             label: 'Unique Visitors',
//             data: [20, 40, 70, 100, 140, 180, 240, 230, 120, 40],
//             fill: false,
//             borderColor: '#0C8CFB',
//             tension: 0.1
//         },
//         impressions: {
//             label: 'Impressions Visitors',
//             data: [120, 240, 170, 100, 40, 80, 140, 130, 140, 60],
//             fill: false,
//             borderColor: '#7F48FB',
//             tension: 0.1
//         },
//         new_subscribers: {
//             label: 'New Subscribers',
//             data: [220, 40, 210, 110, 140, 80, 240, 170, 100, 40],
//             fill: false,
//             borderColor: '#FB48C9',
//             tension: 0.1
//         },
//         revenue: {
//             label: 'Revenue',
//             data: [130, 40, 100, 150, 200, 250, 220, 150, 170, 100],
//             fill: false,
//             borderColor: '#FB9E48',
//             tension: 0.1
//         },
//     })


//     const [data, setData] = useState({
//         labels: labels,
//         datasets: [
//             {
//                 label: 'Unique Visitors',
//                 data: [20, 40, 70, 100, 140, 180, 240, 230, 120, 40],
//                 fill: false,
//                 borderColor: '#0C8CFB',
//                 tension: 0.1
//             },
//             {
//                 label: 'Impressions Visitors',
//                 data: [120, 240, 170, 100, 40, 80, 140, 130, 140, 60],
//                 fill: false,
//                 borderColor: '#7F48FB',
//                 tension: 0.1
//             },
//             {
//                 label: 'New Subscribers',
//                 data: [220, 40, 210, 110, 140, 80, 240, 170, 100, 40],
//                 fill: false,
//                 borderColor: '#FB48C9',
//                 tension: 0.1
//             },
//             {
//                 label: 'Revenue',
//                 data: [130, 40, 100, 150, 200, 250, 220, 150, 170, 100],
//                 fill: false,
//                 borderColor: '#FB9E48',
//                 tension: 0.1
//             }
//         ]
//     })

//         useEffect(() => {
//         if (window.screen.width < 454) {
//             setscreenWidth(true)
//         }
//         setTimeout(() => {
//             renderCalendar();
//         }, 1500);

//     }, []);


//     useEffect(() => {
//         if (slug)
//             getStats(week_before.getMonth() + ' ' + week_before.getDate(), today.getMonth() + ' ' + today.getDate())
//     }, [slug])

//     const getStats = (from_date, to_date) => {
//         setloading(true)
//         const queryParams = "?from_date=" + from_date + "&to_date=" + to_date;
//         const formdata = new FormData();
//         var myHeaders = new Headers();
//         myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
//         const setOptions = {
//             method: "GET",
//             headers: myHeaders,
//         }
//         let temp_graph_data = { ...graphData }
//         statGets(apiUrl + apiBasePath + "/get/" + slug + queryParams, hideLoader, setOptions).then((response) => {
//             setstatsData({ ...response })
//             temp_graph_data.impressions.data = response?.graph_data?.impressions;
//             temp_graph_data.new_subscribers.data = response?.graph_data?.new_subscribers;
//             temp_graph_data.revenue.data = response?.graph_data?.revenue;
//             temp_graph_data.unique_visitors.data = response?.graph_data?.unique_visitors;
//             setgraphdata({
//                 ...graphData,
//                 "impressions": { ...temp_graph_data.impressions },
//                 "new_subscribers": { ...temp_graph_data.new_subscribers },
//                 "revenue": { ...temp_graph_data.revenue },
//                 "unique_visitors": { ...temp_graph_data.unique_visitors },
//             })
//         statGets(apiUrl + apiBasePath + "/getDetailed/" + slug + queryParams, hideLoader, setOptions).then((response) => {
//             settopSources(response?.top_sources)
//             // settopSources()
//             setloading(false)
//         })
//             setloading(false)
//         })

//     }

//     const renderCalendar = () => {
//         var myCalendar = $('#calendar-picker').mobiscroll().datepicker({
//             controls: ['calendar'],
//             display: 'inline',
//             calendarSize: 1,
//             selectMultiple: true,
//             selectMin: 1,
//             selectMax: 2,
//             returnFormat: 'locale',
//             themeVariant: 'light',
//             theme: 'ios',
//             onChange: function (event, inst) {
//                 if (event.value[0]) {
//                     first_date = new Date(event.value[0]);
//                     first_date_text = months[first_date.getMonth()] + ' ' + first_date.getDate();
//                 }
//                 if (event.value[1]) {
//                     last_date = new Date(event.value[1]);
//                     last_date_text = months[last_date.getMonth()] + ' ' + last_date.getDate();
//                 }
//                 if (first_date < last_date) {
//                     $(".date-filter-dropdown-menu-selected").text(first_date_text + ' - ' + last_date_text);
//                 }
//                 else {
//                     $(".date-filter-dropdown-menu-selected").text(last_date_text + ' - ' + first_date_text);
//                 }
//                 
// console.log("first_date", first_date);
//                 getStats(setDateFormat(first_date, false, true), setDateFormat(last_date, false, true))
//             }
//         }).mobiscroll('getInst');

//         // alert(today);
//         setTimeout(() => {
//             myCalendar.setVal([week_before, today]);
//         }, 1500);
//     }

//     useEffect(() => {
//         // mobiscroll.setOptions({
//         //     theme: 'ios',
//         //     themeVariant: 'light'
//         // });
//         if (!loading) {

//             $('.date-filter-dropdown-menu').on('click', function (e) {
//                 e.stopPropagation();
//             });
//             $(".date-filter-dropdown-menu-selected").text(week_before_text + ' - ' + today_text);

//         }
//     }, [loading])


//     useEffect(() => {
//         regenerateGraph();
//     }, [graphData])


//     function regenerateGraph() {
//         let tempArr = []
//         for (let index = 0; index < 4; index++) {
//             if ($('input[name="analytics-graph-filter[]"]').eq(index).is(':checked')) {
//                 var checked_value = $('input[name="analytics-graph-filter[]"]').eq(index).val();
//                 tempArr.push(graphData[checked_value]);

//             }
//         }
//         setData({
//             labels: labels,
//             datasets: tempArr
//         });
//     }

//     const analyticsGraphFilterClick = () => {
//         // showLoader();
//         if ($('input[name="analytics-graph-filter[]"]:checked').length == 4) {
//             $(".filter-dropdown-menu-selected").text('All');
//         }
//         else if ($('input[name="analytics-graph-filter[]"]:checked').length > 0) {
//             $(".filter-dropdown-menu-selected").text('Applied');
//         }
//         else {
//             $(".filter-dropdown-menu-selected").text('None');
//         }
//     }

//     return (
//         <>
//             <div id="wrapper">
//                 <PageHeader />
//                 <div class="analytics-main">
//                     <div class="left-side-bar d-flex align-items-center flex-column justify-content-start">
//                         <a href="" class="left-menu-item active">
//                             <img src={menuOverview} width="20px" alt="" />
//                             <p class="mb-0 ms-2">Overview</p>
//                         </a>
//                     </div>


//                     <section class="analytics-section">

//                         <div class="section-header">
//                             <h1 class="mb-0">Overview</h1>
//                         </div>

//                         {
//                             loading ?

//                                 <div class="section-main mt-4">
//                                     <div class="d-flex align-items-end justify-content-between analytics-header">

//                                         <div class="search-bar d-flex align-items-center jusitfy-content-start flex-wrap">

//                                             <div class="analytics-card analytics-card-blue">
//                                                 <div class="d-flex align-items-center justify-content-start">
//                                                     <h1 class="mb-1 me-2">

//                                                         <Skeleton width={50} height={40}
//                                                             className="mb-2"
//                                                             highlightColor="#0C8CFB"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </h1>
//                                                     <span class=" d-flex align-items-center justify-content-center">
//                                                         <Skeleton width={40} height={15}
//                                                             className="mb-2"
//                                                             baseColor="#D5F5E3"
//                                                             highlightColor="#0CBD5B"
//                                                             duration={4}
//                                                             borderRadius="5px"
//                                                         />
//                                                     </span>

//                                                 </div>
//                                                 <h5 class="mb-0 font-weight-500">Unique Visitors</h5>
//                                             </div>

//                                             <div class="analytics-card analytics-card-purple">
//                                                 <div class="d-flex align-items-center justify-content-start">
//                                                     <h1 class="mb-1 me-2">
//                                                         <Skeleton width={50} height={40}
//                                                             className="mb-2"
//                                                             highlightColor="#7F48FB"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </h1>
//                                                     <span class="d-flex align-items-center justify-content-center">

//                                                         <Skeleton width={40} height={15}
//                                                             className="mb-2"
//                                                             baseColor="#FCD9DB"
//                                                             highlightColor="#EA1320"
//                                                             duration={4}
//                                                             borderRadius="5px"
//                                                         />
//                                                     </span>
//                                                 </div>
//                                                 <h5 class="mb-0 font-weight-500">Impressions</h5>
//                                             </div>

//                                             <div class="analytics-card analytics-card-pink">
//                                                 <div class="d-flex align-items-center justify-content-start">
//                                                     <h1 class="mb-1 me-2">
//                                                         <Skeleton width={50} height={40}
//                                                             className="mb-2"
//                                                             highlightColor="#FB48C9"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </h1>
//                                                     <span class="d-flex align-items-center justify-content-center">

//                                                         <Skeleton width={40} height={15}
//                                                             className="mb-2"
//                                                             baseColor="#D5F5E3"
//                                                             highlightColor="#0CBD5B"
//                                                             duration={4}
//                                                             borderRadius="5px"
//                                                         />
//                                                     </span>
//                                                 </div>
//                                                 <h5 class="mb-0 font-weight-500">New Subcribers</h5>
//                                             </div>

//                                             <div class="analytics-card analytics-card-orange me-0">
//                                                 <div class="d-flex align-items-center justify-content-start">
//                                                     <h1 class="mb-1 me-2">
//                                                         <Skeleton width={50} height={40}
//                                                             className="mb-2"
//                                                             highlightColor="#FB9E48"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </h1>
//                                                     <span class="d-flex align-items-center justify-content-center">

//                                                         <Skeleton width={40} height={15}
//                                                             className="mb-2"
//                                                             baseColor="#D5F5E3"
//                                                             highlightColor="#0CBD5B"
//                                                             duration={4}
//                                                             borderRadius="5px"
//                                                         />
//                                                     </span>
//                                                 </div>
//                                                 <h5 class="mb-0 font-weight-500">Revenue</h5>
//                                             </div>
//                                         </div>

//                                         <div class="d-flex align-items-center justify-content-end analytics-filter-main">
//                                             <div class="filter-dropdown dropdown-toggle noselect" id="filter-dropdown"
//                                                 data-bs-toggle="dropdown" aria-expanded="false">
//                                                 <span class="text-muted">Filter:</span>
//                                                 <span class="filter-dropdown-menu-selected ms-2 font-weight-500">All</span>
//                                                 <img src={chevron_down} width="10px" alt="" class="ms-1" />
//                                             </div>

//                                             <div class="ms-1 date-filter-dropdown dropdown-toggle noselect"
//                                                 id="date-filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
//                                                 <span class="date-filter-dropdown-menu-selected font-weight-500">All</span>
//                                                 <img src={chevron_down} width="10px" alt="" class="ms-1" />
//                                             </div>

//                                         </div>

//                                     </div>
//                                     <Skeleton height={300} className="my-2 w-full"
//                                         highlightColor="#ffffff"
//                                         borderRadius="0.5rem" duration={4} />

//                                     <div class="d-flex align-items-start justify-content-between mt-2 row ms-0 mr-0 w-100">
//                                         <div class="col-md-6 col-sm-12 pe-md-4 pe-0 ps-0 mt-5">
//                                             <h3>Top Sources</h3>
//                                             <div class="d-flex flex-column mt-2 pe-md-3 pe-0">

//                                                 <div class="d-flex flex-column analytics-progress">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsInsta} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">https://instagram.com</h6>
//                                                         </div>
//                                                         <Skeleton width={30} height={20}
//                                                             className=" text-analytics-blue"
//                                                             baseColor="rgb(208, 233, 255)"
//                                                             highlightColor="rgb(12, 140, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                     <Skeleton width="65%" height={10}
//                                                         className="w-100"
//                                                         baseColor="rgb(208, 233, 255)"
//                                                         highlightColor="rgb(12, 140, 251)"
//                                                         borderRadius="0.5rem"
//                                                         duration={4}
//                                                     />
//                                                 </div>
//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsYoutube} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">https://youtube.com</h6>
//                                                         </div>
//                                                         <Skeleton width={30} height={20}
//                                                             className=" text-analytics-purple"
//                                                             baseColor="rgb(232, 222, 255)"
//                                                             highlightColor="rgb(127, 72, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                     <Skeleton width="45%" height={10}
//                                                         className="w-100"
//                                                         baseColor="rgb(232, 222, 255)"
//                                                         highlightColor="rgb(127, 72, 251)"
//                                                         borderRadius="0.5rem"
//                                                         duration={4}
//                                                     />
//                                                 </div>
//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsTwitter} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">https://twitter.com</h6>
//                                                         </div>
//                                                         <Skeleton width={30} height={20}
//                                                             className=" text-analytics-pink"
//                                                             baseColor="rgb(255, 226, 247)"
//                                                             highlightColor="rgb(251, 72, 201)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                     <Skeleton width="23%" height={10}
//                                                         className="w-100"
//                                                         baseColor="rgb(255, 226, 247)"
//                                                         highlightColor="rgb(251, 72, 201)"
//                                                         borderRadius="0.5rem"
//                                                         duration={4}
//                                                     />
//                                                 </div>
//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsTiktok} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">https://tiktok.com</h6>
//                                                         </div>
//                                                         <Skeleton width={30} height={20}
//                                                             className=" text-analytics-pink"
//                                                             baseColor="rgb(255, 235, 217)"
//                                                             highlightColor="rgb(251, 158, 72)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                     <Skeleton width="15%" height={10}
//                                                         className="w-100"
//                                                         baseColor="rgb(255, 235, 217)"
//                                                         highlightColor="rgb(251, 158, 72)"
//                                                         borderRadius="0.5rem"
//                                                         duration={4}
//                                                     />
//                                                 </div>
//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsLinkedin} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">https://linkedin.com</h6>
//                                                         </div>
//                                                         <Skeleton width={30} height={20}
//                                                             className=" text-analytics-pink"
//                                                             baseColor="rgb(228, 250, 255)"
//                                                             highlightColor="rgb(73, 219, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                     <Skeleton width="9.8%" height={10}
//                                                         className="w-100"
//                                                         baseColor="rgb(228, 250, 255)"
//                                                         highlightColor="rgb(73, 219, 251)"
//                                                         borderRadius="0.5rem"
//                                                         duration={4}
//                                                     />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div class="col-md-6 col-sm-12 mt-5 ps-0 pe-0">
//                                             <h3>Top Locations</h3>
//                                             <div class="d-flex flex-column mt-2 pe-md-3 pe-0">

//                                                 <div class="d-flex flex-column analytics-progress">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsFlag_uk} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">United Kingdom</h6>
//                                                         </div>
//                                                         <Skeleton width={30} height={20}
//                                                             className=" text-analytics-blue"
//                                                             baseColor="rgb(208, 233, 255)"
//                                                             highlightColor="rgb(12, 140, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                     <Skeleton width="65%" height={10}
//                                                         className="w-100"
//                                                         baseColor="rgb(208, 233, 255)"
//                                                         highlightColor="rgb(12, 140, 251)"
//                                                         borderRadius="0.5rem"
//                                                         duration={4}
//                                                     />
//                                                 </div>

//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsFlag_fr} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">France</h6>
//                                                         </div>
//                                                         <Skeleton width={30} height={20}
//                                                             className=" text-analytics-purple"
//                                                             baseColor="rgb(232, 222, 255)"
//                                                             highlightColor="rgb(127, 72, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                     <Skeleton width="45%" height={10}
//                                                         className="w-100"
//                                                         baseColor="rgb(232, 222, 255)"
//                                                         highlightColor="rgb(127, 72, 251)"
//                                                         borderRadius="0.5rem"
//                                                         duration={4}
//                                                     />
//                                                 </div>

//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsFlag_es} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">Spain</h6>
//                                                         </div>
//                                                         <Skeleton width={30} height={20}
//                                                             className=" text-analytics-pink"
//                                                             baseColor="rgb(255, 226, 247)"
//                                                             highlightColor="rgb(251, 72, 201)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                     <Skeleton width="23%" height={10}
//                                                         className="w-100"
//                                                         baseColor="rgb(255, 226, 247)"
//                                                         highlightColor="rgb(251, 72, 201)"
//                                                         borderRadius="0.5rem"
//                                                         duration={4}
//                                                     />
//                                                 </div>

//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsFlag_in} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">India</h6>
//                                                         </div>
//                                                         <Skeleton width={30} height={20}
//                                                             className=" text-analytics-pink"
//                                                             baseColor="rgb(255, 235, 217)"
//                                                             highlightColor="rgb(251, 158, 72)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                     <Skeleton width="15%" height={10}
//                                                         className="w-100"
//                                                         baseColor="rgb(255, 235, 217)"
//                                                         highlightColor="rgb(251, 158, 72)"
//                                                         borderRadius="0.5rem"
//                                                         duration={4}
//                                                     />
//                                                 </div>

//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsFlag_us} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">United States</h6>
//                                                         </div>
//                                                         <Skeleton width={30} height={20}
//                                                             className=" text-analytics-pink"
//                                                             baseColor="rgb(228, 250, 255)"
//                                                             highlightColor="rgb(73, 219, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                     <Skeleton width="9.8%" height={10}
//                                                         className="w-100"
//                                                         baseColor="rgb(228, 250, 255)"
//                                                         highlightColor="rgb(73, 219, 251)"
//                                                         borderRadius="0.5rem"
//                                                         duration={4}
//                                                     />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div class="d-flex align-items-start justify-content-between mt-2 row ms-0 mr-0 w-100">
//                                         <div class="col-md-4 col-sm-12 pe-md-4 pe-0 ps-0 mt-md-5 mt-4">
//                                             <h3>Devices</h3>

//                                             <div class="d-flex flex-column mt-3">
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={desktopIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Desktop</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar-large">

//                                                         <Skeleton width={"65%"} height={35}
//                                                             className=" w-100"
//                                                             baseColor="rgb(208, 233, 255)"
//                                                             highlightColor="rgb(12, 140, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={mobileIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Mobile</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar-large">

//                                                         <Skeleton width={"45%"} height={35}
//                                                             className=" w-100"
//                                                             baseColor="rgb(232, 222, 255)"
//                                                             highlightColor="rgb(127, 72, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={tabletIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Tablet</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar-large">

//                                                         <Skeleton width={"20%"} height={35}
//                                                             className=" w-100"
//                                                             baseColor="rgb(255, 226, 247)"
//                                                             highlightColor="rgb(251, 72, 201)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                             </div>

//                                         </div>
//                                         <div class="col-md-4 col-sm-12 pe-md-4 pe-0 ps-0 mt-md-5 mt-4">
//                                             <h3>Browsers</h3>

//                                             <div class="d-flex flex-column mt-3">
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={safariIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Safari</span>
//                                                     </div>
//                                                     <div class="w-100 analytics-progress-bar-large">
//                                                         <Skeleton width={"85%"} height={35}
//                                                             className=" w-100"
//                                                             baseColor="rgb(208, 233, 255)"
//                                                             highlightColor="rgb(12, 140, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={chromeIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Chrome</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar-large">
//                                                         <Skeleton width={"45%"} height={35}
//                                                             className=" w-100"
//                                                             baseColor="rgb(232, 222, 255)"
//                                                             highlightColor="rgb(127, 72, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={edgeIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Edge</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar-large">
//                                                         <Skeleton width={"20%"} height={35}
//                                                             className=" w-100"
//                                                             baseColor="rgb(255, 226, 247)"
//                                                             highlightColor="rgb(251, 72, 201)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div class="col-md-4 col-sm-12 pe-md-4 pe-0 ps-0 mt-md-5 mt-4">
//                                             <h3>Languages</h3>

//                                             <div class="d-flex flex-column mt-3">
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={analyticsFlag_uk} alt="" />
//                                                         <span class="font-weight-500 ms-2">English</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar-large">
//                                                         <Skeleton width={"45%"} height={35}
//                                                             className=" w-100"
//                                                             baseColor="rgb(208, 233, 255)"
//                                                             highlightColor="rgb(12, 140, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={analyticsFlag_de} alt="" />
//                                                         <span class="font-weight-500 ms-2">German</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar-large">
//                                                         <Skeleton width={"45%"} height={35}
//                                                             className=" w-100"
//                                                             baseColor="rgb(232, 222, 255)"
//                                                             highlightColor="rgb(127, 72, 251)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={analyticsFlag_pt} alt="" />
//                                                         <span class="font-weight-500 ms-2">Portugues</span>
//                                                     </div>
//                                                     <div class="w-100 analytics-progress-bar-large">
//                                                         <Skeleton width={"20%"} height={35}
//                                                             className=" w-100"
//                                                             baseColor="rgb(255, 226, 247)"
//                                                             highlightColor="rgb(251, 72, 201)"
//                                                             borderRadius="0.5rem"
//                                                             duration={4}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div class="mt-5 d-md-block d-none">
//                                         <h3>Last Activity</h3>
//                                         <div class="col-12 table-responsiveness">

//                                             <table class="table table-borderless table-sm analytics-table">
//                                                 <thead>
//                                                     <tr>
//                                                         <th class="text-gray font-weight-500">Location</th>
//                                                         <th class="text-gray font-weight-500">When</th>
//                                                         <th class="text-gray font-weight-500">From</th>
//                                                         <th class="text-gray font-weight-500">Device</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     <tr>
//                                                         <td>
//                                                             <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
//                                                         </td>
//                                                         <td>
//                                                             <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
//                                                         </td>
//                                                         <td>
//                                                             <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
//                                                         </td>
//                                                         <td>
//                                                             <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
//                                                         </td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>
//                                                             <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
//                                                         </td>
//                                                         <td>
//                                                             <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
//                                                         </td>
//                                                         <td>
//                                                             <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
//                                                         </td>
//                                                         <td>
//                                                             <Skeleton height={35} className="analytics-table-pill" borderRadius="0.5rem" duration={4} />
//                                                         </td>
//                                                     </tr>

//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </div>

//                                 </div>
//                                 :
//                                 <div class="section-main mt-4">
//                                     <div class="d-flex align-items-end justify-content-between analytics-header">

//                                         <div class="search-bar d-flex align-items-center jusitfy-content-start flex-wrap">

//                                             <div class="analytics-card analytics-card-blue">
//                                                 <div class="d-flex align-items-center justify-content-start">
//                                                     <h1 class="mb-1 me-2">{statsData?.unique_visitors?.count}</h1>
//                                                     <span class="analytics-badge analytics-badge-success d-flex align-items-center justify-content-center">
//                                                         {statsData?.unique_visitors?.type !== "-" && <img src={statsData?.unique_visitors?.type == "up" ? analyticsUp : analyticsDown} width="10px" alt="" />}
//                                                         <span class="ms-1">{statsData?.unique_visitors?.progress}%</span>
//                                                     </span>
//                                                 </div>
//                                                 <h5 class="mb-0 font-weight-500">Unique Visitors</h5>
//                                             </div>

//                                             <div class="analytics-card analytics-card-purple">
//                                                 <div class="d-flex align-items-center justify-content-start">
//                                                     <h1 class="mb-1 me-2">{statsData?.impressions?.count}</h1>
//                                                     <span class="analytics-badge analytics-badge-danger d-flex align-items-center justify-content-center">
//                                                         {statsData?.impressions?.type !== "-" && <img src={statsData?.impressions?.type == "up" ? analyticsUp : analyticsDown} width="10px" alt="" />}
//                                                         <span class="ms-1">16%</span>
//                                                     </span>
//                                                 </div>
//                                                 <h5 class="mb-0 font-weight-500">Impressions</h5>
//                                             </div>

//                                             <div class="analytics-card analytics-card-pink">
//                                                 <div class="d-flex align-items-center justify-content-start">
//                                                     <h1 class="mb-1 me-2">{statsData?.new_subscribers?.count}</h1>
//                                                     <span class="analytics-badge analytics-badge-success d-flex align-items-center justify-content-center">
//                                                         {statsData?.new_subscribers?.type !== "-" && <img src={statsData?.new_subscribers?.type == "up" ? analyticsUp : analyticsDown} width="10px" alt="" />}
//                                                         <span class="ms-1">16%</span>
//                                                     </span>
//                                                 </div>
//                                                 <h5 class="mb-0 font-weight-500">New Subcribers</h5>
//                                             </div>

//                                             <div class="analytics-card analytics-card-orange me-0">
//                                                 <div class="d-flex align-items-center justify-content-start">
//                                                     <h1 class="mb-1 me-2">${statsData?.revenue?.count}</h1>
//                                                     <span class="analytics-badge analytics-badge-success d-flex align-items-center justify-content-center">
//                                                         {statsData?.revenue?.type !== "-" && <img src={statsData?.revenue?.type == "up" ? analyticsUp : analyticsDown} width="10px" alt="" />}
//                                                         <span class="ms-1">16%</span>
//                                                     </span>
//                                                 </div>
//                                                 <h5 class="mb-0 font-weight-500">Revenue</h5>
//                                             </div>

//                                         </div>

//                                         <div class="d-flex align-items-center justify-content-end analytics-filter-main">
//                                             <div class="filter-dropdown dropdown-toggle noselect" id="filter-dropdown"
//                                                 data-bs-toggle="dropdown" aria-expanded="false">
//                                                 <span class="text-muted">Filter:</span>
//                                                 <span class="filter-dropdown-menu-selected ms-2 font-weight-500">All</span>
//                                                 <img src={chevron_down} width="10px" alt="" class="ms-1" />
//                                             </div>

//                                             <ul class="dropdown-menu filter-dropdown-menu analytics-filter-dropdown-menu"
//                                                 aria-labelledby="filter-dropdown" onClick={e => e.stopPropagation()}>
//                                                 <li>
//                                                     <a class="dropdown-item active" href="#" data-value="All">
//                                                         <span class="me-5 d-flex align-items-center justify-content-betwen">
//                                                             <input type="checkbox" value="unique_visitors" name="analytics-graph-filter[]" id="graph-label-1" defaultChecked={true} onClick={analyticsGraphFilterClick} />
//                                                             <label htmlFor="graph-label-1">Unique Visitors</label>
//                                                         </span>
//                                                         <small class="text-muted">458</small>
//                                                     </a>
//                                                 </li>

//                                                 <li>
//                                                     <a class="dropdown-item active" href="#" data-value="All">
//                                                         <span class="me-5 d-flex align-items-center justify-content-betwen">
//                                                             <input type="checkbox" value="impressions" name="analytics-graph-filter[]" id="graph-label-2" defaultChecked={true} onClick={analyticsGraphFilterClick} />
//                                                             <label htmlFor="graph-label-2">Impressions</label>
//                                                         </span>
//                                                         <small class="text-muted">554</small>
//                                                     </a>
//                                                 </li>

//                                                 <li>
//                                                     <a class="dropdown-item active" href="#" data-value="All">
//                                                         <span class="me-5 d-flex align-items-center justify-content-betwen">
//                                                             <input type="checkbox" value="new_subscribers" name="analytics-graph-filter[]" id="graph-label-3" defaultChecked={true} onClick={analyticsGraphFilterClick} />
//                                                             <label htmlFor="graph-label-3">New subscribers</label>
//                                                         </span>
//                                                         <small class="text-muted">54</small>
//                                                     </a>
//                                                 </li>


//                                                 <li>
//                                                     <a class="dropdown-item active" href="#" data-value="All">
//                                                         <span class="me-5 d-flex align-items-center justify-content-betwen">
//                                                             <input type="checkbox" value="revenue" name="analytics-graph-filter[]" id="graph-label-4" defaultChecked={true} onClick={analyticsGraphFilterClick} />
//                                                             <label htmlFor="graph-label-4">Revenue</label>
//                                                         </span>
//                                                         <small class="text-muted">$56</small>
//                                                     </a>
//                                                 </li>

//                                             </ul>

//                                             <div class="ms-1 date-filter-dropdown dropdown-toggle noselect"
//                                                 id="date-filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
//                                                 <span class="date-filter-dropdown-menu-selected font-weight-500">All</span>
//                                                 <img src={chevron_down} width="10px" alt="" class="ms-1" />
//                                             </div>

//                                             <ul class="dropdown-menu date-filter-dropdown-menu" aria-labelledby="date-filter-dropdown" onClick={e => e.stopPropagation()}>
//                                                 <li>
//                                                     <div id="calendar-picker"></div>
//                                                 </li>
//                                             </ul>

//                                         </div>

//                                     </div>

//                                     <div class="d-flex mt-4 analytics-main-chart-main mb-4">
//                                         <Line id="analytics-main-chart"
//                                             data={data} config={originalOptions}
//                                             width={"100%"} height={screenWidth ? 50 : 30} />
//                                     </div>
//                                     <div class="d-flex align-items-start justify-content-between mt-2 row ms-0 mr-0 w-100">
//                                         <div class="col-md-6 col-sm-12 pe-md-4 pe-0 ps-0 mt-4">
//                                             <h3>Top Sources</h3>
//                                             <div class="d-flex flex-column mt-2 pe-md-3 pe-0">




//                                             {topSources?.length > 0  && topSources?.map((val, index) => {
//                                                     
// console.log("value", val)
//                                                     return (
//                                                         <div class="d-flex flex-column analytics-progress" key={index}>
//                                                             <div class="d-flex align-items-center justify-content-between">
//                                                                 <div class="d-flex align-items-center py-2">
//                                                                     <img src={val?.icon} width="22px" alt="" />
//                                                                     <h6 class="font-weight-500 mb-0 ms-2 pt-1">{val?.source}</h6>
//                                                                 </div>
//                                                                 <span class="font-weight-500 text-analytics-blue">{val?.count}</span>
//                                                             </div>
//                                                             <div class="w-100 analytics-progress-bar">
//                                                                 <div style={{ width: val?.percent }}></div>
//                                                             </div>
//                                                         </div>
//                                                     )
//                                                 })}
//                                             </div>
//                                         </div>
//                                         <div class="col-md-6 col-sm-12 mt-4 ps-0 pe-0">
//                                             <h3>Top Locations</h3>
//                                             <div class="d-flex flex-column mt-2 pe-md-3 pe-0">

//                                                 <div class="d-flex flex-column analytics-progress">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsFlag_uk} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">United Kingdom</h6>
//                                                         </div>
//                                                         <span class="font-weight-500 text-analytics-blue">65,376</span>
//                                                     </div>
//                                                     <div class="w-100 analytics-progress-bar">
//                                                         <div style={{ width: "65%" }}></div>
//                                                     </div>
//                                                 </div>

//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsFlag_fr} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">France</h6>
//                                                         </div>
//                                                         <span class="font-weight-500 text-analytics-purple">45,376</span>
//                                                     </div>
//                                                     <div class="w-100 analytics-progress-bar">
//                                                         <div style={{ width: "45%" }}></div>
//                                                     </div>
//                                                 </div>

//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsFlag_es} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">Spain</h6>
//                                                         </div>
//                                                         <span class="font-weight-500 text-analytics-pink">23,376</span>
//                                                     </div>
//                                                     <div class="w-100 analytics-progress-bar">
//                                                         <div style={{ width: "23%" }}></div>
//                                                     </div>
//                                                 </div>

//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsFlag_in} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">India</h6>
//                                                         </div>
//                                                         <span class="font-weight-500 text-analytics-orange">15,264</span>
//                                                     </div>
//                                                     <div class="w-100 analytics-progress-bar">
//                                                         <div style={{ width: "15%" }}></div>
//                                                     </div>
//                                                 </div>


//                                                 <div class="d-flex flex-column analytics-progress mt-2">
//                                                     <div class="d-flex align-items-center justify-content-between">
//                                                         <div class="d-flex align-items-center py-2">
//                                                             <img src={analyticsFlag_us} width="22px" alt="" />
//                                                             <h6 class="font-weight-500 mb-0 ms-2 pt-1">United States</h6>
//                                                         </div>
//                                                         <span class="font-weight-500 text-analytics-lightblye">9864</span>
//                                                     </div>
//                                                     <div class="w-100 analytics-progress-bar">
//                                                         <div style={{ width: "9.8%" }}></div>
//                                                     </div>
//                                                 </div>
//                                             </div>

//                                         </div>
//                                     </div>
//                                     <div class="d-flex align-items-start justify-content-between mt-2 row ms-0 mr-0 w-100">
//                                         <div class="col-md-4 col-sm-12 pe-md-4 pe-0 ps-0 mt-md-5 mt-4">
//                                             <h3>Devices</h3>

//                                             <div class="d-flex flex-column mt-3">
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={desktopIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Desktop</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
//                                                         <div style={{ width: "65%" }}></div>
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={mobileIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Mobile</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
//                                                         <div style={{ width: "45%" }}></div>
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={tabletIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Tablet</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
//                                                         <div style={{ width: "20%" }}></div>
//                                                     </div>
//                                                 </div>
//                                             </div>

//                                         </div>

//                                         <div class="col-md-4 col-sm-12 pe-md-4 pe-0 ps-0 mt-md-5 mt-4">
//                                             <h3>Browsers</h3>

//                                             <div class="d-flex flex-column mt-3">
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={safariIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Safari</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
//                                                         <div style={{ width: "85%" }}></div>
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={chromeIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Chrome</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
//                                                         <div style={{ width: "45%" }}></div>
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={edgeIcon} alt="" />
//                                                         <span class="font-weight-500 ms-2">Edge</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
//                                                         <div style={{ width: "20%" }}></div>
//                                                     </div>
//                                                 </div>
//                                             </div>

//                                         </div>

//                                         <div class="col-md-4 col-sm-12 pe-md-4 pe-0 ps-0 mt-md-5 mt-4">
//                                             <h3>Languages</h3>

//                                             <div class="d-flex flex-column mt-3">
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={analyticsFlag_uk} alt="" />
//                                                         <span class="font-weight-500 ms-2">English</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
//                                                         <div style={{ width: "65%" }}></div>
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={analyticsFlag_de} alt="" />
//                                                         <span class="font-weight-500 ms-2">German</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
//                                                         <div style={{ width: "45%" }}></div>
//                                                     </div>
//                                                 </div>
//                                                 <div class="d-flex align-items-center justify-content-start analytics-stats mt-2">
//                                                     <div class="d-flex align-items-center zaap-border-rounded bg-f3f3f3 me-2 px-3 py-2">
//                                                         <img src={analyticsFlag_pt} alt="" />
//                                                         <span class="font-weight-500 ms-2">Portugues</span>
//                                                     </div>

//                                                     <div class="w-100 analytics-progress-bar analytics-progress-bar-large">
//                                                         <div style={{ width: "20%" }}></div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div class="mt-5 d-md-block d-none">
//                                         <h3>Last Activity</h3>
//                                         <div class="col-12 table-responsiveness">

//                                             <table class="table table-borderless table-sm analytics-table">
//                                                 <thead>
//                                                     <tr>
//                                                         <th class="text-gray font-weight-500">Location</th>
//                                                         <th class="text-gray font-weight-500">When</th>
//                                                         <th class="text-gray font-weight-500">From</th>
//                                                         <th class="text-gray font-weight-500">Device</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     <tr>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <img src={analyticsFlag_uk} alt="" />
//                                                                 <span class="text-gray">United Kingdom</span>
//                                                             </span>
//                                                         </td>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <span class="text-gray">11 Hours Ago</span>
//                                                             </span>
//                                                         </td>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <img src={analyticsInsta} alt="" />
//                                                                 <span class="text-gray">https://instagram.com/exampleexam</span>
//                                                             </span>
//                                                         </td>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <img src={mobileIcon} alt="" />
//                                                                 <span class="text-gray">Mobile</span>
//                                                             </span>
//                                                         </td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <img src={analyticsFlag_uk} alt="" />
//                                                                 <span class="text-gray">United Kingdom</span>
//                                                             </span>
//                                                         </td>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <span class="text-gray">11 Hours Ago</span>
//                                                             </span>
//                                                         </td>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <img src={analyticsInsta} alt="" />
//                                                                 <span class="text-gray">https://instagram.com/exampleexam</span>
//                                                             </span>
//                                                         </td>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <img src={mobileIcon} alt="" />
//                                                                 <span class="text-gray">Mobile</span>
//                                                             </span>
//                                                         </td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <img src={analyticsFlag_uk} alt="" />
//                                                                 <span class="text-gray">United Kingdom</span>
//                                                             </span>
//                                                         </td>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <span class="text-gray">11 Hours Ago</span>
//                                                             </span>
//                                                         </td>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <img src={analyticsInsta} alt="" />
//                                                                 <span class="text-gray">https://instagram.com/exampleexam</span>
//                                                             </span>
//                                                         </td>
//                                                         <td>
//                                                             <span class="analytics-table-pill">
//                                                                 <img src={mobileIcon} alt="" />
//                                                                 <span class="text-gray">Mobile</span>
//                                                             </span>
//                                                         </td>
//                                                     </tr>
//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </div>

//                                 </div>
//                         }


//                     </section>

//                 </div>
//             </div >
//         </>
//     )
// }
// export default Analytics
