import { Modal } from "react-bootstrap"
import { apiUrl, pageUrl, protocol } from "../../../../utils/url"
import { hideLoader, showLoader } from "../../../loader"
import embed_svg from "../../../../assets/img/embed-block-icon2.svg"
import embed_block_banner from "../../../../assets/img/embed-block-banner.svg"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"

const ConfirmationLinkModal = ({ confirmationLinkModal, setShow, setIframeURL, callGetProject }) => {

    const dispatch = useDispatch()
    const hideModal = () => {
        setShow({ show: false, embed_type: null, url: null })
    }
    const sendRequest = (values) => {
        
// console.log("values", values)
        showLoader()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData()
        let formValues = { ...values }
        delete formValues?.show;
        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                formdata.append(key, formValues[key]);
            }
        }
        var setOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/project/block/create", setOptions).then(async (res) => {
            const response = await res?.json();
            if (response?.status_code == 200) {
                toast.success(response?.status_message);
                setIframeURL(protocol + '' + pageUrl + '/' + localStorage.slug + '?iframe=1');
                const temp_obj={
                    show: true,
                    id: response?.data?.id,
                    link_title: response?.data?.name,
                    destination_url: JSON?.parse(response?.data?.details?.meta)?.destination_url,
                    link_description: JSON?.parse(response?.data?.details?.meta)?.link_description,
                    open_in_same_tab: JSON?.parse(response?.data?.details?.meta)?.open_in_same_tab,
                    button_text: JSON?.parse(response?.data?.details?.meta)?.button_text,
                    image_url: JSON?.parse(response?.data?.details?.meta)?.link_image,
                    link_image: JSON?.parse(response?.data?.details?.meta)?.link_image
                }
                if (formValues?.block == "link")
                    dispatch({
                        type: "SET_EDIT_LINK_MODAL", payload: temp_obj})
                    
// console.log("temp_obj", temp_obj)
                callGetProject()
                hideLoader();
                hideModal()
            }
            else {
                toast.error(response?.status_message);
                // setError(Object.values(response?.errors)) 
                hideLoader()
            }
        }).catch((err) => hideLoader())
    }

    // const 


    return (
        <Modal show={confirmationLinkModal?.show} onHide={hideModal}>
            <Modal.Body style={{padding: "0px"}}>
                <div style={{
                    borderTopRightRadius: '1.2rem',
                    borderTopLeftRadius: '1.2rem',
                    overflow: 'hidden',
                }}>
                    <img src={embed_block_banner} width="100%" />
                </div>
                <div style={{padding: "20px",marginTop: "-40px"}}>
                    <div class="form-group">
                        <img src={embed_svg} width="30px" style={{boxShadow: "0px 4px 20px rgba(103, 101, 122, 0.19)",borderRadius:"10px"}} alt="" />
                        <h4 class="mt-3 mb-1">Would you like to embed this<br />intead?</h4>
                        <p class="text-gray font-weight-500">Show this content directly on your page by <br />embedding it so users never have to leave.</p>
                    </div>
                    <div class="text-center mt-4 d-flex">
                        <button autoFocus type="button" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center" onClick={() => sendRequest(confirmationLinkModal)}>
                            <b class="me-2">Yes, embed it</b>
                        </button>
                        <button type="button" class="close btn btn-grey btn-semi-rounded d-flex align-items-center justify-content-center ms-2" onClick={() => sendRequest({ ...confirmationLinkModal, block: "link" })}>
                            <b class="me-2">No, keep as link</b>
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ConfirmationLinkModal