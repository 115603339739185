import React from "react";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import productType from "../../assets/img/product-type-icon.svg";
import imageIcon from "../../assets/img/image-icon.svg";
import pricingIcon from "../../assets/img/pricing.svg";
import tickIconPrimary from "../../assets/img/white-tick-icon.svg";
import descriptionIcon from "../../assets/img/description.svg";
import infoIcon from "../../assets/img/info.svg";
import customTheme from "../../assets/img/custom-theme.png";
import filesIcon from "../../assets/img/files.svg";
import returnIcon from "../../assets/img/modals/return.svg";
import trashIcon from "../../assets/img/trash.svg";
import * as $ from "jquery";
import { Link, useHistory } from "react-router-dom";
import { Alert, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import CroppieModal from "../croppie-modal";
import { hideLoader, showLoader } from "../loader";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../utils/url";
import ErrorMessage from "../errors/errorMessage";
import ModalErrors from "../errors/modal-errors";
import FormFieldError from "../errors/formFieldError";
import ProductStep2 from "./productStep2";
const ProductStep1 = ({showProductStep1, setShowProductStep1, nextStep, closeProductType, title, setTitle ,error, setError ,short_description, setShort_Description ,price_type, setPriceType ,price, setPrice ,min_price, setMin_Price ,suggested_price, setSugested_Price ,currency, setCurrency ,errorMessage, setErrorMessage ,product_slug, setProductSlug}) => {
    const history  = useHistory();
    const { basePath, slug } = useSelector((state) => state?.slugReducer);
    const handlePriceTypeChange = (a) => {
        setPriceType(a.target.value)
        if (a.target.value == 'free') {
            setPrice(0);
            setMin_Price(0);
            setSugested_Price(0);
        }else if(a.target.value == 'single_payment'){
            setMin_Price(0);
            setSugested_Price(0);
        }
    };
    const onClose = () => {
        closeProductType();
        setShowProductStep1(false)
        history.push(basePath + "/products")
    };
    const handlePriceChange = (e) => {
        const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');
      
        const singleDecimalValue = sanitizedValue.replace(/(\..*?)\..*/g, '$1');
      if(e.target.name === "price"){
        setPrice(singleDecimalValue);
        setMin_Price(0);
        setSugested_Price(0);
      }
      else if(e.target.name === "min_price"){
      setMin_Price(singleDecimalValue);
      setPrice(0);
      }
      else if(e.target.name === "suggested_price"){
        setSugested_Price(singleDecimalValue);
        setPrice(0);
      }
      };

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
        let slug = e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
        slug = slug.replaceAll(' ', '-');
        setProductSlug(slug)
      }
    return (
        <Modal centered id="newProductModal" show={showProductStep1} onHide={onClose}>
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <form name="product_type" method="post" role="form">
                            <div className="notification-container"></div>
                            <div className="form-group">
                                <img src={productType} width="40px" alt=""  />
                                <h4 className="productHead1 mt-10px">Let’s build your product</h4>
                            </div>
                            <div>
                            <ErrorMessage error={error} />
                                <div className="form-group mt-20px">
                                    <label className="productlabels">
                                        Product Title
                                        <small className="text-red">*</small>
                                    </label>
                                    <input
                                        value={title}
                                        onChange={(e) => handleTitleChange(e)}
                                        type="text"
                                        className="zaapGlobal-input"
                                        name="title"
                                        placeholder="My Awesome Product"
                                    />
                                    <FormFieldError error={error?.title} />
                                </div>
                                <div className="form-group mt-20px">
                                    <label className="productlabels">
                                        Product Description
                                        <small className="text-red">*</small>
                                    </label>
                                    <input
                                        value={short_description}
                                        onChange={(a) =>
                                            setShort_Description(a.target.value)
                                        }
                                        type="text"
                                        className="zaapGlobal-input"
                                        name="short_description"
                                        placeholder="Got my new awesome course!"
                                    />
                                </div>
                            </div>
                            <div
                        id="productPricing"
                        className="form-group"
                        aria-labelledby="headingOne"
                    >
                        <div className="form-group w-100 p-0 mt-3">
                            <label className="productlabels">
                                Pricing
                            </label>
                            <div className="up-in-toggle bg-white price_type_check mt-2 p-0">
                                <input
                                    checked={price_type == 'free' ? true : false}
                                    type="radio"
                                    id="switch_left"
                                    name="pricingType"
                                    value="free"
                                    onChange={(a) => { handlePriceTypeChange(a) }}
                                />
                                <label
                                    htmlFor="switch_left"
                                    className="d-flex align-items-start flex-column"
                                >
                                    <span className="w-100 d-flex align-items-center justify-content-between">
                                        <h5 className="m-0 font-weight-500 pricing-text productlabel2">
                                            Free
                                        </h5>
                                        <img
                                            className="mb--u"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                        />
                                    </span>
                                    <h6 className="productlabel3">
                                        Allow access for free.
                                    </h6>
                                </label>
                                <input
                                    checked={price_type == 'single_payment' ? true : false}
                                    type="radio"
                                    id="switch_right"
                                    name="pricingType"
                                    value="single_payment"
                                    onChange={(a) => { handlePriceTypeChange(a) }}
                                />
                                <label
                                    htmlFor="switch_right"
                                    className="d-flex align-items-start flex-column"
                                >
                                    <span className="w-100 d-flex align-items-center justify-content-between">
                                        <h5
                                            className="m-0 font-weight-500 pricing-text productlabel2"
                                        >
                                            Single Payment
                                        </h5>
                                        <img
                                            className="mb--u"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                        />
                                    </span>
                                    <h6  className="productlabel3">
                                        Charge a one-time fee.
                                    </h6>
                                </label>
                            </div>
                        </div>
                        <div className="form-group w-100 p-0 mt-3">
                            <div className="up-in-toggle bg-white price_type_check p-0 pay-class">
                                <input checked={price_type == 'Pay_what_you_want' ? true : false}
                                    type="radio"
                                    id="payment"
                                    name="pricingType"
                                    value="Pay_what_you_want"
                                    onChange={(a) => { handlePriceTypeChange(a) }}
                                />
                                <label
                                    htmlFor="payment"
                                    className="d-flex align-items-start flex-column"
                                >
                                    <span className="w-100 d-flex align-items-center justify-content-between">
                                        <h5
                                            className="m-0 font-weight-500 pricing-tex productlabel2"
                                        >
                                            Pay what you want
                                        </h5>
                                        <img
                                            className="mb--u"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                        />
                                    </span>
                                    <h6  className="productlabel3">
                                        Let customers choose
                                    </h6>
                                </label>
                                <input
                                    type="radio"
                                    id="membership"
                                    name="pricingType"
                                    value="menbership"
                                    disabled
                                />
                                <label
                                    htmlFor="membership"
                                    className="d-flex align-items-start flex-column membershipstyle"
                                >
                                    <span className="w-100 d-flex align-items-center justify-content-between ">
                                        <h5
                                            className="m-0 font-weight-500 pricing-text productlabel2"
                                            style={{
                                                color: "#b1b1b1",
                                            }}
                                        >
                                            Membership
                                        </h5>
                                        <img
                                            className="mb--u"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                        />
                                    </span>
                                    <h6
                                        className="productlabel3"
                                        style={{
                                            color: "#cfcfcf",
                                        }}
                                    >
                                        COMING SOON
                                    </h6>
                                </label>
                            </div>
                        </div>
                    </div>
                    {price_type == "single_payment" && (
                        <div className="form-group mt-3">
                            <label className="productlabels">
                                Price
                                <small className="text-red">*</small>
                            </label>
                            <div>
                                <div className="d-flex w-100 align-items-center gap-1 zaapGlobal-input">
                                    <span>
                                        {currency == "eur" ? '€' : currency == "gbp" ? '£' : '$'}
                                    </span>
                                    <div className="w-100">
                                        <input value={price} onChange={handlePriceChange} className="" type="text" name="price" placeholder="0" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {price_type == "Pay_what_you_want" && (
                        <div>
                            <div className="d-flex gap-3">
                                <div className="w-100">
                                    <div className="form-group mt-3">
                                        <label className="productlabels">
                                                Minimum Price
                                                <small className="text-red">
                                                    *
                                                </small>
                                        </label>
                                        <div className="d-flex w-100 align-items-center gap-1 zaapGlobal-input">
                                            <span>
                                                {currency == "eur" ? '€' : currency == "gbp" ? '£' : '$'}
                                            </span>
                                            <div className="w-100">
                                                <input value={min_price} onChange={handlePriceChange} className="" type="text" name="min_price" placeholder="0" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div className="form-group mt-3">
                                        <label className="productlabels">
                                                Suggested Price 
                                                <small className="text-red">
                                                    *
                                                </small>
                                        </label>
                                        <div className="d-flex w-100 align-items-center gap-1 zaapGlobal-input">
                                            <span>
                                                {currency == "eur" ? '€' : currency == "gbp" ? '£' : '$'}
                                            </span>
                                            <div className="w-100">
                                                <input value={suggested_price} onChange={handlePriceChange} className="" type="text" name="suggested_price" placeholder="0" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(price_type == "Pay_what_you_want") || (price_type == "single_payment") ?
                        <div className="currency-dropdown mt-4">
                            <div className="d-flex flex-column">
                                <span>Currency</span>
                                <small className="text-gray">This can't be edited later</small>
                            </div>
                            <div className="currency-select-box">
                                <select name="currency" id="currency" disabled={localStorage?.edit} value={currency} onChange={(a) => setCurrency(a.target.value)}>
                                    {localStorage?.edit ?
                                        <option value={currency}>{currency.toUpperCase()}</option> : <>
                                            <option value="usd">$USD</option>
                                            <option value="eur">€EUR</option>
                                            <option value="gbp">£GBP</option>
                                        </>
                                    }
                                </select>
                            </div>
                        </div> : ''
                    }

                        </form>
                    </div>
                        <div class="modal-foot-u">
                            <div class="text-center d-flex submit-buttons-fixed">
                                <button onClick={nextStep} type="button" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center save-btn-u">
                                    <b class="me-2">Next Step</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 mt-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </div>
                </div>
            </div>
        </Modal>
    );
};
export default ProductStep1;
