
import { Modal } from "react-bootstrap"
import ModalErrors from "../../../errors/modal-errors"
import textIcon from "../../../../assets/img/modals/text-icon.svg";
import leftAlignIcon from "../../../../assets/img/modals/left-align-icon.svg";
import centerAlignIcon from "../../../../assets/img/modals/center-align-icon.svg";
import { useEffect, useState } from "react"

const CreateTextBlockModal =
    ({ show, handleHide, returnIcon, handleSubmit, slug, error, settextBlockModal, textBlockModal }) => {

        const [value, setValue] = useState({
            alignment: "left",
            text: null,
        });
        const handleChange = (e) => {
            setValue({ ...value, [e.target.name]: e.target.value })
        };
        useEffect(() => {
            setValue({ ...value, text: textBlockModal?.details?.text, alignment: (textBlockModal?.details?.alignment)??"left" , })}, [textBlockModal])


        const heading = textBlockModal ? "Update text block" : "Add text block";
            
// console.log(heading)
        return (

            <Modal centered show={show} onHide={!textBlockModal ? handleHide : () => {
                settextBlockModal(!textBlockModal)
            }} id="createTextBlockModal">
                <div class="modal-dialog-centered" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e, value, textBlockModal?.show ? textBlockModal?.id : "text_block", true,)
                            }}>
                                <div class="notification-container"></div>


                                <div class="form-group">
                                    <img src={textIcon} width="30px" alt="" />
                                    <h4 class="mt-2 mb-1">{heading}</h4>
                                    <p class="text-gray font-weight-500">Organise your related links into folders.</p>
                                </div>

                                <div class="form-group">
                                    <label><small>Text alignment</small></label>
                                    <div class="up-in-toggle bg-f1f1f1 w-25 btn-semi-rounded">
                                        <input type="radio" id="text_align_left" name="alignment" checked={value?.alignment == "left" } value={"left"} onChange={handleChange} />
                                        <label htmlFor="text_align_left" class="btn-semi-rounded">
                                            <img src={leftAlignIcon} alt="" />
                                        </label>
                                        <input type="radio" id="text_align_center" checked={value?.alignment == "center"} name="alignment" value={"center"} onChange={handleChange} />
                                        <label htmlFor="text_align_center" class="btn-semi-rounded">
                                            <img src={centerAlignIcon} alt="" />
                                        </label>
                                    </div>

                                </div>

                                <div class="form-group mt-2">
                                    <label><small>Text</small></label>
                                    <textarea rows="5" class={`p-3 resize-none form-control zaap-form-control`} name="text" value={value?.text} onChange={handleChange} required="required" placeholder="Add Text..."></textarea>
                                </div>

                                <div class="text-center mt-4 d-flex">
                                    <button type="button" onClick={!textBlockModal ? handleHide : () => {
                                        settextBlockModal(!textBlockModal)
                                    }} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                        <b class="me-2">Cancel</b>
                                        <small class="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                        <b class="me-2">Save Changes</b>
                                        <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal>
        )
    }

export default CreateTextBlockModal