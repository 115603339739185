
import { Modal } from "react-bootstrap"
import ModalErrors from "../../../errors/modal-errors"
import productIcon from "../../../../assets/img/modals/product-icon.svg"
import { useEffect, useState } from "react"
import { apiUrl } from "../../../../utils/url"
import { hideLoader, showLoader } from "../../../loader"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const mainPath = "products";
const subPath = "createProducts";
const CreateProductWidgetModal =
    ({ show, handleHide, returnIcon, handleSubmit, slug, error, setproductModal, productModal }) => {

        const history = useHistory()
        const location = useLocation()
        const { parent_block_id } = location?.state ?? {};
        const [productsList, setProductsList] = useState([]);
        const [index, setIndex] = useState(1);
        const [value, setValue] = useState({
            name: null,
            product: null

        });
        const { basePath } = useSelector(state => state?.slugReducer);

        useEffect(() => {
            setValue({ ...value, name: productModal?.name, product: productModal?.details })
        }, [productModal])

        useEffect(() => {
            if (productModal?.parent_id || parent_block_id) {
                setValue({ ...value, parent_id:productModal?.parent_id ?? parent_block_id  })
            }
        }, [productModal?.parent_id,parent_block_id])
        const selectBtnClick = (e) => {
            let selectDropdown = document.getElementById("selectDropdown");
            selectDropdown.classList.toggle('toggle');
            // next.style.zIndex = setIndex(index+1);
        }

        const selectOptionClick = (b) => {
            b.target.parentElement.classList.remove('toggle');

            let parent = b.target.closest('.select').children[0];
            parent.setAttribute('data-type', b.target.getAttribute('data-type'));
            parent.innerText = b.target.innerText;

            setValue({ ...value, product: b.target.getAttribute('data-type') });
        }

        useEffect(() => {

            if (slug) {
                showLoader();

                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders
                };
                fetch(apiUrl + `/ProductFlow/list/${slug}`, requestOptions)
                    .then(async (res) => {
                        const response = await res?.json();

                        if (response?.status_code == 200) {
                            setProductsList(response);
                            // let productListTmp = response?.data;
                            
// console.log(productsList);
                            // productListTmp.map((val, index)=>{
                            //     
// console.log(val);
                            // });
                            hideLoader();
                        }

                    }).catch((err) => {
                        hideLoader();
                    })
            }
        }, []);

        const showProductModal = () => history.push(`${basePath}/products`)
        

        return (
            <Modal centered show={show} onHide={!productModal?.show ? handleHide : () => {
                setproductModal({
                    ...productModal,
                    show: !productModal?.show,
                })
            }} id="createProductWidgetModal">
                <div class="modal-dialog-centered" role="document">
                    <div class="modal-content overflow-none">

                        <div class="modal-body">

                            <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e, value,productModal?.from == "folder_block" ? "product" : (productModal?.show ? productModal?.id : "product"), productModal?.from == "folder_block" ? false : true,)
                            }}>
                                <div class="notification-container"></div>

                                <div class="form-group">
                                    <img src={productIcon} width="30px" alt="" />
                                    <h4 class="mt-2 mb-1">Add a product to your page</h4>
                                    <p class="text-gray font-weight-500">Start selling by adding a product to your page.</p>
                                </div>

                                <div class="form-group">
                                    <div class="product-custom-input">
                                        <div class="select">
                                            <div class="product-on-page-toggle product-head-u" onClick={selectBtnClick} data-type={(value?.product) ?? ''}>{(value?.name) ?? 'Select product'}</div>
                                            <div class="selectDropdown" id="selectDropdown">
                                                {productsList?.data?.length > 0 && productsList?.data?.map((val, index) => <div key={index} onClick={selectOptionClick} class="product-widget-option option" data-type={val.id}>{val.name.length > 30 ? `${val.name.slice(0, 20)}...` : val.name}</div>)}
                                            </div>
                                        </div>
                                        <a class="btn btn-white btn-sm btn-semi-rounded btn-light-shadow px-3 py-2" onClick={showProductModal}><b>Create Product</b></a>
                                    </div>
                                </div>

                                <div class="text-center mt-4 d-flex">
                                    <button type="button" onClick={!productModal?.show ? handleHide : () => {
                                        setproductModal({
                                            ...productModal,
                                            show: !productModal?.show,
                                        })
                                    }} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                        <b class="me-2">Cancel</b>
                                        <small class="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                        <b class="me-2">Save Changes</b>
                                        <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal>
        )
    }
export default CreateProductWidgetModal