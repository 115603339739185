import { Button, Modal } from "react-bootstrap"

const ConfirmationModal = ({confirmationModalShow, setShow, setisConfirmMessage, callback}) => {
    const confirmed = (e) => {
        e.preventDefault();
        callback(confirmationModalShow?.id)
        
    }
    
    const handleClose = () => {
        
        setShow({ show: false, text: null, id: null})
        
    }
    
    return(
        <Modal show={confirmationModalShow?.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>{confirmationModalShow?.text}</Modal.Body>
            <form onSubmit={confirmed}>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" type="submit" autoFocus>
              Yes
            </Button>
          </Modal.Footer>
            </form>
        </Modal>

    )
}
export default ConfirmationModal