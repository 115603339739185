import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import blockIcon from "../../../../../assets/img/block-icon.svg";
import returnIcon from "../../../../../assets/img/modals/return.svg";
import { ChevronRight } from '../../../../../utils/svg';
import addFaqIcon from "../../../../../assets/img/products/add-faq-btn.svg";
import dotsIcon from "../../../../../assets/img/products/faq-dots.svg";
import removeicon from "../../../../../assets/img/products/removeicon.svg";
import ErrorMessage from '../../../../errors/errorMessage';

function FaqsPage({ data, showSectionChildModal, setShowSectionChildModal, error, setError, close, handleSubmit, handleUpdate }) {
    const [FAQ_heading, setFAQ_heading] = useState("");
    const [faqs, setFaqs] = useState([
        {
          question: "",
          answer: "",
        },
      ]);


  function handleadd() {
    var newdata = [...faqs];
    newdata.push({ question: "", answer: "" });
    setFaqs(newdata);
  }

  function handleDelete(i) {
    const newarr = [...faqs];
    newarr.splice(i, 1);
    setFaqs(newarr);
  }

  const handleQuestionFaq = (a, i) => {
    const question = a.target.value;
    const tempFaq = [...faqs];
    const currentObj = { ...tempFaq[i] };
    currentObj.question = question;
    tempFaq[i] = currentObj;
    setFaqs([...tempFaq]);
  };

  const handleAnswerFaq = (a, i) => {
    const answer = a.target.value
    let tempFaq = [...faqs];
    const currentObj = { ...tempFaq[i] };
    currentObj.answer = answer;
    tempFaq[i] = currentObj;
    setFaqs([...tempFaq]);
  };
  const handleSave = () => {
    const validFaqs = faqs.filter((faq) => faq?.question && faq?.answer);
    const formattedData = {
      faq_heading: FAQ_heading,
      faq_data: validFaqs.map((faq) => ({
        question: faq.question,
        answer: faq.answer,
      })),
    };

    handleSubmit(formattedData,'faq');
  };
  const handleUpdateData = (e) => {
    const validFaqs = faqs.filter((faq) => faq?.question && faq?.answer);
    const formattedData = {
      faq_heading: FAQ_heading,
      faq_data: validFaqs.map((faq) => ({
        question: faq.question,
        answer: faq.answer,
      })),
    };
    handleUpdate(formattedData,'faq',e.target.dataset?.value);
  };
  useEffect(() => {
    if (data?.meta) {
        const metaObject = JSON.parse(data.meta);
        const { faq_heading, faq_data } = metaObject;
        setFAQ_heading(faq_heading);
        setFaqs(faq_data);
    }
  },[data?.meta])
    return (
        <Modal centered show={showSectionChildModal} onHide={close}>
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <form name="product_type" method="post" role="form">
                            <div className="notification-container"></div>
                            <div className="form-group">
                                <img src={blockIcon} width="40px" alt="" />
                                <h4 className="productHead1 mt-15px">Edit FAQ Section</h4>
                            </div>
                            <ErrorMessage error={error} />
                       
                            <div className="form-group mt-20px">
                                    <label className="productlabels">
                                        FAQ Section Title
                                    </label>
                                    <input onChange={(a) => setFAQ_heading(a.target.value)} type="text" className="zaapGlobal-input mt-10px text-dark " name="short_description" value={FAQ_heading} placeholder="Frequently Asked Question"/>
                            </div>

                            {faqs?.map((e, i) => {return (<div key={i}>
                                <div className="form-group mt-15px">
                                    <div className='qnaContainer'>
                                        <div className='w-100'>
                                            <div style={{width:'100%'}}><div style={{display: "flex",alignItems: "center",}}>
                                                <div className="qna-box">Q</div>
                                                <input className="zaapGlobal-input text-dark" value={e.question} onChange={(a) => {handleQuestionFaq(a, i);}} placeholder="Add question here..." style={{padding: 7,marginLeft: 5,}}/>
                                                </div>
                                            </div>
                                            <div style={{display: "flex",alignItems: "center",marginTop: 4,}}>
                                                <div className="qna-box">A</div>
                                                <input className="zaapGlobal-input text-dark" value={e.answer} onChange={(a) => {handleAnswerFaq(a, i);}} placeholder="Add answer here..." style={{padding: 7,marginLeft: 5}}/>
                                            </div>
                                        </div>
                                        {faqs.length > 1 ? 
                                        <div>
                                            <img src={removeicon} width={"13px"} style={{marginRight:'10px'}} onClick={(a) => handleDelete(i)}/>
                                        </div>
                                         : <></>}
                                        </div>
                                    </div>
                                </div>
                            );})}


                            <div className="mt-15px">
                                <button type="button" className="newGraybtn" onClick={handleadd} width="100%">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z" fill="#333333" />
                                    </svg>
                                    <span className="me-2">Add FAQ</span>
                                </button>
                            </div>
                            <div class="text-center d-flex submit-buttons-fixed mt-20px">
                            <button type="button" onClick={close} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center save-btn-u">
                                <span class="me-2 font-weight-500">Cancel</span>
                                <small class="badge bg-light text-dark p-1">Esc</small>
                            </button>
                            <button type="button" data-value={data?.id} onClick={data?.id ? handleUpdateData : handleSave } class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center save-btn-u">
                                <span data-value={data?.id} class="me-2 font-weight-500">Save Changes</span>
                                <small data-value={data?.id} class="badge bg-primary-dark text-dark px-2 py-1 mt-1 d-flex align-items-center justify-content-center">
                                    <img src={returnIcon} alt="" />
                                </small>
                            </button>
                        </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default FaqsPage