import { useState } from "react"
import { Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import ErrorMessage from "../components/errors/errorMessage"
import { hideLoader, showLoader } from "../components/loader"
import PasswordValidation from "../components/password-validation"
import returnIcon from "../assets/img/modals/return.svg"

import {
    isContainNumber,
    isLowerCase,
    isUpperCase,
    isSpecialChar,
} from "../utils/helper";
import { apiUrl } from "../utils/url"
import { useAlert } from "../contexts/alertContext"

const Accounts = ({ accountsModalOpen }) => {
    const dispatch = useDispatch()
    const [error, setError] = useState([])
    const [value, setValue] = useState({ name: localStorage.user, email: localStorage.email });
    const { setAlert } = useAlert();


    const handleChange = (e) => setValue({ ...value, [e.target.name]: e.target.value })
    const closeAccountsModal = (e) => dispatch({ type: "RESET_ACCOUNTS_MODAL" });

    const updateSettings = (e) => {
        e.preventDefault();
        showLoader();
        const api = apiUrl + "/account-settings";
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData();

        formdata.append("name", value?.name)
        formdata.append("email", value?.email)

        const setOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata
        }

        fetch(api, setOptions)
            .then(async (res) => {
                const response = await res?.json();
                
// console.log("response", response)
                if (response?.status_code == 200) {
                    localStorage.setItem('email',value?.email);
                    localStorage.setItem('user',value?.name);
                    setAlert({ type: 'success', title: response.status_message });
                    // toast.success(response.status_message)
                    hideLoader();
                }
                else {
                    const errors = Object.values(response.errors);
                    
// console.log("errors", errors)
                    setError(errors);
                    setAlert({ type: 'error', title: response.status_message });
                    // toast.error(response?.status_message)
                    hideLoader()
                }
            }).catch((errors) => {
                hideLoader()

            })

    }



    return (
        // <Modal centered show={accountsModalOpen} onHide={closeAccountsModal} id="createFolderModal" size="lg">
        //     <div className="modal-dialog-centered" role="document">
        //         <div className="modal-content modal-semi-curve">
        //             <div className="modal-body">
                        <section class="container h-100 account-form" style={{overflowY:"auto"}}>
                            <div className="container settings-section" style={{paddingLeft: "30px",paddingRight: "30px",paddingBottom:"30px"}}>
                            <form action="" method="post" onSubmit={updateSettings}>
                                <ErrorMessage error={error} />
                                {/* <div className="container mb-5"> */}
                                    <div class="">
                                        <b class="acount-header settings-section-heading">Profile</b>
                                        <div class="form-group mt-3">
                                            <label htmlFor="name">Account Name</label>
                                            <input type="text" id="name" name="name" class="form-control" onChange={handleChange} value={value?.name} />
                                        </div>

                                        <div class="form-group mt-2">
                                            <label htmlFor="email">Email Address</label>
                                            <input type="text" id="email" name="email" class="form-control" onChange={handleChange} value={value?.email} />
                                        </div>

                                        <button type="submit" name="submit" class="settings-button-primary btn-primary " style={{marginTop:"20px"}}>
                                            Update           
                                            <small><img src={returnIcon} alt="" /></small>

                                        </button>
                                    </div>
                                {/* </div> */}
                            </form>
                            </div>
                        </section>
        //             </div>
        //         </div>
        //     </div>
        // </Modal>
    )
}
export default Accounts