import { useEffect, useRef, useState } from "react";
import personIcon from "../../assets/img/person-icon.png";
import { hideLoader, showLoader } from '../../components/loader';
import { toast } from 'react-toastify';
import * as $ from "jquery";
import { useLocation } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../utils/url";
import { useAlert } from "../../contexts/alertContext";

const getErrors = (errors) => {
    const errorList = []
    if (errors?.title)
        errors?.title?.map((val) => errorList?.push(val))
    if (errors?.description)
        errors?.description?.map((val) => errorList?.push(val))
    if (errors?.imageData)
        errors?.imageData?.map((val) => errorList?.push(val))
    if (errors?.theme)
        errors?.theme?.map((val) => errorList?.push(val))
    if (errors?.step)
        errors?.step?.map((val) => errorList?.push(val))

    return errorList
}

const Step3 = ({ moveNext, moveBack, imageBlob }) => {
    const dispatch = useDispatch()
    const { state } = useLocation();
    const [error, setError] = useState([])
    const [allThemes, setAllThemes] = useState([]);
    const [value, setValue] = useState({
        title: localStorage?.user, description: null, theme:"Dark", step: 5
    });
    const [selectedTheme, setSelectedTheme] = useState({header:"#11151a",background:"#212930",font:"#ffffff"});
    const { uploadImageReducer } = useSelector(state => state)
    const profilePicRef = useRef(null)
    const { setAlert } = useAlert();
    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    };
    const handleThemeChange = (e) => {
        setValue({ ...value, ["theme"]: e.target.id });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(e, "next", value)
    };

    useEffect(() => {
        try {
            const state = JSON.parse(localStorage?.backStepValue)
            if (state?.title || state?.description || state?.theme) {

                // dispatch({ type: "SET_UPLOAD_IMAGE", payload: { imageData: response } })

                setValue({ ...value, ...state })
                profilePicRef.current.src = state?.imageData ? state?.imageData : personIcon
            }
        } catch { }
    }, [localStorage?.backStepValue])

    const onSubmit = (e, from, values) => {
        showLoader();
        setError([]);
        var myHeaders = new Headers();

        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData();
        if(profilePicRef?.current?.src != "https://imagedelivery.net/tqC70bVt8T6GtQUXNsa2-g/743efd57-1c7a-4ea5-402b-ec349ceae400/public"){        
            formdata.append("imageData", uploadImageReducer?.imageData)
        }
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) formdata.append(key, values[key]);
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/walkthrough", requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    if (from == "next" || from == "skip") {
                        dispatch({ type: "RESET_UPLOAD_IMAGE" })
                        // toast.success(response?.status_message)
                        setAlert({ type: 'success', title: response?.status_message})

                        hideLoader()
                        moveNext(e, 6)
                    }
                    else if (from == "back") {
                        moveBack(e, response)

                        hideLoader()
                    }
                }
                else {
                    setError(getErrors(response?.errors))
                    setAlert({ type: 'error', title: response?.status_message})
                    // toast.error(response?.status_message)

                    hideLoader()
                }

            }).catch((err) => {
                hideLoader()
            })
    }
    const openUploadModal = () => $(".profile_pic_upload_modal").click();

    const getTheme = async (e) => {
        showLoader()
        fetch(apiUrl + "/themes")
            .then(async (res) => {
                hideLoader()
                const response = await res.json();
                setAllThemes(response?.data)
            }).catch((err) => {

                // console.log(err)
                hideLoader()
            })
    }
    useEffect(() => {
        if (allThemes.length == 0) getTheme()
    }, [])

    useEffect(() => {
        if (state?.description && state?.title) setValue({ ...value, ...state })
    }, [state])

    useEffect(()=>{
        const Theme = allThemes?.find(val => val.name.toLowerCase() == value?.theme.toLowerCase() );
        if (Theme) {
            setSelectedTheme({
                header: JSON.parse(Theme?.meta)?.header?.color,
                background:JSON.parse(Theme?.meta)?.background?.color,
                font:JSON.parse(Theme?.meta)?.background?.font,
            });
        }
    },[value?.theme])

    $(document).on('click', '.theme-selection-div', function () {

        // $(".theme-selection-div").removeClass('selected-theme');
        // $(".theme-selection-div").addClass('selected-theme');
        var src_value = $(this).find('span').attr('data-bg');

        $("input[name='theme_color']").val(src_value);
    });
    return (
        <>
            <div class="steps-card card2">
                <div class="counter">4</div>
                <h1 class="text-left newpageheading" style={{marginTop:"11px" , marginBottom:"0"}}>Complete Your Profile</h1>
                <form action=""  method="post" role="form" onSubmit={(e) => handleSubmit(e)}>
                    <div class="rowflex-u">
                        <div>
                            <div class="profile-pic-box">
                                <div className="uploded-imge-area">
                                    <img src={value?.imageData ? value?.imageData : "https://imagedelivery.net/tqC70bVt8T6GtQUXNsa2-g/743efd57-1c7a-4ea5-402b-ec349ceae400/public"} width="80px" id="image_file_preview" class="image_file_preview clickable" ref={profilePicRef} />
                                </div>
                                <div style={{cursor:"pointer"}}>
                                    <span className="profhead">Profile Picture</span>
                                    <div class="upload--profile-btn" onClick={openUploadModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z" fill="#333333"/>
                                        </svg>Upload New
                                    </div>
                                    <input id="image_file_input" type="file" accept=".gif, .ico, .png, .jpg, .jpeg, .svg" class="form-control" style={{ display: "none" }} name="image" />
                                    <input type="hidden" name="image" value="" id="pp_image" />
                                </div>
                            </div>
                            <div>
                                {error?.length > 0 && (
                                    <Alert variant="danger">
                                        {error?.map((val, index) => <li key={index}>{val}</li>)}
                                    </Alert>
                                )}
                                <div class="form-group">
                                    <label htmlFor="" className="newpage-subtitle socialhead">Page Title</label>
                                    <div class='input2u'>
                                        <input type="text" name="title" value={value?.title} onChange={handleChange}
                                            placeholder="John Smith" class="pl-3" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label htmlFor="" className="newpage-subtitle socialhead">Theme</label>
                                    {/* <div class='input'>
                                        <input type="text" name="description"
                                            value={value?.description} onChange={handleChange}
                                            placeholder="Find My Links Below" class="pl-3" />
                                    </div> */}
                                    <div class="theme-main-right col-lg-12 pl-0 pr-0 pl-lg-1">

                                        {
                                            allThemes?.length > 0 &&
                                            allThemes?.map((val, index) => (
                                                val.name.toLowerCase() == value?.theme.toLowerCase()  ?
                                                <div class="theme-selection-div selected-theme" key={val.id} id={val.name.toLowerCase()} name="theme" onClick={(e)=>handleThemeChange(e)}>
                                                       <div class="theme-selection-indiv  p-0" style={{border:`4px solid ${JSON.parse(val.meta).header.color}`,borderRadius:"50%"}} id={val.name.toLowerCase()}>
                                                            <span data-bg={`group${val.id}`} id={val.name.toLowerCase()}>
                                                                <img style={{ filter: "drop-shadow(0px 0px 23px rgba(57, 51, 69, 0.09));",borderRadius:"50%",marginTop:"-4px" }}
                                                                    id={val.name.toLowerCase()}
                                                                    src={val.image} width="26px" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div class="theme-selection-div" key={val.id} id={val.name.toLowerCase()} name="theme" onClick={(e)=>handleThemeChange(e)}>
                                                        <div class="theme-selection-indiv p-0" style={{border:`4px solid ${JSON.parse(val.meta).header.color}`,borderRadius:"50%"}} id={val.name.toLowerCase()}>
                                                            <span data-bg={`group${val.id}`} id={val.name.toLowerCase()}>
                                                                <img style={{ filter: "drop-shadow(0px 0px 23px rgba(57, 51, 69, 0.09));",borderRadius:"50%",marginTop:"-4px" }}
                                                                    id={val.name.toLowerCase()}
                                                                    src={val.image} width="26px" />
                                                                {/* <small>{val.name}</small> */}
                                                            </span>
                                                        </div>
                                                    </div>
                                            ))
                                        }

                                        </div>
                                    <div className="caption">Choose from more themes once completed</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="profilecard" style={{background:`${selectedTheme?.header}`}} >
                                <div className="profile-body" style={{background:`${selectedTheme?.background}`}}>
                                    <div className="user-img-area"><img src={value?.imageData ? value?.imageData : "https://imagedelivery.net/tqC70bVt8T6GtQUXNsa2-g/743efd57-1c7a-4ea5-402b-ec349ceae400/public"} id="image_file_preview_2"  alt="" width={"100%"} /></div>
                                    <h2 className="usertitle-area" style={{color:`${selectedTheme?.font}`}}>{value?.title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group d-flex" style={{gap:"6px" , marginTop: "50px"}}>
                            <div class="form-group">
                                <button type="submit" name="submit"  class="red-btn-u">
                                    Next Step
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none"><path d="M2.85366 8C3.12287 8 3.30511 7.80467 3.30511 7.53715C3.30511 7.39703 3.25127 7.29512 3.16843 7.21019L2.11643 6.15711L1.39162 5.53291L2.34837 5.57537H7.51726C8.55683 5.57537 9 5.09554 9 4.04246V1.52442C9 0.458599 8.55683 0 7.51726 0H5.21859C4.9411 0 4.74643 0.212314 4.74643 0.475584C4.74643 0.734607 4.9411 0.946921 5.21445 0.946921H7.49655C7.91072 0.946921 8.08882 1.12951 8.08882 1.55414V4.01274C8.08882 4.45011 7.91072 4.62845 7.49655 4.62845H2.34837L1.39162 4.67516L2.11643 4.04671L3.16843 2.99788C3.25127 2.91295 3.30511 2.80679 3.30511 2.66667C3.30511 2.4034 3.12287 2.20807 2.85366 2.20807C2.73769 2.20807 2.6093 2.26327 2.51818 2.35669L0.144961 4.75159C0.0497009 4.84501 0 4.9724 0 5.10403C0 5.23142 0.0497009 5.35881 0.144961 5.45223L2.51818 7.85138C2.6093 7.9448 2.73769 8 2.85366 8Z" fill="white"/></svg></span>
                                </button>
                            </div>
                            <button type="button" name="button" class="gray-btn-u"
                                onClick={(e) =>
                                    onSubmit(e, "skip", {
                                        skip: 1,
                                        step: e.target.dataset.step
                                    })}
                                data-step="5">Skip</button>
                            {/* <button
                                onClick={(e) =>
                                    onSubmit(e, "back", {
                                        back: 1,
                                        step: +e.target.dataset.step + +1
                                    })} type="button" class="btn flex-1 mr-2 back-btn text-muted-light"
                                data-step="2">Back</button> */}
                        
                    </div>
                </form>
            </div>


        </>
    )
}
export default Step3