import { Modal } from "react-bootstrap";
import domainIcon from "../../../../assets/img/modals/domain-icon.svg"
import returnIcon from "../../../../assets/img/modals/return.svg";

const CustomDomainConnectedModal = ({ show, handleHide }) => (
    <Modal show={show} centered onHide={handleHide}>
        <Modal.Body style={{ padding: "0px" }}>
            <div class="modal-body">

                <form name="create_folder" method="post" role="form">
                    <div class="notification-container"></div>


                    <div class="form-group">
                        <img src={domainIcon} width="30px" alt="" />
                        <h4 class="mt-2 mb-1">Woo Hoo! Your domain is connected.</h4>
                        <p class="text-gray font-weight-500">We're doing the technical stuff to make your<br />domain work. This should only take a few<br /> minutes.</p>
                    </div>

                    <div class="text-center mt-4 d-flex">
                        <button type="button" data-bs-dismiss="modal" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center" onClick={()=>handleHide(true)}>
                            <b class="me-2">Close</b>
                            <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                <img src={returnIcon} alt="" />
                            </small>
                        </button>
                    </div>

                </form>


            </div>
        </Modal.Body>
    </Modal>
)
export default CustomDomainConnectedModal;
