import { ReactSortable } from "react-sortablejs";
import returnIcon from "../../../assets/img/modals/return.svg";
import dragIcon from "../../../assets/img/page/drag-icon.svg";
// import * as socialsOptions from "../../../utils/socials.json"
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../loader";
import { apiUrl } from "../../../utils/url";
import { matchObject } from "../customize-page";

const SocialsCustomize = ({ handleSubmit, setShowSaveChanges, }) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state?.customizeSocialsReducer);
    const [socialFormState, setSocialFormState] = useState({ ...value });
    const { slug } = useSelector(state => state?.slugReducer);
    const [socials, setSocials] = useState([]);
    const [timer, setTimer] = useState(null);
    const [countChange, setCountChange] = useState(0);

    useEffect(() => {
        if (slug) {
            showLoader()
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders
            };
            fetch(apiUrl + `/project/get-socials/${slug}`, requestOptions)
                .then(async (res) => {
                    const response = await res?.json();
                    if (response?.status_code == 200) {
                        setSocials(Object.values(response?.data));
                        hideLoader();
                    }
                }).catch((err) => {
                    hideLoader();
                })
        }
    }, [])

    const handleChange = (e,val_id) => {
        if (e.target.value) {
            $('.add-'+val_id).addClass("addbtn-act")
        }else{
            $('.add-'+val_id).removeClass("addbtn-act")
        }
       
        // let tempArr = []
        // tempArr = [...socials]
        // tempArr.find((social, i) => {
        //     if (social.name === e.target.name) {
        //         tempArr[i] = { ...tempArr[i], value: e.target.value };
        //         return true; // stop searching
        //     }
        // });
        // setSocials(tempArr);
        // setSocials([...socials, { [e.target.name]: e.target.value }])
        // dispatch({ type: "SET_CUSTOMIZE_SOCIALS", payload: tempArr })
        // if (e.target.name == "facebook messenger")
        //     dispatch({ type: "SET_CUSTOMIZE_SOCIALS", payload: { ...value, [e.target.name.replace(" ", "_")]: e.target.value } })
        // else
        //     dispatch({ type: "SET_CUSTOMIZE_SOCIALS", payload: { ...value, [e.target.name]: e.target.value } })
    }
  
    
    
    const handleAdd = (val_id) => {
        let tempArr = []
        tempArr = [...socials]
        tempArr.find((social, i) => {
            if (social.name === val_id) {
                tempArr[i] = { ...tempArr[i], value: $(`input[name="${val_id}"]`).val() };
                return true; // stop searching
            }
        });
        setSocials(tempArr);
        dispatch({ type: "SET_CUSTOMIZE_SOCIALS", payload: tempArr })
        $(`.add_inp-${val_id}`).addClass("link_added");
        $('.add-'+val_id).removeClass("addbtn-act")
        $(`.cross-${val_id}`).css('display','block');
        $(`.inp-${val_id}`).attr("readonly", true);
      };
      
      const handleRemove = (val_id) => {
        let tempArr = []
        tempArr = [...socials]
        tempArr.find((social, i) => {
            if (social.name === val_id) {
                tempArr[i] = { ...tempArr[i], value: null };
                return true; // stop searching
            }
        });
        setSocials(tempArr);
        dispatch({ type: "SET_CUSTOMIZE_SOCIALS", payload: tempArr })
        $(`.add_inp-${val_id}`).removeClass("link_added");
        $('.add-'+val_id).addClass("addbtn-act")
        $(`.cross-${val_id}`).css('display','none');
        $(`.inp-${val_id}`).attr("readonly", false);
      };
        // useEffect(()=>{
        //     $('.new-input-area').each(function () {
                
        //     })
        // },[])
    // const draggableIcon = () => <img src={dragIcon} id="drag" className="" alt="" />
    const draggableIcon = (icon) =>  <div id="drag" dangerouslySetInnerHTML={{ __html: icon }} />;
    function changeDelay() {
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
        }
        setTimer(
          setTimeout(() => {
            handleSubmit(value, "socials");
          }, 3000)
        );
    };
    useEffect(()=>{
        if (countChange > 0) {
            if (value && socialFormState && !matchObject(value, socialFormState)) {
                // changeDelay();
                setShowSaveChanges(prevState => ({
                    ...prevState,
                    show: true,
                    formId: prevState.formId.includes("socialValue")
                        ? prevState.formId
                        : [...prevState.formId, "socialValue"]
                }));
            } else {
                setShowSaveChanges(prevState => ({
                    ...prevState,
                    show: true,
                    formId: prevState.formId.filter(form => form !== "socialValue")
                }));
            }
        } else {
            setCountChange(1);
            return;
        }
    },[value])
    return (
        <div class="tab-pane fade customize-page-main active show" id="customizePageSocials" role="tabpanel" aria-labelledby="social-tab">
            <h4 class="mb-2">Social Icons</h4>
            <section>
                <form
                    style={{ paddingBottom: "60px" }}
                    action="" onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit(value, "socials")
                    }}>
                    <ReactSortable handle="#drag" list={socials} setList={(e, i) => {
                        setSocials(e)
                        dispatch({ type: "SET_CUSTOMIZE_SOCIALS", payload: e });
                    }}>
                        {socials && socials?.map((val, index) => {
                            const social_name = val?.name?.toLowerCase().replace(" ", "_");
                            const isLinkAdded = val?.value !== null && val?.value !== 'null' && val?.value.trim() !== '';
                            return (
                                
                                <div data-folder={val.name}>
                                    <div class="form-group mt-3" key={val?.name}>
                                        <label class="text-gray">   
                                            {val?.title && val.title.charAt(0).toUpperCase() + val.title.slice(1)}
                                        </label>
                                        <div class={`new-input-area  ${isLinkAdded ? "link_added" : ""} add_inp-${val.name}`}>
                                            <div class="w-100 d-flex">
                                                <label class={`social-icon-u`} id="basic-addon1">
                                                    {draggableIcon(val.icon)}
                                                </label>
                                                <div className="your-social-area d-flex align-items-center ">
                                                    <span>{val?.prefix}</span>
                                                    <input type="text" name={social_name} readOnly={isLinkAdded ? true : false} onChange={(e) => handleChange(e,val.name)} class={`Default_inp inp-${val.name}`} placeholder={val.placeholder} aria-label="Username" value={val?.value != 'null' ? val?.value : null} aria-describedby="basic-addon1" />
                                                </div>
                                            </div>
                                           {/* {val?.value == null ? <span></span> 
                                            :   
                                        
                                            <button type="button" onClick={() => handleRemove(val.name)} className={`cross-u cross-${val.name}`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 12L8 8M8 8L12 4M8 8L4 4M8 8L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>
                                            } */}
                                            <button type="button" onClick={() => handleRemove(social_name)} className={`cross-u cross-${social_name}  ${isLinkAdded ? "crossact" : ""}`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 12L8 8M8 8L12 4M8 8L4 4M8 8L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>
                                            
                                            <button type="button" onClick={() => handleAdd(social_name)} className={`add-btn-u add-${social_name}`}>Add</button>
                                            
                                           
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </ReactSortable>
                    {/* <div class="text-center mt-4 d-flex submit-buttons-fixed">
                        <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                            <b class="me-2">Save Changes</b>
                            <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                <img src={returnIcon} alt="" />
                            </small>
                        </button>
                    </div> */}
                </form>
            </section>
        </div >
    )
}
export default SocialsCustomize