import * as $ from "jquery"
export const showLoader = () => {
    $(".full-page-loader").removeClass('d-none');
}
export const hideLoader = () => {
    $(".full-page-loader").addClass('d-none');
}
export default function Loader() {
    return (

        <div class="full-page-loader d-none">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    )
}