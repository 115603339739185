import { apiUrl } from "../utils/url";

const getNewsletter = async (slug, params, apiName) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/${apiName}/get/${slug}?${params}`, {
            headers: myHeaders
        })
        const response = await res.json();
        if(response?.status_code == 200){
           return response?.data; 
        }
        return response;
        
    }
    catch (err) {
// console.log("error", err);
    }
}
export default getNewsletter