import { showLoader } from "../components/loader";
import { apiUrl } from "../utils/url";

const getProducts = async (slug, params) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/ProductFlow/get/${slug}?${params}`, {
            headers: myHeaders
        })
        const response = await res.json();
        return response?.data
        
    }
    catch (err) {
        
// console.log("error", err);
    }
}
export default getProducts