
import { Modal } from "react-bootstrap";
import payout_settings_warning_banner from "../assets/img/payout-setting-warning-banner.svg"
import notConnected from "../assets/img/payout-settings-not-connect-icon.svg";
import returnIcon from "../assets/img/modals/return.svg";

const DisonnectModal = ({ show, setShow, disconnect }) => {
    const hideModal = () => setShow(false)
    return (
        <Modal show={show} onHide={hideModal} >
            <Modal.Body style={{ padding: "0px" }}>
                <div style={{
                    borderTopRightRadius: '1.2rem',
                    borderTopLeftRadius: '1.2rem',
                    overflow: 'hidden',
                }}>
                    <img src={payout_settings_warning_banner} width="100%" />
                </div>
                <div style={{ padding: "20px", marginTop: "-40px" }}>
                    <div class="form-group">
                        <div style={{ marginTop: "-5px" }}>
                            <img src={notConnected} width="50px" style={{ boxShadow: "0px 4px 20px rgba(103, 101, 122, 0.19)", borderRadius: "10px" }} alt="" />
                        </div>
                        <h4 class="mt-3 mb-1">Disconnect PayPal</h4>
                        <p class="text-gray font-weight-500">Disconnecting your PayPal account will prevent you from offering PayPal services and products on your website. Do you wish to continue?</p>
                    </div>
                    <div class="text-center mt-4 d-flex">
                        <button type="button" onClick={hideModal} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                            <b class="me-2">Cancel</b>
                            <small class="badge bg-light text-dark p-1">Esc</small>
                        </button>
                        <form action="" onSubmit={disconnect}>
                            <button autoFocus type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                <b class="me-2">Continue</b>
                                <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                    <img src={returnIcon} alt="" />
                                </small>
                            </button>
                        </form>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}
export default DisonnectModal;
