const INIT_STATE = {
    isPro: false, open: false
}
const upgradeModalReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_UPGRADE_MODAL':
            return action.payload 
        case 'RESET_UPGRADE_MODAL':
            return INIT_STATE
        default:
            return state
    }

}
export default upgradeModalReducer