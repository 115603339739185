
import user_pp from "../../../assets/media/user_pp/image1.png"
import dotsIcon from "../../../assets/img/dots.svg";
import gearIcon from "../../../assets/img/gear.svg";
import { ChevronRight } from "../../../utils/svg";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { audiencePosts as apiService } from "../../../services/postRequests/audience";
import { hideLoader, showLoader } from "../../loader";
import { apiUrl, pageUrl } from "../../../utils/url";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const DashboardProjects = ({ isLoading, setStats, projects, selectedProfile, setSelectedProfile, setSelectedProject, selectedSlug, setShowModal, setconfirmationModalShow }) => {
    const dispatch = useDispatch()
    const getStats = (e, id, slug) => {
        e && e.preventDefault();
        setSelectedProfile(id)
        const api = apiUrl + "/project/get-dashboard/" + slug;
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
        }
        apiService(null, api, hideLoader, setOptions)?.then((response) => {
            if (response) {
                setStats(response);
            }
        })
    }
    useEffect(() => {
        if(selectedSlug){
        localStorage.slug=selectedSlug
        getStats(null, selectedProfile, selectedSlug)
    }
    }, [selectedSlug])
    return (
        !isLoading ?
            projects?.length > 0 && projects?.map((val, index) => {
                return (
                    <div key={index} className={`project-small d-flex align-items-center justify-content-between mb-2 ${val.id == selectedProfile ? "active" : null}`} id="project-small-dropdown"
                    >
                        <div className="d-flex align-items-center justify-content-start project-small-details-main"
                           onClick={(e) => {
                            localStorage.slug=val.slug
                            
                            getStats(e, val.id, val?.slug)
                            // setSelectedProject(true)
                        }}>
                            <div className="project-small-user-pp">
                                <img src={val?.settings[1]?.value} width="100%" alt="" />
                            </div>
                            <div className="project-small-details d-flex align-items-start justify-content-center flex-column ps-2">
                                <div className="project-small-project-title">
                                    <b>{val?.settings[0]?.value}</b>
                                </div>
                                <div className="project-small-project-link"
                                    >
                                    <span className="text-muted-light">{pageUrl}/{val.slug}</span>
                                </div>
                            </div>
                        </div>
                        <div className="project-small-action-button dropdown-toggle noselect d-md-flex d-none" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={dotsIcon} alt="" style={{padding: "10px 5px"}} />
                        </div>
                        <ul className="dropdown-menu" aria-labelledby="project-small-dropdown">
                            <li>
                                <Link to={`project/${val.slug}/page-editor`} className="dropdown-item">
                                    <span className="me-5">
                                        Edit Page
                                    </span>
                                    <ChevronRight />
                                </Link>
                            </li>
                            <li>
                                <Link to={`project/${val.slug}/analytics`} className="dropdown-item">
                                    <span className="me-5">
                                        Page Analytics
                                    </span>
                                    <ChevronRight />
                                </Link>
                            </li>
                            <li>
                                <Link to={`project/${val.slug}/products`} className="dropdown-item">
                                    <span className="me-5">
                                        Products
                                    </span>
                                    <ChevronRight />
                                </Link>
                            </li>
                            <li>
                                <Link to={`project/${val.slug}/audience`} className="dropdown-item">
                                    <span className="me-5">
                                        Community
                                    </span>
                                    <ChevronRight />
                                </Link>
                            </li>
                            <li>
                                <Link to={`project/${val.slug}/settings/team-members`} className="dropdown-item">
                                    <span className="me-5">
                                        Page Settings
                                    </span>
                                    <ChevronRight />
                                </Link>
                            </li>
                            <li>
                                <Link to={`project/${val.slug}/duplicate`} onClick={(e) => {
                                    e.preventDefault()
                                    setShowModal({ show: true, duplicate: true, slug: val.slug })
                                }} className="dropdown-item">
                                    <span className="me-5">
                                        Duplicate
                                    </span>
                                    <ChevronRight />
                                </Link>
                            </li>
                            <li className="px-2"><div className="dropdown-divider"></div></li>
                            <li>
                                <Link to={`delete-projects`} onClick={((e) => {
                                    e.preventDefault();
                                    setconfirmationModalShow({
                                        show: true, text: "Are you sure you want to delete this project?", id: val?.slug, callBackTo: "delete"
                                    })
                                }).bind()} data-value={val?.slug} className="dropdown-item theme-active semi-bold">
                                    <span className="me-5" data-value={val?.slug}>Delete Page</span>
                                </Link>
                            </li>
                        </ul>
                        <Link to={`project/${val.slug}/page-editor`} className="project-small-manage-button d-md-none d-flex align-items-center justify-content-center text-decoration-none" style={{ color: "#000" }}>
                            <img src={gearIcon} width="17px" alt="" />
                            <span><b>Manage</b></span>
                        </Link>
                    </div >
                )
            })
            :
            <>
                <div className="project-small d-flex align-items-center justify-content-between mb-2" id="project-small-dropdown">
                    <div className="d-flex align-items-center justify-content-start project-small-details-main">
                        <div className="project-small-user-pp">
                            <Skeleton
                                circle
                                height={45}
                                width={45}
                                containerClassName="avatar-skeleton"
                            />
                        </div>
                        <div className="project-small-details d-flex align-items-start justify-content-center flex-column ps-2">
                            <div className="project-small-project-title">
                                <Skeleton />
                            </div>
                            <div className="project-small-project-link">
                                <Skeleton />
                            </div>
                        </div>
                    </div>
                    <div className="project-small-action-button dropdown-toggle noselect d-md-flex d-none" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src={dotsIcon} alt="" />
                    </div>
                </div>
            </>
    )
}

export default DashboardProjects