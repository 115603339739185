const INIT_STATE = {
    api_key: null,
    list_id: null,
    tab: "integrations"
}
const integrationSendinblueReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_SENDINBLUE_INTEGRATION':
            return action.payload 
        case 'RESET_SENDINBLUE_INTEGRATION':
            return INIT_STATE
        default:
            return state
    }
}
export default integrationSendinblueReducer