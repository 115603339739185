import { useState } from "react"
import { hideLoader, showLoader } from '../../components/loader';
import { toast } from 'react-toastify';
import { Alert } from "react-bootstrap";
import { apiUrl } from "../../utils/url";
import { useEffect } from "react";
import * as $ from "jquery";
import { useAlert } from "../../contexts/alertContext";


const getErrors = (errors) => {
    const errorList = []
    if (errors?.instagram)
        errors?.instagram?.map((val) => errorList?.push(val))
    if (errors?.tiktok)
        errors?.tiktok?.map((val) => errorList?.push(val))
    if (errors?.youtube)
        errors?.youtube?.map((val) => errorList?.push(val))
    if (errors?.twitter)
        errors?.twitter?.map((val) => errorList?.push(val))
    if (errors?.step)
        errors?.step?.map((val) => errorList?.push(val))

    return errorList
}

const Step2 = ({ moveNext, moveBack }) => {
    const [error, setError] = useState([])
    const [value, setValue] = useState({
        instagram: null,
        tiktok: null, youtube: null, twitter: null,twitch:null, step: 2
    })
    const { setAlert } = useAlert();

    useEffect(() => {
        try {
            const socials = JSON.parse(localStorage?.backStepValue)?.socials
            if (socials) {
                setValue({ ...value, 
                    instagram: (socials?.instagram !== "null" && socials?.instagram !== "undefined") ? socials?.instagram : null,
                    tiktok: (socials?.tiktok !== "null" && socials?.tiktok !== "undefined") ? socials?.tiktok : null,
                    youtube: (socials?.youtube !== "null" && socials?.youtube !== "undefined") ? socials?.youtube : null,
                    twitter: (socials?.twitter !== "null" && socials?.twitter !== "undefined") ? socials?.twitter : null
                })
            }
        } catch { }
    }, [localStorage?.backStepValue])

    const handleChange = (e,name) => {
        if (e.target.value) {
            $('.add-'+name).addClass("addbtn-act")
        }else{
            $('.add-'+name).removeClass("addbtn-act")
        }
        // setValue({ ...value, [e.target.name]: e.target.value });
    };

    const handleAdd = (name) => {
        if ($(`input[name="${name}"]`).val()) {
            setValue({ ...value, [name]: $(`input[name="${name}"]`).val() });
            $(`.add_inp-${name}`).addClass("link_added");
            $('.add-'+name).removeClass("addbtn-act")
            $(`.cross-${name}`).css('display','block');
            $(`.inp-${name}`).attr("readonly", true);
        }else{
            setValue({ ...value, [name]: null });
        }
      };
      const handleRemove = (name) => {
        setValue({ ...value, [name]: null });
        $(`.add_inp-${name}`).removeClass("link_added");
        $('.add-'+name).addClass("addbtn-act")
        $(`.cross-${name}`).css('display','none');
        $(`.inp-${name}`).attr("readonly", false);
      };

    const onSubmit = (e, from, values) => {
        showLoader();
        setError([])
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData();
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                formdata.append(key, values[key]);
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/walkthrough", requestOptions)
            .then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    if (from == "next" || from == "skip") {
                        setAlert({ type: 'success', title: response?.status_message})
                        // toast.success(response?.status_message)
                        moveNext(e, 3)
                    }
                    else if (from == "back") {
                        moveBack(e, response, "link")
                        hideLoader()
                    }
                }
                else {
                    setError(getErrors(response?.errors))
                    // toast.error(response?.status_message)
                    setAlert({ type: 'error', title: response?.status_message})
                }

            }).catch((err) => {
                hideLoader()
            })

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(e, "next", value)
    }

    return (
        <>
            <div class="steps-card card2">
                <div class="counter">1</div>
                <div class=" border-0 p-0 m-0 w-100 h-100">
                    <div>
                        <h1 class="text-left newpageheading" style={{marginTop:"11px" , marginBottom:"0"}}>Add your socials</h1>

                        <form action="" method="post" role="form" onSubmit={(e) => handleSubmit(e)}>
                            {error?.length > 0 && (
                                <Alert variant="danger">
                                    {error?.map((val, index) => <li>{val}</li>)}
                                </Alert>
                            )}
                            <div class="form-group">
                                <label htmlFor="" className="newpage-subtitle socialhead">Instagram Username</label>
                                <div class='new-input-area add_inp-instagram newinput-u'>
                                    <span id="input-prefix">
                                        <div className="social-icon" style={{paddingRight:'6px'}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M12.7848 5.24959H12.7923M2.28479 9C2.28479 7.11479 2.28479 6.17219 2.54994 5.41443C3.02486 4.05719 4.09198 2.99007 5.44922 2.51515C6.20698 2.25 7.14958 2.25 9.03479 2.25C10.92 2.25 11.8626 2.25 12.6204 2.51515C13.9776 2.99007 15.0447 4.05719 15.5197 5.41443C15.7848 6.17219 15.7848 7.11479 15.7848 9C15.7848 10.8852 15.7848 11.8278 15.5197 12.5856C15.0447 13.9428 13.9776 15.0099 12.6204 15.4849C11.8626 15.75 10.92 15.75 9.03479 15.75C7.14958 15.75 6.20698 15.75 5.44922 15.4849C4.09198 15.0099 3.02486 13.9428 2.54994 12.5856C2.28479 11.8278 2.28479 10.8852 2.28479 9ZM11.8679 8.57985C11.9563 9.1758 11.8545 9.78435 11.577 10.3191C11.2996 10.8538 10.8606 11.2874 10.3225 11.5583C9.78434 11.8292 9.17452 11.9234 8.57977 11.8277C7.98502 11.732 7.43555 11.4512 7.00957 11.0252C6.58359 10.5992 6.30278 10.0498 6.20708 9.45502C6.11137 8.86027 6.20565 8.25045 6.47649 7.71233C6.74733 7.17423 7.18096 6.73523 7.71569 6.45777C8.25044 6.18032 8.85899 6.07852 9.45486 6.1669C10.0627 6.25702 10.6255 6.54027 11.06 6.97478C11.4945 7.4093 11.7778 7.97205 11.8679 8.57985Z" stroke="#303A47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        instagram.com/
                                    </span>
                                    <input type="text" name="instagram"
                                        onChange={(e)=>handleChange(e,"instagram")}
                                        value={value.instagram}
                                        placeholder="username" />
                                    <button type="button" onClick={() => handleAdd('instagram')} className={`add-btn-u add-instagram`}>Add</button>
                                    <button type="button" onClick={() => handleRemove('instagram')} className="cross-u cross-instagram"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 12L8 8M8 8L12 4M8 8L4 4M8 8L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>
                                </div>
                            </div>
                            <div class="form-group">
                                <label htmlFor="" className="newpage-subtitle socialhead">TikTok Username</label>
                                <div class={`new-input-area add_inp-tiktok newinput-u`}>
                                    <span id="input-prefix">
                                        <div className="social-icon" style={{paddingRight:'6px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 24 24">
                                            <path d="M 6 3 C 4.3550302 3 3 4.3550302 3 6 L 3 18 C 3 19.64497 4.3550302 21 6 21 L 18 21 C 19.64497 21 21 19.64497 21 18 L 21 6 C 21 4.3550302 19.64497 3 18 3 L 6 3 z M 6 5 L 18 5 C 18.56503 5 19 5.4349698 19 6 L 19 18 C 19 18.56503 18.56503 19 18 19 L 6 19 C 5.4349698 19 5 18.56503 5 18 L 5 6 C 5 5.4349698 5.4349698 5 6 5 z M 12 7 L 12 14 C 12 14.56503 11.56503 15 11 15 C 10.43497 15 10 14.56503 10 14 C 10 13.43497 10.43497 13 11 13 L 11 11 C 9.3550302 11 8 12.35503 8 14 C 8 15.64497 9.3550302 17 11 17 C 12.64497 17 14 15.64497 14 14 L 14 10.232422 C 14.616148 10.671342 15.259118 11 16 11 L 16 9 C 15.952667 9 15.262674 8.7809373 14.78125 8.3613281 C 14.299826 7.941719 14 7.4149911 14 7 L 12 7 z" fill="#303a47"></path>
                                        </svg>
                                        </div>
                                        tiktok.com/@
                                    </span>
                                    <input type="text" name="tiktok"
                                        onChange={(e)=>handleChange(e,"tiktok")}
                                        value={value.tiktok}
                                        placeholder="username" />
                                    <button type="button" onClick={() => handleAdd('tiktok')} className={`add-btn-u add-tiktok`}>Add</button>
                                    <button type="button" onClick={() => handleRemove('tiktok')} className="cross-u cross-tiktok"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 12L8 8M8 8L12 4M8 8L4 4M8 8L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>
                                </div>
                            </div>
                            <div class="form-group">
                                <label htmlFor="" className="newpage-subtitle socialhead">YouTube Username</label>
                                <div class='new-input-area add_inp-youtube newinput-u'>
                                    <span id="input-prefix">
                                        <div className="social-icon" style={{paddingRight:'6px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px" height="18px">
                                            <path d="M 12 4 C 12 4 5.7455469 3.9999687 4.1855469 4.4179688 C 3.3245469 4.6479688 2.6479687 5.3255469 2.4179688 6.1855469 C 1.9999687 7.7455469 2 12 2 12 C 2 12 1.9999687 16.254453 2.4179688 17.814453 C 2.6479687 18.675453 3.3255469 19.352031 4.1855469 19.582031 C 5.7455469 20.000031 12 20 12 20 C 12 20 18.254453 20.000031 19.814453 19.582031 C 20.674453 19.352031 21.352031 18.674453 21.582031 17.814453 C 22.000031 16.254453 22 12 22 12 C 22 12 22.000031 7.7455469 21.582031 6.1855469 C 21.352031 5.3255469 20.674453 4.6479688 19.814453 4.4179688 C 18.254453 3.9999687 12 4 12 4 z M 12 6 C 14.882 6 18.490875 6.1336094 19.296875 6.3496094 C 19.465875 6.3946094 19.604391 6.533125 19.650391 6.703125 C 19.891391 7.601125 20 10.342 20 12 C 20 13.658 19.891391 16.397875 19.650391 17.296875 C 19.605391 17.465875 19.466875 17.604391 19.296875 17.650391 C 18.491875 17.866391 14.882 18 12 18 C 9.119 18 5.510125 17.866391 4.703125 17.650391 C 4.534125 17.605391 4.3956094 17.466875 4.3496094 17.296875 C 4.1086094 16.398875 4 13.658 4 12 C 4 10.342 4.1086094 7.6011719 4.3496094 6.7011719 C 4.3946094 6.5331719 4.533125 6.3946094 4.703125 6.3496094 C 5.508125 6.1336094 9.118 6 12 6 z M 10 8.5351562 L 10 15.464844 L 16 12 L 10 8.5351562 z" fill="#303a47"/>
                                        </svg>
                                        </div>
                                        youtube.com/
                                    </span>
                                    <input type="text" name="youtube"
   e                                     onChange={(e)=>handleChange(e,"youtube")}
                                        value={value.youtube}
                                        placeholder="username" />
                                    <button type="button" onClick={() => handleAdd('youtube')} className={`add-btn-u add-youtube`}>Add</button>
                                    <button type="button" onClick={() => handleRemove('youtube')} className="cross-u cross-youtube"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 12L8 8M8 8L12 4M8 8L4 4M8 8L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>
                                </div>
                            </div>
                            <div class="form-group">
                                <label htmlFor="" className="newpage-subtitle socialhead">Twitter Username</label>
                                <div class='new-input-area add_inp-twitter newinput-u'>
                                    <span id="input-prefix">
                                        <div className="social-icon" style={{paddingRight:'6px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M15.7206 3.94117C15.855 3.63343 15.5018 3.37424 15.2059 3.53287C14.7418 3.7816 14.2534 3.98382 13.7477 4.1361C11.7121 1.59389 8.20496 3.73412 8.72326 6.47581C8.74008 6.56477 8.6739 6.6509 8.58337 6.64919C6.67647 6.61334 5.33026 5.99299 4.03123 4.53138C3.84681 4.32387 3.52043 4.33365 3.38091 4.57366C2.52266 6.04997 0.403959 10.6293 5.85441 12.4654C4.78851 13.1889 3.39789 13.8034 2.68551 14.0972C2.50931 14.1699 2.50179 14.4199 2.67557 14.4982C9.78791 17.7019 16.7099 13.0356 14.5181 5.69149C15.0288 5.18784 15.436 4.59314 15.7206 3.94117Z" stroke="#303A47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        </div>
                                        twitter.com/
                                    </span>
                                    <input type="text" name="twitter"
   e                                     onChange={(e)=>handleChange(e,"twitter")}
                                        value={value.twitter}
                                        placeholder="username" />
                                    <button type="button" onClick={() => handleAdd('twitter')} className={`add-btn-u add-twitter`}>Add</button>
                                    <button type="button" onClick={() => handleRemove('twitter')} className="cross-u cross-twitter"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 12L8 8M8 8L12 4M8 8L4 4M8 8L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>
                                </div>
                            </div>
                            <div class="form-group">
                                <label htmlFor="" className="newpage-subtitle socialhead">Twitch Username</label>
                                <div class='new-input-area add_inp-twitch newinput-u'>
                                    <span id="input-prefix">
                                        <div className="social-icon" style={{paddingRight:'6px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18px" height="18px" viewBox="0,0,256,256">
                                        <g fill="#303a47" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode:"normal"}} ><g transform="scale(10.66667,10.66667)"><path d="M5.36328,2l-3.36328,4.63672v13.36328h5v3h3l3,-3h4l5,-5v-13zM6,4h14v9l-3,3h-5l-3,3v-3h-3zM11,7v5h2v-5zM16,7v5h2v-5z"></path></g></g>
                                        </svg>
                                        </div>
                                        twitch.tv/
                                    </span>
                                    <input type="text" name="twitch"
   e                                     onChange={(e)=>handleChange(e,"twitch")}
                                        value={value.twitch}
                                        placeholder="username" />
                                    <button type="button" onClick={() => handleAdd('twitch')} className={`add-btn-u add-twitch`}>Add</button>
                                    <button type="button" onClick={() => handleRemove('twitch')} className="cross-u cross-twitch"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 12L8 8M8 8L12 4M8 8L4 4M8 8L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>
                                </div>
                            </div>
                            <div class="form-group d-flex" style={{gap:"6px" , marginTop: "50px"}}>
                                <div class="form-group">
                                    <button type="submit" name="submit"  class="red-btn-u">
                                        Next Step
                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none"><path d="M2.85366 8C3.12287 8 3.30511 7.80467 3.30511 7.53715C3.30511 7.39703 3.25127 7.29512 3.16843 7.21019L2.11643 6.15711L1.39162 5.53291L2.34837 5.57537H7.51726C8.55683 5.57537 9 5.09554 9 4.04246V1.52442C9 0.458599 8.55683 0 7.51726 0H5.21859C4.9411 0 4.74643 0.212314 4.74643 0.475584C4.74643 0.734607 4.9411 0.946921 5.21445 0.946921H7.49655C7.91072 0.946921 8.08882 1.12951 8.08882 1.55414V4.01274C8.08882 4.45011 7.91072 4.62845 7.49655 4.62845H2.34837L1.39162 4.67516L2.11643 4.04671L3.16843 2.99788C3.25127 2.91295 3.30511 2.80679 3.30511 2.66667C3.30511 2.4034 3.12287 2.20807 2.85366 2.20807C2.73769 2.20807 2.6093 2.26327 2.51818 2.35669L0.144961 4.75159C0.0497009 4.84501 0 4.9724 0 5.10403C0 5.23142 0.0497009 5.35881 0.144961 5.45223L2.51818 7.85138C2.6093 7.9448 2.73769 8 2.85366 8Z" fill="white"/></svg></span>
                                    </button>
                                </div>
                                <button type="button" name="button" class="gray-btn-u"
                                    onClick={(e) =>
                                        onSubmit(e, "skip", {
                                            skip: 1,
                                            step: e.target.dataset.step
                                        })}
                                    data-step="2">Skip</button>
                                {/* <button
                                    onClick={(e) =>
                                        onSubmit(e, "back", {
                                            back: 1,
                                            step: +e.target.dataset.step + +1
                                        })}
                                    type="button" class="btn flex-1 mr-2 back-btn text-muted-light"
                                    data-step="1">Back</button> */}
                          
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            {/* <div class="page-aside-bg"></div> */}
        </>
    )
}
export default Step2