import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IroColorPicker, { handleSubmitCustomTheme } from "../IroColorPicker"

const SelectedCustomTheme = ({ color, themeType, nthChild }) => {
const dispatch = useDispatch()
    const { productModalReducer, customizeThemeReducer, customizePageReducer } = useSelector(state => state);
    const [inputColor, setInputColor] = useState("#ffffff")
    const onColorChange = color => {
       
        dispatch({
            type:"SET_CUSTOM_COLOR", payload: {
                ...color,
                [themeType]: color.hexString
            }})
        // 
// console.log(color.hexString);
    };
    const handleChangeColor = (e) => {
        // try {
            // if (color.length <= 6 || (e.target.value.charCodeAt(0)) != "08") {
                clearTimeout(typingTimer);
                if ($(e.target).val()) {
                    typingTimer = setTimeout(() => {
                    dispatch({
                        type:"SET_CUSTOM_COLOR", payload: {
                            ...color,
                            [themeType]: $(e.target).val()
                        }})
                }, doneTypingInterval);
            }
            // }
        // }
        // catch { }
    }
    useEffect(() => {
        setInputColor(color)
    }, [color]);
    const closeModal = () => {
        handleSubmitCustomTheme("SET_CUSTOMIZE_THEME", dispatch, customizeThemeReducer)
        $('.color-picker-main').removeClass('d-flex');
        $('.color-picker-main').addClass('d-none');
    };
    return (
        <div class="color-picker-modal accordion-header d-flex justify-content-between color-picker-main d-none" id="iro-picker-container">
        <div id="upgradeTeamModal">
            
            <div className="" role="document">  
                <div className="modal-content modal-semi-curve h-auto" style={{ boxShadow: "0px 0px 29px rgba(48, 49, 62, 0.12)", border: 'none' }}>
                    <div className="modal-header">
                        <span>Edit Colour</span>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body modal-u2">
            <IroColorPicker color={inputColor} themeType={themeType} onColorChange={onColorChange} width={230} boxHeight={127} layoutDirection="vertical" nthChild={nthChild} />
        </div>
        </div>
        </div>
        </div>
        </div>
    )
}
export default SelectedCustomTheme