const INIT_STATE = {show: false, to: "page-editor"}
const editlinkProfilePicUploadReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_EDITLINK_PROFILE_PIC_MODAL':
            return action.payload 
        case 'RESET_EDITLINK_PROFILE_PIC_MODAL':
            return INIT_STATE
        default:
            return state
    }

}
export default editlinkProfilePicUploadReducer