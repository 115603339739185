import { Modal } from "react-bootstrap";
import IroColorPicker, { handleSubmitCustomTheme } from "./IroColorPicker";
import { useEffect, useState } from "react";
import SelectedThemeInput from "./commons/select-theme-input";
import SelectedCustomTheme from "./commons/select-custom-theme";
import { useDispatch, useSelector } from "react-redux";

const colorConfig = (_this, name, color) => {
    $(_this).parent().find(".text-color-img").removeClass('active');
    $(_this).addClass('active');
    if (_this) {
        if ($(_this).attr('data-type') == 'header') {
            if ($(_this).attr('data-original-title') != 'Colored') {
                $(".custom-theme-preview svg rect:first-child").css('fill', $(_this).attr('data-color'));
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
                $("#hex_code").val($(_this).attr('data-color'));
                $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
                $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            }
            else {
                $(".custom-theme-preview svg rect:first-child").css('fill', $("#hex_code").val());
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-none');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-flex');
            }
        }
        else if ($(_this).attr('data-type') == 'background') {
            
// console.log($(_this).attr('data-color'))
            if ($(_this).attr('data-original-title') != 'Colored') {
                $(".custom-theme-preview svg rect:nth-child(2)").css('fill', $(_this).attr('data-color'));
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
                $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
                $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            }
            else {
                $(".custom-theme-preview svg rect:nth-child(2)").css('fill', $("#hex_code2").val());
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-none');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-flex');
            }
        }
        else if ($(_this).attr('data-type') == 'links') {
            if ($(_this).attr('data-original-title') != 'Colored') {
                $(".custom-theme-preview svg g path").css('fill', $(_this).attr('data-color'));
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-none');

                $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
                $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            }
            else {
                $(".custom-theme-preview svg g path").css('fill', $("#hex_code3").val());
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-none');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-flex');
            }
        }
        else if ($(_this).attr('data-type') == 'buttons') {
            if ($(_this).attr('data-original-title') != 'Colored') {
                $(".custom-theme-preview svg path:nth-child(4)").css('fill', $(_this).attr('data-color'));
                $(".custom-theme-preview svg path:nth-child(5)").css('fill', $(_this).attr('data-color'));
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
                $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
                $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            }
            else {
                $(".custom-theme-preview svg path:nth-child(4)").css('fill', $("#hex_code4").val());
                $(".custom-theme-preview svg path:nth-child(5)").css('fill', $("#hex_code4").val());
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-none');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-flex');
            }
        }
        else if ($(_this).attr('data-type') == 'button_text') {
            if ($(_this).attr('data-original-title') != 'Colored') {
                // $(".custom-theme-preview svg path:nth-child(4)").css('fill', $(_this).attr('data-color'));
                // $(".custom-theme-preview svg path:nth-child(5)").css('fill', $(_this).attr('data-color'));
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
                $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
                $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            }
            else {
                // $(".custom-theme-preview svg path:nth-child(4)").css('fill', $("#hex_code4").val());
                // $(".custom-theme-preview svg path:nth-child(5)").css('fill', $("#hex_code4").val());
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-none');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-flex');
            }
        }
        else if ($(_this).attr('id') == 'hex_code') {
            $(".custom-theme-preview svg rect:first-child").css('fill', $(_this).attr('data-color'));
            $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
        }
    }
    else if (name) {
        if (name == 'header') {
            $(".custom-theme-preview svg rect:first-child").css('fill', color);
            $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
            $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
            $("#hex_code").val(color);
            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);
        }
        else if (name == '') {
            $(".custom-theme-preview svg rect:nth-child(2)").css('fill', color);
            $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
            $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);

        }
        else if (name == 'links') {
            $(".custom-theme-preview svg g path").css('fill', color);
            $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
            $(_this).parent().parent().find('.color-picker-main').addClass('d-none');

            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);
        }
        else if (name == 'buttons') {
            $(".custom-theme-preview svg path:nth-child(4)").css('fill', color);
            $(".custom-theme-preview svg path:nth-child(5)").css('fill', color);
            $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
            $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);

        }
        else if (name == 'button_text') {
            $(".custom-theme-preview svg path:nth-child(4)").css('fill', color);
            $(".custom-theme-preview svg path:nth-child(5)").css('fill', color);
            $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
            $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);
        }
        else if (name == 'hex_code') {
            $(".custom-theme-preview svg rect:first-child").css('fill', color);
            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);
        }
    }
}
const CustomThemeModal = ({ setOpenCustomTheme, openCustomTheme, openCustomThemeFrom }) => {
    const dispatch = useDispatch()
    const handleHide = () => setOpenCustomTheme(false)

    const { productModalReducer, customizeThemeReducer, customizePageReducer } = useSelector(state => state);
    const { customThemeColorReducer } = useSelector(state => state)
    useEffect(() => {
        if (openCustomThemeFrom == "customize" && customizeThemeReducer?.custom_theme) {
            const temp_obj = {
                header: JSON.parse(customizeThemeReducer?.custom_theme)?.header?.color ? JSON.parse(customizeThemeReducer?.custom_theme)?.header?.color : JSON.parse(customizeThemeReducer?.custom_theme)?.header,

                background: JSON.parse(customizeThemeReducer?.custom_theme)?.background?.color ? JSON.parse(customizeThemeReducer?.custom_theme)?.background?.color : JSON.parse(customizeThemeReducer?.custom_theme)?.background,

                links: JSON.parse(customizeThemeReducer?.custom_theme)?.links?.color ? JSON.parse(customizeThemeReducer?.custom_theme)?.links?.color : JSON.parse(customizeThemeReducer?.custom_theme)?.links,

                buttons: JSON.parse(customizeThemeReducer?.custom_theme)?.buttons?.color ? JSON.parse(customizeThemeReducer?.custom_theme)?.buttons?.color : JSON.parse(customizeThemeReducer?.custom_theme)?.buttons,

                button_text: JSON.parse(customizeThemeReducer?.custom_theme)?.button_text?.color ? JSON.parse(customizeThemeReducer?.custom_theme)?.button_text?.color : JSON.parse(customizeThemeReducer?.custom_theme)?.button_text,

            };
            dispatch({ type: "SET_CUSTOM_COLOR", payload: temp_obj })
        }
        else if(openCustomThemeFrom == "product" && productModalReducer?.custom_theme){
            const temp_obj = {
                header: JSON.parse(productModalReducer?.custom_theme)?.header?.color ? JSON.parse(productModalReducer?.custom_theme)?.header?.color : JSON.parse(productModalReducer?.custom_theme)?.header,

                background: JSON.parse(productModalReducer?.custom_theme)?.background?.color ? JSON.parse(productModalReducer?.custom_theme)?.background?.color : JSON.parse(productModalReducer?.custom_theme)?.background,

                links: JSON.parse(productModalReducer?.custom_theme)?.links?.color ? JSON.parse(productModalReducer?.custom_theme)?.links?.color : JSON.parse(productModalReducer?.custom_theme)?.links,

                buttons: JSON.parse(productModalReducer?.custom_theme)?.buttons?.color ? JSON.parse(productModalReducer?.custom_theme)?.buttons?.color : JSON.parse(productModalReducer?.custom_theme)?.buttons,

                button_text: JSON.parse(productModalReducer?.custom_theme)?.button_text?.color ? JSON.parse(productModalReducer?.custom_theme)?.button_text?.color : JSON.parse(productModalReducer?.custom_theme)?.button_text,

            };
            dispatch({ type: "SET_CUSTOM_COLOR", payload: temp_obj })
        }
    }, [openCustomThemeFrom, customizeThemeReducer, productModalReducer])

    useEffect(() => {
        $(".text-color-img").click((e) => colorConfig(e.target));
    }, []);

    useEffect(() => {
        colorConfig(null, "header", customThemeColorReducer?.header)
        colorConfig(null, "background", customThemeColorReducer?.background)
        colorConfig(null, "links", customThemeColorReducer?.links)
        colorConfig(null, "buttons", customThemeColorReducer?.buttons)
        colorConfig(null, "button_text", customThemeColorReducer?.button_text)
    }, [customThemeColorReducer]);

    return (
        <div className="d-flex justify-content-between align-items-center">
                <div name="custom_theme_div">
                    <div className="zaap-normal-text font-weight-700 mb-4">
                        <span className="zaap-normal-text font-weight-700 modal-title create-biolink-heading">Custom Theme</span>
                    </div>
                    <div className="notification-container"></div>

                    <input type="hidden" name="request_type" value="custom_theme" />
                    <input type="hidden" name="link_id" value="505308" />


                        <div className="">
                            <div className="">
                                <div className="col-6 pl-0 d-flex align-items-center" id="header-theme">
                                    <span className="zaap-normal-text">Header</span>
                                </div>

                                <SelectedThemeInput data_type={"header"} themeType="header" color={customThemeColorReducer?.header}/>
                                <SelectedCustomTheme nthChild="rect:first-child" themeType="header" color={customThemeColorReducer?.header} />
                            </div>
                            <div className="mt-2">
                                <div className="col-6 pl-0 d-flex align-items-center" id="/-theme">
                                    <span className="zaap-normal-text">Background</span>
                                </div>


                                <SelectedThemeInput data_type={"background"} themeType="background" color={customThemeColorReducer?.background}/>
                                <SelectedCustomTheme nthChild="rect:nth-child(2)" themeType="background" color={customThemeColorReducer?.background} />
                            </div>
                            <div className="mt-2">
                                <div className="col-6 pl-0 d-flex align-items-center">
                                    <span className="zaap-normal-text">Links</span>
                                </div>
                                <SelectedThemeInput data_type={"links"} themeType="links" color={customThemeColorReducer?.links}/>
                                <SelectedCustomTheme nthChild="g path" themeType="links" color={customThemeColorReducer?.links} />
                            </div>
                            <div className="mt-2">
                                <div className="col-6 pl-0 d-flex align-items-center">
                                    <span className="zaap-normal-text">Text</span>
                                </div>
                                <SelectedThemeInput data_type={"buttons"} themeType="buttons" color={customThemeColorReducer?.buttons}/>
                                <SelectedCustomTheme nthChild="path:nth-child(4)" themeType="buttons" color={customThemeColorReducer?.buttons} />
                                {/* <div className="d-flex color-picker-main">
                                    <CustomTheme init_color="#140e39" data_type="first" hex_code="#hex_code2" nthChild="rect:nth-child(2)" />
                                    <input type="text" name="" id={`hex_code2`} defaultValue="#140e39" className="" style={{ borderWidth: "1px" }} />

                                </div> */}
                            </div>
                            <div className="col-8 row m-0 p-0 mt-2 d-none">
                                <div className="col-6 pl-0 d-flex align-items-center">
                                    <strong>Button Text</strong>
                                </div>
                                <SelectedThemeInput data_type={"button_text"} themeType="button_text" />
                                <SelectedCustomTheme nthChild="" themeType="button_text" color={customThemeColorReducer?.button_text} />
                            </div> {/**/}
                        </div>
                        
                        {/* <div class="text-center mt-4 d-flex">

                            <button data-bs-dismiss="modal" class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center" onClick={handleHide}>
                                <b class="me-2">Cancel</b>
                                <small class="badge bg-light text-dark p-1">Esc</small>
                            </button>
                            <button type="button" onClick={() => {
                                openCustomThemeFrom == "product" ? handleSubmitCustomTheme("SET_NEW_PRODUCT_DETAILS", dispatch, productModalReducer) : handleSubmitCustomTheme("SET_CUSTOMIZE_THEME", dispatch, customizeThemeReducer)
                                handleHide()
                            }} name="submit" className="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                <b>Save Theme</b>
                            </button>

                        </div> */}
                </div>
                <div class="text-center">
                    <div class="custom-theme-preview">

                        <svg width="186" height="320" viewBox="0 0 219 378" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="219" height="378" rx="26" style={{ fill: "rgb(123, 94, 251)" }}></rect>
                            <rect y="79" width="219" height="299" rx="26" style={{ fill: "rgb(20, 14, 57)" }}></rect>
                            <g filter="url(#filter0_d_0_225)">
                                <path d="M36 155.5C36 145.835 43.5524 138 52.8689 138H166.131C175.448 138 183 145.835 183 155.5V305.5C183 315.165 175.448 323 166.131 323H52.8689C43.5524 323 36 315.165 36 305.5V155.5Z"></path>
                            </g>
                            <path d="M46.9565 299C46.9565 291.82 52.2707 286 58.8261 286H161.087C167.642 286 172.957 291.82 172.957 299C172.957 306.18 167.642 312 161.087 312H58.8261C52.2707 312 46.9565 306.18 46.9565 299Z" style={{ fill: "rgb(178, 15, 255)" }}></path>
                            <path d="M46.9565 166C46.9565 157.716 53.0883 151 60.6522 151H159.261C166.825 151 172.957 157.716 172.957 166V218C172.957 226.284 166.825 233 159.261 233H60.6522C53.0883 233 46.9565 226.284 46.9565 218V166Z" style={{ fill: "rgb(178, 15, 255)" }}></path>
                            <defs>
                                <filter id="filter0_d_0_225" x="6" y="112" width="207" height="245" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                                    <feOffset dy="4"></feOffset>
                                    <feGaussianBlur stdDeviation="15"></feGaussianBlur>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.02375 0 0 0 0 0.0706167 0 0 0 0 0.316667 0 0 0 0.1 0"></feColorMatrix>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_225"></feBlend>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_225" result="shape"></feBlend>
                                </filter>
                            </defs>
                        </svg>

                    </div>
                </div>
        </div>
    )
}
export default CustomThemeModal