import React from 'react'

const EmptyState = ({from,clickedFunction,firstText,secondText,buttonText}) => {
  return (
    <div className='oval-container'>
        <div class="oval4-u">
          <div class="oval3-u">
              <div class="oval2-u">
                  <div class="oval1-u">
                      <h2 class="oval-h2">{firstText}</h2>
                      <p class="oval-para" dangerouslySetInnerHTML={{ __html: secondText }}></p>
                      <button class="btn-u-primical text-nowrap" onClick={clickedFunction}>{buttonText}</button>
                  </div>
              </div>
          </div>
      </div>
    </div>
  )
};

export default EmptyState;