const INIT_STATE = {
    slug: null, basePath: null, newChange: null
}
const slugReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_SLUG':
            return action.payload 
        case 'RESET_SLUG':
            return INIT_STATE
        default:
            return state
    }

}
export default slugReducer