import { Modal } from "react-bootstrap"
import ModalErrors from "../../../errors/modal-errors"
import tiktokIcon from "../../../../assets/img/tiktok.svg";
import { useEffect, useState } from "react";

const CreateTiktokWidgetModal =
    ({ show, handleHide, returnIcon, handleSubmit, slug, error, tiktokModal, settiktokModal }) => {

        const handleChange = (e) => {
            setValue({ ...value, [e.target.name]: e.target.value })
        };
        const [value, setValue] = useState({
            title: null,
            no_of_tiktoks: null,
            connect: 1
        });

        useEffect(() => {
            setValue({ ...value, title: tiktokModal?.name, no_of_tiktoks: tiktokModal?.details?.no_of_tiktoks })
        }, [tiktokModal])
        const heading = tiktokModal ? "Update your latest TikTok's" : "Sync your latest TikTok's";

        return (
            <Modal centered show={show} onHide={!tiktokModal?.show ? handleHide : () => {
                settiktokModal({
                    ...tiktokModal,
                    show: !tiktokModal?.show,
                })
            }} id="createTiktokWidgetModal">
                <div class="modal-dialog-centered" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e, value, tiktokModal?.show ?  tiktokModal?.id : "tiktok", true, "tiktok" )
                            }}>
                                <div class="notification-container"></div>


                                <div class="form-group">
                                    <img src={tiktokIcon} width="30px" alt="" />
                                    <h4 class="mt-2 mb-1">{heading}</h4>
                                    <p class="text-gray font-weight-500">Keep your page updated with your latest TikTok's.</p>
                                </div>

                                <div class="form-group">
                                    <label><small>Title</small></label>
                                    <input type="text" class={`form-control zaap-form-control`} onChange={handleChange} value={value?.title} name="title" required="required" placeholder="Latest Tiktok's" />
                                </div>

                                <div class="form-group mt-2">
                                    <label><small>Number of TikTok's</small></label>
                                    <input type="number" class={`form-control zaap-form-control`} onChange={handleChange} value={value?.no_of_tiktoks} name="no_of_tiktoks" required="required" placeholder="5" />
                                </div>


                                <div class="text-center mt-4 d-flex">
                                    <button type="button" onClick={!tiktokModal?.show ? handleHide : () => {
                                        settiktokModal({
                                            ...tiktokModal,
                                            show: !tiktokModal?.show,
                                        })
                                    }} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                        <b class="me-2">Cancel</b>
                                        <small class="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                        <b class="me-2">Save Changes</b>
                                        <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal>
        )

    }
export default CreateTiktokWidgetModal