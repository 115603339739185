
import searchIcon from "../../assets/img/search-icon.svg"

const HeaderSearch = ({ filter, setfilter, name }) => {

    const doneTypingInterval = 500;
    let typingTimer;
    const handleTypingKeyUp = (e) => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            handleSearch(e);
        }, doneTypingInterval);
    }

    const handleTypingKeyDown = (e) => clearTimeout(typingTimer);

    const handleSearch = (e) => {
        var queryFilters = { ...filter, [e.target.name]: e.target.value };
        setfilter({ ...queryFilters, page: 1 })
    }

    return (
        <div className="search-bar d-flex align-items-center jusitfy-content-start search-u">
            <img src={searchIcon} width="15px" alt="" />
            <input type="text" name="search" onKeyUp={handleTypingKeyUp} onKeyDown={handleTypingKeyDown} className="form-control zaap-form-control borderless d-md-flex u-input"
                placeholder={`Search ${name}... `} />{/* ${name !== "projects" ? " or email..." : ""}*/}
                {/* by ${name == "orders" ? ' customer name or email' : name == "posts" ? " subject": " name"} ${name == "audience" ? " or email address" : ""} */}
            {/* <input type="text" name="search" onKeyUp={handleTypingKeyUp} onKeyDown={handleTypingKeyDown} className="form-control zaap-form-control borderless d-md-none d-flex"
                placeholder={`Search ${name}`} /> */}
        </div>
    )
}
export default HeaderSearch