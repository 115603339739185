import React, { useEffect, useState } from 'react'
import { hideLoader, showLoader } from '../loader';
import { toast } from 'react-toastify';
import { apiUrl } from '../../utils/url';
import * as $ from "jquery";
import { useAlert } from '../../contexts/alertContext';

function TagStep({ moveNext, moveBack }) {
    const [error, setError] = useState([])
    const [values, setvalues] = useState([])
    const { setAlert } = useAlert();
    useEffect(() => {
        try {
            const tagsString = localStorage?.backStepValue;
            if (tagsString) {
                const tagsArray = JSON.parse(tagsString);
                setvalues([...values, ...tagsArray]);
            }
        } catch { }
    }, [localStorage?.backStepValue]);
    const onSubmit = (e, from, values) => {
        showLoader();
        setError([])
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData();
        if (values.length > 0) {
            formdata.append('tags', values);
        }
        if (from == "skip") {
            for (const key in values) {
                if (Object.hasOwnProperty.call(values, key)) {
                    formdata.append(key, values[key]);
                }
            }
        }else{
            formdata.append('step',3);
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/walkthrough", requestOptions)
            .then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    if (from == "next" || from == "skip") {
                        setAlert({ type: 'success', title: response?.status_message})
                        // toast.success(response?.status_message)
                        moveNext(e, 4)
                    }
                    else if (from == "back") {
                        moveBack(e, response)
                        hideLoader()
                    }
                }
                else {
                    setError(response?.errors)
                    setAlert({ type: 'success', title: response?.status_message})
                    // toast.error(response?.status_message)
                }

            }).catch((err) => {
                hideLoader()
            })

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(e, "next", values)
    }
    function addMore(){
        $('.tag-input-u').val(null);
        $('.input-box-u').toggleClass('input-activer');
    }
    function handleTagInput() {
        var value = $('.tag-input-u').val();
        if(value.trim().length > 0) {
            setvalues([...values,value]);
            $('.input-box-u').toggleClass('input-activer');
        }
      }
    function removeTag(index) {
        setvalues(prevState => {
            const updatedvalues = [...prevState];
            updatedvalues.splice(index, 1);
            return updatedvalues;
        });
    }
      
  return (
    <div className="background-area">
        <div className="steps-card">
            <div className="counter">2</div>
            <h2 className="card-head2">What kind of content do you create?</h2>
            <p className="card-para">Add tags</p>
            <div className="discription-area-box"  >
                <div className="chips_input" data-limit="25">
                    <div className="inner">
                        <div className="tag-append-area">
                        {values.length > 0 && values?.map((val, index) => {
                            return (
                            <span className="chip tag-box-u" >
                                <div className="green-on"></div>
                                <span className="tag-name-u">{val}</span>
                                <button aria-label="remove this chip" className="remove-u" onClick={()=> removeTag(index)}>
                                    <i className="fa-solid fa-xmark"></i>
                                </button>
                            </span>
                            )
                        })}
                        </div>
                        <div className="tag-addinput">
                            <div className="act-inp" onClick={addMore}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none">
                                    <rect x="3" y="0.5" width="1" height="7" rx="0.5" fill="#333333"/>
                                    <rect x="7" y="3.5" width="1" height="7" rx="0.5" transform="rotate(90 7 3.5)" fill="#333333"/>
                                </svg>
                                Add More
                            </div>
                            <div className="input-box-u">
                                <input type="text" id="myinput" name="myinput" className="tag-input-u"  placeholder="Add Tag"/>
                                <button  id="addTagButton" onClick={handleTagInput} className="tagadd-btn">Add</button>
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>
            <div className="bottom-btn-area">
                <button className="red-btn-u" onClick={(e)=>handleSubmit(e)}>
                    Next Step 
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none"><path d="M2.85366 8C3.12287 8 3.30511 7.80467 3.30511 7.53715C3.30511 7.39703 3.25127 7.29512 3.16843 7.21019L2.11643 6.15711L1.39162 5.53291L2.34837 5.57537H7.51726C8.55683 5.57537 9 5.09554 9 4.04246V1.52442C9 0.458599 8.55683 0 7.51726 0H5.21859C4.9411 0 4.74643 0.212314 4.74643 0.475584C4.74643 0.734607 4.9411 0.946921 5.21445 0.946921H7.49655C7.91072 0.946921 8.08882 1.12951 8.08882 1.55414V4.01274C8.08882 4.45011 7.91072 4.62845 7.49655 4.62845H2.34837L1.39162 4.67516L2.11643 4.04671L3.16843 2.99788C3.25127 2.91295 3.30511 2.80679 3.30511 2.66667C3.30511 2.4034 3.12287 2.20807 2.85366 2.20807C2.73769 2.20807 2.6093 2.26327 2.51818 2.35669L0.144961 4.75159C0.0497009 4.84501 0 4.9724 0 5.10403C0 5.23142 0.0497009 5.35881 0.144961 5.45223L2.51818 7.85138C2.6093 7.9448 2.73769 8 2.85366 8Z" fill="white"/></svg></span>
                </button>   
                <button  type="button" name="button" className="gray-btn-u"
                onClick={(e) =>
                        onSubmit(e, "skip", {
                    skip: 1,
                    step: e.target.dataset.step
                })}
                data-step="3">Skip </button>
            </div>
        </div>
    </div>
  )
}

export default TagStep