const INIT_STATE = {lastStep: 0, currentStep: 1}
const walkthroughSteps = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_STEPS':
            return action.payload 
        case 'RESET-STEPS':
            return INIT_STATE
        default:
            return state
    }

}
export default walkthroughSteps