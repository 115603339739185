import { Modal } from "react-bootstrap"
import ModalErrors from "../../../errors/modal-errors"
import istagramIcon from "../../../../assets/img/instagram.svg";
import { useEffect, useState } from "react";

const CreateInstagramWidgetModal =
    ({ show, handleHide, returnIcon, handleSubmit, slug, error, instagramModal, setinstagramModal }) => {

        const [value, setValue] = useState({
            title: null,
            no_of_instagram: null,
            connect: 1
        });

        const handleChange = (e) => {
            setValue({ ...value, [e.target.name]: e.target.value })
        };
        useEffect(() => {
            setValue({ ...value, title: instagramModal?.name, no_of_instagram: instagramModal?.details?.no_of_instagram })
        }, [instagramModal])
        const heading = instagramModal ? "Update your latest Instagram posts" : "Sync Instagram posts";

        return (
            <Modal centered show={show} onHide={!instagramModal?.show ? handleHide : () => {
                setinstagramModal({
                    ...instagramModal,
                    show: !instagramModal?.show,
                })
            }} id="createInstagramWidgetModal">
                <div class="modal-dialog-centered" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e, value, instagramModal?.show ?  instagramModal?.id : "instagram", true, "instagram" )
                            }}>
                                <div class="notification-container"></div>


                                <div class="form-group">
                                    <img src={istagramIcon} width="30px" alt="" />
                                    <h4 class="mt-2 mb-1 new-zaap-head">{heading}</h4>
                                    <p class="new-zaap-para">Keep your page updated with your latest Instagram posts.</p>
                                </div>

                                <div class="form-group">
                                    <label className="new-zaap-labels">Title</label>
                                    <input type="text" class={`form-control zaap-form-control new-zaap-inputs`} onChange={handleChange} value={value?.title} name="title" required="required" placeholder="Eg: Latest Instagram Posts" />
                                </div>

                                <div class="form-group mt-2">
                                    <label className="new-zaap-labels">Number of posts</label>
                                    <input type="number" class={`form-control zaap-form-control new-zaap-inputs`} onChange={handleChange} value={value?.no_of_instagram} name="no_of_instagram" required="required" placeholder="Eg: Latest Instagram Posts" />
                                </div>


                                <div class="text-center mt-4 d-flex">
                                    <button type="button" onClick={!instagramModal?.show ? handleHide : () => {
                                        setinstagramModal({
                                            ...instagramModal,
                                            show: !instagramModal?.show,
                                        })
                                    }} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                        <span class="zaap-cancel-btn">Cancel</span>
                                        <small class="badge bg-light text-dark p-1 ">Esc</small>
                                    </button>
                                    <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                        <span class="me-2 zaap-connect">Connect</span>
                                        <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal>
        )

    }
export default CreateInstagramWidgetModal