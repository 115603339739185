// import "../assets/css/auth.css"
import { useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import viewIcon from "../../assets/images/view-icon.svg";
// import closeEyeIcon from "../../assets/images/close-eye-icon.svg";
import { hideLoader, showLoader } from '../components/loader';
import wrong from "../assets/img/wrong-mark.svg";
import right from "../assets/img/green-tick.svg";
import zaapLogo from "../assets/img/logo.svg"

import {
    isContainNumber,
    isLowerCase,
    isUpperCase,
    isSpecialChar,
} from "../utils/helper";
import { Alert } from "react-bootstrap"

import validateResetPassword from '../validations/reset-password';
import { apiUrl } from '../utils/url';
const ResetPassword = () => {
    const history = useHistory()
    const [values, setvalues] = useState({
        password_confirmation: null, password: null
    });
    const [passwordTC, setPasswordTC] = useState(false);
    const [error, setError] = useState([])
    const [isSixChar, setSixChar] = useState(false);
    const [isOneUpperCase, setOneUpperCase] = useState(false);
    const [isOneNumber, setOneNumber] = useState(false);
    const [isTwentyChar, setTwentyChar] = useState(false);

    const formSubmit = (e) => {
        e.preventDefault();
        showLoader()
        const params = new URLSearchParams(window.location.search);
        setError(null)
        if (params.get("t")) {
            const formdata = new FormData()
            for (const key in values) {
                if (Object.hasOwnProperty.call(values, key)) {
                    formdata.append(key, values[key]);
                }
            }
            fetch(`${apiUrl}/reset-password/${params.get("t")}`, {
                body: formdata,
                method: "POST"
            }).then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    localStorage.setItem("token", params.get("t"));
                    toast.success("Successfully Reset")
                    setTimeout(() => {
                        history.push("/login")
                    }, 3000);
                }
                else {
                    const errorList = [];
                    if (response?.errors?.password) 
                        response?.errors?.password?.map((val) => errorList.push(val))
                    
                    if (response?.errors?.password_confirmation)
                        response?.errors?.password_confirmation?.map((val) => errorList.push(val))

                    setError(errorList)

                    if (response?.status_message) toast.error(response?.status_message);

                }
            }).catch((err) => {
                hideLoader()
                
// console.log(err?.response?.data?.message)
            })
        }
        else{
            hideLoader()
            toast.error("Invalid token")
        }
    };

    const handleChange = (e) => {
        if (e.target.name === "password") {
            if ((e.target.value.length >= 8)
                && (isContainNumber(e.target.value))
                && (isUpperCase(e.target.value))
            ) {
                setPasswordTC(false)
            }
            else {
                setPasswordTC(true)
                if (e.target.value.length >= 8) {
                    setSixChar(true);
                } else if (e.target.value.length < 8) {
                    setSixChar(false);
                }
                if (e.target.value.length >= 20) {
                    setTwentyChar(true);
                } else if (e.target.value.length < 20) {
                    setTwentyChar(false);
                }
                if (isContainNumber(e.target.value)) {
                    setOneNumber(true);
                } else if (!isContainNumber(e.target.value)) {
                    setOneNumber(false);
                }
                if (isUpperCase(e.target.value)) {
                    setOneUpperCase(true);
                } else if (!isUpperCase(e.target.value)) {
                    setOneUpperCase(false);
                }
            }
        }
        setvalues({ ...values, [e.target.name]: e.target.value })

    };

    return (
        <div id="wrapper">
            <nav id="navbar" class="ps-3 pt-2 navbar bavbar-main navbar-expand-lg navbar-light">
                <div class="">
                    <div class="p-lg-0 p-md-0 p-sm-2">
                        <a class="navbar-brand" href="https://zaap.ai/">
                            <img src={zaapLogo}
                                class="img-fluid navbar-logo" alt="Website Logo" />
                        </a>
                    </div>
                </div>
            </nav>
            <div class="page-aside-bg"></div>

            <div class="auth-main">

                <div class="col-xs-11 col-sm-11 col-md-8 col-lg-8 h-100 d-flex align-items-center justify-content-center">
                    <div class="card border-0 p-0 m-0 w-100 h-100">
                        <div class="card-body">
                            <h1 class="page-heading text-left">Reset your Password</h1>

                            <form action="" method="post" class="mt-4" role="form" onSubmit={formSubmit}>
                                    {error?.length > 0 && (
                                        <Alert variant="danger">
                                            {error?.map((val, index) => <li>{val}</li>)}
                                        </Alert>
                                    )}
                                <div class="form-group">
                                    <label class="col-12 p-0 d-flex justify-content-between mt-3 mb-1">
                                        <strong>Reset Password</strong>
                                    </label>

                                    <div class='input'>
                                        <input type="password"
                                            placeholder="Enter a Password" name="password" value={values.password}
                                            onChange={handleChange}
                                            required
                                            onFocus={(e) => {
                                                setPasswordTC(true)
                                                handleChange(e)
                                            }}
                                            onBlur={() => setPasswordTC(false)}
                                            required="required" />

                                        {passwordTC && (
                                            <div className="reset-password-tc-box position-absolute f-ph f-reg rounded shadow-2">
                                                <div className="ptc-title f-l mb-2 f-bol">
                                                    Your password must have:
                                                </div>
                                                <div className="ptc-subtitle">
                                                    {isSixChar ? (
                                                        <img
                                                            src={right}
                                                            alt="wrong"
                                                            className="ptc-img img-fluid"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={wrong}
                                                            alt="wrong"
                                                            className="ptc-img img-fluid"
                                                        />
                                                    )}
                                                    At least 6 characters
                                                </div>
                                                <div className="ptc-subtitle">
                                                    {isTwentyChar ? (
                                                        <img
                                                            src={right}
                                                            alt="wrong"
                                                            className="ptc-img img-fluid"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={wrong}
                                                            alt="wrong"
                                                            className="ptc-img img-fluid"
                                                        />
                                                    )}
                                                    Max 20 Characters
                                                </div>
                                                <div className="ptc-subtitle">
                                                    {isOneUpperCase ? (
                                                        <img
                                                            src={right}
                                                            alt="wrong"
                                                            className="ptc-img img-fluid"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={wrong}
                                                            alt="wrong"
                                                            className="ptc-img img-fluid"
                                                        />
                                                    )}
                                                    At least 1 upper case letter (A .. Z)
                                                </div>
                                                <div className="ptc-subtitle">
                                                    {isOneNumber ? (
                                                        <img
                                                            src={right}
                                                            alt="wrong"
                                                            className="ptc-img img-fluid"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={wrong}
                                                            alt="wrong"
                                                            className="ptc-img img-fluid"
                                                        />
                                                    )}
                                                    At least 1 number (0 .. 9)
                                                </div>
                                                <div className="ptc-subtitle mt-2">
                                                    Avoid Using a password that you use with other websites, that
                                                    might be easy for someone else to guess.
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                </div>

                                <div class="form-group">
                                    <label class="col-12 p-0 d-flex justify-content-between mt-3 mb-1">
                                        <strong>Reset Confirm Password</strong>
                                    </label>
                                    <div class='input'>
                                        <input type="password"
                                            required
                                            placeholder="Enter a Confirm Password" name="password_confirmation" value={values.password_confirmation}
                                            onChange={handleChange}
                                            required="required" />
                                    </div>
                                </div>

                                <div class="form-group mt-4">
                                    <button type="submit" name="submit" class="btn btn-primary btn-block my-1 py-25">
                                        <b>Reset {` `}
                                            <svg width="18" height="12" class="ml-1 align-baseline" viewBox="0 0 24 18"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M14.2517 2.42589C13.711 1.87093 13.711 0.971172 14.2517 0.416217C14.7924 -0.138739 15.6691 -0.138739 16.2098 0.416217L23.5945 7.99516C24.1352 8.55012 24.1352 9.44988 23.5945 10.0048L16.2098 17.5838C15.6691 18.1387 14.7924 18.1387 14.2517 17.5838C13.711 17.0288 13.711 16.1291 14.2517 15.5741L19.2726 10.4211H1.38462C0.619914 10.4211 0 9.78483 0 9C0 8.21517 0.619914 7.57895 1.38462 7.57895H19.2726L14.2517 2.42589Z"
                                                    fill="white" />
                                            </svg>
                                        </b>

                                    </button>
                                </div>

                                <div class="row"></div>
                                <div class="row pt-1 mt-3 border"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ResetPassword