const INIT_STATE = "regular"
const bannerSelected = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_BANNER_SELECTED':
            return action.payload 
        case 'RESET_BANNER_SELECTED':
            return INIT_STATE
        default:
            return state
    }
}
export default bannerSelected