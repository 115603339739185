const INIT_STATE = {
    analytics_id: null,
    tab: "integrations"
}
const integrationGoogleReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_GOOGLE_INTEGRATION':
            return action.payload 
        case 'RESET_GOOGLE_INTEGRATION':
            return INIT_STATE
        default:
            return state
    }
}
export default integrationGoogleReducer