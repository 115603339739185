const INIT_STATE = [];
const audienceTagsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_AUDIENCE_TAGS':
            return action.payload
        case 'RESET_AUDIENCE_TAGS':
            return INIT_STATE
        default:
            return state
    }
}
export default audienceTagsReducer