import { Modal } from "react-bootstrap"
import emailIcon from "../../assets/img/newsletter/newsletter.svg"
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import returnIcon from "../../assets/img/modals/return.svg"
import { apiUrl } from "../../utils/url";
import { useHistory, useParams } from "react-router-dom";
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Checklist from '@editorjs/checklist';
import RawTool from '@editorjs/raw';
import CodeTool from '@editorjs/code';
import Warning from '@editorjs/warning';
import Delimiter from '@editorjs/delimiter';
import Button from 'editorjs-button';
import ImageTool from '@editorjs/image';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';

const EditorJSModal = ({ isOpen, onHide, callNewsletter, edit, setIsEdit, reducer,seteditorjs_template,settemplate_json, editorjs_template,template_json,reducerType, modalHeading, endPoint }) => {
    const isModalOpen = useSelector(state => state?.upgradeModalReducer?.open)
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [editorContent, setEditorContent] = useState([]);
    const [editorTemplate, setEditorTemplate] = useState([]);
    let emailTemplateMarkup = [];
    let editorJSTemplateMarkup = [];
    const dispatch = useDispatch();
    let history = useHistory();
    let ejInstance = useRef();

    
    // useEffect(() => {
        //     setTimeout(() => {
            //         saveEditorchange();
            //     }, 2000)
    // }, [editorContent]);

    const saveEditorContent = (e) => {
        e?.preventDefault();
        seteditorjs_template(editorContent);
        settemplate_json(editorTemplate);
        // dispatch({ type: reducerType, payload: { ...reducer, ['editorjs_template']: editorContent, ['template_json']: editorTemplate } })
        onHide();
    }

    // const saveEditorchange = () => {
    //     alert("Please")
    //     // dispatch({ type: reducerType, payload: { ...reducer, ['editorjs_template']: editorContent, ['template_json']: editorTemplate } })
    //     console.log({...reducer})
    // }

    // const saveEditorchange = (editor, template) => {
    //     alert("Please")
    //     dispatch({ type: reducerType, payload: { ...reducer, ['editorjs_template']: editor, ['template_json']: template } })
    //     // console.log({...reducer})
    // }

    let TEMPLATE_EDIT = {
        time: new Date().getTime(),
        blocks: null
    };

    const initEditor = () => {
        if (editorjs_template?.length > 0) {
            TEMPLATE_EDIT = JSON.parse(editorjs_template);
        }
        else {
            TEMPLATE_EDIT = {
                time: new Date().getTime(),
                blocks: null
            };
        }

        let editorConfig = new EditorJS({
            holder: 'editor',
            onReady: () => {
                ejInstance = editorConfig;
            },
            // autofocus: true,

            tools: {
                header: {
                    class: Header,
                    tunes: ['anyTuneName'],
                    config: {
                        placeholder: 'Enter a heading',
                        levels: [1, 2, 3, 4],
                        defaultLevel: 2,
                    },
                    shortcut: 'CMD+SHIFT+H',
                },
                button: {
                    class: Button,
                    tunes: ['anyTuneName'],
                    shortcut: 'CMD+SHIFT+B',
                },
                paragraph: {
                    class: Paragraph,
                    inlineToolbar: true,
                    tunes: ['anyTuneName'],
                    shortcut: 'CMD+SHIFT+E',
                },
                anyTuneName: {
                    class: AlignmentTuneTool,
                    config: {
                        default: "left",
                        blocks: {
                            header: 'left',
                            list: 'left'
                        }
                    },
                },
                image: {
                    class: ImageTool,
                    config: {
                        endpoints: {
                            byFile: apiUrl + '/ProductFlow/createProduct',
                            // byUrl: apiUrl + '/community/newsletter/create'
                        },
                        field: 'image',
                        types: 'image/*',
                        additionalRequestHeaders: {
                            'authorization': `Bearer ${localStorage?.getItem("token")}`,
                        },
                        additionalRequestData: {
                            'product_content_image': 1
                        }
                    },
                },
                delimiter: {
                    class: Delimiter,
                    shortcut: 'CMD+SHIFT+D',
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'unordered'
                    },
                    shortcut: 'CMD+SHIFT+L',
                },
                raw: RawTool,
                checklist: {
                    class: Checklist,
                    inlineToolbar: true,
                },
                quote: {
                    class: Quote,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+O',
                    config: {
                        quotePlaceholder: 'Enter a quote',
                        captionPlaceholder: 'Quote\'s author',
                    },
                },
                code: CodeTool,
                warning: {
                    class: Warning,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+W',
                    config: {
                        titlePlaceholder: 'Title',
                        messagePlaceholder: 'Message',
                    },
                }
            },
            data: TEMPLATE_EDIT,
            onChange: async () => {
                emailTemplateMarkup = [];
                editorJSTemplateMarkup = [];
                let content = await editorConfig.saver.save();
                for (let i = 0; i < content?.blocks?.length; i++) {
                    const element = content.blocks[i];
                    let key = element.type;
                    let value = element.data.text;
                    if (element.type == 'header') {
                        console.log(element);
                        key = 'h' + element.data.level
                        value = element.data.text + ', align:' + element.tunes.anyTuneName.alignment;
                        console.log("key", key)
                    }
                    else if (element.type == 'paragraph') {
                        key = 'text';
                        value = element;
                    }
                    else if (element.type == 'button') {
                        key = 'button';
                        value = element;
                    }
                    else if (element.type == 'list') {
                        key = 'list';
                        value = element;
                    }
                    else if (element.type == 'delimiter') {
                        key = 'delimiter';
                        value = 'hr';
                        console.log("key", key)
                        console.log("value", value)
                    }
                    else if (element.type == 'image') {
                        console.log(element);
                        key = 'image';
                        value = element.data;
                    }
                    else if (element.type == 'quote') {
                        console.log(element);
                        console.log("Quote");
                        key = 'quote';
                        value = element.data;
                    }
                    else if (element.type == 'checklist') {
                        console.log(element);
                        key = 'checklist';
                        value = element.data;
                    }
                    else if (element.type == 'code') {
                        console.log(element);
                        key = 'code';
                        value = element.data;
                    }
                    else if (element.type == 'raw') {
                        console.log(element);
                        console.log("Raw");
                        key = 'raw';
                        value = element.data;
                    }
                    else if (element.type == 'warning') {
                        console.log(element);
                        key = 'warning';
                        value = element.data;
                    }
                    emailTemplateMarkup[i] = {
                        [key]: value
                    };
                    setEditorContent(JSON.stringify(content));
                    setEditorTemplate(JSON.stringify(emailTemplateMarkup));
                    editorJSTemplateMarkup = content;
                    // saveEditorchange(JSON.stringify(content), JSON.stringify(emailTemplateMarkup));
                }
                // saveEditorchange();
            },
            placeholder: 'Type text or paste a link',
        });
    }

    useEffect(() => {
        setTimeout(() => {
            initEditor();
        }, 2000);

        if (editorjs_template != null && editorjs_template.length>0) {
            setEditorContent(editorjs_template)
            setEditorTemplate(template_json)
        }
    }, []);

    return (
        <>
            <Modal centered id="newBlockModal"
                show={isOpen == null ? isModalOpen : isOpen} backdrop="static" onHide={onHide} size={"lg"} >
                <form onSubmit={saveEditorContent}>
                    <div className=" modal-dialog-centered" role="document">
                        <div className="modal-content ">
                            <div className="modal-body">
                                <div className="form-group">
                                    <img src={emailIcon} style={{ width: "40px" }} alt="" />
                                    <h4 className="mt-2">{modalHeading}</h4>
                                    <p className="editor-modaltext">Add blocks, or just start typing.</p>
                                    <div class="form-group mt-3 mb-3">
                                        <div class="editor-template-body" id="editor">
                                        </div>
                                    </div>
                                    <button type="button" class="close btn btn-grey btn-semi-rounded me-2" onClick={onHide}>
                                        <b class="me-2">Cancel</b>
                                        <small class="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="submit" class="btn btn-primary btn-semi-rounded">
                                        <b class="me-2">Save Editor</b>
                                        <small class="badge bg-primary-dark text-dark ">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}
export default EditorJSModal