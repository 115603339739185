import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loader, { showLoader } from "../components/loader";
import { apiUrl } from "../utils/url";

const TwitterCallback = () => {
    const history = useHistory();

    const sendRequest = (values) => {

        const formData = new FormData();

        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                formData.append(key, values[key]);
            }
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };
        fetch(apiUrl + `/project/block/${values.end_point ? "update" : "create"}`, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    toast.success(response?.status_message);
                } else {
                    toast.error(response?.status_message);
                }
                history.push(localStorage.last_activity)
            })
            .catch((err) => {
                toast.error(err);
                history.push(localStorage.last_activity)
            })
    }

    useEffect(() => {
        showLoader()
        const params = new URLSearchParams(window.location.search);

        const twitter_values = {
            ...JSON.parse(localStorage?.twitter_values),
            oauth_token: params.getAll("oauth_token").toString(),
            oauth_verifier: params.getAll("oauth_verifier").toString(),
            callback: 1
        }
        delete twitter_values["connect"]
        localStorage.removeItem("twitter_values");
        sendRequest(twitter_values)

    }, [])
    return <Loader />
}
export default TwitterCallback