import threeBoxCard from "../../../../assets/img/products/three-box-card.svg"
import horizontailCard from "../../../../assets/img/products/horizontail-card.svg"
import verticalCard from "../../../../assets/img/products/vertical-card.svg"
import faqsCard from "../../../../assets/img/products/faqs-card.svg"
import reviewsCard from "../../../../assets/img/products/reviews-card.svg"
import pricingCard from "../../../../assets/img/products/pricing-card.svg"
import { Route, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import ThreeBoxPage from "./product-section/three-box-page"
import { useState } from "react"
import { useSelector } from "react-redux"
const SectionPage = ({edit, product_id, setShowSectionChildModal, setShow}) => {
    const history = useHistory()
    const [showBoxModal, setShowBoxModal] = useState(false);
  const { basePath, slug } = useSelector((state) => state?.slugReducer);
    const showModal = (paramsName) => {
        setShowSectionChildModal({type:paramsName});
        setShow({show:false,from:null})
        // setTimeout(() => {
        //     history.push({
        //         pathname: `${basePath}/editProduct/${paramsName}`,
        //         state: { product_id: product_id }
        //     })
        // }, 200);
    }
    return (
        <div class="tab-pane fade customize-page-main active show p-0" id="customizePageSocials" role="tabpanel" aria-labelledby="social-tab">
            <h4 class="productHead1 m-0 p20-u">All Sections</h4>
                <div className="card-container h-auto">
                    <div className="section-cards" onClick={()=>showModal('threeBox')}>
                        <img src={threeBoxCard} alt="" />
                    </div>
                    <div className="section-cards" onClick={()=>showModal('horizontal')}>
                        <img src={horizontailCard} alt="" />
                    </div>
                    <div className="section-cards" onClick={()=>showModal('vertical')}>
                        <img src={verticalCard} alt="" />
                    </div>
                    <div className="section-cards" onClick={()=>showModal('faq')}>
                        <img src={faqsCard } alt="" />
                    </div>
                    <div className="section-cards" onClick={()=>showModal('reviews')}>
                        <img src={reviewsCard} alt="" />
                    </div>
                    <div className="section-cards" onClick={()=>showModal('pricing')}>
                        <img src={pricingCard} alt="" />
                    </div>
                </div>
                {/* {showBoxModal && <Route path={basePath + "/editProduct/:createThreeBoxSection"} children={() => <ThreeBoxPage showBoxModal={showBoxModal} setShowBoxModal={setShowBoxModal}/>}/>} */}
        </div >
    )
}
export default SectionPage