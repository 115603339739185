import React from "react";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import productType from "../../assets/img/product-type-icon.svg";
import imageIcon from "../../assets/img/image-icon.svg";
import pricingIcon from "../../assets/img/pricing.svg";
import tickIconPrimary from "../../assets/img/tick-icon-primary.svg";
import descriptionIcon from "../../assets/img/description.svg";
import infoIcon from "../../assets/img/info.svg";
import customTheme from "../../assets/img/custom-theme.png";
import filesIcon from "../../assets/img/files.svg";
import returnIcon from "../../assets/img/modals/return.svg";
import trashIcon from "../../assets/img/trash.svg";
import * as $ from "jquery";
import { Link, useHistory } from "react-router-dom";
import { Alert, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import CroppieModal from "../croppie-modal";
import { hideLoader, showLoader } from "../loader";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../utils/url";
import ErrorMessage from "../errors/errorMessage";
import ModalErrors from "../errors/modal-errors";
import FormFieldError from "../errors/formFieldError";
import CustomThemeModal from "../custom-theme/custom-theme-modal";
import { audiencePosts } from "../../services/postRequests/audience";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import ProductMain from "./product-main";
const ProductTypeModal = (props) => {
  const { isOpen, onHide } = props;
  const history = useHistory();
  const [showProductType, setShowProductType] = useState(false);
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const isModalOpen = useSelector((state) => state?.upgradeModalReducer?.open);
  const onClose = () => onHide();
  const { basePath, slug } = useSelector((state) => state?.slugReducer);

  const showModal = (checkout) => {
    setShowCreateProduct(true)
    history.push({
        pathname: `${basePath}/products/addProduct`,
        state: { show: true,[checkout]: true}
    })
  }
  return (
    <Modal centered id="createFolderModal" show={isOpen} onHide={onClose}>
      <div className="modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <form name="product_type" method="post" role="form">
              <div className="notification-container"></div>
              <div className="form-group">
                <img src={productType} width="40px" alt="" className="mb-2" />
                <h4 className="font-weight-800 mb-1 mt-2">What are you selling?</h4>
                <p className="text-gray font-weight-500 mb-4">
                  Select which type of product you’re selling.
                </p>
              </div>
              {/* `${basePath}/createProducts` */}
              <div className="d-flex justify-content-bwtween" style={{gap:"15px"}}>
                <div className="w-50">
                  {/* <Link
                    to={{
                      pathname: `${basePath}/createProducts`,
                      state: {
                        checkout1: true,
                      },
                    }}
                    name="asdasd"
                  > */}
                    <img
                      src="https://imagedelivery.net/tqC70bVt8T6GtQUXNsa2-g/3663509e-63f2-4f0b-daae-fedf990c0900/public"
                      alt=""
                      className="w-100 product-type-img"
                      onClick={()=>showModal('checkout1')}
                    />
                  {/* </Link> */}
                </div>
                <div className="w-50">
                  {/* <Link
                    to={{
                      pathname: `${basePath}/createProducts`,
                      state: {
                        checkout2: true,
                      },
                    }}
                  > */}
                    <img onClick={()=>showModal('checkout2')} className="w-100 product-type-img" src="https://imagedelivery.net/tqC70bVt8T6GtQUXNsa2-g/98cd6a99-1951-489b-00e3-0660d3d5d800/public" alt=""  />
                  {/* </Link> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showCreateProduct && <Route path={basePath + "/products/:addProduct"} children={() => <ProductMain from={"productType"} closeProductType={onClose}/>}
            />}
    </Modal>
  );
};
export default ProductTypeModal;
